import axios from '../axios/axios';

const getAll = () => {
  return axios.get("/proprietaire");
};
const get = id => {
  return axios.get(`/proprietaire/${id}`);
};

const getLotissement = id => {
  return axios.get(`/proprio_loti/${id}`);
};

const create = data => {
  return axios.post("/proprietaire", data);
};
// const update = (id, data) => {
//   return axios.put(`/proprietaire/${id}`, data);
// };

const update = (id, data) => {
  return axios.post(`/proprio_update/${id}`, data);
};

const remove = id => {
  return axios.delete(`/proprietaire/${id}`);
};
const removeAll = () => {
  return axios.delete(`/proprietaire`);
};

const Nbre_proprietaire = () => {
  return axios.get(`/list_proriot`);
};

const liste_district = () => {
  return axios.get(`/district `);
};

const liste_ville = id => {
  return axios.get(`/ville/${id}`);
};
const ProprietaireCRUD = {
  getAll,
  get,
  getLotissement,
  create,
  update,
  remove,
  removeAll,
  Nbre_proprietaire,
  liste_district,
  liste_ville,
};
export default ProprietaireCRUD;