import axios from '../axios/axios';

const getAll = () => {
  return axios.get("/acquereur");
};
const get = id => {
  return axios.get(`/acquereur/${id}`);
};
const create = data => {
  return axios.post("/acquereur", data);
};
const update = (id, data) => {
  return axios.post(`/acquereur_update/${id}`, data);
};
const remove = id => {
  return axios.delete(`/acquereur/${id}`);
};
const removeAll = () => {
  return axios.delete(`/acquereur`);
};
const verification = code => {
  return axios.get(`/operation_client/${code}`);
};

const nbreClient = () => {
  return axios.get(`/clients`);
};

const ClientDirect = () => {
  return axios.get(`/get_client_direct`);
};


const ClientDirectSoldZero = () => {
  return axios.get(`/Acquereur_Sold_Zero`);
};


const VenteClientDirect = code => {
  return axios.get(`/vente_acquereur/${code}`);
};


const LotsClientDirect = code => {
  return axios.get(`/show_lot_vendu/${code}`);
};

const CompteAcquereurCRUD = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  verification,
  nbreClient,
  ClientDirect,
  ClientDirectSoldZero,
  VenteClientDirect,
  LotsClientDirect,
};
export default CompteAcquereurCRUD;