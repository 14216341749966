import {Link } from 'react-router-dom'
function Page404() {

const LinkDash = () => {
        window.location.replace('/Dash');
    }

   return (
   	 <div className="authincation h-100">
        <div className="container h-100">
            <div className="row justify-content-center h-100 align-items-center">
                <div className="col-md-12 mt-5">
                    <div className="form-input-content text-center error-page">
                        <h1 className="error-text font-weight-bold mb-4">404</h1>
                        <h4 ><i className="fas fa-exclamation-triangle text-warning mb-4"></i> La page que vous cherchiez est introuvable!</h4>
                        <p className="mb-4">Vous avez peut-être mal tapé l'adresse ou la page a peut-être été déplacée.</p>
						<div>
                            <Link className="btn btn-primary" to="/Dash" onClick={()=> LinkDash()}>Dashboard</Link>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Page404;