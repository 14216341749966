import axios from '../axios/axios';

const getAll = () => {
  return axios.get("/lotissement");
};
const get = id => {
  return axios.get(`/lotissement/${id}`);
};
const create = data => {
  return axios.post("/lotissement", data);
};
// const update = (id, data) => {
//   return axios.put(`/lotissement/${id}`, data);
// };

const update = (id, data) => {
  return axios.post(`/lotissement_update/${id}`, data);
};


const remove = id => {
  return axios.delete(`/lotissement/${id}`);
};
const removeAll = () => {
  return axios.delete(`/lotissement`);
};

const nbrEncour = () => {
  return axios.get(`/lotissement_en_cours`);
};

const nbrProjet = () => {
  return axios.get(`/lotissement_en_projet`);
};


const villes = () => {
  return axios.get(`/ville `);
};

const EtatGetall = code => {
  return axios.get(`/etat_lotissement`);
};

const LotissementCRUD = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  nbrEncour,
  nbrProjet,
  villes,
  EtatGetall
};
export default LotissementCRUD;