import Navbar from '../components/Navbar';
import Siderbar from '../components/Siderbar';
import '../index.css';

import React, { useEffect, useState  } from 'react';
import { useParams} from 'react-router-dom';
import ProprietaireCRUD from '../services/ProprietaireCRUD';

import {Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const  ProprietaireFormUpdate = props => {

    const { id }= useParams()


const [data, setData] = useState({
     nom : "",
    prenom : "",
    sexe : "",
    email : "",
    telephone : "",
    numero_piece : "",
    piece : "", 
    district : "",
    departement : "",
    commune : "",
    ville : "",
    village : ""
});

useEffect(() => {
    if (id)
      getProp(id);
  getListDistrict();
  }, [id]);
    


const [district, setDistrict] = useState([])

function getListDistrict() {
      ProprietaireCRUD.liste_district()
      .then((response) => {
      
           setDistrict(response.data)
        
          console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };


function getProp(id){

     ProprietaireCRUD.get(id)
      .then(response => {
        console.log(response.data);
        setData(response.data[0]);
      })
      .catch(e => {
        console.log(e);
      });
}

function handle(e){
     const { name, value } = e.target;
    //const newdata={...data, [name]: value }
    //newdata[e.target.id] = e.target.value
    setData({ ...data, [name]: value })
     console.log(e)
}

const [villes, setVilles] = useState([])

function handleDistrict(e){
     const { name, value } = e.target;
    setData({ ...data, [name]: value })
     console.log(e)

      ProprietaireCRUD.liste_ville(e.target.value)
      .then((response) => {
         
            setVilles(response.data)
          
          console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
}





const [selectedFile, setSelectedFile] = React.useState([]);

const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0])
    console.log(event.target.files[0])
  }


function updateProprietaire(e, id){

      e.preventDefault();
       const formData = new FormData();

       formData.append("piece", selectedFile);
        formData.append('nom',data.nom)
        formData.append('prenom',data.prenom)
        formData.append('sexe',data.sexe)
        formData.append('email',data.email)
        formData.append('telephone',data.telephone)
        formData.append('numero_piece',data.numero_piece)
        formData.append('district',data.district)
        formData.append('departement',data.departement)
        formData.append('commune',data.commune)
        formData.append('ville',data.ville)
        formData.append('village',data.village)
     // console.log(data.nom)
     // console.log(data.prenom)
     // console.log(data.sexe)

    ProprietaireCRUD.update(id, formData)
      .then(response => {
        console.log(response.data);

          Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 5000
                    })

         setTimeout(() => {
            
                window.location.replace('/ProprietaireForm');
            }, 5000);
         
      })
      .catch(e => {
        console.log(e);
      });
}




const LinkProprietaire = () => {
        window.location.replace('/ProprietaireForm');
    }
    return (

<div className="">
        <Navbar />
        <Siderbar />

        <div className="content-body">
          <div className="container-fluid">

       

              <div className="page-titles">
                  <ol className="breadcrumb">
                    <h1>Modification des informations du <b style={{color:"red"}}>Propriétaire Térrien</b></h1>
                  </ol>
                </div>


                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Propriétaire Térrien</h4>
                            </div>
                            <div className="card-body">
                           
                            
                                             

                                <form onSubmit={(e)=>updateProprietaire(e, data.id_proprietaire)} encType="multipart/form-data" id="imageForm">
                                      
                                <div className="basic-form" key={data.id_proprietaire}>
                                        <div className="row">
                                            <div className="mb-3 col-md-3">
                                                <label className="form-label">Nom</label>
                                                <input 
                                                type="text" 
                                                className="form-control" 
                                                 required
                                                defaultValue={data.nom_prop}
                                                
                                                onChange={(e)=>handle(e)}
                                                name="nom"
                                                placeholder="1234 Main St" 
                                                />
                                               
                                            </div>
                                            <div className="mb-3 col-md-4">
                                                <label className="form-label">Prenom</label>
                                                <input 
                                                type="text" 
                                                className="form-control" 
                                                defaultValue={data.prenom_prop}
                                                onChange={(e)=>handle(e)}
                                                name="prenom"
                                                placeholder="1234 Main St" 
                                                />
                                            </div>
                                            <div className="mb-3 col-md-2">
                                                <label className="form-label">Sexe</label>
                                              <select className="form-control wide" onChange={(e)=>handle(e)} name="sexe">
                                                <option defaultValue={data.sexe_prop}>{data.sexe_prop}</option>
                                                <option defaultValue="Homme">Homme</option>
                                                <option defaultValue="Femme">Femme</option>
                                            </select>

                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label className="form-label">Email</label>
                                                <input 
                                                type="email" 
                                                className="form-control" 
                                                placeholder="Email" 
                                                defaultValue={data.email_prop}
                                                onChange={(e)=>handle(e)}
                                                name="email"
                                                />
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label className="form-label">N° téléphone</label>
                                                <input 
                                                type="text" 
                                                className="form-control" 
                                                defaultValue={data.telephone_prop}
                                                onChange={(e)=>handle(e)}
                                                name="telephone"
                                                placeholder="N° téléphone" 
                                                />
                                            </div>
                                            <div className="mb-3 col-md-4">
                                                <label className="form-label">Le N° de la pièce d’identité ou l’attestation</label>
                                                <input 
                                                type="text" 
                                                className="form-control"
                                                defaultValue={data.nume_piece_prop}
                                                onChange={(e)=>handle(e)}
                                                name="numero_piece"
                                                placeholder="Ex: CNI00120120010..." />
                                            </div>

                                            <div className="mb-3 col-md-4">
                                                <label className="form-label">Importer la pièce scanée <code>PDF</code></label>
                                                <input type="file" name="piece"  onChange={handleFileSelect} className="form-control" />
                                            </div>
                                            <div className="mb-3 mt-4 col-md-1">
                                                 <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/"+ data.piece_prop} target={'_blank'} rel="noreferrer"> 
                                                            <i className="fa fa-download fa-2x"></i>
                                                        </a>
                                        </div>
                                        </div>
                                        <div className="row">
                                           
                                            

                                            <div className="mb-3 col-md-3">
                                                <label className="form-label">Le District</label>
                                              <select className="form-control wide"  onChange={(e)=>handleDistrict(e)} name="district">
                                                <option defaultValue={data.district_prop}>{data.nom_district}</option>
                                                {district.map((rows) => (
                                                <option key={rows.id_district} value={rows.id_district}>{rows.nom_district}</option>
                                                  ))}
                                            </select>
                                        </div>

                                        <div className="mb-3 col-md-3">
                                                <label className="form-label">La Ville</label>
                                              <select className="form-control wide"  onChange={(e)=>handle(e)} name="ville">
                                                <option defaultValue={data.ville_prop}>{data.ville_prop}</option>
                                               {villes.map((rowss) => (
                                                <option key={rowss.id_ville} value={rowss.nom_ville}>{rowss.nom_ville}</option>
                                                  ))}
                                            </select>
                                        </div>

                                         <div className="mb-3 col-md-3">
                                                <label className="form-label">Le Département </label>
                                             
                                             <input 
                                                type="text" 
                                                className="form-control" 
                                                id="Input"  
                                                name="departement" 
                                                defaultValue={data.departement_prop}
                                               onChange={(e)=>handle(e)}
                                                placeholder="Département..." />
                                        </div>

                                         <div className="mb-3 col-md-3">
                                                <label className="form-label">La Commune </label>
                                            
                                             <input 
                                                type="text" 
                                                className="form-control" 
                                                id="Input"  
                                                name="commune" 
                                               defaultValue={data.commune_prop}
                                                 onChange={(e)=>handle(e)} 
                                                placeholder="Commune..." />
                                        </div>

                                         <div className="mb-3 col-md-3">
                                                <label className="form-label">Le Village </label>
                                                <input 
                                                type="text" 
                                                className="form-control"  
                                                name="village" 
                                                defaultValue={data.village_prop} 
                                                onChange={(e)=>handle(e)} 
                                                placeholder="Village..." />
                                              
                                        </div>

                                       </div>

                                        
                                        <Link to={"/ProprietaireForm"} onClick={()=> LinkProprietaire()}><button type="submit" style={{float:'left'}}  className="btn btn-danger mt-5">Retour</button></Link>
                                        <button type="submit"  className="btn btn-warning mt-5">Modifier</button>
                                       
                                

                                </div>


                                   </form>     
                                      
                                
                            </div>
                        </div>
                    </div>
                </div>

 










          </div>
        </div>
  </div>

    );

}




export default ProprietaireFormUpdate;
