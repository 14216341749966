// import React from 'react';

export default function PrintStyle() {

    const stylesBorder = {
        'textAlign': 'center',
        'border': '1px solid #000',
    }

    const stylesBorder1 = {
        'border': '1px solid #000',
    }

    const styleHeade = {
        'borderCollapse': 'collapse'
    }

    const bodyBorder = {
        'borderTop': '1px solid #000',
        'borderLeft': '1px solid #000',
        'borderRight': '1px solid #000',
    }

    const bodyBorderEnd = {
        'borderTop': '1px solid #000',
        'borderLeft': '1px solid #000',
        'borderRight': '1px solid #000',
        'borderBottom': '1px solid #000',
    }

    const smallBody = {
        'border': '1px solid #000',
        // 'textAlign': 'right'
    }

    const EndBorder = {
        'borderTop': '1px solid #000',
    }

    const textStylesFooter = {
        'textAlign': 'end',
        'fontSize': 14,
        'marginTop': 50,
    }

    const textStylesFooter1 = {
        'textAlign': 'start',
        'fontSize': 14,
        'lineHeight': 2,
        'marginTop': 50,
    }

    const textEndPosition = {
        'textAlign': 'end',
        'fontSize': 14,
        'marginTop': 40
    }

    const spaceSubHeader = {
        'marginTop': -30
    }

    const textFooter = {
        'fontSize': 9,
        'textAlign': 'center',
        'marginTop': 250,
    }

    return {
        stylesBorder,
        stylesBorder1,
        styleHeade,
        bodyBorder,
        bodyBorderEnd,
        smallBody,
        EndBorder,
        textStylesFooter,
        textStylesFooter1,
        textEndPosition,
        spaceSubHeader,
        textFooter,
    }
}
