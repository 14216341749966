import axios from '../axios/axios';

const getAll = () => {
  return axios.get("/infosadmin");
};
const get = id => {
  return axios.get(`/infosadmin/${id}`);
};
const create = data => {
  return axios.post("/infosadmin", data);
};
const update = (id, data) => {
  return axios.put(`/infosadmin/${id}`, data);
};
const remove = id => {
  return axios.delete(`/infosadmin/${id}`);
};
const removeAll = () => {
  return axios.delete(`/infosadmin`);
};
const findByTitle = title => {
  return axios.get(`/infosadmin?title=${title}`);
};
const InformationAdministrativeFormCRUD = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle
};
export default InformationAdministrativeFormCRUD;