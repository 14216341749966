import React, { useEffect, useState } from 'react';
//import axios from 'axios';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Siderbar from '../components/Siderbar';
import '../index.css';

import { useParams } from 'react-router-dom';

// import DataTable from 'react-data-table-component';
// import DataTableExtensions from 'react-data-table-component-extensions';
// import 'react-data-table-component-extensions/dist/index.css';
// import CompteAcquereurCRUD from '../services/CompteAcquereurCRUD';
import ContratAcquereurCRUD from '../services/ContratAcquereurCRUD';

import Swal from 'sweetalert2';

import { Form, FloatingLabel } from 'react-bootstrap';

const ContratAcquereurForm = () => {

  const { id } = useParams()
  // console.log(id);

  const [detailAcquereur, setDetailAcquereur] = useState([]);

  useEffect(() => {
    if (id)
      DetailAcquereur(id)
  }, [id])

  const DetailAcquereur = (id) => {


    ContratAcquereurCRUD.get(id)
      .then((res) => {

        setDetailAcquereur(res.data);

        console.log(res.data);

      })
      .catch((error) => {
        console.error(error);
      });
  }





  const [dataContrat, setDataContrat] = useState({
    source: "",
    joint: "",
    nom_apporteur: "",
    visite_effectue: "",
    projet: "",
    date_visite: "",
    commentaire: "",
  });

  function HandleChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    const data = { ...dataContrat, [name]: value }
    setDataContrat(data)
    console.log(data)


  }

  function HandleChangeFile(e) {
    const name = e.target.name;
    const value = e.target.files[0];
    const data = { ...dataContrat, [name]: value }
    setDataContrat(data)
    console.log(data)

  }

  const [show, setShow] = useState(false)

  function HandleChangeVisite(e) {
    const name = e.target.name;
    const value = e.target.value;

    const data = { ...dataContrat, [name]: value }
    setDataContrat(data)
    console.log(data)

    if (value === 'OUI') {
      setShow((s) => !s)
    } else {
      setShow(false)
    }

  }


  const [showSource, setShowSource] = useState(false)

  function HandleChangeSource(e) {
    const name = e.target.name;
    const value = e.target.value;

    const data = { ...dataContrat, [name]: value }
    setDataContrat(data)
    console.log(data)

    if (value === "Apporteur d'affaire") {
      setShowSource((s) => !s)
    } else {
      setShowSource(false)
    }

  }




  function handleSubmit(event) {
    event.preventDefault();



    const bodyFormData = new FormData();

    bodyFormData.append('code', id);
    bodyFormData.append('source', dataContrat.source);
    bodyFormData.append('ficher_contrat', dataContrat.joint);
    bodyFormData.append('apporteur', dataContrat.nom_apporteur);
    bodyFormData.append('visite', dataContrat.visite_effectue);
    bodyFormData.append('projet', dataContrat.projet);
    bodyFormData.append('date_visite', dataContrat.date_visite);
    bodyFormData.append('commentaire', dataContrat.commentaire);






    ContratAcquereurCRUD.update_contrat(bodyFormData)
      .then((response) => {


        console.log(response.data);

        setDataContrat('')
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: response.data.message,
          showConfirmButton: false,
          timer: 5000
        })

        // setTimeout(() => {
        //       window.location.replace('/ContratAcquereurUpdateForm/'+id)
        //  }, 5000);

      })
      .catch((error) => {
        console.error(error.response);
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: error.response.data.message,
          showConfirmButton: false,
          timer: 5000
        })
      });


  }

  const LinkCompteAcquereur = () => {
    window.location.replace('/CompteAcquereurForm');
  }

  return (
    <div className="">
      <Navbar />
      <Siderbar />

      {detailAcquereur.map((res) => (
        <div className="content-body" key={res.id_acq}>
          <div className="container-fluid">


            <>
              <div className="page-titles" >
                <ol className="breadcrumb">
                  {(() => {

                    if (res.type_acq === 'Direct') {

                      return (
                        <h1><b style={{ color: "red" }}>Contrat Acquéreur</b></h1>
                      )
                    } else {
                      return (
                        <h1><b style={{ color: "red" }}>Visite Prospect</b></h1>
                      )
                    }
                  })
                    ()}
                </ol>
              </div>






              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="card">
                    <form onSubmit={(e) => handleSubmit(e)} id="formContrat">
                      <div className="card-header" >

                        <h4 className="card-title">Client <b>({res.type_acq})</b>, &nbsp;&nbsp; {res.nom_acq} {res.prenom_acq}, {res.sexe_acq}, {res.profession_acq} </h4>
                      </div>
                      <div className="card-body">

                        {(() => {
                          if (res.type_acq === 'Direct') {
                            return (


                              <>

                                <div className="row mb-5">
                                  <h2 className="mb-4">Contrat d’acquéreur</h2>
                                  <div className="form-group col-md-4 mb-3">

                                    <label className="mb-3">Source de provenance</label>
                                    <select className="form-control wide" name="source" onChange={(e) => HandleChangeSource(e)}>
                                      <option defaultValue={res.source}>{res.source}</option>
                                      <option defaultValue="Direct">Direct</option>
                                      <option defaultValue="Apporteur d'affaire">Apporteur d'affaire</option>
                                    </select>
                                  </div>
                                </div>

                                <hr className="mb-4" />

                                <div className="row mb-5">
                                  <h2 className="mb-4">Contrat de réservation</h2>
                                  <div className="form-group col-md-4 mb-4">

                                    <label className="mb-3">Joindre le ficher du contrat</label>
                                    <input type="file" name="joint" onChange={(e) => HandleChangeFile(e)} className="form-control" />
                                    <p>

                                      {(() => {
                                        if (res.ficher_contrat) {
                                          return (
                                            <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/" + res.ficher_contrat} target={'_blank'} rel="noreferrer">
                                              <i className="fa fa-download fa-2x"></i>
                                            </a>
                                          )

                                        } else {
                                          return (
                                            <b style={{ color: 'red' }}>Aucun</b>
                                          )
                                        }
                                      })
                                        ()}
                                    </p>
                                  </div>
                                  {(() => {
                                    if (res.source === "Apporteur d'affaire") {
                                      return (
                                        <div className="form-group col-md-4 mb-4">

                                          <label className="mb-3">Nom de l’apporteur</label>
                                          <input type="text" name="nom_apporteur" onChange={(e) => HandleChange(e)} defaultValue={res.nom_apporteur} className="form-control" placeholder="Apporteur..." />
                                        </div>

                                      )

                                    } else {
                                      return (
                                        <div className="form-group col-md-4 mb-4" style={{ visibility: showSource ? "visible" : "hidden" }}>

                                          <label className="mb-3">Nom de l’apporteur</label>
                                          <input type="text" name="nom_apporteur" onChange={(e) => HandleChange(e)} className="form-control" placeholder="Apporteur..." />
                                        </div>

                                      )
                                    }
                                  })
                                    ()}

                                </div>


                                <hr className="mb-4" />

                                <div className="row mb-5">
                                  <h2 className="mb-5">Type projet intéressé</h2>
                                  <div className="form-group col-md-4 mb-3">

                                    <label className="mb-3">Projets</label>
                                    <select className="form-control wide" onChange={(e) => HandleChange(e)} name="projet">
                                      <option defaultValue={res.type_proj}>{res.type_proj}</option>
                                      <option defaultValue="Promotion immobilier">Promotion immobilier</option>
                                      <option defaultValue="Lotissement">Lotissement</option>
                                    </select>
                                  </div>
                                </div>



                                <hr className="mb-4" />

                                <div className="row mb-5">
                                  <h2 className="mb-5">Action et visite</h2>
                                  <div className="form-group col-md-3 mb-3">

                                    <label className="mb-3">Visite Effectuée</label>
                                    <select className="form-control wide" onChange={(e) => HandleChangeVisite(e)} name="visite_effectue">
                                      <option defaultValue={res.visite}>{res.visite}</option>
                                      <option defaultValue="OUI">OUI</option>
                                      <option defaultValue="NON">NON</option>
                                    </select>
                                  </div>


                                  {(() => {
                                    if (res.visite === "OUI") {
                                      return (

                                        <div className="col-lg-9">

                                          <div className="row">
                                            <div className="form-group col-md-4 mb-4">

                                              <label className="mb-3">Date de la visite</label>
                                              <input type="date" name="date_visite" defaultValue={res.date_visite} onChange={(e) => HandleChange(e)} className="form-control" />
                                            </div>

                                            <div className="form-group col-md-8 mb-4">

                                              <label className="mb-2">Commentaire</label>

                                              <FloatingLabel controlId="floatingTextarea2">
                                                <Form.Control
                                                  as="textarea"
                                                  placeholder="Leave a comment here"
                                                  style={{ height: '250px' }}
                                                  name="commentaire"
                                                  defaultValue={res.commentaire}
                                                  onChange={(e) => HandleChange(e)}
                                                />
                                              </FloatingLabel>
                                            </div>

                                          </div>
                                        </div>

                                      )

                                    } else {
                                      return (

                                        <div className="col-lg-9" style={{ visibility: show ? "visible" : "hidden" }}>

                                          <div className="row">
                                            <div className="form-group col-md-4 mb-4">

                                              <label className="mb-3">Date de la visite</label>
                                              <input type="date" name="date_visite" defaultValue={res.date_visite} onChange={(e) => HandleChange(e)} className="form-control" />
                                            </div>

                                            <div className="form-group col-md-8 mb-4">

                                              <label className="mb-2">Commentaire</label>

                                              <FloatingLabel controlId="floatingTextarea2" label="commentaire laissé par le visiteur">
                                                <Form.Control
                                                  as="textarea"
                                                  placeholder="Leave a comment here"
                                                  style={{ height: '250px' }}
                                                  name="commentaire"
                                                  defaultValue={res.commentaire}
                                                  onChange={(e) => HandleChange(e)}
                                                />
                                              </FloatingLabel>
                                            </div>

                                          </div>
                                        </div>


                                      )
                                    }
                                  })
                                    ()}




                                </div>

                              </>


                            )
                          } else {

                            return (

                              <>
                                <div className="row mb-5">
                                  <h2 className="mb-5">Action et visite</h2>
                                  <div className="form-group col-md-3 mb-3">

                                    <label className="mb-3">Visite effectuer</label>
                                    <select className="form-control wide" onChange={(e) => HandleChangeVisite(e)} name="visite_effectue">
                                      <option defaultValue={res.visite}>{res.visite}</option>
                                      <option value="OUI">OUI</option>
                                      <option value="NON">NON</option>
                                    </select>
                                  </div>

                                  {(() => {
                                    if (res.visite === "OUI") {
                                      return (

                                        <div className="col-lg-9">

                                          <div className="row">
                                            <div className="form-group col-md-4 mb-4">

                                              <label className="mb-3">Date de la visite</label>
                                              <input type="date" name="date_visite" defaultValue={res.date_visite} onChange={(e) => HandleChange(e)} className="form-control" />
                                            </div>

                                            <div className="form-group col-md-8 mb-4">

                                              <label className="mb-2">Commentaire</label>

                                              <FloatingLabel controlId="floatingTextarea2" label="commentaire laissé par le visiteur">
                                                <Form.Control
                                                  as="textarea"
                                                  placeholder="Leave a comment here"
                                                  style={{ height: '250px' }}
                                                  name="commentaire"
                                                  defaultValue={res.commentaire}
                                                  onChange={(e) => HandleChange(e)}
                                                />
                                              </FloatingLabel>
                                            </div>

                                          </div>
                                        </div>

                                      )

                                    } else {
                                      return (

                                        <div className="col-lg-9" style={{ visibility: show ? "visible" : "hidden" }}>

                                          <div className="row">
                                            <div className="form-group col-md-4 mb-4">

                                              <label className="mb-3">Date de la visite</label>
                                              <input type="date" name="date_visite" defaultValue={res.date_visite} onChange={(e) => HandleChange(e)} className="form-control" />
                                            </div>

                                            <div className="form-group col-md-8 mb-4">

                                              <label className="mb-2">Commentaire</label>

                                              <FloatingLabel controlId="floatingTextarea2" label="commentaire laissé par le visiteur">
                                                <Form.Control
                                                  as="textarea"
                                                  placeholder="Leave a comment here"
                                                  style={{ height: '250px' }}
                                                  name="commentaire"
                                                  defaultValue={res.commentaire}
                                                  onChange={(e) => HandleChange(e)}
                                                />
                                              </FloatingLabel>
                                            </div>

                                          </div>
                                        </div>


                                      )
                                    }
                                  })
                                    ()}

                                </div>
                              </>

                            )
                          }
                        })
                          ()}

                        <hr />
                        <Link to={"/CompteAcquereurForm"} onClick={() => LinkCompteAcquereur()}><button type="submit" style={{ float: 'left' }} className="btn btn-danger mt-5">Retour</button></Link>
                        <button type="submit" className="btn btn-warning mt-5 mb-5">Modifier</button>

                      </div>
                    </form>
                  </div>
                </div>
              </div>


            </>
          </div>
        </div>
      ))}
    </div>
  );

}

export default ContratAcquereurForm;