
import axios from '../axios/axios';

const getAll = () => {
  return axios.get("/login");
};
const get = id => {
  return axios.get(`/login/${id}`);
};

const getUser = id => {
  return axios.get(`/register/${id}`);
};

const create = data => {
  return axios.post("/login", data);
};
const update = (id, data) => {
  return axios.put(`/login/${id}`, data);
};


const updateEtat = (id, data) => {
  return axios.post(`/etat_Users/${id}`, data);
};

const updateUser = (data) => {
  return axios.post(`/updateUsers`, data);
};


const AdminupdateUser = (data) => {
  return axios.post(`/update_Users`, data);
};

const remove = id => {
  return axios.delete(`/login/${id}`);
};
const removeAll = () => {
  return axios.delete(`/login`);
};
const findByTitle = title => {
  return axios.get(`/login?title=${title}`);
};
const UserCRUD = {
  getAll,
  get,
  getUser,
  create,
  update,
  updateEtat,
  updateUser,
  remove,
  removeAll,
  findByTitle,
  AdminupdateUser
};
export default UserCRUD;