// import 'bootstrap/dist/css/bootstrap.min.css';
// import logo from './logo.svg';
// import './App.css';
import { Routes, Route } from "react-router-dom";
import Login from './Pages/Login'
import Register from './Pages/Register'
import Home from './Pages/Home'
import Profile from "./Pages/Profile";
import AutresParametres from "./Pages/AutresParam";

import ProprietaireForm from './Module1/ProprietaireForm'
import ProprietaireFormUpdate from './Module1/ProprietaireFormUpdate'
import ViewProprietaireDetail from './Module1/ViewProprietaireDetail'
import LotissementForm from './Module1/LotissementForm'
import LotissementUpdateForm from './Module1/LotissementUpdateForm'
import LotIlotForm from './Module1/LotIlotForm'

import CompteAcquereurForm from './Module2/CompteAcquereurForm'
import ContratAcquereurForm from './Module2/ContratAcquereurForm'
import ContratAcquereurUpdateForm from './Module2/ContratAcquereurUpdateForm'

import Ventes from './Module3/Ventes'
import Desitements from './Module3/Desitements'
import Cessions from "./Module3/Cessions";
import PaiementComplementaire from "./Module3/PaiementComp";
import Etatglobal from "./Module3/Etats";

import Recu from './Invoice/InvoiceTheme'
import DetailRecu from './Invoice/InvoiceThemeDetail'

import RecuProforma from './Invoice/InvoiceProforma'
import DetailRecuProforma from './Invoice/InvoiceProformaDetail'

import VueRecu from './Invoice/InvoiceVente'
import VueRecuVersement from './Invoice/InvoiceVersement'
import VueRecuVersementDetail from './Invoice/InvoiceVersementDetail'
import RecuPaidComp from './Invoice/InvoicePaidComp'
import TablePaiementComp from './Invoice/InvoiceTableauPaidComp'
import TableEtatLotissement from './Invoice/TableEtatLotissement'


import P404 from './Pages/Page404'
import AuhtUser from './services/AuthUser';

import PrintListPaiement from "./Invoice/ListePaiement";
import TablePaiement from "./Invoice/Liste_Paiements";
import TableVentes from "./Invoice/Liste_Ventes";

import TableReservation from "./Documents/Reservation/TableReservationTerrain";
import ViewReservation from "./Documents/Reservation/DetailDocs";
import Documents from "./Documents/Reservation/Docs";

import TableReservationLogement from "./Documents/Reservation/TableReservationLogement";
import Document2 from "./Documents/Reservation/Docs2";
import ViewReservationLogement from "./Documents/Reservation/DetailDocs2";

import Document3 from "./Documents/Echeancier/Docs3";
import TableEcheancier from "./Documents/Echeancier/TableEcheancier";
import DetailEcheancier from "./Documents/Echeancier/DetailDocs3";




function App() {

  const { getToken } = AuhtUser()
  if (!getToken()) {
    return (
      <Login />
    )
  }

  return (
    <Routes>
      <Route path="*" element={<P404 />} />
      <Route path="/Register" element={<Register />} />
      <Route path="/Dash" element={<Home />} />

      <Route path="/ProprietaireForm" element={<ProprietaireForm />} />
      <Route path="/ProprietaireFormUpdate/:id" element={<ProprietaireFormUpdate />} />
      <Route path="/ViewProprietaireDetail/:id" element={<ViewProprietaireDetail />} />

      <Route path="/LotissementForm" element={<LotissementForm />} />
      <Route path="/LotissementUpdateForm/:id" element={<LotissementUpdateForm />} />




      <Route path="/LotIlotForm" element={<LotIlotForm />} />

      <Route path="/CompteAcquereurForm" element={<CompteAcquereurForm />} />

      <Route path="/ContratAcquereurForm/:id" element={<ContratAcquereurForm />} />
      <Route path="/ContratAcquereurUpdateForm/:id" element={<ContratAcquereurUpdateForm />} />

      <Route path="/Ventes" element={<Ventes />} />
      <Route path="/Desitements" element={<Desitements />} />
      <Route path="/cessions" element={<Cessions />} />

      <Route path="/Recu/:numilot/:lotiss/:codeilot/:codevente" element={<Recu />} />
      <Route path="/DetailRecu/:numilot/:lotiss/:codeilot/:codevente" element={<DetailRecu />} />

      <Route path="/RecuProforma/:numilot/:lotiss/:codeilot/:codevente" element={<RecuProforma />} />
      <Route path="/DetailRecuProforma/:numilot/:lotiss/:codeilot/:codevente" element={<DetailRecuProforma />} />
      
      <Route path="/VueRecu/:id" element={<VueRecu />} />
      <Route path="/VueRecuVersement/:numilot/:lotiss/:codeilot/:id/:Amont/:modepaie" element={<VueRecuVersement />} />
      <Route path="/VueRecuVersementDetail/:numilot/:lotiss/:codeilot/:id/:Amont/:idversement/:modepaie" element={<VueRecuVersementDetail />} />
      <Route path="/PrintListPaiement/:code" element={<PrintListPaiement />} />
      <Route path="/TableVentes" element={<TableVentes />} />
      <Route path="/TablePaiement" element={<TablePaiement />} />
      <Route path="/PaiementComplementaire" element={<PaiementComplementaire />} />
      <Route path="/Etatglobal" element={<Etatglobal />} />
      
      <Route path="/RecuPaidComp/:id" element={<RecuPaidComp />} />
      <Route path="/TablePaiementComp" element={<TablePaiementComp />} />
      <Route path="/TableEtatLotissement" element={<TableEtatLotissement />} />

      <Route path="/Profile" element={<Profile />} />
      <Route path="/AutresParametres" element={<AutresParametres />} />
      
      <Route path="/ListReservationTerrain" element={<TableReservation />} />
      <Route path="/DetailReservationTerrain/:id" element={<ViewReservation />} />
      <Route path="/ReservationTerrain" element={<Documents />} />

      <Route path="/ListReservationLogement" element={<TableReservationLogement />} />
      <Route path="/ReservationLogement" element={<Document2 />} />
      <Route path="/DetailReservatioLogement/:id" element={<ViewReservationLogement />} />

      <Route path="/TableEcheancier" element={<TableEcheancier />} />
      <Route path="/Echeancier" element={<Document3 />} />

       <Route path="/DetailEcheancier/:id" element={<DetailEcheancier />} />


    </Routes>

  );
}

export default App;
