import React, { Component } from 'react';
//import axios from 'axios';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Siderbar from '../components/Siderbar';
import '../index.css';

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import ProprietaireCRUD from '../services/ProprietaireCRUD';

import Swal from 'sweetalert2';

export default class ProprietaireForm extends Component {
  constructor(props) {
    super(props);
    //this.onChangeTitle = this.onChangeTitle.bind(this);
    //this.onChangeDescription  = this.onChangeDescription .bind(this);
    this.onChangeNom = this.onChangeNom.bind(this);
    this.onChangePrenom = this.onChangePrenom.bind(this);
    this.onChangeSexe = this.onChangeSexe.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeTelephone = this.onChangeTelephone.bind(this);
    this.onChangeNumpiece = this.onChangeNumpiece.bind(this);
    this.onChangeDistrict = this.onChangeDistrict.bind(this);
    this.onChangeVille = this.onChangeVille.bind(this);
    this.onChangeDepartement = this.onChangeDepartement.bind(this);
    this.onChangeCommune = this.onChangeCommune.bind(this);
    this.onChangeVillage = this.onChangeVillage.bind(this);

    this.getList = this.getList.bind(this);

    this.state = {
      nom: "",
      prenom: "",
      sexe: "",
      email: "",
      telephone: "",
      numero_piece: "",
      district: "",
      departement: "",
      commune: "",
      ville: "",
      village: "",
      image: "",
      responseMsg: {
        status: "",
        message: "",
        error: "",
      },
      listproprietaires: [],
      listdistrict: [],
      listVille: [],
    };
  }

  // onChangeTitle(e) {
  //     this.setState({
  //       title: e.target.value
  //     });
  //   }

  //    onChangeDescription(e) {
  //     this.setState({
  //       description: e.target.value
  //     });
  //   }


  onChangeNom(e) {
    this.setState({
      nom: e.target.value
    });
  }

  onChangePrenom(e) {
    this.setState({
      prenom: e.target.value
    });
  }


  onChangeSexe(e) {
    this.setState({
      sexe: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }


  onChangeTelephone(e) {
    this.setState({
      telephone: e.target.value
    });
  }

  onChangeNumpiece(e) {
    this.setState({
      numero_piece: e.target.value
    });
  }


  onChangeDistrict(e) {
    this.setState({
      district: e.target.value
    });

    this.getListVille(e.target.value)
  }

  getListVille(id) {
    ProprietaireCRUD.liste_ville(id)
      .then((response) => {
        this.setState({
          listVille: response.data,
        });
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  onChangeVille(e) {
    this.setState({
      ville: e.target.value
    });
  }

  onChangeDepartement(e) {
    this.setState({
      departement: e.target.value
    });
  }

  onChangeCommune(e) {
    this.setState({
      commune: e.target.value
    });
  }

  onChangeVillage(e) {
    this.setState({
      village: e.target.value
    });
  }



  // image onchange hander
  handleChange = (e) => {
    console.log(e)
    this.setState({
      image: e.target.files[0]

    })
  }

  // submit handler
  submitHandler = (e) => {
    e.preventDefault();
    const data = new FormData()

    const nomInput = document.getElementById("nomInput");
    const prenomInput = document.getElementById("prenomInput");
    const sexeInput = document.getElementById("sexeInput");
    const emailInput = document.getElementById("emailInput");

    const telephoneInput = document.getElementById("telephoneInput");
    const num_pieceInput = document.getElementById("num_pieceInput");
    const file_pieceInput = document.getElementById("file_pieceInput");
    const villageInput = document.getElementById("villageInput");

    data.append('images', this.state.image)
    data.append('nom', this.state.nom)
    data.append('prenom', this.state.prenom)
    data.append('sexe', this.state.sexe)
    data.append('email', this.state.email)
    data.append('telephone', this.state.telephone)
    data.append('numero_piece', this.state.numero_piece)
    data.append('district', this.state.district)
    data.append('departement', this.state.departement)
    data.append('commune', this.state.commune)
    data.append('ville', this.state.ville)
    data.append('village', this.state.village)
    console.log(data)

    //axios.post("https://alback.doubleclic-tech.com/api/proprietaire", data)
    ProprietaireCRUD.create(data)
      .then((response) => {
        console.log(data);
        if (response.status === 200) {
          this.setState({
            responseMsg: {
              status: response.data.status,
              message: response.data.message,
            },
          });

          // setTimeout(() => {
          //     this.setState({
          //     image: "",
          //     responseMsg: "",
          //     });
          // }, 5000);
          nomInput.value = ""
          prenomInput.value = ""
          sexeInput.value = ""
          emailInput.value = ""
          telephoneInput.value = ""
          num_pieceInput.value = ""
          file_pieceInput.value = ""
          villageInput.value = ""

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: response.data.message,
            showConfirmButton: false,
            timer: 5000
          })

          document.querySelector("#imageForm").reset();


          this.getList();

        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  componentDidMount() {
    this.getList();
    this.getListDistrict();
  }


  getList() {
    //axios.get("https://alback.doubleclic-tech.com/api/proprietaire")
    ProprietaireCRUD.getAll()
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            listproprietaires: response.data,
          });
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getListDistrict() {
    ProprietaireCRUD.liste_district()
      .then((response) => {
        this.setState({
          listdistrict: response.data,
        });
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  render() {
    const { listproprietaires } = this.state;
    const { listdistrict } = this.state;
    const { listVille } = this.state;


    const columns = [
      {
        name: "Nom",
        selector: (row) => row.nom_prop,
        sortable: true,
        reorder: true
      },
      {
        name: "Prenom",
        selector: (row) => row.prenom_prop,
        sortable: true,
        reorder: true
      },
      {
        name: "Sexe",
        selector: (row) => row.sexe_prop,
        sortable: true,
        reorder: true
      },
      // {
      //     name: "Email",
      //     selector: (row) => row.email_prop,
      //     sortable: true,
      //     reorder: true
      // },
      // {
      //     name: "Telephone",
      //     selector: (row) => row.telephone_prop,
      //     sortable: true,
      //     reorder: true
      // },
      // {
      //     name: "N° Pièce",
      //     selector: (row) => row.nume_piece_prop,
      //     sortable: true,
      //     reorder: true
      // },
      {
        name: "Photo Pièce",
        cell: (row) => (
          <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/" + row.piece_prop} target={'_blank'} rel="noreferrer">
            <i className="fa fa-download fa-2x"></i>
          </a>
        ),
        sortable: true,
        reorder: true
      },
      {
        name: "Action",
        cell: (row) => (

          <div>
            <div className="d-flex">
              <Link className="btn btn-info btn-xs me-2" to={"/ViewProprietaireDetail/" + row.id_proprietaire}><i className="fa fa-eye"></i></Link>
              {/* <Link to={"/ProprietaireFormUpdate/"+row.id_proprietaire}>*/}
              <button className="btn btn-warning btn-xs me-1" onClick={() => UpdateProprietaire(row.id_proprietaire)}><i className="fa fa-edit"></i></button>
              {/*</Link>*/}
              <button className="btn btn-danger btn-xs" onClick={() => deleteProprietaire(row.id_proprietaire)}><i className="fa fa-trash"></i></button>
            </div>

          </div>
        ),
        width: "200px",                       // added line here
        headerStyle: (selector, id) => {
          return { textAlign: "center" };   // removed partial line here
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
    ]


    const UpdateProprietaire = (id) => {
      window.location.replace('/ProprietaireFormUpdate/' + id);
    }

    /***** DELETE DATA *******/

    const deleteProprietaire = (id) => {

      Swal.fire({
        title: 'Es-tu sûr?',
        text: "Vous ne pourrez pas revenir en arrière!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, supprime-le!'
      }).then((result) => {
        if (result.isConfirmed) {

          ProprietaireCRUD.remove(id)
            .then(response => {
              console.log(response.data);


              Swal.fire(
                'Supprimé!',
                'Votre fichier a été supprimé.',
                'success'
              )

              // window.location.reload(false);
              this.getList();

            })
            .catch(e => {
              console.log(e);
            });

        }
      })


    };
    /***** END DELETE DATA *******/
    return (
      <div className="">
        <Navbar />
        <Siderbar />

        <div className="content-body">
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <h1><b style={{ color: "red" }}>Propriétaire Térrien</b></h1>
              </ol>
            </div>

            <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Enregistrement des informations</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal">
                    </button>
                  </div>
                  <form onSubmit={this.submitHandler} encType="multipart/form-data" id="imageForm">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12 col-lg-12">



                          <div className="basic-form">
                            <div className="row">
                              <div className="mb-3 col-md-4">
                                <label className="form-label">Nom</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="nomInput"
                                  required
                                  value={this.state.nom}
                                  onChange={this.onChangeNom}
                                  name="nom"
                                  placeholder="...."
                                />
                              </div>
                              <div className="mb-3 col-md-5">
                                <label className="form-label">Prenom</label>
                                <input
                                  type="text"
                                  className="form-control" id="prenomInput"
                                  value={this.state.prenom}
                                  onChange={this.onChangePrenom}
                                  name="prenom"
                                  placeholder="...."
                                />
                              </div>
                              <div className="mb-3 col-md-3">
                                <label className="form-label">Sexe</label>
                                <select className="form-control" id="sexeInput" required onChange={this.onChangeSexe} name="sexe">
                                  <option value="">Sexe..</option>
                                  <option value="Homme">Homme</option>
                                  <option value="Femme">Femme</option>
                                </select>

                              </div>
                              <div className="mb-3 col-md-6">
                                <label className="form-label">Email</label>
                                <input
                                  type="email"
                                  className="form-control" id="emailInput"
                                  placeholder="Email"
                                  value={this.state.email}
                                  onChange={this.onChangeEmail}
                                  name="email"
                                />
                              </div>
                              <div className="mb-3 col-md-4">
                                <label className="form-label">N° téléphone</label>
                                <input
                                  type="text"
                                  className="form-control" id="telephoneInput"
                                  value={this.state.telephone}
                                  onChange={this.onChangeTelephone}
                                  name="telephone"
                                  placeholder="N° téléphone"
                                  required
                                />
                              </div>
                              <div className="mb-4 col-md-6">
                                <label className="form-label">Le N° de la pièce d’identité ou l’attestation</label>
                                <input
                                  type="text"
                                  className="form-control" id="num_pieceInput"
                                  value={this.state.numero_piece}
                                  onChange={this.onChangeNumpiece}
                                  name="numero_piece"
                                  required
                                  placeholder="Ex: CNI00120120010..." />
                              </div>

                              <div className="mb-4 col-md-6">
                                <label className="form-label">Importer la pièce scanée <code>PDF</code></label>
                                <input
                                  type="file"
                                  name="image"
                                  onChange={this.handleChange}
                                  className="form-control" id="file_pieceInput"
                                  required
                                />
                                <span className="text-danger">
                                  {this.state.responseMsg.error}
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="mb-3 col-md-4">
                                <label className="form-label">District</label>
                                <select className="form-control wide" required onChange={this.onChangeDistrict} name="district">
                                  <option value="">District..</option>
                                  {listdistrict &&
                                    listdistrict.map((rows) => (
                                      <option key={rows.id_district} value={rows.id_district}>{rows.nom_district}</option>
                                    ))}
                                </select>
                              </div>

                              <div className="mb-3 col-md-4">
                                <label className="form-label">Ville</label>
                                <select className="form-control wide" onChange={this.onChangeVille} name="ville">
                                  <option value="">Ville..</option>
                                  {listVille &&
                                    listVille.map((rows) => (
                                      <option key={rows.id_ville} value={rows.nom_ville}>{rows.nom_ville}</option>
                                    ))}
                                </select>
                              </div>

                              <div className="mb-3 col-md-4">
                                <label className="form-label">Département</label>

                                <input
                                  type="text"
                                  className="form-control"
                                  id="Input"
                                  name="departement"
                                  value={this.state.departement}
                                  onChange={this.onChangeDepartement}
                                  placeholder="Département..." />
                              </div>

                              <div className="mb-3 col-md-4">
                                <label className="form-label">Commune </label>

                                <input
                                  type="text"
                                  className="form-control"
                                  id="Input"
                                  name="commune"
                                  value={this.state.commune}
                                  onChange={this.onChangeCommune}
                                  placeholder="Commune..." />
                              </div>

                              <div className="mb-3 col-md-4">
                                <label className="form-label">Village </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="villageInput"
                                  name="village"
                                  value={this.state.village}
                                  onChange={this.onChangeVillage}
                                  placeholder="Village..."
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger light" data-bs-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-primary">Enregistrer</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Datatable</h4>

                    <button type="button" className="btn btn-primary mb-2" data-bs-toggle="modal" data-bs-target=".bd-example-modal-lg">Enregistrement</button>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      {/*<DataTableExtensions
                                      {...tableData}
                                    >
                                    <DataTable
                                        title="DataTable"
                                        //columns={columns}
                                        //data={listproprietaires}
                                        // defaultSortFieldId={1}
                                        // pagination
                                        // selectableRows
                                        // persistTableHead

                                         noHeader
                                        defaultSortField="id"
                                        defaultSortAsc={false}
                                        pagination
                                        highlightOnHover

                                    />
                                    </DataTableExtensions>*/}


                      <DataTableExtensions
                        columns={columns}
                        data={listproprietaires}
                        export={false}
                        print={false}

                      >
                        <DataTable
                          title="DataTable"
                          responsive
                          noHeader
                          defaultSortFieldId={1}
                          defaultSortField="id"
                          defaultSortAsc={false}
                          pagination
                          // selectableRows
                          persistTableHead
                          highlightOnHover
                          exportHeaders={false}

                        />
                      </DataTableExtensions>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}