import React, { useEffect, useState } from "react";
import Navbar from '../components/Navbar';
import Siderbar from '../components/Siderbar';
import '../index.css';
// import {Link } from 'react-router-dom'
import LotIlotCRUD from '../services/LotIlotCRUD';
import LotissementCRUD from '../services/LotissementCRUD';

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

import { Button, Form, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

const IlotForm = () => {

  
 
  const [interval_ilos, setInterval_ilos] = useState([]);



  const [lots, setLots] = useState([]);

  const [show, setShow] = useState(false)
 const [lotissmt, setLotissmt] = useState([])
 const [numIlot, setNumIlot] = useState([])

  const viewLots = (id, ilot, lotissmt) => {
    setShow(true)
           setLotissmt(lotissmt)
           setNumIlot(ilot)

    LotIlotCRUD.getLotIlot(id, ilot, lotissmt)
      .then((res) => {


        setLots(res.data);

        console.log(res.data);

      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleClose = () => setShow(false)

  const columnsLot = [

    {
      name: "N°Lot",
      cell: (rows) => (
        <b style={{ color: "black" }}> 

         {(() => {
            if (rows.remorcele === '0') {
              return (
                <>{rows.numero_lot}</>
              )
                                                                   
            } else {
              return (
                <>{rows.numero_lot} bis </>
              )
            }
        })
        ()}
        
        </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Qté",
      cell: (rows) => (
        <b style={{ color: "black" }}> {rows.qte_lot} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Superficie(m2)",
      cell: (rows) => (
        <b style={{ color: "black" }}> {rows.superficie_lot} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Autre Infos",
      cell: (rows) => (
        <b style={{ color: "black" }}> {rows.infos} </b>
      ),
      sortable: true,
      reorder: true
    },

    {
      name: "Etat vente",
      cell: (rows) => (

        rows.etat_vente === 0 ? (
          <b style={{ color: "red" }}> non vendu </b>
        ) : rows.etat_vente === 1 ? (
          <b style={{ color: "green" }}> Vendu </b>
        ) : (
          ""
        )

      ),
      sortable: true,
      reorder: true
    },

    {
      name: "Lot Supplementaire",
      cell: (rows) => (
        <div>
          <div className="d-flex">
            {(() => {
            if (rows.remorcele === '0') {
              return (
                <>
          <button type="button" onClick={() => LotsSuplementaire(rows.code_ilot, rows.numero_lot, rows.num_ilot, lotissmt)} className="btn btn-outline-success btn-xs me-2"><i className="fa fa-plus"></i></button>
              </>
              )
                                                                   
            } else {
              return (
                <></>
              )
            }
        })
        ()}
       

          </div>
        </div>
      ),
      
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },

    {
      name: "Action",
      cell: (rows) => (
        <div>
          <div className="d-flex">
            
        {(() => {
            if (rows.remorcele === '0') {
              return (
                <>
            <button className="btn btn-danger btn-xs" onClick={() => deleteLot(rows.id_lot, rows.code_ilot, rows.num_ilot)}><i className="fa fa-trash"></i></button>
              </>
              )
                                                                   
            } else {
              return (
                <>
            <button className="btn btn-danger btn-xs" onClick={() => deleteLotRemorcele(rows.id_lot, rows.code_ilot, rows.numero_lot, rows.remorcele, rows.num_ilot)}><i className="fa fa-trash"></i></button>

                </>
              )
            }
        })
        ()}

          </div>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ]

  useEffect(() => {
    getLotissement();
    //getLotIlot();
    // NbrLOT();

  }, []);


const [listelottissement, setListelottissement] = useState([]);

  const getLotissement = () => {
    LotissementCRUD.getAll()
      .then((res) => {

        setListelottissement(res.data);

        console.log(res.data);

      })
      .catch((error) => {
        console.error(error);
      });
  };

 const [listeLotIlot, setlisteLotIlot] = useState([]);

   function SelectLotissement(e) {
    //const { name, value } = e.target;
  
     LotIlotCRUD.getAllIlot(e.target.value)
      .then((res) => {
        setlisteLotIlot(res.data);
         console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
    


  }



  // const getLotIlot = () => {
  //   LotIlotCRUD.getAll()
  //     .then((res) => {
  //       //setlisteLotIlot(res.data);
  //       // console.log(res.data);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  // useEffect(() => {
  //      NbrLOT(codeIlots)
  // }, [codeIlots]);

  // const [nbrLot, setNbreLots] = useState([])
  // function NbrLOT(){

  //         listeLotIlot.map(function(cle) {
  //                     return [cle, values[cle]];
  //                    console.log(cle.code_ilot);

  //          LotIlotCRUD.NombreLots(cle.code_ilot)
  //             .then((res) => {

  //                  setNbreLots(res.data);

  //               console.log(res.data);
  //                     })
  //                   .catch((error) => {
  //                     console.error(error);
  //                   });

  //       })
  // }
  const columns = [

    // {
    //   name: "Code",
    //   selector: (row) => row.code_ilot,
    //   cell: (row) => (
    //     <b style={{ color: "black" }}> {row.code_ilot} </b>
    //   ),
    //   sortable: true,
    //   reorder: true
    // },
    {
      name: "Lotissement",
      selector: (row) => row.libelle_loti,
      cell: (row) => (
        <b style={{ color: "black" }}> {row.libelle_loti} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "N°Ilot",
      cell: (row) => (
        <b style={{ color: "black" }}> {row.numero_ilot} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Lots complémentaire",
      cell: (row) => (
        <div>
          <button type="button" onClick={() => LotsComplementaire(row.code_ilot, row.numero_ilot, row.lotissement_code)} className="btn btn-outline-info btn-xs me-2"><i className="fa fa-plus"></i></button>
        </div>
      ),
      width: "200px",                       // added line here
      headerStyle: (selector, id) => {
        return { textAlign: "center" };   // removed partial line here
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
   

    //  {
    //     name: "Commune",
    //     cell: (row) => (
    //         <b style={{color:"black"}}> {row.commune_loti} </b>
    //          ),
    //     sortable: true,
    //     reorder: true
    // },
    // {
    //     name: "Date de saisie",
    //     cell: (row) => (
    //         <b style={{color:"black"}}> {row.date_ilot} </b>
    //          ),
    //     sortable: true,
    //     reorder: true
    // },
    //  {
    //     name: "Nombre lot",
    //     cell: (row) => (

    //         <b style={{color:"black"}}> {nbrLot} </b>
    //          ),
    //     sortable: true,
    //     reorder: true
    // },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <div className="d-flex">
            {/*<Link to={"/LotIlotFormUpdate/"+row.id_loti}>
                    <button className="btn btn-warning btn-xs me-2"><i className="fa fa-edit"></i></button>
                    </Link>*/}
            <button className="btn btn-info btn-xs me-2" onClick={() => viewLots(row.code_ilot, row.numero_ilot, row.lotissement_code)}><i className="fa fa-eye"></i></button>
            <button className="btn btn-danger btn-xs" onClick={() => deleteLotIlot(row.code_ilot, row.numero_ilot, row.code_loti)}><i className="fa fa-trash"></i></button>
          </div>
        </div>
      ),
      width: "200px",                       // added line here
      headerStyle: (selector, id) => {
        return { textAlign: "center" };   // removed partial line here
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ]

  /***** DELETE DATA *******/

  const deleteLotIlot = (code, num, lotiss) => {
    Swal.fire({
      title: 'Es-tu sûr?',
      html: "Vous ne pourrez pas revenir en arrière! <br> L'ilot sera supprimer y compris les lots qui lui sont affilié",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprime-le!'
    }).then((result) => {
      if (result.isConfirmed) {
        LotIlotCRUD.removeILot(code, num, lotiss)
          .then(response => {
            console.log(response.data);
          
            Swal.fire(
              'Supprimé!',
              'Votre fichier a été supprimé.',
              'success'
            )

            //window.location.reload()
          })
          .catch(e => {
            console.log(e);
          });


          LotIlotCRUD.getAllIlot(lotiss)
          .then((res) => {
            setlisteLotIlot(res.data);
             console.log(res.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    })
  };

  const deleteLot = (id, code, ilot) => {
    Swal.fire({
      title: 'Es-tu sûr?',
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprime-le!'
    }).then((result) => {
      if (result.isConfirmed) {
        LotIlotCRUD.removeLot(id)
          .then(response => {
            console.log(response.data);
            viewLots(code, ilot)
            // getLotIlot();
            Swal.fire(
              'Supprimé!',
              'Votre fichier a été supprimé.',
              'success'
            )
          })
          .catch(e => {
            console.log(e);
          });
      }
    })
  };


   const deleteLotRemorcele = (id, ilot, lot, remoc, num_ilot) => {
    // console.log(ilot)
    // console.log(lot)
    // console.log(remoc)
    Swal.fire({
      title: 'Es-tu sûr?',
      text: "Vous ne pourrez pas revenir en arrière!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprime-le!'
    }).then((result) => {
      if (result.isConfirmed) {
        LotIlotCRUD.removeILotRemorcele(id)
          .then(response => {
            console.log(response.data);
            viewLots(ilot, num_ilot)
            // getLotIlot();
            Swal.fire(
              'Supprimé!',
              'Votre fichier a été supprimé.',
              'success'
            )
          })
          .catch(e => {
            console.log(e);
          });
      }
    })
  };
  /***** END DELETE DATA *******/

 

  const [values, setValues] = useState([])

  const [dataILot, setDataIlot] = useState({
    date: "",
    // code : "",
    code_loti: "",
    num_ilot: "",
    debut_lot: "",
    fin_lot: "",

  });

  function handleLot(e, i) {
    const { name, value } = e.target
    // const  name  = e.target.name;
    // const  value = e.target.value;
    // const newdata={...values, [name]: value }
    setValues({
      ...values,
      [name]: value
    })
    // setDatalot(newdata)
    // console.log(newdata)
    //console.log(values)
  }

  function handle(e) {
    const name = e.target.name;
    const value = e.target.value;
    const newdata = { ...dataILot, [name]: value }
    setDataIlot(newdata)
    console.log(newdata)
  }

  function handleLotissementChange(e) {
    const { name, value } = e.target;
    const newdata = { ...dataILot, [name]: value }
    setDataIlot(newdata)
    console.log(newdata)
    IntervalIlot(e.target.value)
    getLotissementCode(e.target.value)
  }




  function IntervalIlot(id) {
    LotIlotCRUD.getIlotInterval(id)
      .then((response) => {
        console.log(response.data);
        setInterval_ilos(response.data)
        
      })
      .catch((error) => {
        console.error(error);
      });
  }


const [nbreLots, setNbrLots] = useState([])

 const getLotissementCode = (code) => {
    LotissementCRUD.get(code)
      .then((res) => {
        setNbrLots(res.data[0].nombre_lot)

        console.log(res.data[0].nombre_lot);

      })
      .catch((error) => {
        console.error(error);
      });
  };



 const [debutLot, setDebutLot] = useState('');
  const [finLot, setFinLot] = useState('');

  function searchNum(debutLot, finLot, codeIlot, nbreLots) {
    console.log(nbreLots);

    if(finLot > nbreLots){
      
      Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: "Le nombre de lot que vous avez renseigné est supperieur au nombre de lot ("+nbreLots+") enregistré du lotissement ",
          showConfirmButton: false,
          timer: 6000
        })

      setTimeout(() => {
             window.location.reload();
          }, 6000);

    }else{

    LotIlotCRUD.findByNumDebut(debutLot, codeIlot)
      .then((response) => {
        console.log(response.data);
        // Swal.fire({
        //             position: 'top-end',
        //             icon: 'success',
        //             title: response.data.message,
        //             showConfirmButton: false,
        //             timer: 5000
        //         })
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: error.response.data.message,
          showConfirmButton: false,
          timer: 5000
        })
        setDebutLot('');
        setFinLot('');
        document.querySelector("#LotForm").reset();
        document.querySelector("#formComplementaire").reset();

      });
    }
  }




  function handleSubmit(event) {
    event.preventDefault();
    const curr = new Date();
    // curr.setDate(curr.getDate() + 3);
    curr.setDate(curr.getDate());
    const date = curr.toISOString().substr(0, 10);
    const bodyFormData = new FormData();
    bodyFormData.append('date_ilot', date);
    bodyFormData.append('debutLot', debutLot);
    bodyFormData.append('finLot', finLot);
    bodyFormData.append('code_loti', dataILot.code_loti);
    bodyFormData.append('num_ilot', dataILot.num_ilot);

    Object.keys(values).map(function (cle) {
      bodyFormData.append(cle, values[cle]);
      return [cle, values[cle]];
    });

    //console.log(bodyFormData);

    LotIlotCRUD.create(bodyFormData)
      .then((response) => {
        console.log(response.data);
        // getLotIlot();
        document.querySelector("#LotForm").reset();
        setDataIlot('')
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: response.data.message,
          showConfirmButton: false,
          timer: 5000
        })
      })
      .catch((error) => {
        console.error(error.response);
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: error.response.data.message,
          showConfirmButton: false,
          timer: 5000
        })
      });
  }

  var currr = new Date();
  currr.setDate(currr.getDate());
  var dateDay = currr.toISOString().substr(0, 10);

  const [showComplementaire, setShowComplementaire] = useState(false)
  const [codeIlot, setCodeIlot] = useState(false)
  const [ilotNUM, setIlotNUM] = useState([])
 
  const [ilotID, setIlotID] = useState([])

  const LotsComplementaire = (id, num, lotiss) => {
    setShowComplementaire(true)
    setCodeIlot(id)
    setIlotNUM(num)
 
    LotIlotCRUD.getNumIlot(id, num, lotiss)
      .then((res) => {
        setIlotID(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleCloseComplementaire = () => {
    setShowComplementaire(false)
  }

  const [showSuplementaire, setShowSuplementaire] = useState(false)
  const [nbrelot, setNbrLot] = useState([])

  const LotsSuplementaire = (id, lot, ilot, lotissmt) => {

    setShowSuplementaire(true)
       setShow(false)

    setCodeIlot(id)
    setNbrLot(lot)
    console.log(lotissmt);
    LotIlotCRUD.getNumIlot(id, ilot, lotissmt)
      .then((res) => {
        setIlotID(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleCloseSuplementaire = (code, num, lotissmt) => {
    setShowSuplementaire(false)
    setShow(true)
     viewLots(code, num, lotissmt)
  }

  // const [dataLotComp, setDatalotComp] = useState({
  //              date_ilot : "",  
  //             code_loti : "",
  //             num_ilot : "",
  //             debut_lot : "",
  //             fin_lot : "",
  // });

  // function handleComplementaire(e){
  //      const  name  = e.target.name;
  //      const  value = e.target.value;
  //     const newdata={...dataILot, [name]: value }
  //     setDatalotComp(newdata)
  //     console.log(newdata)
  // }

  const [debutLotComple, setDebutLotComple] = useState('');
  const [finLotComple, setFinLotComple] = useState('');

  const [compvalues, setValuesComp] = useState([])

  function handleLotComp(e) {
    const { name, value } = e.target
    setValuesComp({
      ...compvalues,
      [name]: value
    })
  }

  function handleSubmitComplementaire(event) {
    event.preventDefault();
    const bodyFormData = new FormData();
    bodyFormData.append('debutLot', debutLotComple);
    bodyFormData.append('finLot', finLotComple);
    bodyFormData.append('code_ilot', codeIlot);
    bodyFormData.append('num_ilot', ilotNUM);

    Object.keys(compvalues).map(function (cles) {
      bodyFormData.append(cles, compvalues[cles]);
      return [cles, compvalues[cles]];
      // console.log(compvalues)
    });

    LotIlotCRUD.createLotComplementaire(bodyFormData)
      .then((response) => {
        console.log(response.data);
        // getLotIlot();
        handleCloseComplementaire(true)
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: response.data.message,
          showConfirmButton: false,
          timer: 5000
        })
      })
      .catch((error) => {
        console.error(error.response);
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: error.response.data.message,
          showConfirmButton: false,
          timer: 5000
        })
      });
  }


   // var today = new Date();
   //  var dd = String(today.getDate()).padStart(2, '0');
   //  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
   //  var yyyy = today.getFullYear();

   //  var date_Day = dd + '/' + mm + '/' + yyyy;


 const [dataLotsup, setDataLotsup] = useState({
    superficieLotSup: "",
    InformationSup: "",
    

  });


function handleSuplementaire(e) {
    const name = e.target.name;
    const value = e.target.value;
    const newdata = { ...dataLotsup, [name]: value }
    setDataLotsup(newdata)
    console.log(newdata)
  }



    function handleSubmitSuplementaire(event, code_ilot, numLot, dateJr, numIlot) {
    event.preventDefault();
    const bodyFormData = new FormData();

    bodyFormData.append('dateJour', dateJr);
    bodyFormData.append('ilot', code_ilot);
    bodyFormData.append('numero_ilot', numIlot);
    bodyFormData.append('num_lot', numLot);
    bodyFormData.append('qte_lot', '1');
    bodyFormData.append('superficie', dataLotsup.superficieLotSup);
    bodyFormData.append('info', dataLotsup.InformationSup);


    LotIlotCRUD.createRemorcele(bodyFormData)
      .then((response) => {
        console.log(response.data);

        setDataLotsup('')

        // getLotIlot();
        handleCloseComplementaire(true)
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: response.data.message,
          showConfirmButton: false,
          timer: 5000
        })

        handleCloseSuplementaire(code_ilot, numIlot, lotissmt)
      })
      .catch((error) => {
        console.error(error.response);
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: error.response.data.message,
          showConfirmButton: false,
          timer: 5000
        })
      });
  }

  return (

    <div className="">
      <Navbar />
      <Siderbar />

      <div className="content-body">
        <div className="container-fluid">
          <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>
                <h1>ILOT ({numIlot}) LISTE LOTS</h1>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="table-responsive">
                <DataTableExtensions
                  columns={columnsLot}
                  data={lots}
                  export={false}
                  print={false}

                >
                  <DataTable
                    title="DataTable"
                    responsive
                    noHeader
                    defaultSortFieldId={1}
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    // selectableRows
                    persistTableHead
                    highlightOnHover
                    exportHeaders={false}
                  />
                </DataTableExtensions>
              </div>
            </Modal.Body>
            <Modal.Footer>

              <Button onClick={handleClose} variant='secondary'>
                Fermer
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showComplementaire} onHide={handleCloseComplementaire} size="xl">
            {ilotID.map((res) => (
              <Modal.Header closeButton key={res.id_iloti}>
                <Modal.Title>
                  <h2>LOTS COMPLEMENTAIRE</h2>
                  <b style={{ color: 'red' }}>Lotissement : {res.libelle_loti} &nbsp;&nbsp;&nbsp; Code Ilot : {res.code_ilot}</b>
                </Modal.Title>
              </Modal.Header>
            ))}
            <Form onSubmit={(e) => handleSubmitComplementaire(e)} id="formComplementaire">
              {ilotID.map((ress) => (
                <Modal.Body key={ress.id_iloti}>
                  <div>
                    <div className="row mb-5">
                      <div className="col-md-3">
                        <div className='form-group mb-3'>
                          <Form.Label>Date enregistrement {ilotNUM}</Form.Label>
                          <p><b style={{ color: 'black' }}>{ress.date_ilot}</b></p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className='form-group mb-3'>
                          <Form.Label>Lotissement</Form.Label>
                          <p><b style={{ color: 'black' }}>{ress.libelle_loti}</b></p>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className='form-group mb-3'>
                          <Form.Label>N° Ilot</Form.Label>
                          <p><b style={{ color: 'black' }}>{ress.numero_ilot}</b></p>
                        </div>
                      </div>

                       {/*<div className="col-md-2">
                        <div className='form-group mb-3'>
                          <Form.Label>Nbr Lot</Form.Label>
                          <p><b style={{ color: 'black' }}>{ress.nombre_lot}</b></p>
                        </div>
                      </div>*/}
                    </div>
                    <div className="row">
                      <h3 className="mb-3">Enregistrement Lot</h3>
                      <div className="row">
                        <div className="mb-5 col-md-2">
                          <Form.Label className="form-label">Debut</Form.Label>
                          <Form.Control
                            type="number"
                            name="debut"
                            value={debutLot.debut_lot}
                            placeholder=""
                            onChange={(e) => { setDebutLotComple(e.target.value) }}
                            className="form-control"
                            required
                          />
                        </div>
                        <div className="mb-5 col-md-2">
                          <Form.Label className="form-label">Fin</Form.Label>
                          <Form.Control
                            type="number"
                            name="fin"
                            value={finLot.fin_lot}
                            placeholder=""
                            onChange={(e) => { setFinLotComple(e.target.value) }}
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                      <table id="example" className="table display min-w850">
                        <thead>
                          <tr>
                            <th>N° du lot</th>
                            <th>Quantité</th>
                            <th>Superficie (en m2)</th>
                            <th>Autre Info</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {function () {
                            let persons = []
                            for (let i = parseInt(debutLotComple); i < parseInt(finLotComple) + 1; i++) {
                              persons.push(
                                <tr key={i}>
                                  <td>
                                    <input
                                      type="number"
                                      readOnly
                                      name={"NumLotComp" + i}
                                      onChange={(e) => handleLotComp(e)}
                                      value={i || ''}
                                      className="form-control"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      name={"QteLotComp" + i}
                                      onChange={(e) => handleLotComp(e)}
                                      value={compvalues.QteLotComp}
                                      className="form-control"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      name={"superficieLotComp" + i}
                                      className="form-control"
                                      value={compvalues.superficieLotComp}
                                      onChange={(e) => handleLotComp(e)}
                                      placeholder="Superficie"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      name={"InformationComp" + i}
                                      className="form-control"
                                      value={compvalues.InformationComp}
                                      onChange={(e) => handleLotComp(e)}
                                      placeholder="Information"
                                    />
                                  </td>
                                </tr>
                              )
                            }
                            return persons
                          }()
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Modal.Body>
              ))}
              <Modal.Footer>

                <Button onClick={handleCloseComplementaire} variant='danger' style={{ float: 'left' }}>
                  Fermer
                </Button>
                <Button type="submit" className="btn btn-primary">Enregistrer</Button>

              </Modal.Footer>
            </Form>
          </Modal>

          <Modal show={showSuplementaire} onHide={(e) =>handleCloseSuplementaire(codeIlot, numIlot, lotissmt)} size="xl">
            {ilotID.map((res) => (
              <Modal.Header closeButton key={res.id_iloti}>
                <Modal.Title>
                  <h2>LOTS SUPPLEMENTAIRE DU LOT ({nbrelot})</h2>
                  <b style={{ color: 'red' }}>Lotissement : {res.libelle_loti} &nbsp;&nbsp;&nbsp; Ilot : {res.code_ilot}</b>
                </Modal.Title>
              </Modal.Header>
            ))}
            <Form onSubmit={(e) => handleSubmitSuplementaire(e, codeIlot, nbrelot ,dateDay, numIlot)} id="formSuplementaire">
              {ilotID.map((ress) => (
                <Modal.Body key={ress.id_iloti}>
                  <div>
                    <div className="row mb-5">
                      <div className="col-md-3">
                        <div className='form-group mb-3'>
                          <Form.Label>Date creation Ilot</Form.Label>
                          <p><b style={{ color: 'black' }}>{ress.date_ilot}</b></p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className='form-group mb-3'>
                          <Form.Label>Lotissement</Form.Label>
                          <p><b style={{ color: 'black' }}>{ress.libelle_loti}</b></p>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className='form-group mb-3'>
                          <Form.Label>N° Ilot</Form.Label>
                          <p><b style={{ color: 'black' }}>{ress.numero_ilot}</b></p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      
                      <table id="example" className="table display min-w850">
                        <thead>
                          <tr>
                            <th>N° du lot</th>
                            <th>Quantité</th>
                            <th>Superficie (en m2)</th>
                            <th>Autre Info</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>

                         
                                <tr>
                                  <td>
                                    <input
                                      type="number"
                                      readOnly
                                      name="NumLotSup"
                                      onChange={(e) => handleSuplementaire(e)}
                                      className="form-control"
                                      value={nbrelot}
                                    />
                                  </td>
                                  <td>
                                    <input
                                    readOnly
                                      type="number"
                                      name="QteLotSup"
                                      onChange={(e) => handleSuplementaire(e)}
                                      value='1'
                                      className="form-control"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      name="superficieLotSup"
                                      className="form-control"
                                      value={dataLotsup.superficieLotSup}
                                      onChange={(e) => handleSuplementaire(e)}
                                      placeholder="Superficie"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      name="InformationSup"
                                      className="form-control"
                                      value={dataLotsup.InformationSup}
                                      onChange={(e) => handleSuplementaire(e)}
                                      placeholder="Information"
                                    />
                                  </td>
                                </tr>
                             
                        </tbody>
                      </table>
                    </div>
                  </div>

                </Modal.Body>
              ))}
              <Modal.Footer>

                <Button onClick={(e) =>handleCloseSuplementaire(codeIlot, numIlot, lotissmt)} variant='danger' style={{ float: 'left' }}>
                  Fermer
                </Button>
                <Button type="submit" className="btn btn-primary">Enregistrer</Button>

              </Modal.Footer>
            </Form>
          </Modal>

          <div className="page-titles">
            <ol className="breadcrumb">
              <h1><b style={{ color: "red" }} className="me-5">LOTS / ILOTS</b></h1>
              {/*<button type="button" className="btn btn-outline-primary mb-2 me-4">INTEGRATION DE LOTS</button>*/}
            </ol>
          </div>
          <div className="modal fade bd-example-modal-xl" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Enregistrement des informations</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal">
                  </button>
                </div>
                <form onSubmit={(e) => handleSubmit(e)} encType="multipart/form-data" id="LotForm">
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12 col-lg-12">
                        <div className="basic-form">
                          <div className="row">
                            <div className="mb-5 col-md-3">
                              <label className="form-label">Date</label>
                              <input
                                type="date"
                                name="date"
                                // value={this.state.date}
                                defaultValue={dateDay}
                                onChange={(e) => handle(e)}
                                className="form-control"
                              />
                            </div>
                            <div className="mb-5 col-md-3">
                              <label className="form-label">Lotissement</label>
                              <select className="form-control wide" name="code_loti" onChange={(e) => handleLotissementChange(e)} tabIndex="null">
                                <option value="">lotissement..</option>
                                {listelottissement.map((rows) => (
                                  <option key={rows.id_loti} value={rows.code_loti}>{rows.libelle_loti}</option>
                                ))}
                              </select>
                            </div>
                            <div className="mb-5 col-md-2">
                              <label className="form-label">N° Ilot</label>
                              <select className="form-control wide" name="num_ilot" onChange={(e) => handle(e)} tabIndex="null">
                                <option key={0} value={0}>0</option>
                                {interval_ilos.map((keys) => (
                                  <option key={keys.id} value={keys.numero_ilot}>{keys.numero_ilot}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="row">
                            <h3 className="mb-3">Enregistrement du Lot</h3>
                            <div className="row">
                              <div className="mb-5 col-md-2">
                                <label className="form-label">Debut</label>
                                <input
                                  type="number"
                                  name="debut"
                                  value={debutLot.debut_lot}
                                  placeholder=""
                                  onChange={(e) => { setDebutLot(e.target.value) }}
                                  className="form-control"
                                  required
                                />
                              </div>
                              <div className="mb-5 col-md-2">
                                <label className="form-label">Fin</label>
                                <input
                                  type="number"
                                  name="fin"
                                  value={finLot.fin_lot}
                                  placeholder=""
                                  onChange={(e) => { setFinLot(e.target.value); searchNum(debutLot, e.target.value, dataILot.code_ilot, nbreLots) }}
                                  className="form-control"
                                  required
                                />
                              </div>
                              <div className="mb-5 col-md-2 ">
                                <label className="form-label mb-4"></label>
                                {/* <p><button type="button" className="btn btn-outline-primary mb-2 me-5" onClick={() => AddDynamicInput(debutIlot, FinIlot)}><i className="fa fa-search"></i></button></p>*/}
                              </div>
                            </div>
                            <table id="example" className="table display min-w850">
                              <thead>
                                <tr>
                                  <th>N° du lot</th>
                                  <th>Quantité</th>
                                  <th>Superficie (en m2)</th>
                                  <th>Autre Info</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {function () {
                                  let persons = []

                                  for (let i = parseInt(debutLot); i < parseInt(finLot) + 1; i++) {
                                    persons.push(
                                      <tr key={i}>
                                        <td>
                                          <input
                                            type="number"
                                            readOnly
                                            name={"NumLot" + i}
                                            onChange={(e) => handleLot(e, i)}
                                            value={i || ''}
                                            className="form-control"
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            name={"QteLot" + i}
                                            onChange={(e) => handleLot(e, i)}
                                            value={values.QteLot}
                                            className="form-control"
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            name={"superficieLot" + i}
                                            className="form-control"
                                            value={values.superficieLot}
                                            onChange={(e) => handleLot(e, i)}
                                            placeholder="Superficie"
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            name={"Information" + i}
                                            className="form-control"
                                            value={values.Information}
                                            onChange={(e) => handleLot(e, i)}
                                            placeholder="Information"
                                          />
                                        </td>
                                      </tr>
                                    )
                                  }
                                  return persons
                                }()
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger light" data-bs-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn-primary">Enregistrer</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="card">
              <div className="card-header">
                {/*<h4 className="card-title">Datatable</h4>*/}

                 <div className="mb-5 col-md-3">
                              <label className="form-label">Selectionnez un Lotissement</label>
                              <select className="form-control wide" name="" style={{border: '2px solid #000'}} onChange={(e) => SelectLotissement(e)} tabIndex="null">
                                <option value="">lotissement..</option>
                                {listelottissement.map((rows) => (
                                  <option key={rows.id_loti} value={rows.code_loti}>{rows.libelle_loti}</option>
                                ))}
                              </select>
                            </div>

                <button type="button" className="btn btn-primary mb-2" data-bs-toggle="modal" data-bs-target=".bd-example-modal-xl">Enregistrement</button>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <DataTableExtensions
                    columns={columns}
                    data={listeLotIlot}
                    export={false}
                    print={false}
                  >
                    <DataTable
                      title="DataTable"
                      responsive
                      noHeader
                      defaultSortFieldId={1}
                      defaultSortField="id"
                      defaultSortAsc={false}
                      pagination
                      // selectableRows
                      persistTableHead
                      highlightOnHover
                      exportHeaders={false}
                    />
                  </DataTableExtensions>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  );

}


export default IlotForm;