import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import logo from '../logo-al-sanogo.png';
import Navbar from '../components/Navbar';
import Siderbar from '../components/Siderbar';
import '../print.css';
import PrintStyle from '../PrintStyle';

import { useParams } from 'react-router-dom';

import VentesCRUD from '../services/VentesCRUD';
import UserCRUD from '../services/UserCRUD';
import AuhtUser from '../services/AuthUser';



import { NumberToLetter } from 'convertir-nombre-lettre';



const InvoiceThree = props => {

    const {
        stylesBorder,
        styleHeade,
        // stylesBorder1,
        bodyBorder,
        // bodyBorderEnd,
        smallBody,
        // EndBorder,
        textStylesFooter,
        textStylesFooter1,
        textEndPosition,
        spaceSubHeader,
        // textFooter 
    } = PrintStyle()

    const { numilot } = useParams()
    const { lotiss } = useParams()
    const { codeilot } = useParams()
    const { codevente } = useParams()


    useEffect(() => {
        if (codevente)
            VenteByCode(numilot, lotiss, codeilot, codevente);
            // CountLotvendu(codevente)

    }, [numilot, lotiss, codeilot, codevente]);



 

    const [detailVente, setDetailVente] = useState([])
    const [versements, setVersements] = useState([])
const [reliquats, setReliquats] = useState([])
const [codelotissement, setCodelotissement] = useState([])

    const VenteByCode = (numilot, lotiss, codeilot, codevente) => {

        VentesCRUD.getVente(numilot, lotiss, codeilot, codevente)
            .then((res) => {

                setDetailVente(res.data);
                 setVersements(res.data[0].versement);
            setReliquats(res.data[0].reliquat);
            setCodelotissement(res.data[0].code_loti);

                LotVendu(res.data[0].code_vente)
                console.log(res.data);

            })
            .catch((error) => {
                console.error(error);
            });

    }



    const [detailLotvendu, setDetailLotvendu] = useState([]);

    const LotVendu = (code) => {
        VentesCRUD.getLotVendu(code)
            .then((res) => {

                setDetailLotvendu(res.data);

                console.log(res.data);

            })
            .catch((error) => {
                console.error(error);
            });
    }


    const [dataUnitaire, setDataUnitaire] = useState([]);

    function handlePrixUnitaire(e) {
        const { name, value } = e.target

        setDataUnitaire({
            ...dataUnitaire,
            [name]: value
        })
        console.log(dataUnitaire)
    }


  



   

    // var currr = new Date();
    // currr.setDate(currr.getDate());
    // var dateDay = currr.toISOString().substr(0,10);


    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    var dateDay = dd + '/' + mm + '/' + yyyy;



const { NumberToLetter } = require("convertir-nombre-lettre");


// function currencyFormat(num) {
//    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,')
// }


// const [nbrlots, setNbrLots] = useState([])
//  const CountLotvendu = (codevente) => {
//         VentesCRUD.NbreLotsVendu(codevente)
//             .then((res) => {
//                 setNbrLots(res.data)
//                 console.log(res.data);

//             })
//             .catch((error) => {
//                 console.error(error);
//             });
       
//     }



 function PrintRecu(){
   
                window.print();
          
            
                
            }


    const LinkVente = () => {
                window.location.replace('/Ventes');
        
       
    }



    return (
        <>
            <div id="main-wrapper">
                <Navbar />
                <Siderbar />


                {/* Content body start */}
                <div className="content-body">
                    {/* row */}
                    <div className="container-fluid">
                        {detailVente.map((result) => (

                                <div className="row" key={result.id_vente} id="divPrint" >
                                    <div className="col-12">
                                        {/* Header */}
                                        <div className='row mb-5 logoAl'>
                                            <div className='row'>
                                                <div className='col-8'>
                                                    {/*<img src={logo} alt="Img" style={{ width: '200px' }} /><br />*/}
                                                   <div className='col-4' style={{textAlign:'right'}}>
                                                    </div>
                                                </div>
                                                <div className='col-4 dateprint' style={textEndPosition}>
                                                    Abidjan, le {dateDay}
                                                </div>
                                            </div>
                                        </div>
                                        {/* SubHeader */}
                                        <div className='row mb-2' style={spaceSubHeader}>
                                            <div className='col-6 mb-3' id="InfoFacture">
                                             <table className='mb-3 mt-2' style={{fontSize:'12px'}}>
                                                    <tbody className='tbody'>
                                                <tr>
                                                <td id="InfoVente" style={{textAlign:'right', border:'2px solid #000', padding:'1%'}}>Date facture</td> 
                                                
                                                <td id="InfoVente" style={{textAlign:'left', border:'2px solid #000', padding:'1%'}}>&nbsp;&nbsp;<b>{result.date_vente}</b></td>
                                                </tr>

                                                <tr>
                                                <td id="InfoVente" style={{textAlign:'right', border:'2px solid #000', padding:'1%'}}>N° de client</td> 
                                                
                                                <td id="InfoVente" style={{textAlign:'left', border:'2px solid #000', padding:'1%'}}>&nbsp;&nbsp;<b>{result.numero_acq}</b></td>
                                                </tr>

                                                <tr>
                                                <td id="InfoVente" style={{textAlign:'right', border:'2px solid #000', padding:'1%', minWidth:'160px'}}>Mode de paiement</td> 
                                                
                                                <td style={{textAlign:'left', border:'2px solid #000', padding:'1%'}}>&nbsp;&nbsp;<b>{result.mode_paiement}</b></td>
                                                </tr>

                                                <tr>
                                                <td id="InfoVente" style={{textAlign:'right', border:'2px solid #000', padding:'1%'}}>Lotissement</td> 
                                                
                                                <td id="InfoVente" style={{textAlign:'left', border:'2px solid #000', padding:'1%'}}>&nbsp;&nbsp;<b>{result.libelle_loti}</b></td>
                                                </tr>

                                                <tr>
                                                <td id="InfoVente" style={{textAlign:'right', border:'2px solid #000', padding:'1%'}}>Commune</td> 
                                                
                                                <td id="InfoVente" style={{textAlign:'left', border:'2px solid #000', padding:'1%'}}>&nbsp;&nbsp;<b>{result.commune_loti}</b></td>
                                                </tr>

                                                <tr>
                                                <td id="InfoVente" style={{textAlign:'right', border:'2px solid #000', padding:'1%'}}>Village</td> 
                                                
                                                <td id="InfoVente" style={{textAlign:'left', border:'2px solid #000', padding:'1%'}}>&nbsp;&nbsp;<b>{result.village_loti}</b></td>
                                                </tr>

                                                

                                                <tr>
                                                <td style={{textAlign:'right', border:'2px solid #000', padding:'1%'}}>Emis par</td> 
                                                
                                                <td style={{textAlign:'left', border:'2px solid #000', padding:'1%', minWidth:'200px'}}>&nbsp;&nbsp;<b style={{fontSize:'14px'}}>{result.emis_par}</b></td>
                                                </tr>

                                                </tbody>
                                                </table>
                                            </div>
                                            
                                            <div className='col-4' style={{marginLeft:'15%'}} >
                                               
                                                <table className='table' style={{border:'2px solid #000'}}>
                                                    <thead className='tHead'>
                                                        <tr className='tr'>
                                                            <th style={{border:'none', color:'#000'}} className='thClient'>
                                                                <b>
                                                                    {result.nom_acq} {result.prenom_acq} <br />
                                                                                 {result.profession_acq}<br />   
                                                                                 {result.telephone_acq}<br />   
                                                                                 {result.habitation_acq}<br />   

                                                                   
                                                                    
                                                                    
                                                                </b>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                        </div>
                                        {/* Body */}
                                        <div className="row">
                                        <div className='col-12 mb-3 divFact' 
                                        style={{
                                            backgroundColor:'black', 
                                            fontSize:'1.5em',
                                            textAlign:'center',
                                            padding:'10px',
                                            marginTop:'-10px'
                                        }}>
                                        <div className="row">
                                        <div className='col-2'></div>
                                        <div className='col-8' style={{
                                            backgroundColor:'#fff', 
                                            color:'#000',
                                            border:'2px solid #000' 
                                        }}>
                                             <h3 style={{color:'#000'}}  ><b>FACTURE N° :  <span style={{fontSize:'18px'}}>{result.code_vente}</span></b></h3>
                                        </div>
                                        <div className='col-2'></div>

                                        </div>
                                        </div>
                                            <div className="col-12" style={{marginTop:"-10px"}} >
                                                <div className="row">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                               <th className='footTxtTwo' style={{borderTop:'1px solid #000',borderLeft:'1px solid #000', borderRight:'1px solid #000', borderBottom:'1px solid #000'}}>
                                                                    <b>DESIGNATION</b>
                                                                </th>
                                                                <th className='footTxtTwo' style={{borderTop:'1px solid #000',borderLeft:'1px solid #000', borderRight:'1px solid #000', borderBottom:'1px solid #000'}}>
                                                                    <b>ILOT</b>
                                                                </th>
                                                                <th className='footTxtTwo' style={{borderTop:'1px solid #000',borderLeft:'1px solid #000', borderRight:'1px solid #000', borderBottom:'1px solid #000'}}>
                                                                    <b>LOT</b>
                                                                </th>
                                                                
                                                                <th className='footTxtTwo' style={stylesBorder}>
                                                                    <b>QTE</b>
                                                                </th>
                                                                <th className='footTxtTwo' style={stylesBorder}>
                                                                    <b>P.U HT</b>
                                                                </th>
                                                               
                                                               
                                                                
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {detailLotvendu.map((res) => (
                                                                <tr key={res.id_vendu}>
                                                                    {/*<td style={bodyBorder}>{result.libelle_loti}</td>*/}
                                                                    <td id="lineLot" style={{borderBottom:'none',borderLeft:'1px solid #000', borderRight:'1px solid #000'}}>Terrain</td>
                                                                    <td id="lineLot" style={{borderBottom:'none',borderLeft:'none', borderRight:'1px solid #000'}}>{res.nume_ilot}</td>
                                                                    <td id="lineLot" style={{borderBottom:'none',borderLeft:'none', borderRight:'1px solid #000'}}>
                                                                    {(() => {
                                                                    if (res.remorcele_v === '0') {
                                                                      return (
                                                                        <>{res.lot}</>
                                                                      )
                                                                   
                                                                    } else {
                                                                      return (
                                                                        <>{res.lot} Bis</>
                                                                      )
                                                                    }
                                                                  })
                                                                ()}
                                                                    
                                                                    </td>
                                                                    <td id="lineLot" style={{borderBottom:'none', borderRight:'1px solid #000'}}>
                                                                    {res.qte}

                                                                   
                                                                    </td>
                                                                    <td id="lineLot" style={{borderRight:'1px solid #000', borderBottom:'none', width: '100px'}}>
                                                                       {res.prix_vendu}
                                                                    </td>
                                                           
                                                                   
                                                                </tr>
                                                            ))}

                                                            <tr style={{height:'150px'}}>
                                                            <td style={{borderTop:'none', borderLeft:'1px solid #000', borderRight:'1px solid #000', borderBottom: '1px solid #000', width:'200px'}}></td>
                                                            <td style={{borderTop:'none', borderLeft:'1px solid #000', borderRight:'1px solid #000', borderBottom: '1px solid #000', width:'100px'}}></td>
                                                            <td style={{borderTop:'none', borderLeft:'1px solid #000', borderRight:'1px solid #000', borderBottom: '1px solid #000', width:'100px'}}></td>
                                                            <td style={{borderTop:'none', borderLeft:'1px solid #000', borderRight:'1px solid #000', borderBottom: '1px solid #000', width:'200px'}}></td>
                                                            <td style={{borderTop:'none', borderLeft:'1px solid #000', borderRight:'1px solid #000', borderBottom: '1px solid #000', width:'200px'}}></td>
                                                                 
                                                            

                                                            </tr>

                                                             <tr>
                                                                 <td  id="totalColspan" colSpan="3"></td>
                                                                <td style={smallBody}>
                                                                    <b>Montant Total</b>
                                                                </td>
                                                                <td style={{border:'1px solid #000', textAlign:'center'}}>
                                                                    {/*result.total_pay * 0.18*/}
                                                                    {parseInt(result.total_pay)}
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                 <td id="totalColspan"  colSpan="3"></td>
                                                                <td style={smallBody}>
                                                                    <b>Acompte</b>
                                                                </td>
                                                                <td style={{border:'1px solid #000', textAlign:'center'}}>
                                                                    {parseInt(versements)}
                                                                </td>
                                                            </tr>
                                                        
                                                            
                                                            <tr>
                                                                <td id="totalColspan"  colSpan="3"></td>
                                                                <td style={smallBody}>
                                                                    <b>Reste à payer</b>
                                                                </td>
                                                                <td style={{border:'1px solid #000', textAlign:'center'}}>
                                                                    {parseInt(result.reliquat)}
                                                                </td>
                                                            </tr>
                                                             
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Footer */}
                                        <div className="row" id="signatureP">
                                            <div className="col-12">
                                                <div className="row" style={{marginTop:"-20px"}}>
                                                    <div className="col-6">
                                                        <h6 style={textStylesFooter1}>Arrêté la présente facture à la somme de : <br />
                                                            <b style={textStylesFooter1} style={{textTransform:'uppercase'}}>{NumberToLetter(result.total_pay)} FRANCS CFA</b></h6>
                                                    </div>
                                                    <div className="col-6" style={textStylesFooter}>
                                                        <u>
                                                            <h6>Nom Et Cachet Du Gestionnaire Administratif</h6>
                                                        </u>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* sub Footer */}
                                            
                                               
                                           
                                        </div>
                                    </div>
                                    <>
                                        <button type="button" className="btn btn-danger mt-5 mb-5 me-5 col-2" id="btnRetour" onClick={() => LinkVente()}>Retour</button>
                                        <button type="submit" className="btn btn-outline-primary mt-5 mb-5 col-3" id="btnPrint" onClick={()=> PrintRecu()}>Imprimer</button>
                                    </>
                                </div>
                        ))}
                    </div>
                </div>
                {/* Content body end */}

            </div>
        </>

    );
}
export default InvoiceThree;