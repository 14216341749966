import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import Siderbar from '../../components/Siderbar';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import Swal from 'sweetalert2';
import DocumentPrintCRUD from '../../services/DocumentPrintCRUD';
//import { Button, Modal } from 'react-bootstrap';

const TableReservationLogement = () => {


const LinkReservationLogement = () => {
        window.location.replace('/ReservationLogement');
    }

    useEffect(() => {
        Liste_reservation()
    }, []);

    const [detail, setDetail] = useState([]);

    const Liste_reservation = () => {

        DocumentPrintCRUD.getAllLogement()
            .then((res) => {

                setDetail(res.data);


                console.log(res.data);

            })
            .catch((error) => {
                console.error(error);
            });

    }

    // Colomn cession
    const columns = [
        {
            name: "Date enregistrement",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.date_enreg} </b>
            ),
            sortable: true,
            reorder: true
        },

         {
            name: "Code Reservation",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.code_reserve} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Souscripteur",
            cell: (rows) => (
                rows.souscripteur_nom !== null ? (
                <b style={{ color: "black" }}> {rows.souscripteur_nom} {rows.souscripteur_prenom} </b>
                ) : (
                  <b style={{ color: "black" }}> {rows.soussigne} ({rows.form_juridique})</b>
                )
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Adresse",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.souscripteur_adresse} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Telephone",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.souscripteur_cel1} </b>
            ),
            sortable: true,
            reorder: true
        },
        

        
        {
            name: "Etablir par",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.user_by} </b>
            ),
            sortable: true,
            reorder: true
        },
         {
          name: "Supprimer",
          cell: (rows) => (
              <div>
                <div className="d-flex">

                  <button type="button" className="btn btn-outline-info btn-xs me-2" onClick={() => ViewReservation(rows.id_rst)}><i className="fa fa-eye"></i></button>
                  <button type="button" className="btn btn-outline-danger btn-xs" onClick={() => DeleteReservation(rows.id_rst)}><i className="fa fa-trash"></i></button>
                </div>
              </div>

           
      ),
      sortable: true,
      reorder: true
    },

    ]




     const DeleteReservation = (id) => {
    Swal.fire({
      title: 'Es-tu sûr?',
      html: "Vous ne pourrez pas revenir en arrière!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprime-le!'
    }).then((result) => {
      if (result.isConfirmed) {
        DocumentPrintCRUD.removeLogement(id)
          .then(response => {
            console.log(response.data);
           Liste_reservation()
            Swal.fire(
              'Supprimé!',
              'Votre fichier a été supprimé.',
              'success'
            )
          })
          .catch(e => {
            console.log(e);
          });

      }
    })
  };


    function ViewReservation(id){
         window.location.replace('/DetailReservatioLogement/'+id);
    }

    
    return (
        <div className="">
            <Navbar />
            <Siderbar />

            <div className="content-body">
                <div className="container-fluid">
                    <div className="page-titles">
                        <ol className="breadcrumb">
                            <h1><b style={{ color: "red" }}>Liste reservation de logement</b></h1>
                        </ol>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Datatable</h4>

                                     <button 
                                        type="button" 
                                        className="btn btn-primary mb-2" 
                                        onClick={() => LinkReservationLogement()}>
                                        Effectuer une Reservation
                                        </button>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <DataTableExtensions
                                            columns={columns}
                                            data={detail}
                                            export={false}
                                            print={false}
                                        >
                                            <DataTable
                                                title="DataTable"
                                                // customStyles={customStyles}
                                                responsive
                                                noHeader
                                                defaultSortFieldId={1}
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                // selectableRows
                                                persistTableHead
                                                highlightOnHover
                                                exportHeaders={false}
                                            />
                                        </DataTableExtensions>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableReservationLogement;