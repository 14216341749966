import React, {useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Siderbar from '../components/Siderbar';
// import {Link } from 'react-router-dom';
import '../index.css';

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

import Swal from 'sweetalert2';

import UserCRUD from '../services/UserCRUD';
import AuhtUser from '../services/AuthUser';

import ParametresCRUD from '../services/ParametresCRUD';

import { Button, Form, Modal } from 'react-bootstrap';

function AutresParam(){



 const [roleUs, setRoleUs] = useState()
  const [statut, setStatut] = useState()

  useEffect(() => {
    fetchUserDetail()
    getAllInfosUser(statut)
  }, [statut])

 const fetchUserDetail = () => {
      setStatut(JSON.parse(sessionStorage.getItem('token')))
  }

const getAllInfosUser = () => {

       UserCRUD.get(statut)
      .then((res) => {


        setRoleUs(res.data[0].role)

        var role = res.data[0].role;
         if(role !== "Administrateur"){
        Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Vous n\'être pas autoriser à accèder à cette page' ,
            showConfirmButton: false,
            timer: 5000
          })

        setTimeout(() =>{
            window.location.replace('/Dash')
        }, 5000);
    }
         console.log(res.data);
      })
      .catch((error) => {
        console.warn(error)
      })
    }


const [showModal, setShowModal] = useState(false)

const ModalRoles = () =>{
    setShowModal(true)
}

const handleCloseModal = () =>{
    setShowModal(false)
}

 
 

 const [dataRole, setDataRole] = useState({
    role : "",
    description : "",
 })





const handleInfos = (e) => {
     const { name, value } = e.target;
    const newdata={...dataRole, [name]: value }
    setDataRole(newdata)
    console.log(newdata)
  }



function AddRole(e){

    e.preventDefault();

    const formData = new FormData();

    formData.append('role', dataRole.role)
    formData.append('description', dataRole.description)

   ParametresCRUD.create(formData)
      .then(response => {
        console.log(response.data);

           Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 2000
                    })
            ListRoles()
            handleCloseModal(true)
             setDataRole('')
      })
      .catch(e => {
        console.log(e);
         Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 10000
                    })
      });
}


useEffect(() =>{
     ListRoles()
}, [])

const [listRoles, setListRoles] = useState([])
const ListRoles = () =>{
    ParametresCRUD.getAll()
    .then(res =>{
        console.log(res.data)

        setListRoles(res.data)

    })
    .catch((error) => {
        console.error(error);
      });
}

const columns = [

    {
        name: 'Role',
        cell:(rows) => (
            <b>{rows.title_role}</b>
        ),
      sortable: true,
      reorder: true
    },
    {
        name: 'Description',
        cell:(rows) => (
            <b>{rows.title_role}</b>
        ),
      sortable: true,
      reorder: true
    },
    {
        name: 'Action',
        cell:(rows) => (
             <div>
            <div className="d-flex">

              <button type="button" className="btn btn-danger btn-xs me-2" onClick={() => deleteRole(rows.id) }><i className="fa fa-trash"></i></button>
            </div>
          </div>
        ),
      sortable: true,
      reorder: true
    },
]


 const deleteRole = (id) => {
    Swal.fire({
      title: 'Es-tu sûr?',
      html: "Vous ne pourrez pas revenir en arrière!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprime-le!'
    }).then((result) => {
      if (result.isConfirmed) {
        ParametresCRUD.remove(id)
          .then(response => {
            console.log(response.data);
            ListRoles()
            Swal.fire(
              'Supprimé!',
              'La ligne a été supprimé.',
              'success'
            )
          })
          .catch(e => {
            console.log(e);
          });

      }
    })
  };

    return (

<div className="">
        <Navbar />
        <Siderbar />

        <div className="content-body">
          <div className="container-fluid">

        

 

                <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>
                <h1>Ajouter un role ou Fonction</h1>
              </Modal.Title>
            </Modal.Header>
               <Form onSubmit={(e) => AddRole(e)}>
            <Modal.Body>
              <div className="row">
              <div className="col-lg-12 col-md-12">
              <div className="row">
              <div className="col-lg-6 col-md-6">
              <label>Role ou Fonction</label>
                  <input
                    type="text"
                    className="form-control"
                    name="role"
                    value={dataRole.role}
                    onChange={(e) => handleInfos(e)}
                    placeholder="Role ou fonction..."
                    />
              </div>

               <div className="col-lg-6 col-md-6">
                <label>Description</label>
                  <input
                    type="text"
                    className="form-control"
                    name="description"
                    value={dataRole.description}
                    onChange={(e) => handleInfos(e)}
                    placeholder="Description..."
                    />
              </div>
                  
              </div>
              </div>
                   
              </div>
               
            </Modal.Body>
            <Modal.Footer>

              <Button onClick={handleCloseModal} variant='secondary' className="me-5">
                Fermer
              </Button>
             
               <button className="btn btn-warning" type="submit">Enregistrer</button>
            </Modal.Footer>

            </Form>
          </Modal>





                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12 col-lg-12">
                        <div className="card">
                           
                            <div className="card-body">

                            

                                <div className="basic-form">
                               <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="profile-tab">
                                    <div className="custom-tab-1">
                                        <ul className="nav nav-tabs">
                                            
                                            <li className="nav-item"><a href="#about-me" data-bs-toggle="tab" className="nav-link active show">Role & Fonction</a>
                                            </li>
                                            {/*<li className="nav-item"><a href="#profile-settings" data-bs-toggle="tab" className="nav-link">Modification</a>
                                            </li>*/}
                                        </ul>
                                        <div className="tab-content">
                                            
                                            <div id="about-me" className="tab-pane fade active show">
                                                
                                              
                                                
                                                <div className="row mt-5">
                                                    <h4 className="text-primary mb-4">Role & Fonction</h4>
                                                  
                                                    <div className="col-lg-12 col-md-12">
                                                    <button type="button" className="btn btn-primary mb-2" onClick={(e)=> ModalRoles()}>Création</button>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <DataTableExtensions
                                                          columns={columns}
                                                          data={listRoles}
                                                          export={false}
                                                          print={false}
                                                        >
                                                          <DataTable
                                                            title="DataTable"
                                                            // customStyles={customStyles}
                                                            responsive
                                                            noHeader
                                                            defaultSortFieldId={1}
                                                            defaultSortField="id"
                                                            defaultSortAsc={false}
                                                            pagination
                                                            // selectableRows
                                                            persistTableHead
                                                            highlightOnHover
                                                            exportHeaders={false}
                                                          />
                                                        </DataTableExtensions>
                                                      </div>
                                              
                                                </div>
                                            </div>
                                            {/*<div id="profile-settings" className="tab-pane fade">
                                                <div className="pt-3">
                                                    <div className="settings-form mt-5">
                                                        
                                                        
                                                    </div>
                                                </div>
                                            </div>*/}
                                        </div>
                                    </div>
                                   
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                                        
                                </div>
                            </div>
                          
                        </div>
                    </div>
                </div>

          </div>
        </div>
  </div>

        );
    
}
export default AutresParam;