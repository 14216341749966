import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import logo from '../../logo-al-sanogo.png';

import Navbar from '../../components/Navbar';
import Siderbar from '../../components/Siderbar';
import './css2/print3.css';

import Style from '../../style';


import '../../style.js';
import CompteAcquereurCRUD from '../../services/CompteAcquereurCRUD';

import UserCRUD from '../../services/UserCRUD';
import DocumentPrintCRUD from '../../services/DocumentPrintCRUD';
import Swal from 'sweetalert2';



const Docs = props => {



const [nameuser, setNameuser] = useState()
  const [statut, setStatut] = useState()

  useEffect(() => {
    fetchUserDetail()
    getAllInfosUser(statut)
  }, [statut])

 const fetchUserDetail = () => {
      setStatut(JSON.parse(sessionStorage.getItem('token')))
  }

const getAllInfosUser = () => {

       UserCRUD.get(statut)
      .then((res) => {

        setNameuser(res.data[0].name)
        // setRole(res.data[0].role)
      })
      .catch((error) => {
        console.warn(error)
      })
    }




const {

table,
thead,
th,
tbody,
td,

} = Style()



const PrintDoc = () =>{
    window.print();
}



var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    var dateDay = dd + '/' + mm + '/' + yyyy;


  useEffect(() => {
    getDirect();

  }, []);

  const [direct, setDirect] = useState([]);


  const getDirect = () => {
    CompteAcquereurCRUD.ClientDirect()
      .then((res) => {
        setDirect(res.data);
        // console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const [codeClient, setCodeClient] = useState([]);


function handleClient(e) {
    // const name = e.target.name;
    const value = e.target.value;
    setCodeClient(value)
    // console.log(newdata)
  }


  const [dataSouscripteur, setDataSouscripteur] = useState({
    nom_client : "",
    nbr_terrain : "",
    superficie : "",
    duplex : "",
    nbr_piece : "",
    valeur_bien : "",
    fod : "",
    mt_verse : "",
    mt_restant : "",
    mois : "",
    deux_mois : "",
    trois_mois : "",
    mod_un : "",
    mod_deux : "",
    mod_trois : "",
    periode_paie : "",
    date_limite : "",
    apport_ini : "",
    date_echeance : "",
  })




  function SouscripteurInfos(e){
    const name = e.target.name;
    const value = e.target.value;

    const data = {...dataSouscripteur, [name]: value}

    setDataSouscripteur(data)
     console.log(data)
}



const [numClient, setNumClient] = useState([])

function handleSubmit(e){
    e.preventDefault();

    const formData = new FormData();

    if(codeClient !== ""){
        var nomClient = codeClient;
    }else{
         var nomClient = dataSouscripteur.nom_client;
    }


    formData.append('nom_client', nomClient)
    formData.append('nbr_terrain', dataSouscripteur.nbr_terrain)
    formData.append('superficie', dataSouscripteur.superficie)
    formData.append('duplex', dataSouscripteur.duplex)
    formData.append('nbr_piece', dataSouscripteur.nbr_piece)
    formData.append('valeur_bien', dataSouscripteur.valeur_bien)
    formData.append('fod', dataSouscripteur.fod)
    formData.append('mt_verse', dataSouscripteur.mt_verse)
    formData.append('mt_restant', dataSouscripteur.mt_restant)
    formData.append('mois', dataSouscripteur.mois)
    formData.append('deux_mois', dataSouscripteur.deux_mois)
    formData.append('trois_mois', dataSouscripteur.trois_mois)
    formData.append('mod_un', dataSouscripteur.mod_un)
    formData.append('mod_deux', dataSouscripteur.mod_deux)
    formData.append('mod_trois', dataSouscripteur.mod_trois)
    formData.append('periode_paie', dataSouscripteur.periode_paie)

    formData.append('date_limite', dataSouscripteur.date_limite)
    formData.append('apport_ini', dataSouscripteur.apport_ini)
    formData.append('date_echeance', dataSouscripteur.date_echeance)

   
    formData.append('date', dateDay)
    formData.append('nameuser', nameuser)

    DocumentPrintCRUD.createEcheancier(formData).then((response) =>{
        if(response.data.status === 'success'){
             Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: response.data.message,
              showConfirmButton: false,
              timer: 3000
            })

             setNumClient(response.data.code)

             setTimeout(function(){
                window.print();
             },4000)
        }else{
             window.print();
        }

         if (response.data.status === 'error') {
            Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: response.data.message,
                  showConfirmButton: false,
                  timer: 5000
                })
         }
    })
    .catch((error) => {
            console.error(error.response);
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: error.response.data.message,
              showConfirmButton: false,
              timer: 5000
            })
          });

}



 const LinkTableEcheancier = () => {
        window.location.replace('/TableEcheancier');
    }


    return (
        <>
            <div id="main-wrapper">
                <Navbar />
                <Siderbar />


                {/* Content body start */}
                <div className="content-body">
                    <div className="container-fluid">


                     <div className="col-lg-12" id="dlogo">
                          <img className="" style={{marginLeft: '80px', display:'none'}} id="logo" src={logo} alt="" />
                     </div>

                    <form onSubmit={(e) => handleSubmit(e)} id="">
                      <div className="col-lg-12" id="" >
                      <div className="row">
                      <div className="col-lg-4"></div>

                      <div className="col-lg-4" id="title" style={{border:'2px solid #05f', textAlign:'center', marginTop:'-2%'}}>
                          <h2 style={{marginTop:'1%'}} id="title_titre">FICHE D'ECHEANCIER CLIENT</h2>
                      </div>
                          
                      <div className="col-lg-4"></div>
                     </div>
                     </div>

                    <div className="mb-3 col-md-4" id="client">
                    <label className="form-label">Acquéreur</label>
                    <select className="form-control" id="" required onChange={(e) => handleClient(e)} name="client">
                      <option value="">Client...</option>
                      {direct.map((rows) => (
                        <option key={rows.id_acq} value={rows.nom_acq+' '+rows.prenom_acq}>{rows.nom_acq} {rows.prenom_acq}</option>
                      ))}
                    </select>
                  </div>

                  <div className="">
                                
                                <div className="">

                         <div className="col-lg-12" style={{marginTop:'5%'}} >
                         <div className="row">

                         <div className="col-lg-6" id="codeClit">
                             <h3 style={{fontWeight:'bold', color:'#000'}}  ><b style={{textDecoration:'underline', fontSize:'14px'}} >Nom clt:</b> &nbsp; 
                             

                              {(() => {
                                 if (codeClient !== "") {
                                                              return (
                                                                <>
                             <input type="text" name="nom_client" required id="nom_client" defaultValue={codeClient} onChange={(e) => SouscripteurInfos(e)}  className=""  style={{width: '400px', fontWeight:'bold', border:'none',}}  placeholder="........." />
                               </>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                <>
                             <input type="text" name="nom_client" required id="nom_client"  className="" defaultValue={dataSouscripteur.nom_client} onChange={(e) => SouscripteurInfos(e)} style={{width: '400px', fontWeight:'bold', border:'none',}}  placeholder="........." />

                                                                </>
                                                              )
                                                            }
                                                          })
                                                        ()}
                             
                             </h3>
                         </div>
                        
                             
                         </div>
                         </div>

                         <div className="col-lg-12" id="fiche3">
                                
                                <div className="col-lg-12" style={{marginTop: '2%', position: 'absolute'}} id="entete1">

                                    <p style={{color:'#000', fontWeight:'bold', fontSize:'14px'}} >

                                    <b id="typ_bien">TYPE DE BIEN: &nbsp;&nbsp; TERRAINS(S): &nbsp; 
                                    <input 
                                    type="number" 
                                    name="nbr_terrain" 
                                    id="Einput1" 
                                    className="" 
                                    value={dataSouscripteur.nbr_terrain}
                                    onChange={(e) => SouscripteurInfos(e)}
                                    style={{width: '49px', fontWeight:'bold', textAlign:'center', fontSize:'14px'}} 
                                     />

                                     </b>

                                     &nbsp;&nbsp;
                                    <b id="sup"> SUP: &nbsp;

                                     <input 
                                    type="text" 
                                    name="superficie" 
                                    id="Einput2" 
                                    className="" 
                                    value={dataSouscripteur.superficie}
                                    onChange={(e) => SouscripteurInfos(e)}
                                    style={{ width: '100px', fontWeight:'bold', textAlign:'center', fontSize:'14px'}} 
                                     />

                                     </b>

                                     &nbsp;&nbsp;
                                     DUPLEX: &nbsp;

                                      <input 
                                        type="text" 
                                        name="duplex" 
                                        id="Einput3" 
                                        className="" 
                                        value={dataSouscripteur.duplex}
                                    onChange={(e) => SouscripteurInfos(e)}
                                        style={{width: '49px', fontWeight:'bold', textAlign:'center', fontSize:'14px'}} 
                                         />

                                          &nbsp;&nbsp;

                                          Nbre Pièces:&nbsp;

                                          <input 
                                            type="number" 
                                            name="nbr_piece" 
                                            id="Einput4" 
                                            className="" 
                                            value={dataSouscripteur.nbr_piece}
                                            onChange={(e) => SouscripteurInfos(e)}
                                            style={{width: '75px', fontWeight:'bold', textAlign:'center', fontSize:'14px'}} 
                                             />
                                     </p>  
                            
                                
                                    
                                </div>

                                 <div className="col-lg-12" id="dateEng">
                            <h5 style={{fontWeight:'bold', marginLeft:'90%', color:'#000', marginTop:'2%'}}  ><b style={{textDecoration:'underline', }} >Date:</b>&nbsp; {dateDay} </h5>
                         </div>

                                 <div className="col-lg-12">
                                     <table style={table} id="table">
                                    
                                          <thead>
                                             <th colSpan="8"></th>
                                             <th style={th}><br/></th>
                                         </thead>
                                          <thead>
                                             <th colSpan="8"></th>
                                             <th style={th}>Les mensualités</th>
                                         </thead>

                                         <thead style={thead}>
                                             <th style={th}>N°Clt</th>
                                             {/*<th style={th}>Nom et prénoms</th>*/}
                                             <th style={th}>Valeur du bien <br />(F. CFA)</th>
                                             <th style={th}>FOD</th>
                                             <th style={th}>Mt déjà versé <br /> AI + FOD</th>
                                             <th style={th}>Mt Restant <br /> à Payer</th>
                                             <th style={th}>/Mois</th>
                                             <th style={th}>X2/Mois</th>
                                             <th style={th}>X3/Mois</th>
                                             <th style={th}>Période <br /> de paiement</th>
                                         </thead>
                                         <tbody style={tbody}>
                                             <tr>
                                                 <td style={td}  id="num_cli">{numClient}</td>
                                               
                                                 <td style={td}>
                                                      <input 
                                                    type="number" 
                                                    name="valeur_bien" 
                                                    id="Einput6" 
                                                    className="" 
                                                    required
                                                    value={dataSouscripteur.valeur_bien}
                                                    onChange={(e) => SouscripteurInfos(e)}
                                                    style={{width: '150px', fontWeight:'bold', textAlign:'center', border:'none', fontSize:'14px'}} 
                                                     />
                                                 </td>
                                                 <td style={td} id="fob">
                                                     <input 
                                                    type="number" 
                                                    name="fod" 
                                                    id="Einput7" 
                                                    className="" 
                                                    value={dataSouscripteur.fod}
                                                    onChange={(e) => SouscripteurInfos(e)}
                                                    style={{width: '100px', fontWeight:'bold', textAlign:'center', border:'none', fontSize:'14px'}} 
                                                     />
                                                 </td>
                                                 <td style={td}>
                                                      <input 
                                                    type="number" 
                                                    name="mt_verse" 
                                                    id="Einput8" 
                                                    className="" 
                                                     value={dataSouscripteur.mt_verse}
                                                    onChange={(e) => SouscripteurInfos(e)}
                                                    style={{width: '150px', fontWeight:'bold', textAlign:'center', border:'none', fontSize:'14px'}} 
                                                     />
                                                 </td>
                                                 <td style={td}>
                                                      <input 
                                                    type="number" 
                                                    name="mt_restant" 
                                                    id="Einput9" 
                                                    className="" 
                                                    value={dataSouscripteur.mt_restant}
                                                    onChange={(e) => SouscripteurInfos(e)}
                                                    style={{width: '155px', fontWeight:'bold', textAlign:'center', border:'none', fontSize:'14px'}} 
                                                     />
                                                 </td>
                                                 <td style={td} id="mois">
                                                     <input 
                                                    type="number" 
                                                    name="mois" 
                                                    id="Einput10" 
                                                    className=""
                                                    value={dataSouscripteur.mois}
                                                    onChange={(e) => SouscripteurInfos(e)} 
                                                    style={{width: '100px', fontWeight:'bold', textAlign:'center', border:'none', fontSize:'14px'}} 
                                                     />
                                                 </td>
                                                 <td style={td} >
                                                     <input 
                                                    type="number" 
                                                    name="deux_mois" 
                                                    id="Einput11" 
                                                    className="" 
                                                    value={dataSouscripteur.deux_mois}
                                                    onChange={(e) => SouscripteurInfos(e)}
                                                    style={{width: '100px', fontWeight:'bold', textAlign:'center', border:'none', fontSize:'14px'}} 
                                                     />
                                                 </td>
                                                 <td style={td} id="mois">
                                                     <input 
                                                    type="number" 
                                                    name="trois_mois" 
                                                    id="Einput12" 
                                                    className="" 
                                                    value={dataSouscripteur.trois_mois}
                                                    onChange={(e) => SouscripteurInfos(e)}
                                                    style={{width: '100px', fontWeight:'bold', textAlign:'center', border:'none', fontSize:'14px'}} 
                                                     />
                                                 </td>
                                                 <td style={{borderRight:'1px solid #000'}}></td>
                                                 
                                             </tr>

                                              <tr>
                                                 <td colSpan="5" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', color:'red', textAlign:'right'}}>
                                                 Modalité de paiement &nbsp;
                                                 </td>
                                                 <td style={td}>
                                                      <input 
                                                    type="text" 
                                                    name="mod_un" 
                                                    id="Einput13" 
                                                    className="" 
                                                     value={dataSouscripteur.mod_un}
                                                    onChange={(e) => SouscripteurInfos(e)}
                                                    style={{width: '100px', fontWeight:'bold', textAlign:'center', border:'none', fontSize:'14px'}} 
                                                     />
                                                 </td>
                                                 <td style={td}>
                                                     <input 
                                                    type="text" 
                                                    name="mod_deux" 
                                                    id="Einput14" 
                                                    className="" 
                                                    value={dataSouscripteur.mod_deux}
                                                    onChange={(e) => SouscripteurInfos(e)}
                                                    style={{width: '100px', fontWeight:'bold', textAlign:'center', border:'none', fontSize:'14px'}} 
                                                     />
                                                 </td>
                                                 <td style={td}>
                                                     <input 
                                                    type="text" 
                                                    name="mod_trois" 
                                                    id="Einput15" 
                                                    className=""
                                                    value={dataSouscripteur.mod_trois}
                                                    onChange={(e) => SouscripteurInfos(e)} 
                                                    style={{width: '100px', fontWeight:'bold', textAlign:'center', border:'none', fontSize:'14px'}} 
                                                     />
                                                 </td>
                                                 <td style={{borderRight:'1px solid #000'}}>
                                                     <input 
                                                    type="text" 
                                                    name="periode_paie" 
                                                    id="Einput16" 
                                                    className="" 
                                                    required
                                                    value={dataSouscripteur.periode_paie}
                                                    onChange={(e) => SouscripteurInfos(e)} 
                                                    style={{width: '100px',  fontWeight:'bold', textAlign:'center', border:'none', fontSize:'14px'}} 
                                                     />
                                                 </td>
                                             </tr>

                                             <tr>
                                                 <td colSpan="8" style={{borderRight:'1px solid #000',}}>
                                                     <br />
                                                 </td>
                                                  <td style={{borderRight:'1px solid #000'}}></td>
                                             </tr>

                                             

                                             <tr>
                                                 <td colSpan="5" style={{borderRight:'1px solid #000', borderTop:'1px solid #000', textAlign:'right'}}>
                                                     Dates limites de paiements &nbsp;
                                                 </td>
                                                  <td colSpan="3" style={{borderBottom:'1px solid #000' ,borderRight:'1px solid #000', borderTop:'1px solid #000'}}>
                                                      <input 
                                                    type="text" 
                                                    name="date_limite" 
                                                    id="Einput17" 
                                                    className="" 
                                                    required
                                                    value={dataSouscripteur.date_limite}
                                                    onChange={(e) => SouscripteurInfos(e)} 
                                                    style={{width: '100px', height:'17px',  fontWeight:'bold', textAlign:'center', border:'none', fontSize:'14px'}} 
                                                     />
                                                  </td>

                                                  <td style={{borderRight:'1px solid #000'}}></td>
                                             </tr>

                                             <tr>
                                                 <td colSpan="10" style={{borderRight:'1px solid #000', borderTop:'1px solid #000'}}>
                                                     <br />
                                                 </td>
                                             </tr>

                                             <tr>
                                                 <td style={td}></td>
                                                  <td style={td}>Apport initial (AI)</td>
                                                  <td style={td}>
                                                      <input 
                                                    type="text" 
                                                    name="apport_ini" 
                                                    id="Einput18" 
                                                    className="" 
                                                    required
                                                    value={dataSouscripteur.apport_ini}
                                                    onChange={(e) => SouscripteurInfos(e)} 
                                                    style={{width: '100px',  fontWeight:'bold', textAlign:'center', border:'none', fontSize:'14px'}} 
                                                     />
                                                  </td>
                                                  <td colSpan="7" style={{borderRight:'1px solid #000', borderTop:'1px solid #000'}}></td>
                                             </tr>

                                             <tr>
                                                 <td colSpan="10" style={{borderRight:'1px solid #000', borderTop:'1px solid #000'}}>
                                                     <br />
                                                 </td>
                                             </tr>

                                            <tr>
                                                 <td colSpan="4" style={{borderRight:'1px solid #000', borderTop:'1px solid #000'}}>
                                                    
                                                 </td>
                                                  <td style={td}>Date échéances</td>
                                                  <td style={td}>
                                                      <input 
                                                    type="text" 
                                                    name="date_echeance" 
                                                    id="Einput19" 
                                                    className="" 
                                                    required
                                                    value={dataSouscripteur.date_echeance}
                                                    onChange={(e) => SouscripteurInfos(e)} 
                                                    style={{width: '100px',  fontWeight:'bold', textAlign:'center', border:'none', fontSize:'14px'}} 
                                                     />
                                                  </td>
                                                  <td colSpan="3" style={{borderRight:'1px solid #000', borderTop:'1px solid #000'}}>
                                                 </td>
                                             </tr>

                                         </tbody>
                                     </table>
                                 </div>

                            </div>


                            <div className="col-lg-12" id="signature" style={{marginTop:'5%'}} >
                             <div className="row">

                             <div className="col-lg-3" id="suivi">
                                 <h3 style={{textDecoration:'underline', fontWeight:'bold'}} id="suivi1" >Suivi par:</h3>
                                 <h4 id="suivi2"><i>(N&P de l'Agent, signature + cachet)</i></h4>
                                 <h3 id="suivi3" style={{color:'#000'}} > {nameuser} </h3>

                             </div>

                             <div className="col-lg-6" style={{textAlign:'center'}} >
                                <h4 style={{textDecoration:'underline', fontWeight:'bold',}} id="directeur"  >Signature du directeur <br /> commercial & marketing</h4>
                             </div>


                             <div className="col-lg-3" id="clientSIgn">
                                 <h3 style={{textDecoration:'underline', fontWeight:'bold'}} id="clientSIgn1" >Signature du client:</h3>
                                 <h4 id="clientSIgn2"><i>(Bon pour acceptation + Signature)</i></h4>
                             </div>
                                 
                             </div>
                             </div>

                            </div>
                            </div>
            <button onClick={LinkTableEcheancier} className="btn btn-danger mt-5 mb-5 col-3" style={{float:'left'}} id="btnRetour">Retour</button>

            <button type="submit" className="btn btn-outline-primary mt-5 mb-5 col-3" id="btnPrint">Imprimer</button>
                    
                    </form>

                    </div>

                </div>
                {/* Content body end */}

            </div>

        </>

    );
}
export default Docs;