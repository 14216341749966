import React, { useEffect, useState } from 'react';
import LotIlotCRUD from '../services/LotIlotCRUD';
import LotissementCRUD from '../services/LotissementCRUD';
import CompteAcquereurCRUD from '../services/CompteAcquereurCRUD';
import VentesCRUD from '../services/VentesCRUD';
import UserCRUD from '../services/UserCRUD';
import Swal from 'sweetalert2';

export default function Ventes() {



 const [nameuser, setNameuser] = useState()
  const [role, setRole] = useState()
  // const { token, logout } = AuhtUser()
  const [statut, setStatut] = useState()

  useEffect(() => {
    fetchUserDetail()
    getAllInfosUser(statut)
  }, [statut])

 const fetchUserDetail = () => {
      setStatut(JSON.parse(sessionStorage.getItem('token')))
  }

const getAllInfosUser = () => {

       UserCRUD.get(statut)
      .then((res) => {

        setNameuser(res.data[0].name)
        setRole(res.data[0].role)

        var role = res.data[0].role;
         if(role !== "Administrateur" && role !== "Gestionnaire"){
            Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'Vous n\'être pas autoriser à accèder à cette page' ,
                showConfirmButton: false,
                timer: 5000
              })

            setTimeout(() =>{
                window.location.replace('/Dash')
            }, 5000);
        }

      })
      .catch((error) => {
        console.warn(error)
      })
    }


const [showModal, setShowModal] = useState(false);

  const VenteModal = () => {
    setShowModal(true)
  }

  const handleClose = () => {
    setShowModal(false)
    window.location.reload()
  }

  const [listelottissement, setListelottissement] = useState([]);
  const [direct, setDirect] = useState([]);

  useEffect(() => {
    getLotissement();
    getDirect();
    ListVente();

  }, []);

  const getLotissement = () => {
    LotissementCRUD.getAll()
      .then((res) => {
        setListelottissement(res.data);
        // console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getDirect = () => {
    CompteAcquereurCRUD.ClientDirect()
      .then((res) => {
        setDirect(res.data);
        // console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [dataVente, setDataVente] = useState({
    client: "",
    code_loti: "",
    num_ilot: "",
    montant_total: "",
    montant_versement: "",
    commentaire: "",
    montant_total_update: "",
    montant_versement_update: "",
    mode_paie: "",
    numero_mode: "",
    banque_mode: "",

  });




  function handle(e) {
    const name = e.target.name;
    const value = e.target.value;
    const newdata = { ...dataVente, [name]: value }
    setDataVente(newdata)
    // console.log(newdata)
  }


function handleClient(e) {
    const name = e.target.name;
    const value = e.target.value;
    const newdata = { ...dataVente, [name]: value }
    setDataVente(newdata)
    // console.log(newdata)
  }

  const [showNumModePaie, setShowNumModePaie] = useState(false)

  function handleModePaie(e) {
    const name = e.target.name;
    const value = e.target.value;
    const newdata = { ...dataVente, [name]: value }
    setDataVente(newdata)
    


    if(value === 'Chèque' || value === 'Virement'){
      setShowNumModePaie(true)

    }else{
      setShowNumModePaie(false)
    }
    // console.log(newdata)
  }


  function handleLotissementChange(e) {
    const { name, value } = e.target;
    const newdata = { ...dataVente, [name]: value }
    setDataVente(newdata)
    console.log(e.target.value)
    getILot(e.target.value)
  }

  const [lotsvalues, setValuesLots] = useState([])

  function handleLot(e) {
    const { name, value } = e.target
    setValuesLots({
      ...lotsvalues,
      [name]: value
    })
    // console.log(e.target.value)
  }

 const [dataUnitaire, setDataUnitaire] = useState([]);

    function handlePrixUnitaireLot(e) {
        const { name, value } = e.target

        setDataUnitaire({
            ...dataUnitaire,
            [name]: value
        })
        //console.log(dataUnitaire)
    }
  


 

  const [ilotsListe, setIlotsListe] = useState([]);

  function getILot(code) {
    LotIlotCRUD.getILotDispo(code)
      .then((response) => {
        console.log(response.data);
        setIlotsListe(response.data)
      })
      .catch((error) => {
        console.error(error);
      });
  }



 const columnsIlotsDisponible = [
    
     {
      name: "Lotissement",
      cell: (row) => (
        <b style={{ color: "black" }}>{row.libelle_loti} </b>
      ),
      sortable: true,
      reorder: true
    },
    // {
    //   name: "Code ILOT",
    //   cell: (row) => (
    //     <b style={{ color: "black" }}>{row.code_ilot} </b>
    //   ),
    //   sortable: true,
    //   reorder: true
    // },
    {
      name: "Numéro ILOTS",
      cell: (row) => (
        <b style={{ color: "black" }}>{row.numero_ilot} </b>
      ),
      sortable: true,
      reorder: true
    },
     {
      name: "Selectionnez",
      cell: (row) => (
          <button className="btn btn-secondary btn-xs me-2" onClick={() => viewLots(row.code_ilot, row.numero_ilot, row.lotissement_code, dataVente.client)}>
          <i className="fa fa-hand-pointer"></i>
          </button>
        
      ),
      sortable: true,
      reorder: true
    },
  ]


 const [codeilos, setCodeIlos] = useState([]);
 const [numilos, setNumIlos] = useState([]);
 const [lotissmtV, setLotissmtV] = useState([]);

  const [showModalListLots, setShowModalListLots] = useState(false)



  const [lots, setLots] = useState([]);

  const viewLots = (id, num, lotiss) => {

  setShowModal(false)
    setShowModalListLots(true)
    setCodeIlos(id)
    setNumIlos(num)
    setLotissmtV(lotiss)

    LotIlotCRUD.getLotDispo(id, num)
      .then((res) => {
        setLots(res.data);
         console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

const handleCloseListLot = () =>{
  setShowModalListLots(false)
  //setShowModal(true)
  setDataVente('')
  setMontanTT('')

  window.location.reload()
}

  const [show, setShow] = useState(false)

  const [montanTT, setMontanTT] = useState([]);


  function AddMontant(e, codeIlo, numIlot) {
    setShow((s) => !s)


     e.preventDefault();

    const bodyFormData = new FormData();

     bodyFormData.append('ilot', codeIlo);
     bodyFormData.append('num_ilot', numIlot);


     Object.keys(lotsvalues).map(function (cles) {
      bodyFormData.append(cles, lotsvalues[cles]);
      return [cles, lotsvalues[cles]];
      // console.log(cles)
    });

      Object.keys(dataUnitaire).map(function (cles) {
            bodyFormData.append(cles, dataUnitaire[cles]);
            return [cles, dataUnitaire[cles]];
            // console.log(cles)
          });

           VentesCRUD.Mtt_pu_lot(bodyFormData)
            .then((response) => {
              console.log(response.data);

              setMontanTT(response.data)

            })
            .catch((error) => {
              console.error(error);
            });
  }

  //  const [montantTotal, setMontantTotal] = useState([]);
  // const [montantVersement, setMontantVersement] = useState([]);
  // const [reliqua, setReliqua] = useState([]);

  // const Reliqua = (a, b) =>{
  //    setReliqua(parseInt(a) - parseInt(b))
  //     console.log(parseInt(a));
  //     console.log(parseInt(b));
  // }

  function handleSubmit(event, code_ilot, num_ilot, lotiss) {
    event.preventDefault();

    const bodyFormData = new FormData();

    bodyFormData.append('client', dataVente.client);
    bodyFormData.append('lotissement', lotiss);
    bodyFormData.append('ilot', code_ilot);
    bodyFormData.append('numilot', num_ilot);
    bodyFormData.append('total', montanTT);
    bodyFormData.append('versement', dataVente.montant_versement);
    bodyFormData.append('mode_paie', dataVente.mode_paie);
    bodyFormData.append('numero_mode', dataVente.numero_mode);
    bodyFormData.append('banque_mode', dataVente.banque_mode);
    bodyFormData.append('user_emis', nameuser);
    // console.log(x)

    Object.keys(lotsvalues).map(function (cles) {
      bodyFormData.append(cles, lotsvalues[cles]);
      return [cles, lotsvalues[cles]];
      // console.log(cles)
    });
    // console.log(lotsvalues)

    Object.keys(dataUnitaire).map(function (cles) {
            bodyFormData.append(cles, dataUnitaire[cles]);
            return [cles, dataUnitaire[cles]];
            // console.log(cles)
          });
    
    VentesCRUD.create(bodyFormData)
      .then((response) => {
        if (response.data.status === 'success') {
          // console.log(response.data);
          ListVente()
          setDataVente('')
          setValuesLots('')
          // setLots('')
          // setIlos('')
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: response.data.message,
            showConfirmButton: false,
            timer: 5000
          })
          //handleClose(true)

          if(response.data.reliquat === 0){
            setTimeout(() => {
            window.location.replace('/RecuProforma/'+num_ilot+'/'+lotiss+'/'+code_ilot+'/'+response.data.codeVente);
          }, 5000)

          }else{
            setTimeout(() => {
            window.location.replace('/Recu/'+num_ilot+'/'+lotiss+'/'+code_ilot+'/'+response.data.codeVente);
          }, 5000);
          }
          
        }

        if (response.data.status === 'error') {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: response.data.message,
            showConfirmButton: false,
            timer: 5000
          })
        }

      })
      .catch((error) => {
        console.error(error.response);
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: error.response.data.message,
          showConfirmButton: false,
          timer: 5000
        })
      });
  }


function currencyFormat(num) {
   return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,')
}
// console.log(currencyFormat(5000000)); // $2,665.00

  const [getVente, setGetVente] = useState([])

  function ListVente() {
    VentesCRUD.getAll()
      .then((response) => {
        console.log(response.data);
        setGetVente(response.data)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const columns = [
    // {
    //     name: "N°",
    //     cell: (row) => (
    //         <b style={{color:"black"}}> {row.code_vente} </b>
    //          ),
    //     sortable: true,
    //     reorder: true
    // },
     {
      name: "Date & Heure",
      cell: (row) => (
        <b style={{ color: "black" }}> {row.date_vente} {row.heure_vente} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Lotissement",
      cell: (row) => (
        <b style={{ color: "black" }}> {row.libelle_loti} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Nom & Prenom",
      cell: (row) => (
        <b style={{ color: "black" }}> {row.nom_acq} {row.prenom_acq} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Montant",
      cell: (row) => (
        <b style={{ color: "black" }}> {currencyFormat(parseInt(row.total_pay))} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Versement",
      cell: (row) => (
        <b style={{ color: "black" }}> {currencyFormat(parseInt(row.versement))} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Reste à payer",
      cell: (row) => (
        row.reliquat === '0' ? (
          <b style={{ color: "green" }}> {row.reliquat} </b>
        ) : row.type_acq !== '0' ? (
          <b style={{ color: "red" }}> {currencyFormat(parseInt(row.reliquat))} </b>
        ) : (
          ""
        )
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Paiement",
      cell: (row) => (
        row.reliquat === '0' ? (
          <></>
        ) : row.type_acq !== '0' ? (
          <button className="btn btn-secondary btn-xs me-2" onClick={() => UpdateReliquat(row.num_ilot_v, row.lotissement_v, row.ilot_v, row.code_vente)}><i className="fa fa-edit"></i></button>
        ) : (
          ""
        )
      ),
      sortable: true,
      reorder: true
    },
    // {
    //     name: "Etat vente",
    //     cell: (row) => (
    //         row.reliquat === '0' ? (
    //         <b style={{color:"green"}}><i className="fa fa-check"></i></b>
    //         ) : row.type_acq !== '0' ? (
    //         <b style={{color:"orange"}}>Vente en cours </b>
    //          ) : (
    //          ""
    //         )
    //          ),
    //     sortable: true,
    //     reorder: true
    // },
    {
      name: "Etat Versement",
      cell: (row) => (
        <div>
          <div className="d-flex">
            <button className="btn btn-outline-success btn-xs" onClick={() => ListeVersement(row.num_ilot_v, row.lotissement_v, row.ilot_v, row.code_vente, row.reliquat)}><i className="fa fa-dollar-sign"></i></button>
          </div>
        </div>

      ),
      sortable: true,
      reorder: true
    },

    {
      name: "Desistement",
      cell: (row) => (
        row.type_acq !== '0' ? (
          <div>
            <div className="d-flex">
              <button className="btn btn-outline-primary btn-xs" onClick={() => DesistementVente(row.num_ilot_v, row.lotissement_v, row.ilot_v, row.code_vente)}><i className="flaticon-plugin"></i></button>
            </div>
          </div>
        ) : (
          ""
        )
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Cession",
      cell: (row) => (
        row.ceder === 1 ? (
          <div>
            <div className="d-flex">
              <button className="btn btn-outline-info btn-xs  me-2" onClick={() => ViewDetailCession(row.code_vente)}><i className="fa fa-eye"></i></button>
            </div>
          </div>
        ) : row.ceder !== 1 ? (
          <div>
            <div className="d-flex">
              <button className="btn btn-outline-warning btn-xs  me-2" onClick={() => CederUneParcelle(row.num_ilot_v, row.lotissement_v, row.ilot_v, row.code_vente)}><i className="flaticon-plugin"></i></button>
            </div>
          </div>
        ) : (
          ""
        )
      ),

      sortable: true,
      reorder: true
    },
    {
      name: "Imprimer reçu",
      cell: (row) => (
        row.reliquat === '0' ? (
          <div>
            <div className="d-flex">

              <button type="button" className="btn btn-success btn-xs me-2" onClick={() => ViewRecuProforma(row.num_ilot_v, row.lotissement_v, row.ilot_v, row.code_vente)}><i className="fa fa-print"></i></button>
            </div>
          </div>
        ) : row.type_acq !== '0' ? (
          <div>
            <div className="d-flex">

              <button className="btn btn-secondary btn-xs me-2" onClick={() => ViewRecu(row.num_ilot_v, row.lotissement_v, row.ilot_v, row.code_vente)}><i className="fa fa-print"></i></button>
            </div>
          </div>
        ) : (
          ""
        )
      ),
      width: "150px",                       // added line here
      headerStyle: (selector, id) => {
        return { textAlign: "center" };   // removed partial line here
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Action",
      cell: (row) => (
        row.reliquat === '0' ? (
          <div>
            <div className="d-flex">
              <button className="btn btn-info btn-xs me-2" onClick={() => viewVente(row.ilot_v, row.lotissement_v, row.num_ilot_v, row.code_vente)}><i className="fa fa-eye"></i></button>
            </div>
          </div>
        ) : row.type_acq !== '0' ? (
          <div>
            <div className="d-flex">
              <button className="btn btn-info btn-xs me-2" onClick={() => viewVente(row.ilot_v, row.lotissement_v, row.num_ilot_v, row.code_vente)}><i className="fa fa-eye"></i></button>
              <button className="btn btn-warning btn-xs me-2" onClick={() => UpdateVente(row.num_ilot_v, row.lotissement_v, row.ilot_v, row.code_vente)}><i className="fa fa-edit"></i></button>

              <button className="btn btn-danger btn-xs" onClick={() => deleteVente(row.code_vente)}><i className="fa fa-trash"></i></button>
            </div>
          </div>
        ) : (
          ""
        )
      ),
      width: "150px",                       // added line here
      headerStyle: (selector, id) => {
        return { textAlign: "center" };   // removed partial line here
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ]

  const [showModalVente, setShowModalVente] = useState(false);
  const [detailVente, setDetailVente] = useState([]);
  const [codeVente, setCodeVente] = useState([]);

  const viewVente = (codeilot, lotiss, numilot, id) => {
    setShowModalVente(true)
    setCodeVente(id)
    VentesCRUD.detail_lot_Vendu(id)
      .then((res) => {
        setDetailVente(res.data);
        console.log(res.data);

      })
      .catch((error) => {
        console.error(error);
      });
  }

  const columnsLot = [
    {
      name: "N°Lot",
      cell: (rows) => (
        <b style={{ color: "black" }}> 

        {(() => {
            if (rows.remorcele === '0') {
              return (
                <>{rows.numero_lot}</>
              )
                                                                   
            } else {
              return (
                <>{rows.numero_lot} bis </>
              )
            }
        })
        ()}
        </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Qté",
      cell: (rows) => (
        <b style={{ color: "black" }}> {rows.qte_lot} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Superficie(m2)",
      cell: (rows) => (
        <b style={{ color: "black" }}> {rows.superficie_lot} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Autre Infos",
      cell: (rows) => (
        <b style={{ color: "black" }}> {rows.infos} </b>
      ),
      sortable: true,
      reorder: true
    },
     {
      name: "Prix unitaire",
      cell: (rows) => (
        <b style={{ color: "black" }}> {rows.prix_vendu} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Etat vente",
      cell: (rows) => (
        rows.reliquat === '0' ? (
          <b style={{ color: "green" }}> Vendu </b>
        ) : rows.type_acq !== '0' ? (
          <b style={{ color: "orange" }}>Vente en cours </b>
        ) : (
          ""
        )
      ),
      sortable: true,
      reorder: true
    },
  ]

  const handleCloseModalVente = () => setShowModalVente(false)


 const [n_ilot, setN_ilot] = useState([]);
  const [lotis_c, setLotiss_c] = useState([]);
  const [ilot_c, setIlot_c] = useState([]);

  const [detailVenteUnique, setDetailVenteUnique] = useState([]);
  const [showModalReliquat, setShowModalReliquat] = useState(false);


  const UpdateReliquat = (num_ilot, lotissement_code, ilot_code, code_vente) => {
    setShowModalReliquat(true)
    setCodeVente(code_vente);

    setN_ilot(num_ilot);
    setLotiss_c(lotissement_code);
    setIlot_c(ilot_code);


    VentesCRUD.getVente(num_ilot, lotissement_code, ilot_code, code_vente)
      .then((res) => {
        setDetailVenteUnique(res.data);
        // console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleCloseModalReliquat = () => setShowModalReliquat(false)

  function handleSubmitReliquat(event, nm_ilot, lotiss_code, ilot_code, code) {
    event.preventDefault();

    const bodyFormData = new FormData();

    bodyFormData.append('code_vente', code);
    bodyFormData.append('versement', dataVente.montant_versement);
    bodyFormData.append('mode_paie', dataVente.mode_paie);
    bodyFormData.append('numero_mode', dataVente.numero_mode);
    bodyFormData.append('banque_mode', dataVente.banque_mode);

    // console.log(code);

    VentesCRUD.ResteApayer(bodyFormData)
      .then((response) => {
        if (response.data.status === 'success') {
          // console.log(response.data);
          ListVente()
          setDataVente('')
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: response.data.message,
            showConfirmButton: false,
            timer: 5000
          })

          handleCloseModalReliquat(true)

           setTimeout(() => {
            window.location.replace('/VueRecuVersement/'+nm_ilot+'/'+lotiss_code+'/'+ilot_code+'/'+code+'/'+dataVente.montant_versement+'/'+dataVente.mode_paie);
          }, 3000);


        }
        if (response.data.status === 'error') {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: response.data.message,
            showConfirmButton: false,
            timer: 5000
          })
        }
      })
      .catch((error) => {
        console.error(error.response);
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: error.response.data.message,
          showConfirmButton: false,
          timer: 5000
        })
      });
  }

  /***** DELETE DATA *******/




  const [lotissementD, setLotissementD] = useState([]);
  const [ilotD, setIlotD] = useState([]);
  const [NumilotD, setNumilotD] = useState([]);

  const [detailVenteDesistement, setDetailVenteDesistement] = useState([]);

  const [showModalDesiste, setShowModalDesiste] = useState(false);
  const handleCloseModalDesiste = () => setShowModalDesiste(false)

  const DesistementVente = (num_ilot, lotissement_code, ilot_code, code) => {
    setShowModalDesiste(true)
    setCodeVente(code)
    setNumilotD(num_ilot)

    VentesCRUD.getVente(num_ilot, lotissement_code, ilot_code, code)
      .then((res) => {
        setDetailVenteDesistement(res.data);
        // LotVenduDesist(res.data[0].code_ilot, res.data[0].code_loti, res.data[0].num_ilot_v, res.data[0].code_vente)
        LotVenduDesist(code)

        setLotissementD(res.data[0].code_loti)
        setIlotD(res.data[0].numero_ilot)

        console.log(res.data);

      })
      .catch((error) => {
        console.error(error);
      });

  };


const [detailLotvenduD, setDetailLotvenduD] = useState([]);

  const LotVenduDesist = (code) => {
    VentesCRUD.detail_lot_Vendu(code)
      .then((res) => {

        setDetailLotvenduD(res.data);

        // console.log(res.data);

      })
      .catch((error) => {
        console.error(error);
      });
  }



 const [lotsDessiste, setLotsDessiste] = useState([])

  function handleLotDessiste(e) {
    const { name, value } = e.target
    setLotsDessiste({
      ...lotsDessiste,
      [name]: value
    })

    console.log(e.target.value)
  }


  function handleSubmitDesistement(event, code, lotissementD, ilotD, NumilotD) {
    event.preventDefault();

    const bodyFormData = new FormData();

    bodyFormData.append('code_vente', code);
    bodyFormData.append('code_loti', lotissementD);
    bodyFormData.append('code_ilot', ilotD);
    bodyFormData.append('num_ilot', NumilotD);
    bodyFormData.append('commentaire', dataVente.commentaire);


    Object.keys(lotsDessiste).map(function (cles) {
      bodyFormData.append(cles, lotsDessiste[cles]);
      return [cles, lotsDessiste[cles]];
      // console.log(cles)
    });

    VentesCRUD.Desitements(bodyFormData)
      .then((response) => {
        if (response.data.status === 'success') {
          // console.log(response.data);
          ListVente()
          setDataVente('')
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: response.data.message,
            showConfirmButton: false,
            timer: 5000
          })

          handleCloseModalDesiste(true)
        }

        if (response.data.status === 'error') {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: response.data.message,
            showConfirmButton: false,
            timer: 5000
          })
        }

      })
      .catch((error) => {
        console.error(error.response);
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: error.response.data.message,
          showConfirmButton: false,
          timer: 5000
        })
      });

  }
  const deleteVente = (code) => {
    Swal.fire({
      title: 'Es-tu sûr?',
      html: "Vous ne pourrez pas revenir en arrière! <br> La vente sera supprimer et les lots qui lui sont affilié seront disponible",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprime-le!'
    }).then((result) => {
      if (result.isConfirmed) {
        VentesCRUD.remove(code)
          .then(response => {
            console.log(response.data);
            ListVente()
            Swal.fire(
              'Supprimé!',
              'Votre fichier a été supprimé.',
              'success'
            )
          })
          .catch(e => {
            console.log(e);
          });

      }
    })
  };

  const [showModalUpdateVente, setShowModalUpdateVente] = useState(false);
  const [detailUpdateVente, setDetailUpdateVente] = useState([]);
  const handleCloseModalUpdateVente = () => setShowModalUpdateVente(false)

  const UpdateVente = (num_ilot, lotissement_code, ilot_code, id) => {
    setShowModalUpdateVente(true)
    setCodeVente(id)
    VentesCRUD.getVente(num_ilot, lotissement_code, ilot_code, id)
      .then((res) => {
        setDetailUpdateVente(res.data);
        // viewVenteUpdate(res.data[0].ilot_v, res.data[0].lotissement_v, res.data[0].num_ilot_v, id)
        viewVenteUpdate(id)
        console.log(res.data);

      })
      .catch((error) => {
        console.error(error);
      });
  }

  const [detailVenteUpdate, setDetailVenteUpdate] = useState([]);

  const viewVenteUpdate = (id) => {
        

    VentesCRUD.detail_lot_Vendu(id)
      .then((res) => {
        setDetailVenteUpdate(res.data);
         console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const columnsLotUpdate = [
    {
      name: "N°Lot",
      cell: (rows) => (
        <b style={{ color: "black" }}> {rows.numero_lot} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Qté",
      cell: (rows) => (
        <b style={{ color: "black" }}> {rows.qte_lot} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Superficie(m2)",
      cell: (rows) => (
        <b style={{ color: "black" }}> {rows.superficie_lot} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Autre Infos",
      cell: (rows) => (
        <b style={{ color: "black" }}> {rows.infos} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Etat vente",
      cell: (rows) => (
        rows.reliquat === '0' ? (
          <b style={{ color: "green" }}> Vendu </b>
        ) : rows.type_acq !== '0' ? (
          <b style={{ color: "orange" }}>Vente en cours </b>
        ) : (
          ""
        )
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Retirer",
      cell: (row) => (
        <div>
          <div className="d-flex">
            <button type="button" className="btn btn-outline-primary btn-xs" onClick={() => DeleteLotVendu(row.ilot_v, row.lotissement_v, row.num_ilot_v, row.lot, row.vente, row.remorcele_v)}><i className="fa fa-trash"></i></button>
          </div>
        </div>
      ),
      sortable: true,
      reorder: true
    },

  ]

  function handleSubmitUpdate(event, code) {
    event.preventDefault();

    const bodyFormData = new FormData();

    bodyFormData.append('code_vente', code);
    bodyFormData.append('total', dataVente.montant_total_update);
    bodyFormData.append('versement', dataVente.montant_versement_update);

    // console.log(code);

    VentesCRUD.updateVente(bodyFormData)
      .then((response) => {
        // console.log(response.data);
        if (response.data.status === 'success') {
          ListVente()
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: response.data.message,
            showConfirmButton: false,
            timer: 5000
          })
          handleCloseModalUpdateVente(true)
        }
        if (response.data.status === 'error') {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: response.data.message,
            showConfirmButton: false,
            timer: 5000
          })
        }

      })
      .catch((error) => {
        console.error(error.response);
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: error.response.data.message,
          showConfirmButton: false,
          timer: 5000
        })
      });
  }

  const DeleteLotVendu = (codeilot, lotiss, numilot, lot, code, remorcele) => {
     console.log(codeilot);
     console.log(lotiss);
     console.log(numilot);
     console.log(lot);
     console.log(code);
     console.log(remorcele);
    Swal.fire({
      title: 'Es-tu sûr?',
      html: "Vous ne pourrez pas revenir en arrière! <br> Le  lot sera retiré de la vente et sera disponible",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprime-le!'
    }).then((result) => {
      if (result.isConfirmed) {
        VentesCRUD.removeIlot(codeilot, lot, remorcele)
          .then(response => {
            // console.log(response.data);
            viewVenteUpdate(codeilot, lotiss, numilot, code)
            ListVente()
            Swal.fire(
              'Supprimé!',
              'Votre fichier a été supprimé.',
              'success'
            )
          })
          .catch(e => {
            console.log(e);
          });

      }
    })
  };

  const [showModalPaiement, setShowModalPaiement] = useState(false);
  const [detailPaiement, setDetailPaiement] = useState([]);
  const [reliquatVente, setReliquatVente] = useState([]);

 

  const ListeVersement = (numilot, lotiss_code, codeilot, code, reliquat) => {
    setShowModalPaiement(true)
    setCodeVente(code)
    setReliquatVente(reliquat);

    setN_ilot(numilot);
    setLotiss_c(lotiss_code);
    setIlot_c(codeilot);

    VentesCRUD.getVersement(code)
      .then((res) => {
        setDetailPaiement(res.data);
        // console.log(res.data);

      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleCloseModalPaiement = () => setShowModalPaiement(false)

  var i = 1

  const columnsVersement = [
    {
      name: "N°",
      cell: (rows) => (
        <b style={{ color: "black" }}> {i++} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Date",
      cell: (rows) => (
        <b style={{ color: "black" }}> {rows.date_paie} </b>
      ),
      sortable: true,
      reorder: true
    },

    {
      name: "Mode paiement",
      cell: (rows) => (
        <b style={{ color: "black" }}> {rows.mode_paie} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Versement",
      cell: (rows) => (
        <b style={{ color: "black" }}> {currencyFormat(parseInt(rows.versement_paie))} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Reste",
      cell: (rows) => (
        <b style={{ color: "black" }}> {currencyFormat(parseInt(rows.reliquat_paie))} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Imprimer reçu",
      cell: (rows) => (
        
          <div>
            <div className="d-flex">

              <button 
                type="button" 
                className="btn btn-outline-info btn-xs" 
                onClick={() => RecuVersement(n_ilot, lotis_c, ilot_c, rows.vente_paie, rows.versement_paie, rows.id_paie, rows.mode_paie)}>
                <i className="fa fa-print"></i></button>
            </div>
          </div>
     
      ),
      width: "150px",                       // added line here
      headerStyle: (selector, id) => {
        return { textAlign: "center" };   // removed partial line here
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Retirer",
      cell: (rows) => (
        reliquatVente !== '0' ? (
          <div>
            <div className="d-flex">

              <button type="button" className="btn btn-outline-danger btn-xs" onClick={() => DeletePaiement(rows.id_paie, rows.vente_paie)}><i className="fa fa-trash"></i></button>
            </div>
          </div>

        ) : (
          ""
        )
      ),
      sortable: true,
      reorder: true
    },

  ]




const RecuVersement = (numilot, lotiss, codeilot, code, amount, idvers, mode) =>{
  window.location.replace('/VueRecuVersementDetail/'+numilot+'/'+lotiss+'/'+codeilot+'/'+code+'/'+amount+'/'+idvers+'/'+mode);
}



  const DeletePaiement = (id, code) => {
    console.log(code);
    console.log(id);
    Swal.fire({
      title: 'Es-tu sûr?',
      html: "Vous ne pourrez pas revenir en arrière! <br> Le  versement sera retiré",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprime-le!'
    }).then((result) => {
      if (result.isConfirmed) {
        VentesCRUD.removeVersement(id)
          .then(response => {
            console.log(response.data);
            ListeVersement(code)
            Swal.fire(
              'Supprimé!',
              'Votre fichier a été supprimé.',
              'success'
            )
          })
          .catch(e => {
            console.log(e);
          });

      }
    })
  };

  //////////////////////////////CESSION/////////////////////////////////////////

  const [aquereur, setAquereur] = useState([]);
  const [lotissement, setLotissement] = useState([]);
  const [ilot, setIlot] = useState([]);
  const [numIlot, setNumIlot] = useState([]);

  const [showModalCession, setShowModalCession] = useState(false);
  const [detailVenteCession, setDetailVenteCession] = useState([]);

  const CederUneParcelle = (num_ilot, lotissement_code, ilot_code, id) => {
    setShowModalCession(true)
    setCodeVente(id)
    setNumIlot(num_ilot)

    VentesCRUD.getVente(num_ilot, lotissement_code, ilot_code, id)
      .then((res) => {
        setDetailVenteCession(res.data);
        // LotVenduCession(res.data[0].code_ilot, res.data[0].code_loti, res.data[0].num_ilot_v, res.data[0].code_vente)
        LotVenduCession(id)

        setAquereur(res.data[0].client)
        //setAquereur(res.data[0].nom_acq+' '+res.data[0].prenom_acq)
        setLotissement(res.data[0].code_loti)
        setIlot(res.data[0].numero_ilot)

        console.log(res.data);

      })
      .catch((error) => {
        console.error(error);
      });

  }

  const handleCloseModalCession = () => setShowModalCession(false)


  const [detailLotvenduCession, setDetailLotvenduCession] = useState([]);

  const LotVenduCession = (code) => {
    VentesCRUD.detail_lot_Vendu(code)
      .then((res) => {

        setDetailLotvenduCession(res.data);

        // console.log(res.data);

      })
      .catch((error) => {
        console.error(error);
      });
  }


  const [dataVenteCession, setDataVenteCession] = useState({
    // aquereur_deux: "",
    type_cession: "",
    lien_parent: "",

  });

  function handleCession(e) {
    const name = e.target.name;
    const value = e.target.value;
    const newdata = { ...dataVenteCession, [name]: value }
    setDataVenteCession(newdata)
    console.log(newdata)
  }

const [dataAqcceder, setDataAqcceder] = useState('')
function handleAcquereur(e) {
    const name = e.target.name;
    const value = e.target.value;
    const newdata = { ...dataAqcceder, [name]: value }
    setDataAqcceder(newdata)
    console.log(newdata)
  }

  const [lotsCession, setLotsCession] = useState([])

  function handleLotCession(e) {
    const { name, value } = e.target
    setLotsCession({
      ...lotsCession,
      [name]: value
    })

    console.log(e.target.value)
  }



  const [showLien, setShowLien] = useState(false)

  function HandleChangeTypeCession(e) {
    const name = e.target.name;
    const value = e.target.value;

    const data = { ...dataVenteCession, [name]: value }
    setDataVenteCession(data)
    console.log(data)

    if (value === 'Parent') {
      setShowLien((s) => !s)
    } else {
      setShowLien(false)
      setDataVenteCession(false)
    }

  }



  const [fileAutorisation, setFileAutorisation] = React.useState([]);

  const handleAutorisation = (event) => {
    setFileAutorisation(event.target.files[0])
    console.log(event.target.files[0])
  }



  function handleSubmitCession(event, codeVente, aquereur, lotissement, ilot, numIlot) {
    event.preventDefault();

    // console.log(codeVente)
    // console.log(aquereur)
    // console.log(lotissement)
    // console.log(ilot)

    const bodyFormData = new FormData();


    bodyFormData.append('vente', codeVente);
    bodyFormData.append('client_cess', aquereur);
    bodyFormData.append('lotissement', lotissement);
    bodyFormData.append('ilot', ilot);
    bodyFormData.append('num_ilot', numIlot);
    bodyFormData.append('type', dataVenteCession.type_cession);
    bodyFormData.append('client_cede', dataAqcceder.aquereur_deux);
    bodyFormData.append('membre', dataVenteCession.lien_parent);


    bodyFormData.append("document", fileAutorisation);


    Object.keys(lotsCession).map(function (cles) {
      bodyFormData.append(cles, lotsCession[cles]);
      return [cles, lotsCession[cles]];
      // console.log(cles)
    });
    // console.log(lotsCession)


    VentesCRUD.createCession(bodyFormData)
      .then((response) => {

        if (response.data.status === 'success') {

          console.log(response.data);
          ListVente()

          setDataVenteCession('')
          setFileAutorisation('')

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: response.data.message,
            showConfirmButton: false,
            timer: 5000
          })

          handleCloseModalCession(true)
        }

        if (response.data.status === 'error') {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: response.data.message,
            showConfirmButton: false,
            timer: 5000
          })
        }

      })
      .catch((error) => {
        console.error(error.response);
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: error.response.data.message,
          showConfirmButton: false,
          timer: 5000
        })
      });


  }





  const [showModalDetailCession, setShowModalDetailCession] = useState(false);
  const [detailCession, setDetailCession] = useState([]);

  const ViewDetailCession = (id) => {
    setShowModalDetailCession(true)
    setCodeVente(id)

    VentesCRUD.getCession(id)
      .then((res) => {

        setDetailCession(res.data);


        console.log(res.data);

      })
      .catch((error) => {
        console.error(error);
      });

  }

  const handleCloseModalDetailCession = () => setShowModalDetailCession(false)


  const columnsCessionVente = [

    {
      name: "1er Acquéreur",
      cell: (rows) => (
        <b style={{ color: "black" }}> {rows.client_cess} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Date cession",
      cell: (rows) => (
        <b style={{ color: "black" }}> {rows.date_cess} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Doc autorisation",
      cell: (rows) => (
        <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/" + rows.document_cess} target={'_blank'} rel="noreferrer">
          <i className="fa fa-download fa-2x"></i>
        </a>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Lotissement",
      cell: (rows) => (
        <b style={{ color: "black" }}> {rows.libelle_loti} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "N°Ilot",
      cell: (rows) => (
        <b style={{ color: "black" }}> {rows.num_ilot_cess} </b>
      ),
      sortable: true,
      reorder: true
    },

    {
      name: "N°Lot",
      cell: (rows) => (

        <b style={{ color: "black" }}> {rows.lot_cess} </b>
      ),
      sortable: true,
      reorder: true
    },

  ]

  const ViewRecu = (num_ilot, lotissement_code, ilot_code, code_vente) => {
    window.location.replace('/DetailRecu/' + num_ilot +'/'+ lotissement_code +'/'+ ilot_code +'/'+ code_vente);
  }

  const ViewRecuProforma = (num_ilot, lotissement_code, ilot_code, code_vente) => {
    window.location.replace('/DetailRecuProforma/' + num_ilot +'/'+ lotissement_code +'/'+ ilot_code +'/'+ code_vente);
  }
  

 const PrintTabPaimement = (code) => {
    window.location.replace('/PrintListPaiement/' + code);
  }


 // const LinkPrintAllVente = () => {
 //        window.location.replace('/TableVentes');
 //    }


 //     const LinkPrintAllPaid = () => {
 //        window.location.replace('/TablePaiement');
 //    }

  return{
showModalCession,
handleCloseModalCession,
codeVente,
handleSubmitCession,
aquereur,
lotissement,
ilot,
detailVenteCession,
detailLotvenduCession,
handleLotCession,
handleCession,
handleAcquereur,
direct,
HandleChangeTypeCession,
showLien,
dataVenteCession,
handleAutorisation,
showModalDetailCession,
handleCloseModalDetailCession,
columnsCessionVente,
detailCession,
showModalVente,
handleCloseModalVente,
columnsLot,
detailVente,
showModalReliquat,
handleCloseModalReliquat,
handleSubmitReliquat,
n_ilot,
lotis_c,
ilot_c,
detailVenteUnique,
dataVente,
handle,
showModalDesiste,
handleCloseModalDesiste,
handleSubmitDesistement,
lotissementD,
ilotD,
NumilotD,
numIlot,
detailVenteDesistement,
detailLotvenduD,
handleLotDessiste,
showModalUpdateVente,
handleCloseModalUpdateVente,
handleSubmitUpdate,
detailUpdateVente,
columnsLotUpdate,
detailVenteUpdate,
showModal,
handleClose,
handleClient,
handleLotissementChange,
listelottissement,
columnsIlotsDisponible,
ilotsListe,
showModalListLots,
handleCloseListLot,
handleSubmit,
codeilos,
numilos,
lotissmtV,
lots,
handleLot,
handlePrixUnitaireLot,
dataUnitaire,
AddMontant,
show,
montanTT,
handleModePaie,
showNumModePaie,
showModalPaiement,
handleCloseModalPaiement,
columnsVersement,
detailPaiement,
PrintTabPaimement,
VenteModal,
columns,
getVente,
  }

}