import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import logo from '../../logo-al-sanogo.png';
import Navbar from '../../components/Navbar';
import Siderbar from '../../components/Siderbar';
import './css/print.css';
import CompteAcquereurCRUD from '../../services/CompteAcquereurCRUD';
import DocumentPrintCRUD from '../../services/DocumentPrintCRUD';
import UserCRUD from '../../services/UserCRUD';
import Swal from 'sweetalert2';
import Style from '../../style';




const Docs = props => {


const {

table,


} = Style()


const [nameuser, setNameuser] = useState()
  const [statut, setStatut] = useState()

  useEffect(() => {
    fetchUserDetail()
    getAllInfosUser(statut)
  }, [statut])

 const fetchUserDetail = () => {
      setStatut(JSON.parse(sessionStorage.getItem('token')))
  }

const getAllInfosUser = () => {

       UserCRUD.get(statut)
      .then((res) => {

        setNameuser(res.data[0].name)
        // setRole(res.data[0].role)
      })
      .catch((error) => {
        console.warn(error)
      })
    }







var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    var dateDay = dd + '/' + mm + '/' + yyyy;




useEffect(() => {
    getDirect();

  }, []);


const [direct, setDirect] = useState([]);


  const getDirect = () => {
    CompteAcquereurCRUD.ClientDirect()
      .then((res) => {
        setDirect(res.data);
        // console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const [codeClient, setCodeClient] = useState([]);


function handleClient(e) {
    const name = e.target.name;
    const value = e.target.value;
    setCodeClient(value)
    getDirectBycode(value)
    // console.log(newdata)
  }

  const [nomClient, setNomClient] = useState([]);
  const [prenomClient, setPrenomClient] = useState([]);
  const [adresseClient, setAdresseClient] = useState([]);
  const [telClient, setTelClient] = useState([]);
  //const [detailClient, setDetailClient] = useState([]);

 function getDirectBycode(code) {
    CompteAcquereurCRUD.get(code)
      .then((res) => {
        //setDetailClient(res.data);

        setNomClient(res.data[0].nom_acq);
        setPrenomClient(res.data[0].prenom_acq);
        setAdresseClient(res.data[0].habitation_acq);
        setTelClient(res.data[0].telephone_acq);
        // console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };


const [dataSouscripteur, setDataSouscripteur] = useState({
    sousc_nom: "",
    sousc_prenom: "",
    sousc_adresse: "",
    sousc_cel1: "",
    sousc_cel2: "",
    sousc_ville: "",
    co_sousc_nom: "",
    co_sousc_prenom: "",
    co_sousc_adresse: "",
    co_sousc_cel1: "",
    co_sousc_cel2: "",
    co_sousc_ville: "",
    checkbox1: "",
    checkbox2: "",
    checkbox3: "",

    prix_lettre: "",
    pourcentage: "",
    num_pourcentage: "",

    nbr_piece_villa : "",
    nbr_piece_duplexe : "",
    nbr_piece_appart : "",

    periode_de:"",
    fait_a:"",

    societe_titre: "",
    societe_form: "",
    societe_legal: "",
    societe_nom: "",
    societe_prenom:"",
    societe_qualite: "",

    checkbox4: "",
    checkbox5: "",
    checkbox6: "",
})

function SouscripteurInfos(e){
    const name = e.target.name;
    const value = e.target.value;

    const data = {...dataSouscripteur, [name]: value}

    setDataSouscripteur(data)
     console.log(data)
}


const [codeResev, setCodeReserv] = useState([])


function handleSubmit(e){
    e.preventDefault();

    const formData = new FormData();

     if(nomClient !== ""){
        var nom_clt = nomClient;
    }else{
         var nom_clt = dataSouscripteur.sousc_nom;
    }

     if(prenomClient !== ""){
        var prenom_clt = prenomClient;
    }else{
         var prenom_clt = dataSouscripteur.sousc_prenom;
    }

    if(adresseClient !== ""){
        var adresse = adresseClient;
    }else{
         var adresse = dataSouscripteur.sousc_adresse;
    }

     if(telClient !== ""){
        var tel = telClient;
    }else{
         var tel = dataSouscripteur.sousc_cel1;
    }

    formData.append('codeClient', codeClient)
    formData.append('sousc_nom', dataSouscripteur.sousc_nom)
    formData.append('sousc_prenom', dataSouscripteur.sousc_prenom)
    formData.append('sousc_adresse', dataSouscripteur.sousc_adresse)
    formData.append('sousc_cel1', dataSouscripteur.sousc_cel1)
    formData.append('sousc_cel2', dataSouscripteur.sousc_cel2)
    formData.append('sousc_ville', dataSouscripteur.sousc_ville)
    formData.append('co_sousc_nom', dataSouscripteur.co_sousc_nom)
    formData.append('co_sousc_prenom', dataSouscripteur.co_sousc_prenom)
    formData.append('co_sousc_adresse', dataSouscripteur.co_sousc_adresse)
    formData.append('co_sousc_cel1', dataSouscripteur.co_sousc_cel1)
    formData.append('co_sousc_cel2', dataSouscripteur.co_sousc_cel2)
    formData.append('co_sousc_ville', dataSouscripteur.co_sousc_ville)
    formData.append('checkbox1', dataSouscripteur.checkbox1)
    formData.append('checkbox2', dataSouscripteur.checkbox2)
    formData.append('checkbox3', dataSouscripteur.checkbox3)

    formData.append('checkbox4', dataSouscripteur.checkbox4)
    formData.append('checkbox5', dataSouscripteur.checkbox5)
    formData.append('checkbox6', dataSouscripteur.checkbox6)

    formData.append('societe_titre', dataSouscripteur.societe_titre)
    formData.append('societe_form', dataSouscripteur.societe_form)
    formData.append('societe_legal', dataSouscripteur.societe_legal)
    formData.append('societe_nom', dataSouscripteur.societe_nom)
    formData.append('societe_prenom', dataSouscripteur.societe_prenom)
    formData.append('societe_qualite', dataSouscripteur.societe_qualite)



    formData.append('pourcentage', dataSouscripteur.pourcentage)
    formData.append('num_pourcentage', dataSouscripteur.num_pourcentage)
    formData.append('nbr_piece_villa', dataSouscripteur.nbr_piece_villa)
    formData.append('nbr_piece_duplexe', dataSouscripteur.nbr_piece_duplexe)
    formData.append('nbr_piece_appart', dataSouscripteur.nbr_piece_appart)

    formData.append('prix_lettre', dataSouscripteur.prix_lettre)
    formData.append('lieu', dataSouscripteur.fait_a)
    formData.append('periode_de', dataSouscripteur.periode_de)
    formData.append('date', dateDay)
    formData.append('nameuser', nameuser)

    DocumentPrintCRUD.createLogement(formData).then((response) =>{
        if(response.data.status === 'success'){
             Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: response.data.message,
              showConfirmButton: false,
              timer: 3000
            })

             setCodeReserv(response.data.code)

             setTimeout(function(){
                window.print();
             },4000)
        }else{
             window.print();
        }

         if (response.data.status === 'error') {
            Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: response.data.message,
                  showConfirmButton: false,
                  timer: 5000
                })
         }
    })
    .catch((error) => {
            console.error(error.response);
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: error.response.data.message,
              showConfirmButton: false,
              timer: 5000
            })
          });

}


// const PrintDoc = () =>{
//     window.print();
// }


const LinkReservationLogement = () => {
        window.location.replace('/ListReservationLogement');
    }


    return (
        <>
            <div id="main-wrapper">
                <Navbar />
                <Siderbar />


                {/* Content body start */}
                <div className="content-body">
                    <div className="container-fluid">

                    <div className="col-lg-12" id="dlogo" >
                          <img className="" style={{display:'none'}} id="logo" src={logo} alt="" />
                     </div>


                    <div className="mb-3 col-md-4" id="client">
                    <label className="form-label">Acquéreur</label>
                    <select className="form-control" id="" required onChange={(e) => handleClient(e)} name="client">
                      <option value="">Client...</option>
                      {direct.map((rows) => (
                        <option key={rows.id_acq} value={rows.numero_acq}>{rows.nom_acq} {rows.prenom_acq}</option>
                      ))}
                    </select>
                  </div>


                   <div className="col-lg-12" id="dtitle" >
                      <div className="row">
                      <div className="col-lg-3"></div>

                      <div className="col-lg-6" id="Rtitle" style={{border:'2px solid #000', textAlign:'center', marginTop:'2%'}}>
                          <h3 style={{marginTop:'1%', fontWeight:'bold'}} id="titlename">FICHE DE RESERVATION DE LOGEMENT(S)</h3>
                      </div>
                          
                      <div className="col-lg-3"></div>
                     </div>
                     </div>

                      <div className="col-lg-12">
                      <div className="row">
                      <div className="col-lg-6">
                        </div>
                        <div className="col-lg-6" style={{textAlign:'right', marginTop:'1%'}} >
                            <h4 style={{fontWeight:'bold'}} >CODE Clt: {codeResev} </h4>&nbsp;
                        </div>
                        </div>
                        </div>

                  <form onSubmit={(e) => handleSubmit(e)} id="">

                         <div id="fiche1">

                         <div className="col-lg-12">
                            <table style={table}>
                                <thead>
                                <tr>
                                    <th colSpan="4" id="titreS1" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc'}} >
                                        <h3 style={{fontWeight:'bold'}} >ESPACE RESERVE AU(X) PARTICULIER(S)</h3>
                                    </th>
                                </tr>
                                <tr>
                                    <th colSpan="4" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc'}} >
                                        <h4 style={{textAlign:'left',fontWeight:'bold'}} >&nbsp;&nbsp;Nous soussignés,</h4>
                                    </th>
                                </tr>
                                <tr>

                                    <th colSpan="2" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', borderRight:'1px solid #cccc'}} >
                                        <h4 style={{color:'#05f', textAlign:'left', fontWeight:'bold'}} >&nbsp;&nbsp;Souscripteur</h4>
                                    </th>
                                    <th colSpan="2" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc'}} >
                                        <h4 style={{color:'red', fontWeight:'bold', textAlign:'left'}}>&nbsp;&nbsp;Co-souscripteur</h4>
                                    </th>
                                </tr>

                                </thead>
                                <tbody>
                                    <tr>
                                       <td colSpan="2" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', borderRight:'1px solid #cccc', textAlign:'left'}}>
                                       <h4>&nbsp;&nbsp;Nom: &nbsp;

                                       {(() => {
                                 if (nomClient !== "") {
                                                              return (
                                                                <>
                                    <input 
                                       type="text" 
                                       name="sousc_nom" 
                                       id="input1" 
                                       defaultValue={nomClient} 
                                       onChange={(e) => SouscripteurInfos(e)} 
                                       className="" 
                                       style={{border: 'none', fontWeight:'bold'}}
                                       placeholder="........." />
                               </>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                <>
                                     <input 
                                      type="text" 
                                       name="sousc_nom" 
                                       id="input1" 
                                       vlue={dataSouscripteur.sousc_nom} 
                                       onChange={(e) => SouscripteurInfos(e)} 
                                       className="" 
                                       style={{border: 'none', fontWeight:'bold'}}
                                       placeholder="........." />

                                                                </>
                                                              )
                                                            }
                                                          })
                                                        ()}

                                       

                                       </h4>
                                       </td> 
                                       <td colSpan="2" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  textAlign:'left'}}>
                                       <h4>&nbsp;&nbsp;Nom:&nbsp;
                                        <input 
                                        type="text" 
                                        name="co_sousc_nom" 
                                        id="input1_2" 
                                        value={dataSouscripteur.co_sousc_nom} 
                                        onChange={(e) => SouscripteurInfos(e)} 
                                        className="" 
                                        style={{border: 'none', fontWeight:'bold'}} 
                                        placeholder="........." />

                                       </h4>
                                       </td> 
                                    </tr>

                                    <tr>
                                       <td colSpan="2" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', borderRight:'1px solid #cccc', textAlign:'left'}}>
                                       <h4>&nbsp;&nbsp;Prénom(s):&nbsp;
                                         

                                          {(() => {
                                 if (prenomClient !== "") {
                                                              return (
                                                                <>
                                    <input 
                                       type="text" 
                                         name="sousc_prenom" 
                                         id="input2"  
                                       defaultValue={prenomClient} 
                                       onChange={(e) => SouscripteurInfos(e)} 
                                       className="" 
                                       style={{border: 'none', fontWeight:'bold', width:'78%'}} 
                                         placeholder="........." />
                               </>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                <>
                                     <input 
                                      type="text" 
                                        name="sousc_prenom" 
                                         id="input2" 
                                       vlue={dataSouscripteur.sousc_prenom} 
                                       onChange={(e) => SouscripteurInfos(e)} 
                                       className="" 
                                      style={{border: 'none', fontWeight:'bold', width:'78%'}} 
                                         placeholder="........." />

                                                                </>
                                                              )
                                                            }
                                                          })
                                                        ()}

                                       </h4>
                                       </td> 
                                       <td colSpan="2" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', textAlign:'left'}}>
                                       <h4>&nbsp;&nbsp;Prénom(s):&nbsp;
                                         <input 
                                         type="text" 
                                         name="co_sousc_prenom" 
                                         id="input2_2" 
                                         value={dataSouscripteur.co_sousc_prenom} 
                                         onChange={(e) => SouscripteurInfos(e)} 
                                         className="" 
                                         style={{border: 'none', fontWeight:'bold', width:'78%'}} 
                                         placeholder="........." />

                                       </h4>
                                       </td> 
                                    </tr>

                                    <tr>
                                       <td id="td_adresse" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', textAlign:'left'}}>
                                       <h4>&nbsp;&nbsp;Adresse:
                                        {(() => {
                                 if (adresseClient !== "") {
                                                              return (
                                                                <>
                             <input 
                             type="text" 
                             name="sousc_adresse" 
                             id="input3" 
                             defaultValue={adresseClient} 
                             onChange={(e) => SouscripteurInfos(e)} 
                             className="" 
                             style={{width: '65%', border: 'none', fontWeight:'bold'}} 
                             placeholder="........." />
                               </>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                <>
                             <input 
                             type="text" 
                             name="sousc_adresse" 
                             id="input3" 
                             value={dataSouscripteur.sousc_adresse} 
                             onChange={(e) => SouscripteurInfos(e)} 
                             className="" style={{width: '65%', border: 'none', fontWeight:'bold'}} 
                             placeholder="........." />

                                                                </>
                                                              )
                                                            }
                                                          })
                                                        ()}
                                       </h4>
                                       </td> 
                                       <td id="td_ville" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', borderRight:'1px solid #cccc', textAlign:'left'}}>
                                       <h4 id="sc_ville">&nbsp;&nbsp;Ville:
                                     <input 
                                     type="text" 
                                     name="sousc_ville" 
                                     id="input3_1" 
                                     value={dataSouscripteur.sousc_ville} 
                                     onChange={(e) => SouscripteurInfos(e)} 
                                     className="" style={{width: '70%', border: 'none', fontWeight:'bold'}} 
                                     placeholder="........." />

                                       </h4>
                                       </td> 

                                        <td id="td_adresse" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', textAlign:'left'}}>
                                       <h4>&nbsp;&nbsp;Adresse:
                                         <input 
                                         type="text" 
                                         name="co_sousc_adresse" 
                                         id="input3_2" 
                                         value={dataSouscripteur.co_sousc_adresse} 
                                         onChange={(e) => SouscripteurInfos(e)} 
                                         className="" 
                                         style={{width: '65%', border: 'none', fontWeight:'bold'}} 
                                         placeholder="........." />

                                       </h4>
                                       </td> 
                                       <td id="td_ville" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', textAlign:'left'}}>
                                       <h4 id="co_ville">&nbsp;&nbsp;Ville:

                                         <input 
                                         type="text" 
                                         name="co_sousc_ville" 
                                         id="input3_2_3" 
                                         value={dataSouscripteur.co_sousc_ville} 
                                         onChange={(e) => SouscripteurInfos(e)} 
                                         className="" style={{width: '65%', border: 'none', fontWeight:'bold'}} 
                                         placeholder="........." />

                                       </h4>
                                       </td> 

                                    </tr>


                                     <tr>
                                       <td colSpan="2" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', borderRight:'1px solid #cccc', textAlign:'left'}}>
                                       <h4>&nbsp;&nbsp;Cel 1:&nbsp;

                                        {(() => {
                                 if (telClient !== null) {
                                                              return (
                                                                <>
                                               <input 
                                               type="text" 
                                               name="sousc_cel1" 
                                               id="input3_3" 
                                               defaultValue={telClient} 
                                               onChange={(e) => SouscripteurInfos(e)} 
                                               className="" 
                                               style={{border: 'none', fontWeight:'bold'}} 
                                               placeholder="........." />
                                                     </>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                <>
                                              <input 
                                              type="text" 
                                              name="sousc_cel1" 
                                              id="input3_3" 
                                              defaultValue={dataSouscripteur.sousc_cel1} 
                                              onChange={(e) => SouscripteurInfos(e)} 
                                              className="" 
                                              style={{border: 'none', fontWeight:'bold'}} 
                                              placeholder="........." />
                                                                </>
                                                              )
                                                            }
                                                          })
                                                        ()}

                                       </h4>
                                       </td> 
                                       <td colSpan="2" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', textAlign:'left'}}>
                                       <h4>&nbsp;&nbsp;Cel 1:&nbsp;

                                        <input 
                                        type="text" 
                                        name="co_sousc_cel1" 
                                        id="input3_4" 
                                        value={dataSouscripteur.co_sousc_cel1} 
                                        onChange={(e) => SouscripteurInfos(e)} 
                                        className="" 
                                        style={{border: 'none', fontWeight:'bold'}} 
                                        placeholder="........." />

                                       </h4>
                                       </td> 
                                    </tr>


                                     <tr>
                                       <td colSpan="2" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', borderRight:'1px solid #cccc', textAlign:'left'}}>
                                       <h4>&nbsp;&nbsp;Cel 2:&nbsp;
                             <input 
                             type="text" 
                             name="sousc_cel2" 
                             id="input4" 
                             value={dataSouscripteur.sousc_cel2} 
                             onChange={(e) => SouscripteurInfos(e)} 
                             className="" 
                             style={{border: 'none', fontWeight:'bold'}} 
                             placeholder="........." />

                                       </h4>
                                       </td> 
                                       <td colSpan="2" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', textAlign:'left'}}>
                                       <h4>&nbsp;&nbsp;Cel 2:&nbsp;
                             <input 
                             type="text" 
                             name="co_sousc_cel2" 
                             id="input4_2" 
                             value={dataSouscripteur.co_sousc_cel2} 
                             onChange={(e) => SouscripteurInfos(e)} 
                             className="" 
                             style={{border: 'none', fontWeight:'bold'}} 
                             placeholder="........." />

                                       </h4>
                                       </td> 
                                    </tr>

                                </tbody>
                            </table>
                        </div>



                        <div className="col-lg-12">
                            <table style={table}>
                                <thead>
                                <tr>
                                    <th colSpan="4" id="titreS2" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  borderRight:'1px solid #cccc', borderLeft:'1px solid #cccc'}} >
                                        <h3 style={{fontWeight:'bold'}}>ESPACESPACE RESERVE AUX SOCIETES</h3>
                                    </th>
                                </tr>
                               

                                

                                </thead>
                                <tbody>
                                <tr>
                                    <th colSpan="4" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  borderRight:'1px solid #cccc', borderLeft:'1px solid #cccc'}} >
                                        <h4 style={{textAlign:'left',fontWeight:'bold'}} >&nbsp;&nbsp;Nous soussignés,</h4>
                                    </th>
                                    
                                </tr>
                                <tr>
                                   
                                    <td colSpan="4" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  borderRight:'1px solid #cccc', borderLeft:'1px solid #cccc'}} >
                                    <input 
                                    type="text" 
                                    name="societe_titre" 
                                    id="input8" 
                                    value={dataSouscripteur.societe_titre} 
                                    onChange={(e) => SouscripteurInfos(e)} 
                                    className="" 
                                    style={{border: 'none', fontWeight:'bold', width:'100%', marginLeft:'5px', width:'99%'}} 
                                    placeholder="........." />

                                    </td>
                                </tr>


                                 <tr>
                                    <th colSpan="4" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  borderRight:'1px solid #cccc', borderLeft:'1px solid #cccc'}} >
                                        <h4 style={{textAlign:'left',fontWeight:'bold'}} >&nbsp;&nbsp;Forme juridique</h4>
                                    </th>
                                    
                                </tr>
                                <tr>
                                   
                                    <td colSpan="4" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  borderRight:'1px solid #cccc', borderLeft:'1px solid #cccc'}} >
                                     <input 
                                     type="text" 
                                     name="societe_form" 
                                     id="input9" 
                                     value={dataSouscripteur.societe_form} 
                                     onChange={(e) => SouscripteurInfos(e)} 
                                     className="" 
                                     style={{border: 'none', fontWeight:'bold', width:'100%', marginLeft:'5px', width:'99%'}} 
                                     placeholder="........." />

                                    </td>
                                </tr>


                                 <tr>
                                    <th colSpan="4" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  borderRight:'1px solid #cccc', borderLeft:'1px solid #cccc'}} >
                                        <h4 style={{textAlign:'left',fontWeight:'bold'}} >&nbsp;&nbsp;Représentant légal de la personne morale</h4>
                                    </th>
                                    
                                </tr>
                                <tr>
                                   
                                    <td colSpan="4" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  borderRight:'1px solid #cccc', borderLeft:'1px solid #cccc'}} >
                                        <input 
                                        type="text" 
                                        name="societe_legal" 
                                        id="input10" 
                                        value={dataSouscripteur.societe_legal} 
                                        onChange={(e) => SouscripteurInfos(e)} 
                                        className="" 
                                        style={{border: 'none', fontWeight:'bold', width:'100%', marginLeft:'5px', width:'99%'}} 
                                        placeholder="........." />

                                    </td>
                                </tr>


                                <tr>
                                    <th colSpan="4" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  borderRight:'1px solid #cccc', borderLeft:'1px solid #cccc'}} >
                                        <h4 style={{textAlign:'left',fontWeight:'bold'}} >&nbsp;&nbsp;Nom</h4>
                                    </th>
                                    
                                </tr>
                                <tr>
                                   
                                    <td colSpan="4" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  borderRight:'1px solid #cccc', borderLeft:'1px solid #cccc'}} >
                                         <input 
                                           type="text" 
                                           name="societe_nom" 
                                           id="input11" 
                                           value={dataSouscripteur.societe_nom} 
                                           onChange={(e) => SouscripteurInfos(e)} 
                                           className="" 
                                           style={{border: 'none', fontWeight:'bold', width:'100%', marginLeft:'5px', width:'99%'}} placeholder="........." />

                                    </td>
                                </tr>



                                 <tr>
                                    <th colSpan="4" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  borderRight:'1px solid #cccc', borderLeft:'1px solid #cccc'}} >
                                        <h4 style={{position:'absolute', textAlign:'left',fontWeight:'bold'}} >&nbsp;&nbsp;Agissant en qualité de:</h4>
                                        <h4 style={{float:'right', textAlign:'right',fontWeight:'bold'}} >dûment habilté(e)s. &nbsp;&nbsp;</h4>
                                    </th>
                                    
                                </tr>
                                <tr>
                                   
                                    <td colSpan="4" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  borderRight:'1px solid #cccc', borderLeft:'1px solid #cccc'}} >
                                         <input 
                                         type="text" 
                                         name="societe_qualite" 
                                         id="input13" 
                                         value={dataSouscripteur.societe_qualite} 
                                         onChange={(e) => SouscripteurInfos(e)} 
                                         className="" 
                                         style={{border: 'none', fontWeight:'bold', width:'100%', marginLeft:'5px', width:'99%'}} placeholder="........." />

                                    </td>
                                </tr>


                                </tbody>
                            </table>
                        </div>


                        <div className="col-lg-12" style={{marginTop:'2%', textAlign:'center',}} >
                            <h3 id="textfoot" style={{color:'#000'}} >
                                Agissant en qualité de <b style={{fontSize:'18px'}}>SOUSCRIPTEUR</b> au programme d’acquisition de logements initié par la Société AL SANOGO,
                                Nous engageons de manière ferme et irrévocable, de nous porter acquéreur d’un logement de type :
                            </h3>
                        </div>

                        <div className="col-lg-12" style={{marginTop:'2%'}} >


                            <div className="row">
                            <div className="col-md-3" id="dvilla">
                            <h4><label style={{fontSize:'', color:'#000'}} id="villa">VILLA BASSE</label>&nbsp;
                                <input type="checkbox" name="checkbox1" id="check_villa" value={'VILLA BASSE'} onChange={(e) => SouscripteurInfos(e)}  style={{ width:'75px', height:'20px',   fontWeight:'bold'}} className="" />
                                <p><b>Nbre de pèce</b> 
                                <input 
                                type="number" 
                                name="nbr_piece_villa" 
                                value={dataSouscripteur.nbr_piece_villa} 
                                onChange={(e) => SouscripteurInfos(e)}   
                                className="" 
                                style={{width:'50px', textAlign:'center'}} />
                                </p>
                            </h4>
                            </div>
                            <div className="col-md-3" id="dDupl">
                                 <h4>
                                 &nbsp;<label style={{fontSize:'', color:'#000'}} id="dupl">DUPLEX</label>
                                <input type="checkbox" name="checkbox2" value={'DUPLEX'} onChange={(e) => SouscripteurInfos(e)} id="" style={{ width:'32px', height:'20px',   fontWeight:'bold'}} className="" />
                                <p>
                                <input 
                                type="number" 
                                name="nbr_piece_duplexe" 
                                value={dataSouscripteur.nbr_piece_duplexe} 
                                onChange={(e) => SouscripteurInfos(e)} 
                                className="" 
                                style={{width:'130px', textAlign:'center'}} />
                                </p>
                            </h4>
                            </div>
                            <div className="col-md-3" id="dAppart">
                                <h4>
                                &nbsp;<label style={{fontSize:'', color:'#000'}} id="appart">APPARTEMENT</label>
                                <input type="checkbox" name="checkbox3" value={'APPARTEMENT'} onChange={(e) => SouscripteurInfos(e)}  id="" style={{ width:'32px', height:'20px',   fontWeight:'bold'}} className="" />
                                 <p>
                                 <input 
                                 type="number" 
                                 name="nbr_piece_appart" 
                                  value={dataSouscripteur.nbr_piece_appart} 
                                onChange={(e) => SouscripteurInfos(e)} 
                                 className="" 
                                 style={{width:'208px', textAlign:'center'}} 
                                 /></p>
                            </h4>
                            </div>

                            

                            <div className="col-md-3">
                            <div className="row">
                                <div className="" id="Dtitre_mod_reserv" style={{textAlign:'left'}} ><h4 id="titre_mod_reserv">Mode de payment: </h4></div>
                                <div className="col-md-6" id="mode">
                                    <div><h4 id="cash">Cash  <input type="checkbox" name="checkbox4" value={'Cash'} onChange={(e) => SouscripteurInfos(e)} style={{ width:'32px', height:'20px'}} /></h4></div>
                                    <div><h4 id="virement">Virement  <input type="checkbox" name="checkbox5" value={'Virement'} onChange={(e) => SouscripteurInfos(e)} style={{ width:'32px', height:'20px'}} /></h4></div>
                                    <div><h4 id="cheque">Chèque  <input type="checkbox" name="checkbox6" value={'Chèque'} onChange={(e) => SouscripteurInfos(e)} style={{ width:'32px', height:'20px'}} /></h4></div>
                                </div>
                            </div>
                            </div>
                                
                            </div>
                        </div>


                        <div className="col-lg-12" style={{marginTop:'2%'}} id="">

                        




                        <table style={{width:'100%', marginTop:'2%'}} >

                             <tr>
                                <td id="td_prix" style={{borderBottom:'1px solid #000', textAlign:'left'}} >
                                    <h3 style={{color:'#000'}} >Le prix de vente du logement réservé est arrêté à la somme de &nbsp;&nbsp;

                                 <input 
                                 type="text" 
                                 name="prix_lettre" 
                                 id="input7" 
                                 value={dataSouscripteur.prix_lettre} 
                                 onChange={(e) => SouscripteurInfos(e)} 
                                 style={{border: 'none', fontWeight:'bold',  width:'100%'}} 
                                 placeholder="........." />
                                    
                                    </h3>
                                </td>

                                
                            </tr>
                        </table>




                        
                        </div>



                             <h4 style={{textAlign:'left', color:'#000', marginTop:'3%'}} id="condition_ci_apres">Dans les conditions ci-après définies.</h4>
                         <div className="col-lg-12" style={{marginTop:'2%'}} id="dcondition">
                             <br/>
                             <br/>
                             <h3 style={{textAlign:'center', textDecoration:'underline', color:'#000', }} id="condition_g"> CONDITIONS GENERALES DE SOUSCRIPTION</h3>

                             <h5 style={{color:'#000'}} >- La signature d’un contrat de vente.</h5>
                             <h5 style={{color:'#000'}} >- Le pLe prix d’acquisition est ferme et définitif, et doit être entièrement soldé avant la remise des titres de propriété relatifs au(x) bien(s). </h5>
                             <h5 style={{color:'#000'}} >- Le versement des frais de dossiers à hauteur de <b>cinq cent mille (500.000) francs.</b></h5>
                             <h5 style={{color:'#000'}} >- Le versement d’un apport initial correspondant à <b> trente pourcent (30%)</b> du prix d’acquisition du bien foncier</h5>
                             <h5 style={{color:'#000'}} >- L’établissement d’un échelonnement du solde du prix sur une période de 
                             <input 
                                 type="text" 
                                 name="periode_de" 
                                 id="" 
                                 required
                                 value={dataSouscripteur.periode_de} 
                                 onChange={(e) => SouscripteurInfos(e)} 
                                 style={{border: 'none', fontWeight:'bold',  width:'50px', textAlign:'center'}} 
                                 placeholder=".........." /> mois. Organisé selon des échéances mensuelles, bimensuelles ou trimestrielles.</h5>
                             <h5 style={{color:'#000'}}> - Il sera prélevé &nbsp;
                             <input 
                                 type="text" 
                                 name="pourcentage" 
                                 id="" 
                                 required
                                 value={dataSouscripteur.pourcentage} 
                                 onChange={(e) => SouscripteurInfos(e)} 
                                 style={{border: 'none', fontWeight:'bold',  width:'90px', textAlign:'center'}} 
                                 placeholder="...................." />

                                 &nbsp;
                                 pour cent (
                                     <input 
                                 type="text" 
                                 name="num_pourcentage" 
                                 id="" 
                                 required
                                 value={dataSouscripteur.num_pourcentage} 
                                 onChange={(e) => SouscripteurInfos(e)} 
                                 style={{border: 'none', fontWeight:'bold',  width:'50px', textAlign:'center'}} 
                                 placeholder="..........." />)% sur l’apport initial constitué par le <b>SOUSCRIPTEUR</b> en guise d’indemnité d’immobilisation si le <b>SOUSCRIPTEUR</b> ne donne pas
                                suite à sa  &nbsp;&nbsp;&nbsp;réservation  pour quelque motif que ce soit dans un délai de Trois (03) mois à compter de la signature du contrat ou après le dernier versement enregistré à la
                                <br /> &nbsp;&nbsp;&nbsp;comptabilité de la Société AL SANOGO. Cette somme restera acquise à la Société AL SANOGO à titre d’indemnité d’immobilisation. </h5>

                                  <h5 style={{color:'#000'}}> &nbsp;&nbsp;&nbsp;Le <b>SOUSCRIPTEUR</b> s’oblige, de manière diligente à respecter toutes les obligations prises aux termes des présentes.</h5>
                         </div>


                         <div className="col-lg-12" style={{marginTop:'2%'}}>

                         <div className="row">
                             <div className="col-lg-6" id="vide"></div>
                             <div className="col-lg-3" id="faita">
                             <p>
                             <h4 style={{position:'absolute'}} > Fait à: </h4>
                                 <h4 className="" id="lieu" style={{ fontWeight:'bold', marginLeft:'22%'}}>
                                 <input 
                                 type="text" 
                                 name="fait_a" 
                                 id="fait_a" 
                                 required
                                 value={dataSouscripteur.fait_a} 
                                 onChange={(e) => SouscripteurInfos(e)} 
                                 style={{border:"none", fontWeight:'bold',  width:'100%'}} 
                                 placeholder="......." />
                                 </h4>
                            </p>
                             </div>
                             <div className="col-lg-3" id="date_fait">
                             <h4 style={{position:'absolute'}} >Le: </h4>
                                 <h4 className="" id="date" style={{borderBottom:'1px solid #000', fontWeight:'bold', marginLeft:'10%'}}>
                                 &nbsp;{dateDay}
                             </h4>
                             </div>
                         </div>
                             
                             
                         </div>




                         <div className="col-lg-12" style={{marginTop:'5%'}}>

                         <div className="row">
                             <div className="col-lg-4" id="Signature1">
                                  <h3 style={{textDecoration:'underline', fontWeight:'bold'}} id="titre_sign1" >Signature du souscripteur</h3>
                             </div>

                             <div className="col-lg-4" id="Signature2">
                             <h3 style={{textDecoration:'underline', fontWeight:'bold'}} id="titre_sign2" >Agent responsable</h3>
                             <h4 style={{color:'#000'}} id="respo_name"> {nameuser} </h4>

                             </div>

                             <div className="col-lg-3" id="Signature3">
                             <h3 style={{textDecoration:'underline', fontWeight:'bold'}} id="titre_sign3">Signature du cosouscripteur</h3>
                                 
                            
                             </div>
                         </div>

                           <div className="col-lg-12" style={{marginTop:'5%', textAlign:'center'}} id="footerBottom">
                               
                           <h5>
                               La société AL SANOGO Société Anonyme avec conseil d’administration, au capital de 200 000 000 Frs CFA Siège Social : Abidjan
                            Cocody Riviera-Palmeraie 01 BP 5793 Abidjan 01 RCCM N° CI-ABJ-03-2018-B13-30877 - CC N° 1861919 T - Tel : (+225) 22 46 82 51 Cel
                            : (+225) 05 33 98 98 - (+225) 07 33 98 98 Ecobank : CI059 01046 121250615001 42 - Code Swift : ECOCCIAB <b style={{color:"#05f"}} >www.alsanogo.com</b>
                              &nbsp;&nbsp;<b style={{color:"#05f"}} >alsanogo@alsanogo.com</b>

                           </h5>

                           </div>
                             
                             
                         </div>


                        </div>

                   
            <button onClick={LinkReservationLogement} className="btn btn-danger mt-5 mb-5 col-3" style={{float:'left'}} id="btnRetour">Retour</button>
            <button type="submit"  className="btn btn-outline-primary mt-5 mb-5 col-3" id="btnPrint">Imprimer</button>

                    </form>
                    </div>

                </div>
                {/* Content body end */}

            </div>

        </>

    );
}
export default Docs;