import Navbar from '../components/Navbar';
import Siderbar from '../components/Siderbar';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';


import { Button, Form, Modal, FloatingLabel } from 'react-bootstrap';



import RequestVente from '../Requetes/Ventes';


function Ventes() {





const {
showModalCession,
handleCloseModalCession,
codeVente,
handleSubmitCession,
aquereur,
lotissement,
ilot,
detailVenteCession,
detailLotvenduCession,
handleLotCession,
handleCession,
handleAcquereur,
direct,
HandleChangeTypeCession,
showLien,
dataVenteCession,
handleAutorisation,
showModalDetailCession,
handleCloseModalDetailCession,
columnsCessionVente,
detailCession,
showModalVente,
handleCloseModalVente,
columnsLot,
detailVente,
showModalReliquat,
handleCloseModalReliquat,
handleSubmitReliquat,
n_ilot,
lotis_c,
ilot_c,
detailVenteUnique,
dataVente,
handle,
showModalDesiste,
handleCloseModalDesiste,
handleSubmitDesistement,
lotissementD,
ilotD,
NumilotD,
numIlot,
detailVenteDesistement,
detailLotvenduD,
handleLotDessiste,
showModalUpdateVente,
handleCloseModalUpdateVente,
handleSubmitUpdate,
detailUpdateVente,
columnsLotUpdate,
detailVenteUpdate,
showModal,
handleClose,
handleClient,
handleLotissementChange,
listelottissement,
columnsIlotsDisponible,
ilotsListe,
showModalListLots,
handleCloseListLot,
handleSubmit,
codeilos,
numilos,
lotissmtV,
lots,
handleLot,
handlePrixUnitaireLot,
dataUnitaire,
AddMontant,
show,
montanTT,
handleModePaie,
showNumModePaie,
showModalPaiement,
handleCloseModalPaiement,
columnsVersement,
detailPaiement,
PrintTabPaimement,
VenteModal,
columns,
getVente,
} = RequestVente()




  
  return (
    <div className="">
      <Navbar />
      <Siderbar />


      <div className="content-body">
        <div className="container-fluid">
          <div className="page-titles">
            <ol className="breadcrumb">
              <h1><b style={{ color: "red" }}>Paiements</b></h1>
            </ol>
          </div>
          <Modal show={showModalCession} onHide={handleCloseModalCession} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>
                <h1>LOTS A CEDER ({codeVente})</h1>
              </Modal.Title>
            </Modal.Header>
            <Form onSubmit={(e) => handleSubmitCession(e, codeVente, aquereur, lotissement, ilot, numIlot)} id="formComplementaire">

              <Modal.Body>
                {detailVenteCession.map((result) => (
                  <>
                    <div className="row" key={result.id_vente}>

                      <div className="mb-5 col-md-12">
                        <label className="form-label">Ancien Acquéreur</label>
                        <h3>{result.nom_acq + " " + result.prenom_acq}</h3>
                      </div>
                      <div className="mb-5 col-md-5">
                        <label className="form-label">Lotissement</label>
                        <h3>{result.libelle_loti}</h3>
                      </div>
                      {/*<div className="mb-5 col-md-4">
                        <label className="form-label">ILot</label>
                        <h3>{result.ilot_v}</h3>
                      </div>*/}
                      <div className="mb-5 col-md-3">
                        <label className="form-label">N°ILot</label>
                        <h3>{result.numero_ilot}</h3>
                      </div>

                      <hr />

                      <h4 className="mb-5">Selectionner</h4>
                      {detailLotvenduCession.map((res) => (
                        <div className="col-xl-4 col-xxl-4 col-4 mb-5" key={res.id_lot}>
                          <div className="form-check custom-checkbox mb-3 checkbox-warning">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name={"lot" + res.id_lot}
                              onChange={(e) => handleLotCession(e)}
                              style={{ width: '50px', height: '50px' }}
                              // value={res.numero_lot}
                              value={res.id_lot}
                              defaultChecked=""
                              id={"customCheckBox" + res.id_lot}
                            />

                             {(() => {
                                    if (res.remorcele === '0') {
                                      return (
                                         <label
                                      className="form-check-label badge badge-success"
                                      htmlFor={"customCheckBox" + res.id_lot}
                                      style={{ fontSize: '2em' }}>
                                      Lot {res.numero_lot}
                                    </label>
                                      )
                                                           
                                    } else {
                                      return (
                                         <label
                                      className="form-check-label badge badge-success"
                                      htmlFor={"customCheckBox" + res.id_lot}
                                      style={{ fontSize: '2em' }}>
                                      Lot {res.numero_lot} bis
                                    </label>
                                      )
                                    }
                                  })
                                ()}
                           
                          </div>
                        </div>
                      ))}

                      <hr />

                      <div className="mb-3 col-md-6">
                        <label className="form-label">Nouveau Acquéreur</label>
                        <select className="form-control" id="" required onChange={(e) => handleAcquereur(e)} name="aquereur_deux">
                          <option value="">Client...</option>
                          {direct.map((rows) => (
                            <option key={rows.id_acq} value={rows.numero_acq}>{rows.nom_acq} {rows.prenom_acq}</option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label">Type Affiliation (Parent ou Autres)</label>
                        <select className="form-control wide" onChange={(e) => HandleChangeTypeCession(e)} name="type_cession">
                          <option value="">...</option>
                          <option value="Parent">Parent</option>
                          <option value="Particulier">Particulier</option>
                        </select>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div style={{ visibility: showLien ? "visible" : "hidden" }}>
                          <label className="form-label">Lien de parenté</label>
                          <input
                            type="text"
                            className="form-control"
                            value={dataVenteCession.lien_parent}
                            onChange={(e) => handleCession(e)}
                            name="lien_parent"
                            placeholder="Frère, Soeur, Oncle...."
                          />
                        </div>
                      </div>
                      <div className="mb-3 col-md-8">
                        <label className="form-label">Document attestant l'accord de l'encien Acquéreur <code>PDF</code></label>
                        <input
                          type="file"
                          className="form-control"
                          name="attestation"
                          onChange={handleAutorisation} />
                      </div>
                    </div>
                  </>
                ))}
              </Modal.Body>
              <Modal.Footer>

                <Button onClick={handleCloseModalCession} variant='secondary'>
                  Fermer
                </Button>
                <Button type="submit" className="btn btn-primary">
                  Effectuer
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>


          <Modal show={showModalDetailCession} onHide={handleCloseModalDetailCession} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>
                <h1 style={{ color: 'orange' }}>LOTS CEDE </h1>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="table-responsive">
                <DataTable
                  columns={columnsCessionVente}
                  data={detailCession}
                  title="DataTable"
                  responsive
                  noHeader
                  defaultSortFieldId={1}
                  defaultSortField="id"
                  defaultSortAsc={false}
                  // pagination
                  // selectableRows
                  persistTableHead
                  highlightOnHover
                  exportHeaders={false}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>

              <Button onClick={handleCloseModalDetailCession} variant='secondary'>
                Fermer
              </Button>
            </Modal.Footer>
          </Modal>




          <Modal show={showModalVente} onHide={handleCloseModalVente} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>
                <h1>LOTS VENDU ({codeVente})</h1>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="table-responsive">
                <DataTableExtensions
                  columns={columnsLot}
                  data={detailVente}
                  export={false}
                  print={false}
                >
                  <DataTable
                    title="DataTable"
                    responsive
                    noHeader
                    defaultSortFieldId={1}
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    // selectableRows
                    persistTableHead
                    highlightOnHover
                    exportHeaders={false}

                  />
                </DataTableExtensions>
              </div>
            </Modal.Body>
            <Modal.Footer>

              <Button onClick={handleCloseModalVente} variant='secondary'>
                Fermer
              </Button>
            </Modal.Footer>
          </Modal>


          <Modal show={showModalReliquat} onHide={handleCloseModalReliquat} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>
                <h1>PAIEMENTS ({codeVente})</h1>
              </Modal.Title>
            </Modal.Header>
            <Form onSubmit={(e) => handleSubmitReliquat(e, n_ilot, lotis_c, ilot_c, codeVente)} id="formComplementaire">
              <Modal.Body>
                {detailVenteUnique.map((result) => (
                  <>
                    <div className="row mb-4" key={result.id_vente} style={{ textAlign: 'center' }}>
                      <div className="mb-3 col-md-6">
                        <label className="form-label">Montant total des Lots selectionnés</label>

                        <h2><b>{result.total_pay}</b></h2>
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label">Versement du client</label>
                        <h2><b>{result.versement}</b></h2>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="mb-3 col-md-4"></div>
                      <div className="mb-3 col-md-4" style={{ textAlign: 'center' }}>
                        <label className="form-label" style={{ color: 'red' }}>Reste à payer</label>
                        <h2><b>{result.reliquat}</b></h2>
                      </div>
                      <div className="mb-3 col-md-4"></div>
                    </div>
                    <div className="row mb-4" style={{ textAlign: 'center' }}>
                      <div className="mb-3 col-md-2"></div>
                      <div className="mb-3 col-md-8">
                        <label className="form-label" style={{ color: 'orange' }}>Nouveau versement du client</label>
                        <input
                          type="number"
                          required
                          className="form-control" id=""
                          name="montant_versement"
                          defaultValue={dataVente.montant_versement}
                          onChange={(e) => handle(e)}
                          placeholder="...."
                          style={{ fontSize: '2em', textAlign: 'center' }}
                        />
                      </div>
                      <div className="mb-3 col-md-2"></div>
                    </div>
                  </>
                ))}
                <div className="row">
                 <div className="mb-3 col-md-3"></div>
                <div className="mb-3 col-md-6" style={{ textAlign: 'center' }}>
                    <label className="form-label">Mode de paiement</label>
                    <select style={{ textAlign: 'center' }} className="form-control wide" name="mode_paie" required onChange={(e) => handleModePaie(e)} >
                      <option value="">choix</option>
                        <option value="Chèque">Chèque</option>
                        <option value="Espèce">Espèce</option>
                        <option value="Virement">Virement</option>
                      
                    </select>
                  
                </div>
                 <div className="mb-3 col-md-3"></div>
                 </div>
                <div className="row" style={{ visibility: showNumModePaie ? "visible" : "hidden" }}>
                 <div className="mb-3 col-md-3"></div>
                  <div className="mb-3 col-md-6" style={{ textAlign: 'center' }}>
                    <label className="form-label">Numero</label>
                    <input
                      type="text"
                      className="form-control"
                      name="numero_mode"
                      defaultValue={dataVente.numero_mode}
                      onChange={(e) => handle(e)}
                      placeholder="Numero...."
                      style={{ fontSize: '1.5em' }}
                      
                    />
                  </div>
                   <div className="mb-3 col-md-3"></div>
                    <div className="mb-3 col-md-3"></div>

                   <div className="mb-3 col-md-6" style={{ textAlign: 'center' }}>
                    <label className="form-label">Banque</label>
                    <input
                      type="text"
                      className="form-control"
                      name="banque_mode"
                      defaultValue={dataVente.banque_mode}
                      onChange={(e) => handle(e)}
                      placeholder="Nom de la Banque...."
                      style={{ fontSize: '1.5em' }}
                      
                    />
                  </div>
                   <div className="mb-3 col-md-3"></div>
                </div>
              </Modal.Body>
              <Modal.Footer>

                <Button onClick={handleCloseModalReliquat} variant='secondary'>
                  Fermer
                </Button>

                <Button type="submit" className="btn btn-primary">
                  Effectuer
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>


          <Modal show={showModalDesiste} onHide={handleCloseModalDesiste} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>
                <h1>Desistement ({codeVente})</h1>
              </Modal.Title>
            </Modal.Header>
            <Form onSubmit={(e) => handleSubmitDesistement(e, codeVente, lotissementD, ilotD, NumilotD)} id="formComplementaire">
              <Modal.Body>
              {detailVenteDesistement.map((results) => (
                  
                    <div className="row" key={results.id_vente}>

                      
                      <div className="mb-5 col-md-5">
                        <label className="form-label">Lotissement</label>
                        <h3>{results.libelle_loti}</h3>
                      </div>
                      {/*<div className="mb-5 col-md-4">
                        <label className="form-label">ILot</label>
                        <h3>{results.ilot_v}</h3>
                      </div>*/}
                      <div className="mb-5 col-md-3">
                        <label className="form-label">N°ILot</label>
                        <h3>{results.numero_ilot}</h3>
                      </div>

                      <hr />

                      <h4 className="mb-5">Selectionner</h4>
                      {detailLotvenduD.map((ress) => (
                        <div className="col-xl-4 col-xxl-4 col-4 mb-5">
                          <div className="form-check custom-checkbox mb-3 checkbox-warning">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name={"lot" + ress.id_lot}
                              onChange={(e) => handleLotDessiste(e)}
                              style={{ width: '50px', height: '50px' }}
                              value={ress.id_lot}
                              defaultChecked=""
                              id={"customCheckBox" + ress.id_lot}
                            />

                             {(() => {
                                    if (ress.remorcele === '0') {
                                      return (
                                         <label
                                      className="form-check-label badge badge-success"
                                      htmlFor={"customCheckBox" + ress.id_lot}
                                      style={{ fontSize: '2em' }}>
                                      Lot {ress.numero_lot}
                                    </label>
                                      )
                                                           
                                    } else {
                                      return (
                                         <label
                                      className="form-check-label badge badge-success"
                                      htmlFor={"customCheckBox" + ress.id_lot}
                                      style={{ fontSize: '2em' }}>
                                      Lot {ress.numero_lot} bis
                                    </label>
                                      )
                                    }
                                  })
                                ()}
                                
                           
                          </div>
                        </div>
                      ))}

                      <hr />

                      </div>
                      

                      ))}

                <div className="row" style={{ textAlign: 'center' }}>
                  <div className="mb-3 col-md-2"></div>
                  <div className="mb-3 col-md-8">
                    <label className="form-label" style={{ color: 'black' }}>Le Motif</label>
                    <FloatingLabel controlId="floatingTextarea2" label="Motif du Desistement">
                      <Form.Control
                        as="textarea"
                        placeholder="Leave a comment here"
                        style={{ height: '250px' }}
                        name="commentaire"
                        value={dataVente.commentaire}
                        onChange={(e) => handle(e)}
                        required
                      />
                    </FloatingLabel>
                  </div>
                  <div className="mb-3 col-md-2"></div>
                </div>
              </Modal.Body>
              <Modal.Footer>

                <Button onClick={handleCloseModalDesiste} variant='secondary'>
                  Fermer
                </Button>

                <Button type="submit" className="btn btn-primary">
                  Effectuer
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>


          <Modal show={showModalUpdateVente} onHide={handleCloseModalUpdateVente} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>
                <h3>Modification de la  vente ({codeVente})</h3>
              </Modal.Title>
            </Modal.Header>
            <Form onSubmit={(e) => handleSubmitUpdate(e, codeVente)} id="formUpdate">
              <Modal.Body>
                {detailUpdateVente.map((rows) => (
                  <div key={rows.id_vente}>
                    <div className="row">
                      <div className="mb-3 col-md-5">
                        <label className="form-label">Acquéreur</label>
                        <h4>{rows.nom_acq} {rows.prenom_acq}</h4>
                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label">Lotissement</label>
                        <h4>{rows.libelle_loti}</h4>
                      </div>
                      <div className="mb-3 col-md-3">
                        <label className="form-label">Code Ilot</label>
                        <h4>{rows.ilot_v}</h4>
                      </div>

                      <div className="mb-3 col-md-3">
                        <label className="form-label">Numero Ilot</label>
                        <h4>{rows.num_ilot_v}</h4>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-12 col-lg-12">
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">LOTS</h4>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="mb-5 alert alert-primary solid alert-rounded text-center" ><h3 style={{ color: '#fff' }}>{rows.ilot_v}</h3></div>
                              <div className="table-responsive">

                                <DataTable
                                  title="DataTable"
                                  columns={columnsLotUpdate}
                                  data={detailVenteUpdate}
                                  responsive
                                  noHeader
                                  defaultSortFieldId={1}
                                  defaultSortField="id"
                                  defaultSortAsc={false}
                                  // pagination
                                  // selectableRows
                                  persistTableHead
                                  highlightOnHover
                                  exportHeaders={false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-5 col-md-8">
                        <label className="form-label">Montant total des Lots selectioné</label>
                        <input
                          type="number"
                          className="form-control" id=""
                          name="montant_total_update"
                          defaultValue={rows.total_pay}
                          onChange={(e) => handle(e)}
                          placeholder="Montant total...."
                          style={{ fontSize: '2em' }}
                        />
                      </div>
                      {/*<div className="mb-5 col-md-8">
                                                <label className="form-label" style={{color:'red'}}>Reste à payer</label>
                                                <input 
                                                type="number" 
                                                readOnly
                                                className="form-control" 
                                                id="" 
                                                name=""
                                                defaultValue={rows.reliquat}
                                                onChange={(e)=>handle(e)}
                                                placeholder="1er versement...." 
                                                style={{fontSize:'2em'}}
                                                />
                                            </div>*/}
                      <div className="mb-5 col-md-8">
                        <label className="form-label">Versement du client</label>
                        <input
                          type="number"
                          className="form-control" id=""
                          name="montant_versement_update"
                          defaultValue={rows.versement}
                          onChange={(e) => handle(e)}
                          placeholder="1er versement...."
                          style={{ fontSize: '2em' }}
                        />
                      </div>
                      {/*<div className="col-md-4">{reliqua}</div>*/}
                    </div>

                  </div>
                ))}
              </Modal.Body>
              <Modal.Footer>

                <Button onClick={handleCloseModalUpdateVente} variant='secondary'>
                  Fermer
                </Button>

                <Button type="submit" className="btn btn-warning" variant='secondary'>
                  Modifier
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>






          <Modal show={showModal} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>
                <h3>Processus de vente</h3>
              </Modal.Title>
            </Modal.Header>
              <Modal.Body>
                <div className="row" >
                  <div className="mb-3 col-md-4">
                    <label className="form-label">Acquéreur</label>
                    <select className="form-control" id="" required onChange={(e) => handleClient(e)} name="client">
                      <option value="">Client...</option>
                      {direct.map((rows) => (
                        <option key={rows.id_acq} value={rows.numero_acq}>{rows.nom_acq} {rows.prenom_acq}</option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3 col-md-4">
                    <label className="form-label">Lotissement</label>
                    <select className="form-control wide" name="code_loti" required onChange={(e) => handleLotissementChange(e)} tabIndex="null">
                      <option value="">lotissement..</option>
                      {listelottissement.map((rows) => (
                        <option key={rows.id_loti} value={rows.code_loti}>{rows.libelle_loti}</option>
                      ))}
                    </select>
                  </div>

                  
                </div>

                <div className="mb-3 col-md-12">
                    <h4 className="form-label">ILOTS DISPONIBLE</h4>
                    <div className="table-responsive">

                      <DataTable
                        title="DataTable"
                        columns={columnsIlotsDisponible}
                        data={ilotsListe}
                        responsive
                        noHeader
                        defaultSortFieldId={1}
                        defaultSortField="id"
                        defaultSortAsc={false}
                        // pagination
                        // selectableRows
                        persistTableHead
                        highlightOnHover
                        exportHeaders={false}
                      />
                    </div>
                    


                   
                  </div>

                
              


               

               

              </Modal.Body>
              <Modal.Footer>

                <Button onClick={handleClose} variant='secondary'>
                  Fermer
                </Button>

              </Modal.Footer>
          </Modal>







          <Modal show={showModalListLots} onHide={handleCloseListLot} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>
                <h3>Processus de vente</h3>
              </Modal.Title>
            </Modal.Header>
            <Form onSubmit={(e) => handleSubmit(e, codeilos, numilos, lotissmtV)} id="formVente">
              <Modal.Body>
                

                

                <div className="row">
                  <div className="col-xl-12 col-lg-12">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">LIST LOTS ILOT (N° {numilos})</h4>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="mb-5 alert alert-primary solid alert-rounded text-center" ><h3 style={{ color: '#fff' }}>{codeilos}</h3></div>
                          <table className="table">
                          <tr>
                              <th>Selectionnez</th>
                              <th>N° LOT</th>
                              <th>Prix Unitaire</th>
                          </tr>
                          {lots.map((res) => (
                            <tr key={res.id_lot}>
                              <th>
                              <div className="form-check custom-checkbox mb-3 checkbox-success">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name={"lot" + res.id_lot}
                                  onChange={(e) => handleLot(e)}
                                  style={{ width: '45px', height: '45px' }}
                                  //value={res.numero_lot}
                                  value={res.id_lot}
                                  defaultChecked=""
                                  id={"customCheckBox" + res.id_lot}
                                />
                                 </div>
                              </th>
                              <td>
                                 {(() => {
                                                            if (res.remorcele === '0') {
                                                              return (
                                                                <h2>Lot {res.numero_lot}</h2>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                <h2>Lot {res.numero_lot} bis</h2>
                                                              )
                                                            }
                                                          })
                                                        ()}
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name={"prix_unitaire" + res.id_lot}
                                  onChange={(e) => handlePrixUnitaireLot(e)}
                                  value={dataUnitaire.prix_unitaire}
                                  placeholder="prix..."
                                  style={{ fontSize: '1.5em' }}
                                />
                              </td>
                            </tr>
                               ))}
                          </table>

                                
                             
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-xl-12 col-lg-12">
                    <Button onClick={(e) => AddMontant(e, codeilos, numilos)} style={{ float: 'left' }} variant='warning'>
                      Acompte
                    </Button>
                  </div>
                </div>


                <div className="row" style={{ visibility: show ? "visible" : "hidden" }}>
                  <div className="mb-3 col-md-8">
                    <label className="form-label">Montant total des Lots selectioné</label>
                    <input
                      type="number"
                      className="form-control" 
                      id=""
                      readOnly
                      name="montant_total"
                      value={montanTT}
                      onChange={(e) => handle(e)}
                      placeholder="Montant total...."
                      style={{ fontSize: '2em' }}
                      required
                    />
                  </div>
                  <div className="mb-5 col-md-8">
                    <label className="form-label">Versement du client</label>
                    <input
                      type="number"
                      className="form-control" id=""
                      name="montant_versement"
                      defaultValue={dataVente.montant_versement}
                      onChange={(e) => handle(e)}
                      placeholder="Montant versement...."
                      style={{ fontSize: '2em' }}
                      required
                    />
                  </div>

                  <div className="mb-3 col-md-8">
                    <label className="form-label">Mode de paiement</label>
                    <select className="form-control wide" name="mode_paie" required onChange={(e) => handleModePaie(e)} >
                      <option value="">choix</option>
                        <option value="Chèque">Chèque</option>
                        <option value="Espèce">Espèce</option>
                        <option value="Virement">Virement</option>
                      
                    </select>
                  </div>
                  {/*<div className="col-md-4">{reliqua}</div>*/}
                </div>

                <div className="row" style={{ visibility: showNumModePaie ? "visible" : "hidden" }}>
                  <div className="mb-3 col-md-8">
                    <label className="form-label">Numero</label>
                    <input
                      type="text"
                      className="form-control"
                      name="numero_mode"
                      defaultValue={dataVente.numero_mode}
                      onChange={(e) => handle(e)}
                      placeholder="Numero...."
                      style={{ fontSize: '2em' }}
                      
                    />
                  </div>

                   <div className="mb-3 col-md-8">
                    <label className="form-label">Banque</label>
                    <input
                      type="text"
                      className="form-control"
                      name="banque_mode"
                      defaultValue={dataVente.banque_mode}
                      onChange={(e) => handle(e)}
                      placeholder="Nom de la Banque...."
                      style={{ fontSize: '2em' }}
                      
                    />
                  </div>
                </div>

              </Modal.Body>
              <Modal.Footer>

                <Button onClick={handleCloseListLot} variant='secondary'>
                  Fermer
                </Button>

                <Button type="submit" className="btn btn-primary">
                  Effectuer
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>




          <Modal show={showModalPaiement} onHide={handleCloseModalPaiement} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>
                <h1>Liste versement vente : ({codeVente})</h1>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="table-responsive">
                <DataTable
                  title="DataTable"
                  columns={columnsVersement}
                  data={detailPaiement}
                  responsive
                  noHeader
                  defaultSortFieldId={1}
                  defaultSortField="id"
                  defaultSortAsc={false}
                  // pagination
                  // selectableRows
                  persistTableHead
                  highlightOnHover
                  exportHeaders={false}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>

              {/*<Button onClick={handleCloseModalPaiement} variant='secondary'>
                Fermer
              </Button>*/}
              <Button variant='danger' onClick={()=> PrintTabPaimement(codeVente)}>
                Imprimer <i className="fa fa-print"></i>
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Datatable</h4>

                  {/*<button type="button" className="btn btn-outline-info mb-2" onClick={()=> LinkPrintAllVente()}><i className="fa fa-print"></i> Etat de vente</button>
                  <button type="button" className="btn btn-outline-danger mb-2" onClick={()=> LinkPrintAllPaid()}><i className="fa fa-print"></i> Etat de versement</button>*/}
                  
                  <button type="button" className="btn btn-primary mb-2" onClick={() => VenteModal()}>Effectuer un paiement</button>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <DataTableExtensions
                      columns={columns}
                      data={getVente}
                      export={false}
                      print={false}
                    >
                      <DataTable
                        title="DataTable"
                        // customStyles={customStyles}
                        responsive
                        noHeader
                        defaultSortFieldId={1}
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        // selectableRows
                        persistTableHead
                        highlightOnHover
                        exportHeaders={false}
                      />
                    </DataTableExtensions>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ventes;
