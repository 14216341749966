import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
 import logo from '../logo-al-sanogo.png';
import Navbar from '../components/Navbar';
import Siderbar from '../components/Siderbar';
import '../print.css';
import PrintStyle from '../PrintStyle';


import VentesCRUD from '../services/VentesCRUD';

// import { NumberToLetter } from 'convertir-nombre-lettre';


const TablePaiement = props => {

    const { 
    	stylesBorder, 
    	styleHeade, 
    	// stylesBorder1, 
    	bodyBorder, 
    	// bodyBorderEnd, 
    	// smallBody, 
    	// EndBorder, 
    	// textStylesFooter, 
    	// textStylesFooter1, 
    	textEndPosition, 
    	// spaceSubHeader, 
    	// textFooter 
    } = PrintStyle()



 useEffect(() => {
 	
     AllPaiement();

}, []);


const [versements, setVersements] = useState([])


 const AllPaiement = () =>{

     VentesCRUD.getAllVersement()
      .then((res) => {
    
            setVersements(res.data);
          console.log(res.data);
       
      })
      .catch((error) => {
        console.error(error);
      });
    
}





function PrintRecu(){
   
                
                window.print();
            
                
            }

            

   //const { NumberToLetter } = require("convertir-nombre-lettre");  



 const LinkVente = () => {
        window.location.replace('/Etatglobal');
    }

// var currr = new Date();
// currr.setDate(currr.getDate());
// var dateDay = currr.toISOString().substr(0,10);


var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

var dateDay = dd + '/' + mm + '/' + yyyy;


function currencyFormat(num) {
   return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,')
}

	return (
        <>
        <div id="main-wrapper">
            <Navbar />
        <Siderbar />


                     {/* Content body start */}
                <div className="content-body">
                    {/* row */}
                    <div className="container-fluid">
                  

                                <div className="row">
                                    <div className="col-12">
                                        {/* Header */}
                                        <div className='row'>
                                            <div className='row'>
                                                <div className='col-8'>
                                                    <img src={logo} alt="Img" id="logoEtat" style={{ width: '200px' }} />
                                                </div>
                                                <div className='col-4 mb-5' id="faitAEtat" style={textEndPosition}>
                                                    Abidjan, le {dateDay}
                                                </div>
                                            </div>
                                        </div>
                                        {/* SubHeader */}
                                      
                                        {/* Body */}
                                        <div className="row" id="cardEtat">
                                            <div className="col-12">
                                                <div className="row">
                                                    <table style={styleHeade}>
                                                        <thead>
                                                            <tr>
                                                               <th className='footTxtTwo' style={stylesBorder}>Code Vente</th> 
                                                               <th className='footTxtTwo' style={stylesBorder}>Nom & Prenom</th> 
                                                               <th className='footTxtTwo' style={stylesBorder}>Date</th> 
                                                               <th className='footTxtTwo' style={stylesBorder}>Versement</th> 
                                                               <th className='footTxtTwo' style={stylesBorder}>Reste</th> 
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        <>
                                                           {versements.map((result) => (

                                                            <tr key={result.id_paie}>

                                                             <td  style={{bodyBorder, borderBottom:'1px solid #000', borderRight:'1px solid #000', borderLeft:"1px solid #000"}}>
                                                            {result.vente_paie}</td>

                                                             <td  style={{bodyBorder, borderBottom:'1px solid #000', borderRight:'1px solid #000', borderLeft:"1px solid #000"}}>
                                                             {result.nom_acq} {result.prenom_acq}</td>

                                                            <td  style={{bodyBorder, borderBottom:'1px solid #000', borderRight:'1px solid #000', borderLeft:"1px solid #000"}}>
                                                            {result.date_paie}</td>
                                                            <td  style={{bodyBorder, borderBottom:'1px solid #000', borderRight:'1px solid #000', borderLeft:"1px solid #000"}}>
                                                            {currencyFormat(parseInt(result.versement_paie))}</td>
                                                            <td  style={{bodyBorder, borderBottom:'1px solid #000', borderRight:'1px solid #000', borderLeft:"1px solid #000"}}>
                                                            {currencyFormat(parseInt(result.reliquat_paie))}</td>
                                                             

                                                            </tr>

                                                        ))}
                                                           </>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Footer */}
                                    
                        
                                    </div>
                                    <>
                                        <button type="button"  className="btn btn-danger mt-5 mb-5 me-5 col-2" id="btnRetour" onClick={()=> LinkVente()}>Retour</button>
                        <button type="button"  className="btn btn-outline-primary mt-5 mb-5 col-3" id="btnPrint" onClick={()=> PrintRecu()}>Imprimer</button>
                  
                                    </>
                                </div>
                    </div>
        	  </div>
                {/* Content body end */}

        </div>
        </>

        );
}
export default TablePaiement;