import axios from '../axios/axios';

const getAll = () => {
  return axios.get("/register");
};
const get = id => {
  return axios.get(`/register/${id}`);
};
const create = data => {
  return axios.post("/insert_Users", data);
};
const update = (id, data) => {
  return axios.put(`/register/${id}`, data);
};
const remove = id => {
  return axios.delete(`/register/${id}`);
};


const removeAll = () => {
  return axios.delete(`/register`);
};
const findByTitle = title => {
  return axios.get(`/register?title=${title}`);
};
const RegisterCRUD = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle
};
export default RegisterCRUD;