import React, { useEffect, useState } from 'react';

import './Siderbar.css'
import { Link } from 'react-router-dom'

import UserCRUD from '../services/UserCRUD';
import AuhtUser from '../services/AuthUser';

function Siderbar() {


// const [nameuser, setNameuser] = useState()
  const [role, setRole] = useState()
  const { token } = AuhtUser()
  const [statut, setStatut] = useState()

  useEffect(() => {
    fetchUserDetail()
    getAllInfosUser(statut)
  }, [statut])

 const fetchUserDetail = () => {
      setStatut(JSON.parse(sessionStorage.getItem('token')))
  }

const getAllInfosUser = () => {

       UserCRUD.get(statut)
      .then((res) => {

        // setNameuser(res.data[0].name)
        setRole(res.data[0].role)
      })
      .catch((error) => {
        console.warn(error)
      })
    }

    const LinkDash = () => {
        window.location.replace('/Dash');
    }

    const LinkProprietaire = () => {
        window.location.replace('/ProprietaireForm');
    }

    const LinkLotissement = () => {
        window.location.replace('/LotissementForm');
    }



    const LinkLotIlot = () => {
        window.location.replace('/LotIlotForm');
    }



    const LinkRegister = () => {
        window.location.replace('/Register');
    }

    const LinkCompteAcquereur = () => {
        window.location.replace('/CompteAcquereurForm');
    }

    const LinkVentes = () => {
        window.location.replace('/Ventes');
    }


    const LinkDesitements = () => {
        window.location.replace('/Desitements');
    }

    const LinkCessions = () => {
        window.location.replace('/cessions');
    }


    const LinkPaiementComp = () => {
        window.location.replace('/PaiementComplementaire');
    }

    const LinkDetat = () => {
        window.location.replace('/Etatglobal');
    }

     const LinkAutreParams = () => {
        window.location.replace('/AutresParametres');
    }




const LinkReservationTerrain = () => {
        window.location.replace('/ListReservationTerrain');
    }


    const LinkReservationLogement = () => {
        window.location.replace('/ListReservationLogement');
    }


    const LinkTableEcheancier = () => {
        window.location.replace('/TableEcheancier');
    }
    return (
        <div className="deznav">
            <div className="deznav-scroll">
                <ul className="metismenu" id="menu">
                    <li>
                        <Link className="has-arrow ai-icon" to="/Dash" onClick={() => LinkDash()}>
                            <i className="flaticon-dashboard-1" />
                            <span className="nav-text">Tableau de bord</span>
                        </Link>
                    </li>
                    <li><Link className="has-arrow ai-icon" to="!#" onClick={() => { }}>
                        <i className="flaticon-form-1"></i>
                        <span className="nav-text">AMENAGEMENT</span>
                    </Link>
                        <ul>
                            <li><Link to={"/ProprietaireForm"} onClick={() => LinkProprietaire()}>Propriétaire foncier</Link></li>
                            <li><Link to={"/LotissementForm"} onClick={() => LinkLotissement()}>Lotissements</Link></li>
                            <li><Link to={"/LotIlotForm"} onClick={() => LinkLotIlot()}>Lots / Ilots</Link></li>

                        </ul>
                    </li>
                    <li><Link className="has-arrow ai-icon" to="!#" onClick={() => { }}>
                        <i className="flaticon-bar-chart-2"></i>
                        <span className="nav-text">COMPTE CLIENT</span>
                    </Link>
                        <ul>
                            <li><Link to={"/CompteAcquereurForm"} onClick={() => LinkCompteAcquereur()}>Acquéreur / Prospect</Link></li>
                            {/*<li><Link to={"/ContratAcquereurForm"} onClick={()=> LinkContratAcquereur()}>Contrat acquéreur</Link></li>
                            <li><Link to={"chart-chartist.html"}>Contrat de réservation</Link></li>
                            <li><Link to={"chart-sparkline.html"}>Projet Intéressé</Link></li>
                            <li><Link to={"chart-peity.html"}>Action et visite</Link></li>
                            <li><Link to={"chart-peity.html"}>Administrative et foncières acquéreur</Link></li>*/}
                        </ul>
                    </li>

                    <li>
                        <Link className="has-arrow ai-icon" to="!#" onClick={() => { }}>
                            <i className="fa fa-cart-arrow-down"></i>
                            <span className="nav-text">PAIEMENT</span>
                        </Link>
                        <ul>
                            <li><Link to={"/Ventes"} onClick={() => LinkVentes()}>Effectuer un paiement</Link></li>
                            <li><Link to={"/Desitements"} onClick={() => LinkDesitements()}>Liste des desistements</Link></li>
                             <li><Link to={"/Cessions"} onClick={() => LinkCessions()}>Liste des cessions</Link></li> 
                             <li><Link to={"/PaiementComplementaire"} onClick={() => LinkPaiementComp()}>Paiement Annexe</Link></li> 
                        </ul>

                    </li>

                      
                     <li>
                        <Link className="has-arrow ai-icon" to="!#" onClick={() => { }}>
                            <i className="fa fa-file"></i>
                            <span className="nav-text">DOCUMENTS</span>
                        </Link>
                        <ul>
                            <li><Link to={"/ListReservationTerrain"} onClick={() => LinkReservationTerrain()}>Reservation Terrain</Link></li>
                            <li><Link to={"/ListReservationLogement"} onClick={() => LinkReservationLogement()}>Reservation Logement</Link></li>
                             <li><Link to={"/TableEcheancier"} onClick={() => LinkTableEcheancier()}>Echeancier de paiement</Link></li> 
                        </ul>

                    </li>

                
                    <li>
                        <Link className="has-arrow ai-icon" to="/Etatglobal" onClick={() => LinkDetat()}>
                            <i className="fa fa-list" />
                            <span className="nav-text">ETATS</span>
                        </Link>
                    </li>

                   

                
                  
                    <li>
                                                        
                        <Link className="has-arrow ai-icon" to="!#" onClick={() => { }}>
                            <i className="flaticon-settings"></i>
                            <span className="nav-text">PARAMETRES</span>
                        </Link>
                        <ul>
                            <li><Link to="/Register" onClick={() => LinkRegister()}>Compte utilisateurs</Link></li>
                            <li><Link to="/AutresParametres" onClick={() => LinkAutreParams()}>Autres paramètres</Link></li>
                        </ul>
                    </li>
                  
                    

                </ul>

                <div className="copyright">
                    {/*<p><strong>Omah Dashboard</strong> © 2022 All Rights Reserved</p>
                    <p>by DexignZone</p>*/}
                </div>
            </div>
        </div>

    );
}

export default Siderbar;
