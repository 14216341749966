import React, {useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Siderbar from '../components/Siderbar';
// import {Link } from 'react-router-dom';
import '../index.css';
import RegisterCRUD from '../services/RegisterCRUD';
import UserCRUD from '../services/UserCRUD';
import AuhtUser from '../services/AuthUser';
import ParametresCRUD from '../services/ParametresCRUD';


import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

import { Button, Form, Modal } from 'react-bootstrap';

import Swal from 'sweetalert2';

function Register(){




  const [roleUs, setRoleUs] = useState()
  const [statut, setStatut] = useState()

  useEffect(() => {
    fetchUserDetail()
    getAllInfosUser(statut)
  }, [statut])

 const fetchUserDetail = () => {
      setStatut(JSON.parse(sessionStorage.getItem('token')))
  }

const getAllInfosUser = () => {

       UserCRUD.get(statut)
      .then((res) => {


        setRoleUs(res.data[0].role)

        var role = res.data[0].role;
         if(role !== "Administrateur"){
        Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Vous n\'être pas autoriser à accèder à cette page' ,
            showConfirmButton: false,
            timer: 5000
          })

        setTimeout(() =>{
            window.location.replace('/Dash')
        }, 5000);
    }
         console.log(res.data);
      })
      .catch((error) => {
        console.warn(error)
      })
    }





   const  refreshPage = () => {
    setTimeout(() => {
    
            window.location.reload(false);
        }, 2000);
  }


// const { listelutilasateurs } = this.state;
// const { errorMsg } = this.state;

const [Utilasateurs, setUtilasateurs] = useState([]);
const [listRoles, setListRoles] = useState([])

 useEffect(() => {
    ListeUtilasateurs();
    ListRoles();
  }, []);

const ListRoles = () =>{
    ParametresCRUD.getAll()
    .then(res =>{
        console.log(res.data)

        setListRoles(res.data)

    })
    .catch((error) => {
        console.error(error);
      });
}


   const ListeUtilasateurs = () => {
    RegisterCRUD.getAll()
      .then(response => {
        setUtilasateurs(response.data);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };




 const initialUsers = {
           full_name: "",
            email: "",
            username: "",
            password: "",
            password_confirmation: "",
            role: "",
  };

const [users, setUsers] = useState(initialUsers);

const UsersInputChange = event => {
    const { name, value } = event.target;
    setUsers({ ...users,  [name]: value });
  };

// const [submitted, setSubmitted] = useState(false);
 // const [errorMsgName, setMsgName] = useState('') 
 // const [errorMsgUsename, setMsgUsername] = useState('') 
 // const [errorMsgPassword, setMsgPassword] = useState('') 
 // const [errorMsgRole, setMsgRole] = useState('') 

  function SaveUsers(e) {
   

     e.preventDefault();

    const formData = new FormData();

    formData.append('name', users.full_name)
    formData.append('email', users.email)
    formData.append('username', users.username)
    formData.append('password', 'alsanogo2022')
    formData.append('role', users.role)

    RegisterCRUD.create(formData)
      .then(response => {
       
        console.log(response.data);

         refreshPage();
        ListeUtilasateurs();

          Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 2000
                    })

      })
      .catch((error) => {
        //console.log(error.response.data.errors);
        // console.log(error.response.data);
         //setMsgName(error.response.data.errors.name)
         // setMsgUsername(error.response.data.errors.username)
         // setMsgPassword(error.response.data.errors.password)
         //setMsgRole(error.response.data.errors.role)
      });
  };


 var dataOff = {
      etat: 0,
      
    };


const OffCompte = (id) =>{
    UserCRUD.updateEtat(id, dataOff)
      .then(response => {
        setUsers({
          id: id,
          etat: response.data.etat,
        });
        console.log(response.data);

        ListeUtilasateurs();

      })
}

var dataOn = {
      etat: 1,
      
    };
const OnCompte = (id) =>{
    UserCRUD.updateEtat(id, dataOn)
      .then(response => {
        setUsers({
         id: id,
           etat: response.data.etat,
        });
        console.log(id);

        ListeUtilasateurs();

      })
}
 const columns = [
        {
            name: "Photo",
            // selector: (row) => row.name,
             cell: (row) => (
                <img src={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/"+row.photo} className="img-fluid" width="70" alt="" />
                 ),
            sortable: true,
            reorder: true
        },
        {
            name: "Nom & Prenom",
            // selector: (row) => row.name,
             cell: (row) => (
                <b style={{color:"black"}}> {row.name} </b>
                 ),
            sortable: true,
            reorder: true
        },
        {
            name: "Telephone",
            // selector: (row) => row.name,
             cell: (row) => (
                <b style={{color:"black"}}> {row.tel} </b>
                 ),
            sortable: true,
            reorder: true
        },
        {
            name: "Email",
            // selector: (row) => row.email,
             cell: (row) => (
                <b style={{color:"black"}}> {row.email} </b>
                 ),
            sortable: true,
            reorder: true
        },
        {
            name: "Nom utilisateur",
            cell: (row) => (
                <b style={{color:"black"}}> {row.username} </b>
                 ),
            sortable: true,
            reorder: true
        },
        
        {
            name: "Role",
            cell: (row) => (
                <b style={{color:"black"}}> {row.role} </b>
                 ),
            sortable: true,
            reorder: true
        },
         {
            name: "Etat",
            cell: (row) => (
                 roleUs === 'Administrateur' ? (
              row.etat === 1 ? (
                <span role="button" onClick={() => OffCompte(row.id)}><i className="fa fa-toggle-on fa-2x" style={{color:'green'}}></i></span>
                ) : row.etat === 0 ? (
                 <span role="button" onClick={() => OnCompte(row.id)}><i className="fa fa-toggle-off fa-2x" style={{color:'red'}}></i></span>
                 ) : (
                 ""
                )
                
                ) : (
                 ""
                )
            
                 ),
            sortable: true,
            reorder: true
        },
        {
            name: "Action",
            cell: (row) => (
              roleUs === 'Administrateur' ? (
               <div>
                <div className="d-flex">
                                               
                                             
              {/*<button className="btn btn-secondary btn-xs me-3" onClick={() => AutorisationUsers(row.name, row.statut)}><i className="fa fa-lock"></i></button>*/}
              <button className="btn btn-warning btn-xs me-2" onClick={() => DetailUsers(row.id)}><i className="fa fa-edit"></i></button>
               
                                              

                <button className="btn btn-danger btn-xs" onClick={() => deleteUsers(row.id)}><i className="fa fa-trash"></i></button>
                                               </div>


                                             
              </div>
              
               ) : (
                 ""
                )
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
]




/***** DELETE DATA *******/

        const deleteUsers = (id) => {


            Swal.fire({
              title: 'Es-tu sûr?',
              text: "Vous ne pourrez pas revenir en arrière!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Oui, supprime-le!'
            }).then((result) => {
              if (result.isConfirmed) {

            RegisterCRUD.remove(id)
              .then(response => {
                console.log(response.data);

                 Swal.fire(
                  'Supprimé!',
                  'Votre fichier a été supprimé.',
                  'success'
                )

                
                ListeUtilasateurs();
              })
              .catch(e => {
                console.log(e);
              });

                }
            })
          };
        /***** END DELETE DATA *******/



const [showModalDetail, setShowModalDetail] = useState(false);

 const [photo, setPhoto] = useState()
 const [nameuser, setNameuser] = useState()
 const [email, setEmail] = useState()
 const [telephone, setTelephone] = useState()
 const [adresse, setAdresse] = useState()
 const [contrat, setContrat] = useState()
  const [role, setRole] = useState()
  const [username, setUsername] = useState()
  const [password, setPassword] = useState()
 const [idusers, setIdusers] = useState()
 const [datenaissance, setDatenaissance] = useState()



const DetailUsers = (id) =>{

    setShowModalDetail(true)

    UserBy(id)

}




function UserBy(id){
     UserCRUD.getUser(id)
      .then(res => {
        setIdusers(res.data[0].id)
        setPhoto(res.data[0].photo)
        setNameuser(res.data[0].name)
        setEmail(res.data[0].email)
        setRole(res.data[0].role)
        setUsername(res.data[0].username)
        setPassword(res.data[0].password)
        setTelephone(res.data[0].tel)
        setAdresse(res.data[0].adresse)
        setContrat(res.data[0].contrat)
        setDatenaissance(res.data[0].date_naiss)

        console.log(res.data);
      })
      .catch(e => {
        console.log(e);
      });
}

const handleCloseDetail = () => {
    setShowModalDetail(false)
  }






const [dataUsers, setDataUsers] = useState({
    photoProfile : "",
    naissance : "",
    nomComplet:"",
    telp : "",
    email : "",
    adresse : "",
    username : "",
    password : "",
    role : "",
    contrat : "",
 })


const [photoP, setPhotoP] = useState([]);

const handlePhoto = (event) => {
    setPhotoP(event.target.files[0])
    console.log(event.target.files[0])
  }

const [dataInfos, setDataInfos] = useState([]);


const handleInfos = (e) => {
     const { name, value } = e.target;
    const newdata={...dataInfos, [name]: value }
    setDataInfos(newdata)
    console.log(newdata)
  }



function UpdateInfosUsers(e, id){

    e.preventDefault();

    const formData = new FormData();

    formData.append('idUser', id)
    formData.append('photoUser', photoP)
    formData.append('nomUser', dataInfos.nomComplet)
    formData.append('naissanceUser', dataInfos.naissance)
    formData.append('telUser', dataInfos.telp)
    formData.append('emailUser', dataInfos.email)
    formData.append('adresseUser', dataInfos.adresse)
    formData.append('username', dataInfos.username)
    formData.append('password', dataInfos.password)
    formData.append('roleUser', dataInfos.role)
    formData.append('contratUser', dataInfos.contrat)

   UserCRUD.AdminupdateUser(formData)
      .then(response => {
        console.log(response.data);

           Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 2000
                    })
           setTimeout(() => {
            window.location.reload();

          }, 2000);
      })
      .catch(e => {
        console.log(e);
         Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 10000
                    })
      });
}






// const [showModalAutorisation, setShowModalAutorisation] = useState(false);
// const [detailMenu, setDetailMenu] = useState([]);
// const [nameAutoris, setNameAutoris] = useState([]);


// const AutorisationUsers = (name, status) =>{
//     console.log(status)
//     setShowModalAutorisation(true)
//     setNameAutoris(name)

//      ParametresCRUD.Autorisation(status)
//       .then(response => {
       
//        setDetailMenu(response.data)
//         console.log(response.data);


//       })

// }

// const handleCloseModalAutorisation = () =>{
//      setShowModalAutorisation(false)
// }



  // const [checkMenu, setCheckMenu] = useState([])

  // function HandleChangeMenu(e) {
  //   const { name, value } = e.target
  //   setCheckMenu({
  //     ...checkMenu,
  //     [name]: value
  //   })

  //   console.log(e.target.value)
  // }


    return (

<div className="">
        <Navbar />
        <Siderbar />

        <div className="content-body">
          <div className="container-fluid">

        

              <div className="page-titles">
                  <ol className="breadcrumb">
                    <h1 style={{color:"red"}}>Compte utilisateurs</h1>
                  </ol>
                </div>

 
<Modal show={showModalDetail} onHide={handleCloseDetail} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>
                <h1>Modification Information utilisateur</h1>
              </Modal.Title>
            </Modal.Header>
                <form  onSubmit={(e)=>UpdateInfosUsers(e, idusers)} encType="multipart/form-data" id="Form">
            <Modal.Body>
              <div className="">
                                                          <div className="row mb-4">
                                                              

                                                            <div className="mb-3 col-md-2">
                                                             {(() => {
                                                            if (photo) {
                                                              return (
                                                                <img src={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/"+photo} className="img-fluid rounded-circle" alt="" />
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                <>
                                                                 <i className="fa fa-user-circle fa-3x"></i><br />
                                                                 <code><i className="fa fa-exclamation-triangle"></i> photo</code>
                                                                 </>

                                                              )
                                                            }
                                                          })
                                                        ()}
                                                             </div>

                                                             <div className="mb-3 col-md-6">
                                                                 <span className="input-group-text">Importer Votre photo</span>
                                                                            <div className="form-file">
                                                                                <input 
                                                                                type="file" 
                                                                                className="form-file-input form-control" 
                                                                                name="photoProfile"
                                                                                onChange={handlePhoto}
                                                                                />
                                                                            </div>
                                                             </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label">Nom complet</label>
                                                                    <input 
                                                                      type="text" 
                                                                      name="nomComplet" 
                                                                      placeholder="Nom complet" 
                                                                      className="form-control" 
                                                                       onChange={(e)=>handleInfos(e)}
                                                                      defaultValue={nameuser}
                                                                      />
                                                                </div>
                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label">Date de naissance</label>
                                                                    <input 
                                                                     type="date" 
                                                                     name="naissance"
                                                                     placeholder="telp" 
                                                                     className="form-control" 
                                                                      onChange={(e)=>handleInfos(e)}
                                                                     defaultValue={datenaissance}
                                                                     />
                                                                </div>
                                                            </div>
                                                          
                                                            <div className="row mb-3">
                                                              <div className="mb-3 col-md-6">
                                                                <label className="form-label">Telephone</label>
                                                                <input 
                                                                  type="text" 
                                                                  name="telp"
                                                                  placeholder="Telephone" 
                                                                  className="form-control" 
                                                                   onChange={(e)=>handleInfos(e)}
                                                                  defaultValue={telephone}
                                                                  />
                                                            </div>

                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label">Email</label>
                                                                    <input 
                                                                     type="email" 
                                                                     name="email"
                                                                     placeholder="Email" 
                                                                     className="form-control"
                                                                      onChange={(e)=>handleInfos(e)}
                                                                     defaultValue={email} 
                                                                     />
                                                                </div>
                                                                
                                                            </div>
                                                            <div className="mb-4">
                                                                    <label className="form-label">Adresse</label>
                                                                    <input 
                                                                     type="text" 
                                                                     name="adresse"
                                                                     placeholder="Apartment, studio, or floor" 
                                                                     className="form-control" 
                                                                      onChange={(e)=>handleInfos(e)}
                                                                     defaultValue={adresse}
                                                                     />
                                                                </div>
                                                                 <div className="row">
                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label">Username</label>
                                                                <input 
                                                                  type="text" 
                                                                  name="username"
                                                                  placeholder="1234 Main St" 
                                                                  className="form-control" 
                                                                   onChange={(e)=>handleInfos(e)}
                                                                  defaultValue={username}
                                                                  />
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label">Mot de passe</label>
                                                                <input 
                                                                  type="password" 
                                                                  name="password"
                                                                  placeholder="*********" 
                                                                  className="form-control" 
                                                                   onChange={(e)=>handleInfos(e)}
                                                                  defaultValue={password}
                                                                  />
                                                            </div>
                                                            </div>

                                            <div className="row">
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label">Role </label>
                                                  <select className="form-control wide" name="role" onChange={(e)=>handleInfos(e)} tabIndex="null">
                                                    <option value={role}>{role}</option>
                                                    {listRoles.map((rows) => (
                                                    <option key={rows.id} value={rows.title_role}>{rows.title_role}</option>
                                                  ))}
                                                   
                                                </select>
                                                 
                                            </div>
                                            <div className="mb-3 col-md-6">
                                                    <label className="form-label">Contrat </label>
                                                  <select className="form-control wide" name="contrat" onChange={(e)=>handleInfos(e)} tabIndex="null">
                                                    <option value={contrat}>{contrat}</option>
                                                    <option value="CDI">CDI</option>
                                                    <option value="CDD">CDD</option>
                                                   
                                                </select>
                                                 
                                            </div>
                                        </div>
                                                           
                                                            
              </div>
            </Modal.Body>
            <Modal.Footer>

              <Button onClick={handleCloseDetail} variant='secondary' className="me-5">
                Fermer
              </Button>
              <button className="btn btn-warning" type="submit">Modifier</button>
            </Modal.Footer>
                                                        </form>
          </Modal>
                

<div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Enregistrement des informations</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal">
                                                    </button>
                                                  </div>
                            <form  onSubmit={(e)=>SaveUsers(e)} encType="multipart/form-data" id="Form">
                                                <div className="modal-body">
                                                     <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12 col-lg-12">
                        

                                        
                              
                           <div className="row">
                                               
                                        <div className="mb-3 col-md-4">
                                        <div className="form-group">
                                            <label className="mb-1"><strong>Nom & Prenom</strong></label>
                                            <input type="text" className="form-control" name="full_name" required id="full_name" value={users.full_name} onChange={UsersInputChange} placeholder="Nom & Prenom" />
                                            {/*<span style={{color:'red'}}><p>{errorMsgName}</p></span>*/}
                                        </div>
                                        </div>

                                        <div className="mb-3 col-md-4">
                                        <div className="form-group">
                                            <label className="mb-1"><strong>Email</strong></label>
                                            <input type="email" className="form-control" name="email" id="email"  value={users.email} onChange={UsersInputChange} placeholder="hello@example.com" />
                                            
                                        </div>
                                        </div>
                                        <div className="mb-3 col-md-4">
                                        <div className="form-group">
                                            <label className="mb-1"><strong>Nom utilisateur</strong></label>
                                            <input type="text" className="form-control" name="username" id="username" required value={users.username} onChange={UsersInputChange} placeholder="utilisateur" />
                                            {/*<span style={{color:'red'}}><p>{errorMsgUsename}</p></span>*/}
                                        </div>
                                        </div>

                                        <div className="mb-3 col-md-4">
                                        <div className="form-group">
                                            <label className="mb-1"><strong>Mot de passe</strong></label>
                                            <input type="text" className="form-control" name="password" id="password" required value={'alsanogo2022'} readOnly  onChange={UsersInputChange} placeholder="........" />
                                            {/*<span style={{color:'red'}}><p>{errorMsgPassword}</p></span>*/}
                                        </div>
                                        </div>

                                        {/*<div className="mb-3 col-md-4">
                                        <div className="form-group">
                                            <label className="mb-1"><strong>Confirmer le Mot de passe</strong></label>
                                            <input type="password" className="form-control" name="password_confirmation" id="password_confirmation" value={users.password_confirmation} onChange={UsersInputChange} placeholder="........" />
                                      
                                        </div>
                                        </div>*/}


                                        <div className="mb-3 col-md-4">
                                                <label className="form-label">Role </label>
                                              <select className="form-control wide" name="role" required onChange={UsersInputChange} tabIndex="null">
                                                <option value="">Role..</option>
                                                {listRoles.map((rows) => (
                                                    <option key={rows.id} value={rows.title_role}>{rows.title_role}</option>
                                                  ))}
                                               
                                            </select>
                                             {/*<span style={{color:'red'}}><p>{errorMsgRole}</p></span>*/}
                                        </div>


                                </div>
                          
                       
                    </div>
                </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-danger light" data-bs-dismiss="modal">Close</button>
                                                    <button type="submit" className="btn btn-primary">Enregistrer</button>
                                                </div>
                                     </form>
                                            </div>
                                        </div>
                                    </div>






 {/*<Modal show={showModalAutorisation} onHide={handleCloseModalAutorisation} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>
                <h3>Non Autorisations des menu à {nameAutoris}</h3>
              </Modal.Title>
            </Modal.Header>
               <Form onSubmit="">
            <Modal.Body>
              <div className="row">
              <div className="col-lg-12 col-md-12">
               {detailMenu.map((res) => (



         <div className="row mb-3" key={res.id_autoris}>
              <div className="col-lg-2 col-md-2">
              <div className="form-check custom-checkbox mb-3 checkbox-warning">
               

                  { res.etat === 1 ? (
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={"customCheckBox" + res.id_autoris}
                    checked
                    name={"menu"+res.id_autoris}
                    value={res.id_autoris}
                    onChange={(e) => HandleChangeMenu(e)}
                     style={{ width: '50px', height: '50px' }}
                    />
                    ) : res.etat === 0 ? (

                    <input
                    type="checkbox"
                    className="form-check-input"
                    
                    name={"menu"+res.id_autoris}
                    value={res.id_autoris}
                    onChange={(e) => HandleChangeMenu(e)}
                     style={{ width: '50px', height: '50px' }}
                    />
                 ) : (
                  ""
                )}
              </div>
              </div>
                <div className="col-lg-6 col-md-6">
                <h1>
                 <label
                    className="form-check-label"
                    htmlFor={"customCheckBox" + res.id_autoris}
                    >
                   {res.autorisation} {res.etat}
                </label>
              </h1>
              </div>
              </div>


              

              ))}


               


              




              </div>





                   
              </div>
               
            </Modal.Body>
            <Modal.Footer>

              <Button onClick={handleCloseModalAutorisation} variant='secondary' className="me-5">
                Fermer
              </Button>
             
               <button className="btn btn-warning" type="submit">Enregistrer</button>
            </Modal.Footer>

            </Form>
          </Modal>*/}

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                               <h4 className="card-title">Datatable</h4>
                               {
                                 roleUs === 'Administrateur' ? (
                                    <button type="button" className="btn btn-primary mb-2" data-bs-toggle="modal" data-bs-target=".bd-example-modal-lg">Enregistrement</button>
                                    ) : (
                                     ""
                                    )
                               }
                             
                            </div>
                            <div className="card-body">

                            

                                <div className="basic-form">
                                <div className="table-responsive">
                                     <DataTableExtensions
                                        columns={columns}
                                        data={Utilasateurs}
                                        export={false}
                                        print={false}

                                    >
                                      <DataTable
                                       title="DataTable"
                                       responsive
                                        noHeader
                                        defaultSortFieldId={1}
                                        defaultSortField="id"
                                        defaultSortAsc={false}
                                        pagination
                                         // selectableRows
                                        persistTableHead
                                        highlightOnHover
                                        exportHeaders = {false}

                                      />
                                    </DataTableExtensions>
                                </div>
                                        
                                </div>
                            </div>
                          
                        </div>
                    </div>
                </div>

          </div>
        </div>
  </div>

        );
    
}
export default Register;