import Navbar from '../components/Navbar';
import Siderbar from '../components/Siderbar';
// import AppContent from '../components/AppContent';
import AppFooter from '../components/AppFooter';
import React, { useEffect, useState } from 'react';

import LotissementCRUD from '../services/LotissementCRUD';
import ProprietaireCRUD from '../services/ProprietaireCRUD';
import CompteAcquereurCRUD from '../services/CompteAcquereurCRUD';
import VentesCRUD from '../services/VentesCRUD';


import UserCRUD from '../services/UserCRUD';
import AuhtUser from '../services/AuthUser';


function DashComponents() {

  const [role, setRole] = useState()
  const { token } = AuhtUser()
  const [statut, setStatut] = useState()

  useEffect(() => {
    fetchUserDetail()
    getAllInfosUser(statut)
  }, [statut])

 const fetchUserDetail = () => {
      setStatut(JSON.parse(sessionStorage.getItem('token')))
  }

const getAllInfosUser = () => {

       UserCRUD.get(statut)
      .then((res) => {

        setRole(res.data[0].role)
      })
      .catch((error) => {
        console.warn(error)
      })
    }



  const [nbrelotissementencour, setNbrelotissementencour] = useState([])
  const [nbreilotencour, setNbreilotencour] = useState([])
  const [nbrelotencour, setNbreLotencour] = useState([])

  const [nbrelotissementprojet, setNbrelotissementprojet] = useState([])
  const [nbreilotprojet, setNbreilotprojet] = useState([])
  const [nbrelotprojet, setNbreLotprojet] = useState([])

useEffect(() => {
     NbreLotissementEncour();
     NbreLotissementEnprojet();
     NbreProprietaire();
     NbreClients();
     NbreVenteDay();
     DetailPaidComp();
     NbreResevation();

}, []);


    const NbreLotissementEncour = () => {
        LotissementCRUD.nbrEncour()
              .then((res) => {
            
                    setNbrelotissementencour(res.data.lotissement);
                    setNbreilotencour(res.data.ilot);
                    setNbreLotencour(res.data.lot);
                  
                  console.log(res.data.lotissement);
                  console.log(res.data.ilot);
                  console.log(res.data.lot);
               
              })
              .catch((error) => {
                console.error(error);
              });
    }


      const NbreLotissementEnprojet = () => {
        LotissementCRUD.nbrProjet()
              .then((res) => {
            
                    setNbrelotissementprojet(res.data.lotissement);
                    setNbreilotprojet(res.data.ilot);
                    setNbreLotprojet(res.data.lot);
                  
                  console.log(res.data.lotissement);
                  console.log(res.data.ilot);
                  console.log(res.data.lot);
               
              })
              .catch((error) => {
                console.error(error);
              });
    }


const [nbreProprio, setNbreProprio] = useState([])

const NbreProprietaire = () => {
        ProprietaireCRUD.Nbre_proprietaire()
              .then((res) => {
            
                    setNbreProprio(res.data.propritaire);
                  
                  console.log(res.data.propritaire);
               
              })
              .catch((error) => {
                console.error(error);
              });
    }


const [nbreDirect, setNbreDirect] = useState([])
const [nbreProspect, setNbreProspect] = useState([])

const NbreClients = () => {
        CompteAcquereurCRUD.nbreClient()
              .then((res) => {
            
                    setNbreDirect(res.data.acquareur);
                    setNbreProspect(res.data.prospere);
                  
                  console.log(res.data.acquareur);
                  console.log(res.data.prospere);
               
              })
              .catch((error) => {
                console.error(error);
              });
    }


const [totalVente, setTotalVente] = useState([])
const [allVersement, setAllVersement] = useState([])
const [nbreVente_day, setNbreVente_day] = useState([])
const [nbreLot_day, setNbreLot_day] = useState([])
const [allnbreLot, setAllnbreLot] = useState([])

const NbreVenteDay = () => {
        VentesCRUD.nbreVenteToDay()
              .then((res) => {
            
                    setTotalVente(res.data.all_vente);
                    setNbreVente_day(res.data.vente_j);
                    setNbreLot_day(res.data.lot_j);
                    setAllVersement(res.data.all_paiement_vente);
                    setAllnbreLot(res.data.allNbrlot);
                  
                  console.log(res.data);
               
              })
              .catch((error) => {
                console.error(error);
              });
    }



const [sumMontant, setSumMontant] = useState([])
 const [tva, setTva] = useState([])


    const DetailPaidComp = () => {

        VentesCRUD.SumAllPaiementComp()
            .then((res) => {
                 

                setSumMontant(res.data.SumMontant);
                 setTva(res.data.SumMontantTva)

                console.log(res.data);

            })
            .catch((error) => {
                console.error(error);
            });

    }


const [countRT, setCountRT] = useState([])
const [countRL, setCountRL] = useState([])

  const NbreResevation = () => {

        VentesCRUD.CountNbrReservation()
            .then((res) => {
                 

                setCountRT(res.data.SumRT);
                setCountRL(res.data.SumRL);

                console.log(res.data);

            })
            .catch((error) => {
                console.error(error);
            });

    }


const LinkProprietaire = () => {
        window.location.replace('/ProprietaireForm');
    }

    const LinkLotissement = () => {
        window.location.replace('/LotissementForm');
    }


const LinkVentes = () => {
        window.location.replace('/Ventes');
    }


    const LinkPaiementComp = () => {
        window.location.replace('/PaiementComplementaire');
    }


 const LinkCompteAcquereur = () => {
        window.location.replace('/CompteAcquereurForm');
    }
    return (
      <>
        <Navbar />
        <Siderbar />
         
        {/* <AppContent />*/}


        <div className="content-body">
            {/* row */}
            <div className="container-fluid">
                <div className="form-head d-md-flex mb-sm-4 mb-3 align-items-start">
                    <div className="me-auto  d-lg-block">
                        <h2 className="text-black font-w600">Tableau de bord</h2>
                        <p className="mb-0">Bienvenue dans l'administration de la propriété AL SANOGO</p>
                    </div>
                    
                </div>

                   {(() => {
                                    if (role === 'Administrateur' || role === 'Gestionnaire') {
                                      return (  
                <div className="row">
                    <div className="col-xl-6 col-xxl-12">
                        <div className="row">
                           
                            <div className="col-sm-12 col-md-6">
                                <div className="card" onClick={LinkLotissement}>
                                    <div className="card-body">
                                        <div className="media align-items-center">
                                            
                                            <div className="media-body me-3">
                                                <h2 className="fs-36 text-black font-w600">{nbrelotissementencour}</h2>
                                                <p className="fs-18 mb-0 text-black font-w500">Lotissement en Cours</p>
                                                 <span className="fs-13" style={{color:'green', fontWeight: 'bold'}}>ILOTS: {nbreilotencour}</span>&nbsp;&nbsp;
                                                 <span className="fs-13" style={{color:'green', fontWeight: 'bold'}}>LOTS: {nbrelotencour}</span>
                                            </div>
                                                
                                            {/*<div className="d-inline-block position-relative donut-chart-sale">
                                                <span className="donut1" data-peity="{ &quot;fill&quot;: [&quot;rgb(60, 76, 184)&quot;, &quot;rgba(236, 236, 236, 1)&quot;],   &quot;innerRadius&quot;: 38, &quot;radius&quot;: 10}">5/8</span>
                                                <small className="text-primary">71%</small>
                                                <span className="circle bgl-primary" />
                                            </div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="card" onClick={LinkLotissement}>
                                    <div className="card-body">
                                        <div className="media align-items-center">
                                            <div className="media-body me-3">
                                                <h2 className="fs-36 text-black font-w600">{nbrelotissementprojet}</h2>
                                                <p className="fs-18 mb-0 text-black font-w500">Lotissement en Projet</p>
                                                <span className="fs-13"  style={{color:'green', fontWeight: 'bold'}}>ILOTS: {nbreilotprojet}</span>&nbsp;&nbsp;
                                                 <span className="fs-13"  style={{color:'green', fontWeight: 'bold'}}>LOTS: {nbrelotprojet}</span>
                                            </div>
                                           {/* <div className="d-inline-block position-relative donut-chart-sale">
                                                <span className="donut1" data-peity="{ &quot;fill&quot;: [&quot;rgb(55, 209, 90)&quot;, &quot;rgba(236, 236, 236, 1)&quot;],   &quot;innerRadius&quot;: 38, &quot;radius&quot;: 10}">7/8</span>
                                                <small className="text-success">90%</small>
                                                <span className="circle bgl-success" />
                                            </div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                     <div className="col-xl-3 col-xxl-12">
                        <div className="card" onClick={LinkVentes}>
                            <div className="card-header border-0 pb-0">
                                <h3 className="fs-18 text-black">Montant Total</h3>
                               
                            </div>
                            <div className="card-body pt-2 pb-0">
                                <div className="d-flex flex-wrap align-items-center">
                                    <span className="fs-28 text-black font-w600 me-3">{totalVente} XOF</span>
                                    <div className="d-flex align-items-center">
                                      
                                        
                                    </div>
                                </div>
                                <span className="fs-13 text-red">Reste à payer: {parseInt(totalVente) - parseInt(allVersement)} XOF</span>&nbsp;&nbsp;
                                {/*<span className="fs-13 mb-5">LOTS: {allnbreLot}</span>*/}
                            </div>
                        </div>
                    </div>

                     <div className="col-xl-3 col-xxl-12">
                        <div className="card" onClick={LinkVentes}>
                            <div className="card-header border-0 pb-0">
                                <h3 className="fs-18 text-black">Total Acompte</h3>
                               
                            </div>
                            <div className="card-body pt-2 pb-0">
                                <div className="d-flex flex-wrap align-items-center">
                                    <span className="fs-28 text-black font-w600 me-3">{allVersement} XOF</span>
                                    <div className="d-flex align-items-center">
                                      
                                        
                                    </div>
                                </div>
                                {/*<span className="fs-13">ILOTS: 0</span>&nbsp;&nbsp;*/}
                                <span className="fs-13 mb-5">NOMBRE LOTS: {allnbreLot}</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-xxl-12">
                        <div className="card" onClick={LinkVentes}>
                            <div className="card-header border-0 pb-0">
                                <h3 className="fs-20 text-black">Acompte du jour</h3>
                               
                            </div>
                            <div className="card-body pt-2 pb-0">
                                <div className="d-flex flex-wrap align-items-center">
                                    <span className="fs-28 text-black font-w600 me-3">{nbreVente_day} XOF</span>
                                   {/* <p className="me-sm-auto me-3 mb-sm-0 mb-3">le mois 0 XOF</p>*/}
                                    <div className="d-flex align-items-center">
                                     
                                        
                                    </div>
                                </div>
                               {/* <span className="fs-13">ILOTS: 0</span>&nbsp;&nbsp;*/}
                                                 <span className="fs-13 mb-5">NOMBRE LOTS: {nbreLot_day}</span>
                            </div>
                        </div>
                    </div>
                     <div className="col-xl-3 col-xxl-12">
                        <div className="card" onClick={LinkPaiementComp}>
                            <div className="card-header border-0 pb-0">
                                <h3 className="fs-18 text-black">Paiement Annexe</h3>
                               
                            </div>
                            <div className="card-body pt-2 pb-0">
                                <div className="d-flex flex-wrap align-items-center">
                                    <span className="fs-28 text-black font-w600 me-3">{parseInt(sumMontant)} XOF</span>
                                    <div className="d-flex align-items-center">
                                      
                                    </div>
                                </div>
                                 <span className="fs-13">TVA: {tva} XOF</span>&nbsp;&nbsp;
                               
                            </div>
                        </div>
                    </div>

                     <div className="col-xl-3 col-xxl-12">
                        <div className="card" onClick={LinkProprietaire}>
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap mt-3">
                                                    <div className="media-body me-3">
                                                        <h2 className="fs-36 text-black font-w600">{nbreProprio}</h2>
                                                        <p className="fs-18 mb-0 text-black font-w500">Propriétaires terrien</p>
                                                        {/*<span className="fs-13">Target 3k/month</span>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                    </div>


                     <div className="col-xl-3 col-xxl-12">
                        <div className="card" onClick={LinkCompteAcquereur}>
                                    <div className="card-body">
                                                <div className="d-flex flex-wrap mt-3">
                                                    <div className="media-body me-3">
                                                        <h2 className="fs-36 text-black font-w600">{nbreProspect}</h2>
                                                        <p className="fs-18 mb-0 text-black font-w500">Prospect(s)</p>
                                                        {/*<span className="fs-13">Target 3k/month</span>*/}
                                                    </div>
                                                </div>
                                            </div>
                                    
                            </div>
                    </div>



                    <div className="col-xl-3 col-xxl-12">
                        <div className="card" onClick={LinkCompteAcquereur}>
                                                        <div className="card-body">
                                                            <div className="d-flex flex-wrap mt-3">
                                                                <div className="media-body me-3">
                                                                    <h2 className="fs-36 text-black font-w600">{nbreDirect}</h2>
                                                                    <p className="fs-18 mb-0 text-black font-w500">Acquéreur(s)</p>
                                                                    {/*<span className="fs-13">Target 3k/month</span>*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                    </div>


                    <div className="col-xl-3 col-xxl-12">
                        <div className="card" onClick={LinkCompteAcquereur}>
                                                        <div className="card-body">
                                                            <div className="d-flex flex-wrap mt-3">
                                                                <div className="media-body me-3">
                                                                    <h2 className="fs-36 text-black font-w600">{countRT}</h2>
                                                                    <p className="fs-18 mb-0 text-black font-w500">Reservation Terrain(s)</p>
                                                                    {/*<span className="fs-13">Target 3k/month</span>*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                    </div>


                    <div className="col-xl-3 col-xxl-12">
                        <div className="card" onClick={LinkCompteAcquereur}>
                                                        <div className="card-body">
                                                            <div className="d-flex flex-wrap mt-3">
                                                                <div className="media-body me-3">
                                                                    <h2 className="fs-36 text-black font-w600">{countRL}</h2>
                                                                    <p className="fs-18 mb-0 text-black font-w500">Reservation Logement(s)</p>
                                                                    {/*<span className="fs-13">Target 3k/month</span>*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                    </div>



                  
                    
                </div>
                )

                 }
                                  })
                                ()}

               
            </div>
        </div>
      
        <AppFooter />

      </>
    );
}

export default DashComponents;
