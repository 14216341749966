import React, { useEffect, useState } from 'react';
import { useParams} from 'react-router-dom';

// import { Link } from 'react-router-dom';
import logo from '../../logo-al-sanogo.png';

import Navbar from '../../components/Navbar';
import Siderbar from '../../components/Siderbar';
// import Fiche1 from './FICHE_DE_SOUSCRIPTION_ACQUISITION_DE_LOGEMENT_PERSONNE_MORALE_et_PHYSIQUE.jpg';
// import Fiche2 from './FICHE_DE_SOUSCRIPTION_ACQUISITION_DE_LOG_PM_et_PP_page.jpg';
// import Fiche3 from './echeancier_de_paiement.jpg';
import './css2/print3.css';

import Style from '../../style';


import '../../style.js';
import CompteAcquereurCRUD from '../../services/CompteAcquereurCRUD';

import UserCRUD from '../../services/UserCRUD';
import DocumentPrintCRUD from '../../services/DocumentPrintCRUD';
import Swal from 'sweetalert2';



const DetailDocs3 = props => {

const { id }= useParams()

 useEffect(() => {
        if(id)
        Getecheancier(id)
    }, [id]);

    const [detail, setDetail] = useState([]);

    const Getecheancier = (id) => {

        DocumentPrintCRUD.getEcheancier(id)
            .then((res) => {

                setDetail(res.data);


                console.log(res.data);

            })
            .catch((error) => {
                console.error(error);
            });

    }






const {

table,
thead,
th,
tbody,
td,

} = Style()










const Print = () => {
    window.print()
}



 const LinkTableEcheancier = () => {
        window.location.replace('/TableEcheancier');
    }

    
    return (
        <>
            <div id="main-wrapper">
                <Navbar />
                <Siderbar />


                {/* Content body start */}
                <div className="content-body">
                    <div className="container-fluid">


                     <div className="col-lg-12" id="dlogo">
                          <img className="" style={{marginLeft: '80px', display:'none'}} id="logo" src={logo} alt="" />
                     </div>

                      <div className="col-lg-12" id="" >
                      <div className="row">
                      <div className="col-lg-4"></div>

                      <div className="col-lg-4" id="title" style={{border:'2px solid #05f', textAlign:'center',  marginTop:'-2%'}}>
                          <h2 style={{marginTop:'1%'}} id="title_titre">FICHE D'ECHEANCIER CLIENT</h2>
                      </div>
                          
                      <div className="col-lg-4"></div>
                     </div>
                     </div>

                    

                  <div className="">
                                
                                 {detail.map((value) => (

                                <div className="" key={value.id_ech}>

                         <div className="col-lg-12" style={{marginTop:'5%'}} >
                         <div className="row">

                         <div className="col-lg-6" id="codeClit">
                             <h3 style={{fontWeight:'bold', color:'#000'}}  ><b style={{textDecoration:'underline', fontSize:'14px'}} >Nom clt:</b> &nbsp; 
                             

                             <input type="text" name="nom_client" id="nom_client"   className="" readOnly defaultValue={value.nom_client}  style={{width: '400px', fontWeight:'bold', background:'#eeeeee', border:'none',}}  placeholder="........." />

                                                            
                             
                             </h3>
                         </div>
                        
                             
                         </div>
                         </div>

                         <div className="col-lg-12" id="fiche3">
                                
                                <div className="col-lg-12" style={{marginTop: '2%', position: 'absolute'}} id="entete1">

                                    <p style={{color:'#000', fontWeight:'bold', fontSize:'14px'}} >

                                    <b id="typ_bien">TYPE DE BIEN: &nbsp;&nbsp; TERRAINS(S): &nbsp; 
                                    <input 
                                    type="number" 
                                    name="nbr_terrain" 
                                    id="input1" 
                                    className=""
                                    readOnly 
                                    defaultValue={value.nbr_terrain}
                                    
                                    style={{width: '49px', fontWeight:'bold', background:'#eeeeee', border:'none', textAlign:'center', fontSize:'18px'}} 
                                     />

                                     </b>

                                     &nbsp;&nbsp;
                                      <b id="sup">SUP: &nbsp;

                                     <input 
                                    type="text" 
                                    name="superficie" 
                                    id="Einput2" 
                                    className=""
                                    readOnly 
                                    defaultValue={value.superficie}
                                    
                                    style={{ width: '100px', fontWeight:'bold', background:'#eeeeee', border:'none', textAlign:'center', fontSize:'18px'}} 
                                     />

                                     </b>

                                     &nbsp;&nbsp;
                                     DUPLEX: &nbsp;

                                      <input 
                                        type="text" 
                                        name="duplex" 
                                        id="input3" 
                                        className=""
                                        readOnly 
                                        defaultValue={value.duplex}
                                    
                                        style={{width: '49px', fontWeight:'bold', background:'#eeeeee', border:'none', textAlign:'center', fontSize:'18px'}} 
                                         />

                                          &nbsp;&nbsp;

                                          Nbre Pièces:&nbsp;

                                          <input 
                                            type="number" 
                                            name="nbr_piece" 
                                            id="input4" 
                                            className=""
                                            readOnly 
                                            defaultValue={value.nbr_piece}
                                            
                                            style={{width: '75px', fontWeight:'bold', background:'#eeeeee', border:'none', textAlign:'center', fontSize:'18px'}} 
                                             />
                                     </p>  
                            
                                
                                    
                                </div>


                                  <div className="col-lg-12" id="dateEng">
                            <h5 style={{fontWeight:'bold', marginLeft:'90%', color:'#000', marginTop:'2%'}}  ><b style={{textDecoration:'underline', }} >Date:</b>&nbsp; {value.date_day} </h5>
                         </div>

                                 <div className="col-lg-12">
                                     <table style={table} id="table">
                                    
                                          <thead>
                                             <th colSpan="8"></th>
                                             <th style={th}><br/></th>
                                         </thead>
                                          <thead>
                                             <th colSpan="8"></th>
                                             <th style={th}>Les mensualités</th>
                                         </thead>

                                         <thead style={thead}>
                                             <th style={th}>N°Clt</th>
                                             {/*<th style={th}>Nom et prénoms</th>*/}
                                             <th style={th}>Valeur du bien <br />(F. CFA)</th>
                                             <th style={th}>FOD</th>
                                             <th style={th}>Mt déjà versé <br /> AI + FOD</th>
                                             <th style={th}>Mt Restant  <br /> à Payer</th>
                                             <th style={th}>/Mois</th>
                                             <th style={th}>X2/Mois</th>
                                             <th style={th}>X3/Mois</th>
                                             <th style={th}>Période  <br /> de paiement</th>
                                         </thead>
                                         <tbody style={tbody}>
                                             <tr>
                                                 <td style={td}  id="num_cli">{value.num_client}</td>
                                               
                                                 <td style={td}>
                                                      <input 
                                                    type="number" 
                                                    name="valeur_bien" 
                                                    id="Einput6" 
                                                    className=""
                                                    readOnly 
                                                    defaultValue={value.valeur_bien}
                                                    
                                                    style={{width: '194px', fontWeight:'bold', textAlign:'center', background:'#eeeeee', border:'none', fontSize:'14px'}} 
                                                     />
                                                 </td>
                                                 <td style={td} id="fob">
                                                     <input 
                                                    type="number" 
                                                    name="fod" 
                                                    id="Einput7" 
                                                    className=""
                                                    readOnly 
                                                    defaultValue={value.fod}
                                                    
                                                    style={{width: '100px', fontWeight:'bold', textAlign:'center', background:'#eeeeee', border:'none', fontSize:'14px'}} 
                                                     />
                                                 </td>
                                                 <td style={td}>
                                                      <input 
                                                    type="number" 
                                                    name="mt_verse" 
                                                    id="Einput8" 
                                                    className=""
                                                    readOnly 
                                                     defaultValue={value.mt_deja_verse}
                                                    
                                                    style={{width: '165px', fontWeight:'bold', textAlign:'center', background:'#eeeeee', border:'none', fontSize:'14px'}} 
                                                     />
                                                 </td>
                                                 <td style={td}>
                                                      <input 
                                                    type="number" 
                                                    name="mt_restant" 
                                                    id="Einput9" 
                                                    className=""
                                                    readOnly 
                                                    defaultValue={value.mt_restant}
                                                    
                                                    style={{width: '155px', fontWeight:'bold', textAlign:'center', background:'#eeeeee', border:'none', fontSize:'14px'}} 
                                                     />
                                                 </td>
                                                 <td style={td} id="mois">
                                                     <input 
                                                    type="number" 
                                                    name="mois" 
                                                    id="Einput10" 
                                                    className=""
                                                    readOnly
                                                    defaultValue={value.mois}
                                                     
                                                    style={{width: '100px', fontWeight:'bold', textAlign:'center', background:'#eeeeee', border:'none', fontSize:'14px'}} 
                                                     />
                                                 </td>
                                                 <td style={td} >
                                                     <input 
                                                    type="number" 
                                                    name="deux_mois" 
                                                    id="Einput11" 
                                                    className=""
                                                    readOnly 
                                                    defaultValue={value.deux_mois}
                                                    
                                                    style={{width: '100px', fontWeight:'bold', textAlign:'center', background:'#eeeeee', border:'none', fontSize:'14px'}} 
                                                     />
                                                 </td>
                                                 <td style={td} id="mois">
                                                     <input 
                                                    type="number" 
                                                    name="trois_mois" 
                                                    id="Einput12" 
                                                    className=""
                                                    readOnly 
                                                    defaultValue={value.trois_mois}
                                                    
                                                    style={{width: '100px', fontWeight:'bold', textAlign:'center', background:'#eeeeee', border:'none', fontSize:'14px'}} 
                                                     />
                                                 </td>
                                                 <td style={{borderRight:'1px solid #000'}}></td>
                                                 
                                             </tr>

                                              <tr>
                                                 <td colSpan="5" style={{borderBottom:'1px solid #000', borderRight:'1px solid #000', color:'red', textAlign:'right'}}>
                                                 Modalité de paiement &nbsp;
                                                 </td>
                                                 <td style={td}>
                                                      <input 
                                                    type="text" 
                                                    name="mod_un" 
                                                    id="Einput13" 
                                                    className=""
                                                    readOnly 
                                                     defaultValue={value.mod_paie_mois}
                                                    
                                                    style={{width: '100px', fontWeight:'bold', textAlign:'center', background:'#eeeeee', border:'none', fontSize:'14px'}} 
                                                     />
                                                 </td>
                                                 <td style={td}>
                                                     <input 
                                                    type="text" 
                                                    name="mod_deux" 
                                                    id="Einput14" 
                                                    className=""
                                                    readOnly 
                                                    defaultValue={value.mod_paie_2mois}
                                                    
                                                    style={{width: '100px', fontWeight:'bold', textAlign:'center', background:'#eeeeee', border:'none', fontSize:'14px'}} 
                                                     />
                                                 </td>
                                                 <td style={td}>
                                                     <input 
                                                    type="text" 
                                                    name="mod_trois" 
                                                    id="Einput15" 
                                                    className=""
                                                    readOnly
                                                    defaultValue={value.mod_paie_3mois}
                                                     
                                                    style={{width: '100px', fontWeight:'bold', textAlign:'center', background:'#eeeeee', border:'none', fontSize:'14px'}} 
                                                     />
                                                 </td>
                                                 <td style={{borderRight:'1px solid #000'}}>
                                                     <input 
                                                    type="text" 
                                                    name="periode_paie" 
                                                    id="Einput16" 
                                                    className=""
                                                    readOnly 
                                                    defaultValue={value.periode_paiement}
                                                     
                                                    style={{width: '100px',  fontWeight:'bold', textAlign:'center', background:'#eeeeee', border:'none', fontSize:'14px'}} 
                                                     />
                                                 </td>
                                             </tr>

                                             <tr>
                                                 <td colSpan="8" style={{borderRight:'1px solid #000',}}>
                                                     <br />
                                                 </td>
                                                  <td style={{borderRight:'1px solid #000'}}></td>
                                             </tr>

                                             

                                             <tr>
                                                 <td colSpan="5" style={{borderRight:'1px solid #000', borderTop:'1px solid #000', textAlign:'right'}}>
                                                     Dates limites de paiements &nbsp;
                                                 </td>
                                                  <td colSpan="3" style={{borderBottom:'1px solid #000' ,borderRight:'1px solid #000', borderTop:'1px solid #000'}}>
                                                      <input 
                                                    type="text" 
                                                    name="date_limite" 
                                                    id="Einput17" 
                                                    className=""
                                                    readOnly 
                                                    defaultValue={value.date_limite}
                                                     
                                                    style={{width: '100px', height:'17px',  fontWeight:'bold', textAlign:'center', background:'#eeeeee', border:'none', fontSize:'14px'}} 
                                                     />
                                                  </td>

                                                  <td style={{borderRight:'1px solid #000'}}></td>
                                             </tr>

                                             <tr>
                                                 <td colSpan="10" style={{borderRight:'1px solid #000', borderTop:'1px solid #000'}}>
                                                     <br />
                                                 </td>
                                             </tr>

                                             <tr>
                                                 <td style={td}></td>
                                                  <td style={td}>Apport initial (AI)</td>
                                                  <td style={td}>
                                                      <input 
                                                    type="text" 
                                                    name="apport_ini" 
                                                    id="Einput18" 
                                                    className=""
                                                    readOnly 
                                                    defaultValue={value.apport_initial}
                                                     
                                                    style={{width: '100px',  fontWeight:'bold', textAlign:'center', background:'#eeeeee', border:'none', fontSize:'14px'}} 
                                                     />
                                                  </td>
                                                  <td colSpan="7" style={{borderRight:'1px solid #000', borderTop:'1px solid #000'}}></td>
                                             </tr>

                                             <tr>
                                                 <td colSpan="10" style={{borderRight:'1px solid #000', borderTop:'1px solid #000'}}>
                                                     <br />
                                                 </td>
                                             </tr>

                                            <tr>
                                                 <td colSpan="4" style={{borderRight:'1px solid #000', borderTop:'1px solid #000'}}>
                                                    
                                                 </td>
                                                  <td style={td}>Date échéances</td>
                                                  <td style={td}>
                                                      <input 
                                                    type="text" 
                                                    name="date_echeance" 
                                                    id="Einput19" 
                                                    className=""
                                                    readOnly 
                                                    defaultValue={value.date_echeance}
                                                     
                                                    style={{width: '100px',  fontWeight:'bold', textAlign:'center', background:'#eeeeee', border:'none', fontSize:'14px'}} 
                                                     />
                                                  </td>
                                                  <td colSpan="3" style={{borderRight:'1px solid #000', borderTop:'1px solid #000'}}>
                                                 </td>
                                             </tr>

                                         </tbody>
                                     </table>
                                 </div>

                            </div>


                            <div className="col-lg-12" id="signature" style={{marginTop:'5%'}} >
                             <div className="row">

                             <div className="col-lg-3" id="suivi">
                                 <h3 style={{textDecoration:'underline', fontWeight:'bold'}} id="suivi1" >Suivi par:</h3>
                                 <h4 id="suivi2"><i>(N&P de l'Agent, signature + cachet)</i></h4>
                                 <h3 id="suivi3" style={{color:'#000'}} > {value.suivi_par} </h3>

                             </div>

                             <div className="col-lg-6" style={{textAlign:'center'}}>
                                <h3 style={{textDecoration:'underline', fontWeight:'bold',}} id="directeur"  >Signature du directeur <br /> commercial & marketing:</h3>
                             </div>


                             <div className="col-lg-3" id="clientSIgn">
                                 <h3 style={{textDecoration:'underline', fontWeight:'bold'}} id="clientSIgn1" >Signature du client:</h3>
                                 <h4 id="clientSIgn2"><i>(Bon pour acceptation + Signature)</i></h4>
                             </div>
                                 
                             </div>
                             </div>

                            </div>

                            ))}

                            </div>
            <button onClick={LinkTableEcheancier} className="btn btn-danger mt-5 mb-5 col-3" style={{float:'left'}} id="btnRetour">Retour</button>

            <button type="submit"  onClick={(e) => Print(e)} className="btn btn-outline-primary mt-5 mb-5 col-3" id="btnPrint">Imprimer</button>
                    
                

                    </div>

                </div>
                {/* Content body end */}

            </div>

        </>

    );
}
export default DetailDocs3;