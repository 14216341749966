import logo from '../logo-al-sanogo.png';
import '../index.css'
//import {Link } from 'react-router-dom'
import { useState } from 'react';
import AuhtUser from '../services/AuthUser';

function Login() {

     const { http, setToken } = AuhtUser()
    //const [email, setEmail] = useState()
    const [username, setUsername] = useState('')

    const [password, setPassword] = useState('')
    const [errorMsg, setMsg] = useState('')

    const usernameInput = document.getElementById("username");
    const passwordInput = document.getElementById("pwd");
    // login function
    const handlerSubmit = () => {
        // api call (email, name, role, username, statut, token)
        if (username !== '' && password !== '') {
          http.post('/login', { username: username, password: password })
            .then((res) => {
                console.log(res.data)
                //console.log(res.data.name)
                // console.log(res.data.response['data'])

                if(res.data.statut !== undefined && res.data.statut !== "" && res.data.statut !== false && res.data.etat === 1){

                  setToken(res.data.statut);
                }else{
                   setMsg('Votre compte est desactivé Veuillez contacter l\'administration')
                }

            })
            .catch((error) => {
              setMsg(error.response.data)
              console.error(error.response.data);
              usernameInput.value = ''
              passwordInput.value = ''
          });
        }else
            if(username === '' && password !== ''){
          setMsg('Le champ Nom utilisateur est requis')

        }else
          if(username !== '' && password === ''){
            setMsg('Le champ Mot de passe est requis')
          }else{
             setMsg('Les champs sont requis')
          }
        
    }

    
    return (
      <div className="h-100 mt-5">
          <div className="authincation h-100">
              <div className="container h-100"> 
                  <div className="row justify-content-center h-100 align-items-center">
                      <div className="col-md-6">
                          <div className="authincation-content">
                              <div className="row no-gutters">
                                  <div className="col-xl-12">
                                      <div className="auth-form">
                      									<div className="text-center mb-3">
                      										<a href="index.html">
                                              <img className="logo mb-4" src={logo} alt="" />
                                          </a>
                      									</div>
                                          <h4 className="text-center mb-4">Connectez-vous à votre compte</h4>
                                              <p  className={errorMsg ? "errmsg" : "offscreen"} style={{color:'red', textAlign: 'center', marginBottom: 50, fontSize:20, fontWeight: 'bold'}} >
                                            {errorMsg}
                                            </p>
                                            
                                              <div className="form-group">
                                                <label className="mb-1">
                                                    <strong>Nom utilisateur</strong>
                                                </label>
                                                <input
                                                    type="text"
                                                    id='username'
                                                    name='username'
                                                    className="form-control"
                                                    autoComplete='off'
                                                    placeholder="hello00458"
                                                    onChange={e => setUsername(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="mb-3">
                                                    <strong>Mot de passe</strong>
                                                </label>
                                                <input
                                                    type="password"
                                                    id='pwd'
                                                    name='pwd'
                                                    autoComplete='off'
                                                    className="form-control"
                                                    placeholder="Password"
                                                    onChange={e => setPassword(e.target.value)}
                                                    required
                                                />
                                            </div>

                                            <div className="text-center mt-5 mb-4">
                                                <button type="button" className="btn btn-primary btn-block mb-4" onClick={handlerSubmit}>Connexion</button>
                                            </div>



                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          </div>
    );
}

export default Login;
