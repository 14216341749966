import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import logo from '../logo-al-sanogo.png';
import Navbar from '../components/Navbar';
import Siderbar from '../components/Siderbar';
import '../print.css';
import PrintStyle from '../PrintStyle';

import { useParams } from 'react-router-dom';

import VentesCRUD from '../services/VentesCRUD';
import CompteAcquereurCRUD from '../services/CompteAcquereurCRUD';



import { NumberToLetter } from 'convertir-nombre-lettre';



const RecuPaidComp = props => {

    const {
        stylesBorder,
        styleHeade,
        stylesBorder1,
        bodyBorder,
        bodyBorderEnd,
        smallBody,
        EndBorder,
        textStylesFooter,
        textStylesFooter1,
        textEndPosition,
        spaceSubHeader,
        textFooter } = PrintStyle()

    const { id } = useParams()


    useEffect(() => {
        if (id)
            PaidByCode(id);
        DetailPaidByCode(id);

    }, [id]);


    const [dataPaid, setDataPaid] = useState([])

    const PaidByCode = (code) => {

        VentesCRUD.PaidComplementaireBy(code)
            .then((res) => {

                setDataPaid(res.data);

                console.log(res.data);

            })
            .catch((error) => {
                console.error(error);
            });

    }



 const [sumMontant, setSumMontant] = useState([])
 const [sumMontantTTC, setSumMontantTTC] = useState([])
 const [sumMontantTVA, setSumMontantTVA] = useState([])



    const DetailPaidByCode = (code) => {

        VentesCRUD.DetailPaidComplementaireBy(code)
            .then((res) => {
                var SmMontant = res.data.SumMontant;
                var SumTva = res.data.sumAmountTva;

                 var Mtt_HT = parseInt(SmMontant) + parseInt(SumTva);

                setSumMontant(res.data.SumMontant);
                setSumMontantTVA(res.data.sumAmountTva);
                setSumMontantTTC(SmMontant);


                console.log(res.data);

            })
            .catch((error) => {
                console.error(error);
            });

    }







   
function PrintRecu(){
   
                
                window.print();
            
                
            }

   


    const LinkPaiementComplementaire = () => {
        window.location.replace('/PaiementComplementaire');
    }

    // var currr = new Date();
    // currr.setDate(currr.getDate());
    // var dateDay = currr.toISOString().substr(0,10);


    // var today = new Date();
    // var dd = String(today.getDate()).padStart(2, '0');
    // var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    // var yyyy = today.getFullYear();

    // var dateDay = dd + '/' + mm + '/' + yyyy;



const { NumberToLetter } = require("convertir-nombre-lettre");


function currencyFormat(num) {
   return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,')
}


    return (
        <>
            <div id="main-wrapper">
                <Navbar />
                <Siderbar />

                {/* Content body start */}
                <div className="content-body">
                    {/* row */}
                    <div className="container-fluid">
                       
                                <div className="row">
                                    <div className="col-12">
                                        {/* Header */}
                                       
                                        {/* SubHeader */}
                                        
                                        {/* Body */}
                                        <div className="row" id="tableau">
                                            <div className="col-12">
                                                <div className="row">
                                                    <table style={styleHeade}>
                                                        <thead>
                                                            <tr>
                                                                <th className='footTxtTwo' style={stylesBorder}>
                                                                    <b>DESIGNATION</b>
                                                                </th>
                                                                <th className='footTxtTwo' style={stylesBorder}>
                                                                    <b>MONTANT HT</b>
                                                                </th>
                                                                 <th className='footTxtTwo' style={stylesBorder}>
                                                                    <b>TVA 18%</b>
                                                                </th>
                                                                <th className='footTxtTwo' style={stylesBorder}>
                                                                    <b>MONTANT TTC</b>
                                                                </th>
                                                               
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {dataPaid.map((res) => (
                                                                <tr key={res.id_paid_comp}>
                                                                    
                                                                    <td style={{borderTop:'1px solid #000', borderBottom:'1px solid #000', borderLeft:'1px solid #000', borderRight:'1px solid #000'}}>
                                                                    {res.objets}
                                                                    </td>
                                                                    <td style={{borderTop:'1px solid #000', borderBottom:'1px solid #000', borderLeft:'1px solid #000', borderRight:'1px solid #000'}}>
                                                                    {parseInt(res.montant) - parseInt(res.montant_tva)}
                                                                    </td>
                                                                     <td style={{borderTop:'1px solid #000', borderBottom:'1px solid #000', borderLeft:'1px solid #000', borderRight:'1px solid #000'}}>
                                                                    {parseInt(res.montant_tva)}
                                                                    </td>
                                                                    <td style={{borderTop:'1px solid #000', borderBottom:'1px solid #000', borderLeft:'1px solid #000', borderRight:'1px solid #000'}}>
                                                                    {parseInt(res.montant)}
                                                                    </td>
                                                                    
                                                                   
                                                                </tr>
                                                            ))}

                                                           
                                                            
                                                            <tr>
                                                                <td colSpan={1} ></td>
                                                                <td style={smallBody}>
                                                                    <b> {parseInt(sumMontant) - parseInt(sumMontantTVA)}</b>
                                                                </td>
                                                                 <td style={smallBody}> 
                                                                 <b>{parseInt(sumMontantTVA)}</b>
                                                                 </td>
                                                                <td style={smallBody}>
                                                                    {/*<b>{currencyFormat(parseInt(sumMontant))}</b>*/}
                                                                    <b>{parseInt(sumMontantTTC)}</b>
                                                                </td>
                                                            </tr>

                                                            
                                                            
                                                           
                                                          
                                                         </tbody>
                                                    </table>

                                                   
                                                </div>
                                            </div>
                                        </div>
                                        {/* Footer */}
                                      
                                    </div>
                                    <>
                                        <button type="button" className="btn btn-danger mt-5 mb-5 me-5 col-2" id="btnRetour" onClick={() => LinkPaiementComplementaire()}>Retour</button>
                                         <button type="button"  className="btn btn-outline-primary mt-5 mb-5 col-3" id="btnPrint" onClick={()=> PrintRecu()}>Imprimer</button>

                                    </>
                                </div>

                    </div>
                </div>
                {/* Content body end */}
                

            </div>
        </>

    );
}
export default RecuPaidComp;