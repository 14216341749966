import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import Siderbar from '../components/Siderbar';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import Swal from 'sweetalert2';
import VentesCRUD from '../services/VentesCRUD';
//import { Button, Modal } from 'react-bootstrap';

const Cessions = () => {

    useEffect(() => {
        ViewDetailCession()
    }, []);

    const [detailCession, setDetailCession] = useState([]);
    //const [nouveauAcquereur, setNouveauAcquereur] = useState([]);

    const ViewDetailCession = () => {

        VentesCRUD.getCession()
            .then((res) => {

                setDetailCession(res.data);


                console.log(res.data);

            })
            .catch((error) => {
                console.error(error);
            });

    }

    // Colomn cession
    const columnsCessionVente = [

        {
            name: "Ancient Acquéreur",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.client_cess} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Nouveau Acquéreur",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.client_cede} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "Date cession",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.date_cess} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Doc autorisation",
            cell: (rows) => (
                <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/" + rows.document_cess} target={'_blank'} rel="noreferrer">
                    <i className="fa fa-download fa-2x"></i>
                </a>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Lotissement",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.libelle_loti} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "N°Ilot",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.numero_ilot} </b>
            ),
            sortable: true,
            reorder: true
        },

        {
            name: "N°Lot",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.lot_cess} </b>
            ),
            sortable: true,
            reorder: true
        },
        {
            name: "Prix vendu",
            cell: (rows) => (
                <b style={{ color: "black" }}> {rows.prix_vendu} </b>
            ),
            sortable: true,
            reorder: true
        },
         {
          name: "Supprimer",
          cell: (rows) => (
              <div>
                <div className="d-flex">

                  <button type="button" className="btn btn-outline-danger btn-xs" onClick={() => DeleteCession(rows.vente_cede)}><i className="fa fa-trash"></i></button>
                </div>
              </div>

           
      ),
      sortable: true,
      reorder: true
    },

    ]




     const DeleteCession = (code) => {
    console.log(code);
    Swal.fire({
      title: 'Es-tu sûr?',
      html: "Vous ne pourrez pas revenir en arrière!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprime-le!'
    }).then((result) => {
      if (result.isConfirmed) {
        VentesCRUD.DeleteCession(code)
          .then(response => {
            console.log(response.data);
           setDetailCession()
            Swal.fire(
              'Supprimé!',
              'Votre fichier a été supprimé.',
              'success'
            )
          })
          .catch(e => {
            console.log(e);
          });

      }
    })
  };

    return (
        <div className="">
            <Navbar />
            <Siderbar />

            <div className="content-body">
                <div className="container-fluid">
                    <div className="page-titles">
                        <ol className="breadcrumb">
                            <h1><b style={{ color: "red" }}>Liste des cessions</b></h1>
                        </ol>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Datatable</h4>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <DataTableExtensions
                                            columns={columnsCessionVente}
                                            data={detailCession}
                                            export={false}
                                            print={false}
                                        >
                                            <DataTable
                                                title="DataTable"
                                                // customStyles={customStyles}
                                                responsive
                                                noHeader
                                                defaultSortFieldId={1}
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                // selectableRows
                                                persistTableHead
                                                highlightOnHover
                                                exportHeaders={false}
                                            />
                                        </DataTableExtensions>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cessions;