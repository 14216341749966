import axios from '../axios/axios';

const getAll = () => {
  return axios.get("/ilots");
};

const getAllIlot = id => {
  return axios.get(`/ListilotsBy/${id}`);
};


const get = id => {
  return axios.get(`/ilots/${id}`);
};

const getNumIlot = (id, num, lotiss) => {
  return axios.get(`/ilotsBy/${id}/${num}/${lotiss}`);
};

const create = data => {
  return axios.post("/ilots", data);
};

const createLotComplementaire = data => {
  return axios.post("/lots_complementaire", data);
};

const update = (id, data) => {
  return axios.put(`/ilots/${id}`, data);
};
const remove = id => {
  return axios.delete(`/ilots/${id}`);
};


const removeLot = id => {
  return axios.delete(`/lots/${id}`);
};

const removeILot = (code, num, lotiss) => {
  return axios.get(`/delete_ilot/${code}/${num}/${lotiss}`);
};

const removeAll = () => {
  return axios.delete(`/ilots`);
};
const getIlotInterval = id => {
  return axios.get(`/get_ilot_active/${id}`);
};

const getLot = id => {
  return axios.get(`/lots/${id}`);
};

const getLotIlot = (id, ilot) => {
  return axios.get(`/lots_ilot/${id}/${ilot}`);
};

const getILotDispo = code => {
  return axios.get(`/get_ilot_dispo/${code}`);
};


const getLotDispo = (code, num) => {
  return axios.get(`/get_lot_dispo/${code}/${num}`);
};

const findByNumDebut = (debut, code) => {
  return axios.get(`/lots_found/${debut}/${code}`);
};

const NombreLots = code => {
  return axios.get(`/count_ilot_lot/${code}`);
};

const IDILots = () => {
  return axios.get(`/get_ilot_code`);
};


const createRemorcele  = data => {
  return axios.post("/remorcele  ", data);
};


// const removeILotRemorcele = (ilot, lot, remorc, numilot) => {
//   return axios.get(`/remort/${ilot}/${lot}/${remorc}/${numilot}`);
// };


const removeILotRemorcele = (id) => {
  return axios.get(`/remort/${id}`);
};

const LotIlotCRUD = {
  getAll,
  getAllIlot,
  get,
  create,
  update,
  remove,
  removeLot,
  removeILot,
  removeAll,
  getIlotInterval,
  getLot,
  getILotDispo,
  getLotDispo,
  findByNumDebut,
  createLotComplementaire,
  NombreLots,
  IDILots,
  createRemorcele,
  removeILotRemorcele,
  getNumIlot,
  getLotIlot
};
export default LotIlotCRUD;