import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import logo from '../logo-al-sanogo.png';
import Navbar from '../components/Navbar';
import Siderbar from '../components/Siderbar';
import '../print.css';
import PrintStyle from '../PrintStyle';


import LotissementCRUD from '../services/LotissementCRUD';






const RecuPaidComp = props => {

    const {
        stylesBorder,
        styleHeade,
        // stylesBorder1,
        // bodyBorder,
        // bodyBorderEnd,
        smallBody,
        EndBorder,
        // textStylesFooter,
        // textStylesFooter1,
        textEndPosition,
        spaceSubHeader,
        // textFooter 
    } = PrintStyle()



    useEffect(() => {
        
            Lottissement();

    }, []);


    const [data, setData] = useState([])

    const Lottissement = () => {

        LotissementCRUD.getAll()
            .then((res) => {

                setData(res.data);

                console.log(res.data);

            })
            .catch((error) => {
                console.error(error);
            });

    }





   
function PrintRecu(){
   
                
                window.print();
            
                
            }

   


    const LinkPaiementComplementaire = () => {
        window.location.replace('/Etatglobal');
    }



    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    var dateDay = dd + '/' + mm + '/' + yyyy;





function currencyFormat(num) {
   return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,')
}


    return (
        <>
            <div id="main-wrapper">
                <Navbar />
                <Siderbar />

                {/* Content body start */}
                <div className="content-body">
                    {/* row */}
                    <div className="container-fluid">
                       
                                <div className="row">
                                    <div className="col-12">
                                        {/* Header */}
                                        <div className='row'>
                                            <div className='row'>
                                                <div className='col-8'>
                                                    <img src={logo} alt="Img" style={{ width: '200px' }} />
                                                </div>
                                                <div className='col-4' style={textEndPosition}>
                                                    Abidjan, le {dateDay}
                                                </div>
                                            </div>
                                        </div>
                                        {/* SubHeader */}
                                        <div className='row' style={spaceSubHeader}>
                                            <div className='col-6'>
                                               
                                            </div>
                                            <div className='col-6'>
                                                {/*<b>FACTURE CLIENT N° : </b> {result.code_vente}*/}
                                               
                                            </div>
                                        </div>
                                        {/* Body */}
                                        <div className="row" style={{marginTop:'50px'}}>
                                            <div className="col-12">
                                                <div className="row">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                            <th className='footTxtTwo' style={stylesBorder}>
                                                                    <b>LOTIISSEMENT</b>
                                                                </th>

                                                                <th className='footTxtTwo' style={stylesBorder}>
                                                                    <b>ETAT</b>
                                                                </th>
                                                                <th className='footTxtTwo' style={stylesBorder}>
                                                                    <b>NOMBRE ILOTS</b>
                                                                </th>
                                                                <th className='footTxtTwo' style={stylesBorder}>
                                                                    <b>NOMBRE LOTS TOTAL</b>
                                                                </th>
                                                                 <th className='footTxtTwo' style={stylesBorder}>
                                                                    <b>NOMBRE LOTS VENDU</b>
                                                                </th>
                                                                <th className='footTxtTwo' style={stylesBorder}>
                                                                    <b>NOMBRE DISPONIBLE</b>
                                                                </th>
                                                                
                                                               
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.map((res) => (
                                                                <tr key={res.id_paid_comp}>
                                                                   <td style={{borderTop:'1px solid #000', borderBottom:'1px solid #000', borderLeft:'1px solid #000', borderRight:'1px solid #000'}}>
                                                                   {res.libelle_loti}</td>
                                                                    <td style={{borderTop:'1px solid #000', borderBottom:'1px solid #000', borderLeft:'1px solid #000', borderRight:'1px solid #000'}}>
                                                                    {res.etat_loti}</td>
                                                                    <td style={{borderTop:'1px solid #000', borderBottom:'1px solid #000', borderLeft:'1px solid #000', borderRight:'1px solid #000'}}>
                                                                    {res.nombre_ilot}</td>
                                                                    <td style={{borderTop:'1px solid #000', borderBottom:'1px solid #000', borderLeft:'1px solid #000', borderRight:'1px solid #000'}}>
                                                                    {res.nombre_lot}</td>
                                                                     <td style={{borderTop:'1px solid #000', borderBottom:'1px solid #000', borderLeft:'1px solid #000', borderRight:'1px solid #000'}}>
                                                                    {res.nbr_lot_vendu}</td>
                                                                    <td style={{borderTop:'1px solid #000', borderBottom:'1px solid #000', borderLeft:'1px solid #000', borderRight:'1px solid #000'}}>
                                                                    {parseInt(res.nombre_lot) - parseInt(res.nbr_lot_vendu)}</td>
                                                                    
                                                                   
                                                                </tr>
                                                            ))}

                                                           
                                                           

                                                            
                                                            
                                                           
                                                            
                                                         </tbody>
                                                    </table>

                                                    
                                                </div>
                                            </div>
                                        </div>
                                        {/* Footer */}
                                        
                                    </div>
                                    <>
                                        <button type="button" className="btn btn-danger mt-5 mb-5 me-5 col-2" id="btnRetour" onClick={() => LinkPaiementComplementaire()}>Retour</button>
                                         <button type="button"  className="btn btn-outline-primary mt-5 mb-5 col-3" id="btnPrint" onClick={()=> PrintRecu()}>Imprimer</button>

                                    </>
                                </div>

                    </div>
                </div>
                {/* Content body end */}
                

            </div>
        </>

    );
}
export default RecuPaidComp;