import React, { Component } from 'react';
// import {Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Siderbar from '../components/Siderbar';
import '../index.css';

import ProprietaireCRUD from '../services/ProprietaireCRUD';
import LotissementCRUD from '../services/LotissementCRUD';
import InformationAdministrativeFormCRUD from '../services/InformationAdministrativeFormCRUD';

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

import Swal from 'sweetalert2';

export default class LotissementForm extends Component {
  constructor(props) {
    super(props);

    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeLibelle = this.onChangeLibelle.bind(this);

    this.onChangeSuperficieHa = this.onChangeSuperficieHa.bind(this);
    this.onChangeSuperficieAre = this.onChangeSuperficieAre.bind(this);
    this.onChangeSuperficieCa = this.onChangeSuperficieCa.bind(this);

    this.onChangeProprietaire = this.onChangeProprietaire.bind(this);
    this.onChangePrometeur = this.onChangePrometeur.bind(this);

    this.onChangeDebutIlot = this.onChangeDebutIlot.bind(this);
    this.onChangeFinIlot = this.onChangeFinIlot.bind(this);
    this.onChangeDebutLot = this.onChangeDebutLot.bind(this);
    this.onChangeFinLot = this.onChangeFinLot.bind(this);

    this.onChangeNumArrete = this.onChangeNumArrete.bind(this);
    this.onChangeCentreOP = this.onChangeCentreOP.bind(this);

    // this.onChangeDistrict = this.onChangeDistrict.bind(this);
    this.onChangeVille = this.onChangeVille.bind(this);
    this.onChangeDepartement = this.onChangeDepartement.bind(this);
    this.onChangeCommune = this.onChangeCommune.bind(this);
    this.onChangeVillage = this.onChangeVillage.bind(this);

    this.getPropietaire = this.getPropietaire.bind(this);
    this.getLotissement = this.getLotissement.bind(this);

    // this.onChangeLotiss = this.onChangeLotiss.bind(this);
    this.onChangeSituation = this.onChangeSituation.bind(this);
    this.onChangeConstitution = this.onChangeConstitution.bind(this);
    this.onChangeEtat = this.onChangeEtat.bind(this);

    this.onChangeNumeroArrete = this.onChangeNumeroArrete.bind(this);
    this.onChangeNumeroFoncier = this.onChangeNumeroFoncier.bind(this);
    this.onChangeAffection = this.onChangeAffection.bind(this);
    this.onChangeHabitation = this.onChangeHabitation.bind(this);
    this.onChangeCentre = this.onChangeCentre.bind(this);
    this.onChangeOPerateur = this.onChangeOPerateur.bind(this);

    this.state = {
      date_loti: "",
      commune: "",
      village: "",
      ville: "",
      departement: "",
      libelle: "",
      superficie_ha: "",
      superficie_are: "",
      superficie_ca: "",
      unite: "",
      proprietaire: "",
      prometeur: "",
      debut_ilot: "",
      fin_ilot: "",
      debut_lot: "",
      fin_lot: "",
      num_arrete: "",
      centre_ope: "",
      //attestation :"",
      //arrete :"",

      carte: '',

      situation_dossier: '',
      constitution_dossier: '',
      pv: '',
      attestation: '',
      certificat: '',
      conservation: '',
      attestation_dec: '',
      arrete_dap: '',

      etat: '',

      numero_arrete: '',
      numero_titre_foncier: '',
      affection: '',
      habitation: '',
      centre_operation: '',
      operation: '',

      responseMsg: {
        status: "",
        message: "",
        error: "",
      },

      listproprietaires: [],
      listelottissement: [],
      listelvilles: [],
    };
  }

  //////////////////////// INFORMATION LOTISSEMENT //////////////////////////

  onChangeDate(e) {
    this.setState({
      date_loti: e.target.value
    });
  }

  onChangeLibelle(e) {
    this.setState({
      libelle: e.target.value
    });
  }

  onChangeSuperficieHa(e) {
    this.setState({
      superficie_ha: e.target.value
    });
  }

  onChangeSuperficieAre(e) {
    this.setState({
      superficie_are: e.target.value
    });
  }

  onChangeSuperficieCa(e) {
    this.setState({
      superficie_ca: e.target.value
    });
  }

  onChangeProprietaire(e) {
    this.setState({
      proprietaire: e.target.value
    });
  }

  onChangePrometeur(e) {
    this.setState({
      prometeur: e.target.value
    });
  }

  onChangeDebutIlot(e) {
    this.setState({
      debut_ilot: e.target.value
    });
  }

  onChangeFinIlot(e) {
    this.setState({
      fin_ilot: e.target.value
    });
  }

  onChangeDebutLot(e) {
    this.setState({
      debut_lot: e.target.value
    });
  }

  onChangeFinLot(e) {
    this.setState({
      fin_lot: e.target.value
    });
  }
  onChangeNumArrete(e) {
    this.setState({
      num_arrete: e.target.value
    });
  }

  onChangeCentreOP(e) {
    this.setState({
      centre_ope: e.target.value
    });
  }

  onChangeVille(e) {
    this.setState({
      ville: e.target.value
    });
  }

  onChangeDepartement(e) {
    this.setState({
      departement: e.target.value
    });
  }

  onChangeCommune(e) {
    this.setState({
      commune: e.target.value
    });
  }

  onChangeVillage(e) {
    this.setState({
      village: e.target.value
    });
  }

  //////////////////////// INFORMATION LOTISSEMENT //////////////////////////



  //////////////////////// DOCUMMENT LOTISSEMENT //////////////////////////

  onChangeSituation(e) {
    this.setState({
      situation_dossier: e.target.value
    });
  }

  onChangeConstitution(e) {
    this.setState({
      constitution_dossier: e.target.value
    });
  }

  // image onchange hander
  handleCarteLotissement = (e) => {
    this.setState({
      carte: e.target.files[0]
    })
  }

  handlePvChange = (e) => {
    this.setState({
      pv: e.target.files[0]
    })
  }

  handleAttestationChange = (e) => {
    this.setState({
      attestation: e.target.files[0]
    })
  }


  handleCertificatChange = (e) => {
    this.setState({
      certificat: e.target.files[0]
    })
  }

  handleConservationChange = (e) => {
    this.setState({
      conservation: e.target.files[0]
    })
  }

  handleAttestationDecessionChange = (e) => {
    this.setState({
      attestation_dec: e.target.files[0]
    })
  }

  handleArreteChange = (e) => {
    this.setState({
      arrete_dap: e.target.files[0]
    })
  }


  //////////////////////// DOCUMMENT LOTISSEMENT //////////////////////////




  //////////////////////// ETAT  LOTISSEMENT //////////////////////////

  onChangeEtat(e) {
    this.setState({
      etat: e.target.value
    });
  }


  //////////////////////// ETAT LOTISSEMENT //////////////////////////



  //////////////////////// INFORMATION ADMINISTRATIVE LOTISSEMENT //////////////////////////

  onChangeNumeroArrete(e) {
    this.setState({
      numero_arrete: e.target.value
    });
  }

  onChangeNumeroFoncier(e) {
    this.setState({
      numero_titre_foncier: e.target.value
    });
  }

  onChangeAffection(e) {
    this.setState({
      affection: e.target.value
    });
  }

  onChangeHabitation(e) {
    this.setState({
      habitation: e.target.value
    });
  }

  onChangeCentre(e) {
    this.setState({
      centre_operation: e.target.value
    });
  }

  onChangeOPerateur(e) {
    this.setState({
      operation: e.target.value
    });
  }


  //////////////////////// INFORMATION ADMINISTRATIVE LOTISSEMENT //////////////////////////




  // submit handler
  submitHandler = (e) => {
    e.preventDefault();
    const data = new FormData()

    const curr = new Date();
    // curr.setDate(curr.getDate() + 3);
    curr.setDate(curr.getDate());
    const date = curr.toISOString().substr(0, 10);

    const date_lotiInput = document.getElementById("date_loti");
    const libelleInput = document.getElementById("libelle");
    const superficie_haInput = document.getElementById("superficie_ha");
    const superficie_areInput = document.getElementById("superficie_are");

    const superficie_caInput = document.getElementById("superficie_ca");
    const debut_ilotInput = document.getElementById("debut_ilot");
    const fin_ilotInput = document.getElementById("fin_ilot");
    const debut_lotInput = document.getElementById("debut_lot");
    const fin_lotInput = document.getElementById("fin_lot");
    const num_arreteInput = document.getElementById("num_arrete");
    const centre_opeInput = document.getElementById("centre_ope");

    const situation_dossierInput = document.getElementById("situation_dossierInput");
    const constitution_dossierInput = document.getElementById("constitution_dossierInput");


    const numero_arreteInput = document.getElementById("numero_arrete");
    const numero_titre_foncierInput = document.getElementById("numero_titre_foncier");
    const affectionInput = document.getElementById("affection");
    const habitationInput = document.getElementById("habitation");
    const centre_operationInput = document.getElementById("centre_operation");

    data.append('date_loti', date)
    data.append('commune', this.state.commune)
    data.append('village', this.state.village)
    data.append('ville', this.state.ville)
    data.append('departement', this.state.departement)
    data.append('libelle', this.state.libelle)

    data.append('superficie_ha', this.state.superficie_ha)
    data.append('superficie_ca', this.state.superficie_ca)
    data.append('superficie_are', this.state.superficie_are)

    data.append('unite', this.state.unite)
    data.append('proprietaire', this.state.proprietaire)
    data.append('prometeur', "Al Sanogo")
    data.append('debut_ilot', this.state.debut_ilot)
    data.append('fin_ilot', this.state.fin_ilot)

    data.append('debut_lot', this.state.debut_lot)
    data.append('fin_lot', this.state.fin_lot)
    data.append('num_arrete', this.state.num_arrete)
    data.append('centre_ope', this.state.centre_ope)

    data.append('carte', this.state.carte)

    data.append('pv', this.state.pv)
    data.append('attestation', this.state.attestation)
    data.append('certificat', this.state.certificat)
    data.append('concervation', this.state.conservation)
    data.append('cession', this.state.attestation_dec)
    data.append('arrete', this.state.arrete_dap)

    data.append('situation', this.state.situation_dossier)
    data.append('constitution', this.state.constitution_dossier)

    data.append('etat', this.state.etat)

    data.append('arrete_infos', this.state.numero_arrete)
    data.append('titre', this.state.numero_titre_foncier)
    data.append('affectation', this.state.affection)
    data.append('habitation', this.state.habitation)
    data.append('centre', this.state.centre_operation)
    data.append('operation', 'Lot')

    LotissementCRUD.create(data)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            responseMsg: {
              status: response.data.status,
              message: response.data.message,
            },
          });
          // setTimeout(() => {
          //     this.setState({
          //     attestation: "",
          //     arrete: "",
          //     responseMsg: "",
          //     });
          // }, 5000);
          document.querySelector("#LotiissementForm").reset();

          date_lotiInput.value = ""
          libelleInput.value = ""
          superficie_haInput.value = ""
          superficie_areInput.value = ""
          superficie_caInput.value = ""
          debut_ilotInput.value = ""
          fin_ilotInput.value = ""
          debut_lotInput.value = ""
          fin_lotInput.value = ""
          num_arreteInput.value = ""
          centre_opeInput.value = ""

          situation_dossierInput.value = ""
          constitution_dossierInput.value = ""

          numero_arreteInput.value = ""
          numero_titre_foncierInput.value = ""
          affectionInput.value = ""
          habitationInput.value = ""
          centre_operationInput.value = ""

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: response.data.message,
            showConfirmButton: false,
            timer: 5000
          })

          // getting uploaded images
          //this.refs.child.getImages();
          this.getLotissement();

        }
        if (response.status === 300) {
          Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: response.data.message,
            showConfirmButton: false,
            timer: 10000
          })
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: error.response.data.message,
          showConfirmButton: false,
          timer: 10000
        })
      });
  }

  componentDidMount() {
    this.getPropietaire();
    this.getLotissement();
    this.getInfosAdmin();
    this.getVilles();
  }


  getPropietaire() {
    ProprietaireCRUD.getAll()
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            listproprietaires: response.data,
          });
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getLotissement() {
    LotissementCRUD.getAll()
      .then((res) => {
        this.setState({
          listelottissement: res.data,
        });
        console.log(res.data);

      })
      .catch((error) => {
        console.error(error);
      });
  };

  getVilles() {
    LotissementCRUD.villes()
      .then((res) => {
        this.setState({
          listelvilles: res.data,
        });
        console.log(res.data);

      })
      .catch((error) => {
        console.error(error);
      });
  };

  getInfosAdmin() {
    InformationAdministrativeFormCRUD.getAll()
      .then((res) => {
        this.setState({
          listeInfosAdmin: res.data,
        });
        console.log(res.data);

      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    const { listproprietaires } = this.state;
    const { listelottissement } = this.state;
    const { listelvilles } = this.state;

    const columns = [
      {
        name: "Code",
        selector: (row) => row.code_loti,
        sortable: true,
        reorder: true
      },
      // {
      //   name: "Date",
      //   selector: (row) => row.date_loti,
      //   sortable: true,
      //   reorder: true
      // },
      {
        name: "Lotissement",
        selector: (row) => row.libelle_loti,
        sortable: true,
        reorder: true
      },
      // {
      //     name: "Sup... Ha",
      //     selector: (row) => row.superficie_ha,
      //     sortable: true,
      //     reorder: true
      // },
      // {
      //     name: "Sup... Are",
      //     selector: (row) => row.superficie_are,
      //     sortable: true,
      //     reorder: true
      // },
      // {
      //     name: "Sup... Ca",
      //     selector: (row) => row.superficie_ca,
      //     sortable: true,
      //     reorder: true
      // },
      {
        name: "Propriétaire terrien",
        selector: (row) => row.nom_prop + ' ' + row.prenom_prop,
        sortable: true,
        reorder: true
      },
      {
        name: "Nbre d’ilots",
        selector: (row) => row.nombre_ilot,
        sortable: true,
        reorder: true

      },
      {
        name: "Nbre lots",
        selector: (row) => row.nombre_lot,
        sortable: true,
        reorder: true
      },
      // {
      //   name: "commune",
      //   selector: (row) => row.commune,
      //   sortable: true,
      //   reorder: true
      // },
      //  {
      //     name: "Numéro arrêté",
      //     selector: (row) => row.arrete_loti,
      //     sortable: true,
      //     reorder: true

      // },
      // {
      //     name: "Centre opérationnel domaniale",
      //     selector: (row) => row.centre_ope_loti,
      //     sortable: true,
      //     reorder: true

      // },
      {
        name: "Etat",
        cell: (row) => (
          <b style={{ color: "green" }}> {row.etat_loti} </b>
        ),
        sortable: true,
        reorder: true
      },
      {
        name: "Action",
        cell: (row) => (
          <div>
            <div className="d-flex">
              <button className="btn btn-info btn-xs me-2" data-bs-toggle="modal" data-bs-target={"#ViewModalCenter" + row.id_loti}><i className="fa fa-eye"></i></button>

              {/*<Link to={"/LotissementUpdateForm/"+row.code_loti}>*/}
              <button className="btn btn-warning btn-xs me-2" onClick={() => UpdateLotissement(row.code_loti)}><i className="fa fa-edit"></i></button>
              {/*</Link>*/}

              <button className="btn btn-danger btn-xs" onClick={() => deleteLotissement(row.code_loti)}><i className="fa fa-trash"></i></button>
            </div>

            <div className="modal fade" id={"ViewModalCenter" + row.id_loti}>
              <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">{row.code_loti} {row.libelle_loti}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal">
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12 col-lg-12">
                        <div className="basic-form">
                          <h4 className="mb-5 alert alert-primary" style={{ fontSize: '26px', textAlign: 'center' }}>LOTISSEMENT</h4>
                          <div className="row">
                            <div className="mb-4 col-md-2">
                              <label className="form-label">Date</label>
                              <h3><b>{row.date_loti}</b></h3>
                            </div>
                            <div className="mb-4 col-md-2">
                              <label className="form-label">Ville</label>
                              <h3><b>{row.ville_loti}</b></h3>
                            </div>
                            <div className="mb-4 col-md-2">
                              <label className="form-label">Commune </label>
                              <h3><b>{row.commune_loti}</b></h3>
                            </div>
                            <div className="mb-4 col-md-2">
                              <label className="form-label">Département </label>
                              <h3><b>{row.departement_loti}</b></h3>
                            </div>
                            <div className="mb-4 col-md-2">
                              <label className="form-label">Le Village </label>
                              <h3><b>{row.village_loti}</b></h3>
                            </div>
                            <div className="mb-4 col-md-2">
                              <label className="form-label">Libéllé</label>
                              <h3><b>{row.libelle_loti}</b></h3>
                            </div>
                            <div className="mb-4 col-md-2">
                              <label className="form-label">Superficie Ha</label>
                              <h3><b>{row.superficie_ha}</b></h3>
                            </div>
                            <div className="mb-4 col-md-2">
                              <label className="form-label">Superficie Are</label>
                              <h3><b>{row.superficie_are}</b></h3>
                            </div>
                            <div className="mb-4 col-md-2">
                              <label className="form-label">Superficie Ca</label>
                              <h3><b>{row.superficie_ca}</b></h3>
                            </div>
                            <div className="mb-5 col-md-4">
                              <label className="form-label">Propriétaire terrien</label>
                              <h3><b>{row.nom_prop} {row.prenom_prop}</b></h3>
                            </div>
                          </div>
                          <div className="row">
                            <div className="mb-5 col-md-3">
                              <label className="form-label">Promoteur</label>
                              <h3><b>{row.prometeur_loti}</b></h3>
                            </div>
                            <div className="mb-4 col-md-3">
                              <label className="form-label">Nbre d’ilots</label>
                              <h3><b>De {row.debut_ilot} à {row.fin_ilot}</b></h3>
                            </div>
                            <div className="mb-4 col-md-3">
                              <label className="form-label">Nbre lots</label>
                              <h3><b>De {row.debut_lot} à {row.fin_lot}</b></h3>
                            </div>
                            <div className="mb-4 col-md-3">
                              <label className="form-label">Numéro arrêté</label>
                              <h3><b>{row.arrete_loti}</b></h3>
                            </div>
                          </div>
                          <div className="row">
                            <div className="mb-4 col-md-3">
                              <label className="form-label">Centre opérationnel domaniale</label>
                              <h3><b>{row.centre_ope_loti}</b></h3>
                            </div>
                            <div className="mb-4 col-md-3">
                              <label className="form-label">Carte lotissement</label>
                              <p>
                                {(() => {
                                  if (row.carte) {
                                    return (
                                      <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/" + row.carte} target={'_blank'} rel="noreferrer">
                                        <i className="fa fa-download fa-2x"></i>
                                      </a>
                                    )

                                  } else {
                                    return (
                                      <b style={{ color: 'red' }}>Aucun</b>
                                    )
                                  }
                                })
                                  ()}
                              </p>
                            </div>
                          </div>

                          <hr />

                          <h4 className="mb-5 alert alert-primary" style={{ fontSize: '26px', textAlign: 'center' }}>Documents Lotissement</h4>

                          <div className="row">
                            <div className="mb-4 col-md-3">
                              <label className="form-label">PV de la réunion</label>
                              <p>
                                {(() => {
                                  if (row.pv_reunion) {
                                    return (
                                      <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/" + row.pv_reunion} target={'_blank'} rel="noreferrer">
                                        <i className="fa fa-download fa-2x"></i>
                                      </a>
                                    )

                                  } else {
                                    return (
                                      <b style={{ color: 'red' }}>Aucun</b>
                                    )
                                  }
                                })
                                  ()}
                              </p>
                            </div>
                            <div className="mb-4 col-md-3">
                              <label className="form-label">Attestations</label>
                              <p>
                                {(() => {
                                  if (row.attestation) {
                                    return (
                                      <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/" + row.attestation} target={'_blank'} rel="noreferrer">
                                        <i className="fa fa-download fa-2x"></i>
                                      </a>
                                    )

                                  } else {
                                    return (
                                      <b style={{ color: 'red' }}>Aucun</b>
                                    )
                                  }
                                })
                                  ()}
                              </p>
                            </div>
                            <div className="mb-4 col-md-3">
                              <label className="form-label">Certificat de propriété</label>
                              <p>
                                {(() => {
                                  if (row.certificat_prop) {
                                    return (
                                      <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/" + row.certificat_prop} target={'_blank'} rel="noreferrer">
                                        <i className="fa fa-download fa-2x"></i>
                                      </a>
                                    )

                                  } else {
                                    return (
                                      <b style={{ color: 'red' }}>Aucun</b>
                                    )
                                  }
                                })
                                  ()}
                              </p>
                            </div>
                            <div className="mb-4 col-md-3">
                              <label className="form-label">Conservation du lotissement</label>
                              <p>
                                {(() => {
                                  if (row.concervation_loti) {
                                    return (
                                      <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/" + row.concervation_loti} target={'_blank'} rel="noreferrer">
                                        <i className="fa fa-download fa-2x"></i>
                                      </a>
                                    )

                                  } else {
                                    return (
                                      <b style={{ color: 'red' }}>Aucun</b>
                                    )
                                  }
                                })
                                  ()}
                              </p>
                            </div>
                            <div className="mb-4 col-md-3">
                              <label className="form-label">Attestation decession</label>
                              <p>
                                {(() => {
                                  if (row.attestation_cession) {
                                    return (
                                      <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/" + row.attestation_cession} target={'_blank'} rel="noreferrer">
                                        <i className="fa fa-download fa-2x"></i>
                                      </a>
                                    )
                                  } else {
                                    return (
                                      <b style={{ color: 'red' }}>Aucun</b>
                                    )
                                  }
                                })
                                  ()}
                              </p>
                            </div>
                            <div className="mb-4 col-md-3">
                              <label className="form-label">Arrêté d’approbation</label>
                              <p>
                                {(() => {
                                  if (row.arrete_approb) {
                                    return (
                                      <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/" + row.arrete_approb} target={'_blank'} rel="noreferrer">
                                        <i className="fa fa-download fa-2x"></i>
                                      </a>
                                    )

                                  } else {
                                    return (
                                      <b style={{ color: 'red' }}>Aucun</b>
                                    )
                                  }
                                })
                                  ()}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="mb-4 col-md-4">
                              <label className="form-label">Situation du dossier</label>
                              <h3><b>{row.situation_dos}</b></h3>
                            </div>
                            <div className="mb-4 col-md-4">
                              <label className="form-label">Constitution du dossier </label>
                              <h3><b>{row.constitution_dos}</b></h3>
                            </div>
                          </div>

                          <hr />

                          <h4 className="mb-5 alert alert-primary" style={{ fontSize: '26px', textAlign: 'center' }}>Eta Lotissement</h4>

                          <div className="row" style={{ textAlign: 'center' }}>
                            <h1><b>{row.etat_loti}</b></h1>
                          </div>

                          <hr />

                          <h4 className="mb-5 alert alert-primary" style={{ fontSize: '26px', textAlign: 'center' }}>Information administrative et foncière</h4>

                          <div className="row">
                            <div className="mb-4 col-md-4">
                              <label className="form-label">Numéro arrêté</label>
                              <h3><b>{row.numero_arret}</b></h3>
                            </div>
                            <div className="mb-4 col-md-4">
                              <label className="form-label">Numéro titre foncier </label>
                              <h3><b>{row.numero_titre}</b></h3>
                            </div>
                            <div className="mb-4 col-md-4">
                              <label className="form-label">Affection</label>
                              <h3><b>{row.affectation_infos}</b></h3>
                            </div>
                            <div className="mb-4 col-md-4">
                              <label className="form-label">Habitation</label>
                              <h3><b>{row.habitation}</b></h3>
                            </div>
                            <div className="mb-4 col-md-4">
                              <label className="form-label">Centre d’opération nominale</label>
                              <h3><b>{row.centre_nominal}</b></h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger light" data-bs-dismiss="modal">Fermer</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
        width: "200px",                       // added line here
        headerStyle: (selector, id) => {
          return { textAlign: "center" };   // removed partial line here
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },

    ]

    /***** DELETE DATA *******/

    const deleteLotissement = (id) => {
      Swal.fire({
        title: 'Es-tu sûr?',
        html: "Vous ne pourrez pas revenir en arrière! <br> Les informations du lotissement, les documents et les informations administrative seront supprimés!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, supprime-le!'
      }).then((result) => {
        if (result.isConfirmed) {

          LotissementCRUD.remove(id)
            .then(response => {
              console.log(response.data);

              Swal.fire(
                'Supprimé!',
                'Votre fichier a été supprimé.',
                'success'
              )

              this.getLotissement();

            })
            .catch(e => {
              console.log(e);
            });

        }
      })
    };
    /***** END DELETE DATA *******/

    const UpdateLotissement = (id) => {
      window.location.replace('/LotissementUpdateForm/' + id);
    }

    var currr = new Date();
    currr.setDate(currr.getDate());
    var dateDay = currr.toISOString().substr(0, 10);
    return (
      <div className="">
        <Navbar />
        <Siderbar />

        <div className="content-body">
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <h1><b style={{ color: "red" }}>Lotissements</b></h1>
              </ol>
            </div>
            <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-hidden="true">
              <div className="modal-dialog modal-xl">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Enregistrement des informations</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal">
                    </button>
                  </div>
                  <form onSubmit={this.submitHandler} encType="multipart/form-data" id="LotiissementForm">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12 col-lg-12">
                          {/* {this.state.responseMsg.status === "successs" ? (
                                      <div className="alert alert-success">
                                        {this.state.responseMsg.message}
                                      </div>
                                    ) : this.state.responseMsg.status === "failed" ? (
                                      <div className="alert alert-danger">
                                        {this.state.responseMsg.message}
                                      </div>
                                    ) : (
                                      ""
                                    )}*/}
                          <div className="basic-form">
                            <h2 className="mb-5 alert alert-primary" style={{ fontSize: '26px', textAlign: 'center' }}>Lotissement</h2>
                            <div className="row">
                              <div className="mb-3 col-md-4">
                                <label className="form-label">Date</label>
                                <input type="date" readOnly name="date_loti" id="date_loti" defaultValue={dateDay} required onChange={this.onChangeDate} className="form-control" />
                              </div>
                              <div className="mb-3 col-md-4">
                                <label className="form-label">Ville</label>
                                <select className="form-control wide" onChange={this.onChangeVille} name="ville">
                                  <option value="">....</option>
                                  {listelvilles &&
                                    listelvilles.map((rows) => (
                                      <option key={rows.id_ville} value={rows.nom_ville}>{rows.nom_ville}</option>
                                    ))}
                                </select>
                              </div>
                              <div className="mb-3 col-md-4">
                                <label className="form-label">Département</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="Input"
                                  name="departement"
                                  value={this.state.departement}
                                  onChange={this.onChangeDepartement}
                                  placeholder="...." />
                              </div>
                              <div className="mb-3 col-md-4">
                                <label className="form-label">Commune</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="Input"
                                  name="commune"
                                  value={this.state.commune}
                                  onChange={this.onChangeCommune}
                                  placeholder="...." />
                              </div>
                              <div className="mb-3 col-md-4">
                                <label className="form-label">Village</label>
                                <input type="text" className="form-control" name="village" value={this.state.village} onChange={this.onChangeVillage} placeholder="...." />
                              </div>
                              <div className="mb-3 col-md-4">
                                <label className="form-label">Libéllé</label>
                                <input type="text" className="form-control" id="libelle" name="libelle" required value={this.state.libelle} onChange={this.onChangeLibelle} placeholder="Libéllé" />
                              </div>
                              <div className="mb-3 col-md-4">
                                <label className="form-label">Superficie (Ha)</label>
                                <input type="text" className="form-control" id="superficie_ha" name="superficie_ha" required value={this.state.superficie_ha} onChange={this.onChangeSuperficieHa} placeholder="Ha..." />
                              </div>
                              <div className="mb-3 col-md-4">
                                <label className="form-label">Superficie (Are)</label>
                                <input type="text" className="form-control" id="superficie_are" name="superficie_are" required value={this.state.superficie_are} onChange={this.onChangeSuperficieAre} placeholder="Are..." />
                              </div>
                              <div className="mb-3 col-md-4">
                                <label className="form-label">Superficie (CA)</label>
                                <input type="text" className="form-control" id="superficie_ca" name="superficie_ca" required value={this.state.superficie_ca} onChange={this.onChangeSuperficieCa} placeholder="Ca..." />
                              </div>
                              <div className="mb-5 col-md-4">
                                <label className="form-label">Propriétaire terrien</label>
                                <select className="form-control wide" name="proprietaire" value={this.state.proprietaire} required onChange={this.onChangeProprietaire} tabIndex="null">
                                  <option value="">Liste...</option>
                                  {listproprietaires &&
                                    listproprietaires.map((rows) => (
                                      <option key={rows.id_proprietaire} value={rows.id_proprietaire}>{rows.nom_prop} {rows.prenom_prop}</option>
                                    ))}
                                </select>
                              </div>
                              <div className="mb-5 col-md-4">
                                <label className="form-label">Promoteur</label>
                                <input type="text" className="form-control" readOnly name="prometeur" onChange={this.onChangePrometeur} required value={"Al Sanogo"} />
                              </div>
                            </div>
                            <div className="row mb-5">
                              <div className="mb-3 col-md-4">
                                <label className="form-label">Nbre d’ilots</label>
                                <div className="row">
                                  <div className="mb-3 col-md-6">
                                    <label className="form-label" style={{ color: 'black' }}>N° Debut</label>
                                    <input type="number" className="form-control" id="debut_ilot" name="debut_ilot" required value={this.state.debut_ilot} onChange={this.onChangeDebutIlot} placeholder="0" />
                                  </div>
                                  <div className="mb-3 col-md-6">
                                    <label className="form-label" style={{ color: 'black' }}>N° Fin</label>
                                    <input type="number" className="form-control" id="fin_ilot" name="fin_ilot" required value={this.state.fin_ilot} onChange={this.onChangeFinIlot} placeholder="0" />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3 col-md-4">
                                <label className="form-label">Nbre lots</label>
                                <div className="row">
                                  <div className="mb-3 col-md-6">
                                    <label className="form-label" style={{ color: 'black' }}>N° Debut</label>
                                    <input type="number" className="form-control" id="debut_lot" name="debut_lot" required value={this.state.debut_lot} onChange={this.onChangeDebutLot} placeholder="0" />
                                  </div>
                                  <div className="mb-3 col-md-6">
                                    <label className="form-label" style={{ color: 'black' }}>N° Fin</label>
                                    <input type="number" className="form-control" id="fin_lot" name="fin_lot" required value={this.state.fin_lot} onChange={this.onChangeFinLot} placeholder="0" />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3 col-md-4">
                                <label className="form-label">Numéro arrêté</label>
                                <input type="text" className="form-control mt-4" name="num_arrete" id="num_arrete"  value={this.state.num_arrete} onChange={this.onChangeNumArrete} placeholder="Numéro arrêté" />
                              </div>
                              <div className="mb-5 col-md-6">
                                <label className="form-label">Centre opérationnel domaniale</label>
                                <input type="text" className="form-control" name="centre_ope" id="centre_ope"  value={this.state.centre_ope} onChange={this.onChangeCentreOP} placeholder="Centre opérationnel..." />
                              </div>
                              <div className="mb-3 col-md-6">
                                <label className="form-label">Plan lotissement <code>PDF</code></label>
                                <input type="file" className="form-control" name="carte" onChange={this.handleCarteLotissement} />
                              </div>
                            </div>
                            <hr className="mb-4" style={{ color: 'red', height: '5px' }} />
                            <h2 className="mb-5 alert alert-primary" style={{ fontSize: '26px', textAlign: 'center' }}>Documents lotissement</h2>
                            <div className="row">
                              <div className="mb-3 col-md-6">
                                <label className="form-label">PV de la réunion <code>PDF</code></label>
                                <input type="file" className="form-control" name="pv" onChange={this.handlePvChange} />
                              </div>
                              <div className="mb-3 col-md-6">
                                <label className="form-label">Attestations <code>PDF</code></label>
                                <input type="file" className="form-control" name="attestation" onChange={this.handleAttestationChange} />
                              </div>
                            </div>
                            <div className="row">
                              <div className="mb-3 col-md-6">
                                <label className="form-label">Certificat de propriété <code>PDF</code></label>
                                <input type="file" className="form-control" name="certificat" onChange={this.handleCertificatChange} />
                              </div>
                              <div className="mb-3 col-md-6">
                                <label className="form-label">Conservation du lotissement <code>PDF</code></label>
                                <input type="file" className="form-control" name="conservation" onChange={this.handleConservationChange} />
                              </div>
                            </div>
                            <div className="row">
                              <div className="mb-3 col-md-6">
                                <label className="form-label">Attestation decession <code>PDF</code></label>
                                <input type="file" className="form-control" name="attestation_dec" onChange={this.handleAttestationDecessionChange} />
                              </div>
                              <div className="mb-3 col-md-6">
                                <label className="form-label">Arrêté d’approbation <code>PDF</code></label>
                                <input type="file" className="form-control" name="arrete_dap" onChange={this.handleArreteChange} />
                              </div>
                            </div>
                            <div className="row mb-4">
                              <div className="mb-3 col-md-6">
                                <label className="form-label">Situation du dossier</label>
                                <input type="text" className="form-control" name="situation_dossier" id="situation_dossierInput"  onChange={this.onChangeSituation} placeholder="Situation du dossier" />
                              </div>
                              <div className="mb-3 col-md-6">
                                <label className="form-label">Constitution du dossier</label>
                                <input type="text" className="form-control" name="constitution_dossier" id="constitution_dossierInput"  onChange={this.onChangeConstitution} placeholder="Constitution du dossier" />
                              </div>
                            </div>

                            <hr className="mb-4" style={{ color: 'red', height: '5px' }} />

                            <h2 className="mb-5 alert alert-primary" style={{ fontSize: '26px', textAlign: 'center' }}>Etat lotissement</h2>

                            <div className="row mb-5">
                              <div className="mb-3 col-md-4"></div>
                              <div className="mb-3 col-md-4">
                                <label className="form-label">Etat</label>
                                <select className="form-control wide" name="etat" required onChange={this.onChangeEtat} tabIndex="null">
                                  <option value="">Etat..</option>
                                  <option value="En projet">En projet</option>
                                  <option value="En cours">En cours</option>
                                </select>
                              </div>
                              <div className="mb-3 col-md-4"></div>
                            </div>

                            <hr className="mb-4" style={{ color: 'red', height: '5px' }} />

                            <h2 className="mb-5 alert alert-primary" style={{ fontSize: '26px', textAlign: 'center' }}>Information administrative et foncière</h2>

                            <div className="row mb-4">
                              <div className="mb-3 col-md-4">
                                <label className="form-label">Numéro arrêté</label>
                                <input type="text" className="form-control" id="numero_arrete" name="numero_arrete" onChange={this.onChangeNumeroArrete} placeholder="Numéro arrêté" />
                              </div>
                              <div className="mb-3 col-md-4">
                                <label className="form-label">Numéro du titre foncier </label>
                                <input type="text" className="form-control" id="numero_titre_foncier" name="numero_titre_foncier" onChange={this.onChangeNumeroFoncier} placeholder="Numéro du titre foncier " />
                              </div>
                              <div className="mb-3 col-md-4">
                                <label className="form-label">Affection</label>
                                <input type="text" className="form-control" id="affection" name="affection" onChange={this.onChangeAffection} placeholder="Affection" />
                              </div>
                            </div>
                            <div className="row mb-4">
                              <div className="mb-3 col-md-4">
                                <label className="form-label">Habitation</label>
                                <input type="text" className="form-control" id="habitation" name="habitation" onChange={this.onChangeHabitation} placeholder="Habitation" />
                              </div>
                              <div className="mb-3 col-md-6">
                                <label className="form-label">Centre d’opération nominale</label>
                                <input type="text" className="form-control" id="centre_operation" name="centre_operation" onChange={this.onChangeCentre} placeholder="Centre d’opération nominale" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger light" data-bs-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-primary">Enregistrer</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Datatable</h4>
                    <button type="button" className="btn btn-primary mb-2" data-bs-toggle="modal" data-bs-target=".bd-example-modal-lg">Enregistrement</button>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <DataTableExtensions
                        columns={columns}
                        data={listelottissement}
                        export={false}
                        print={false}
                      >
                        <DataTable
                          title="DataTable"
                          responsive
                          noHeader
                          defaultSortFieldId={1}
                          defaultSortField="id"
                          defaultSortAsc={false}
                          pagination
                          // selectableRows
                          persistTableHead
                          highlightOnHover
                          exportHeaders={false}
                          noDataComponents="Pas de donnée"
                          fixHeader
                        />
                      </DataTableExtensions>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}