import React from 'react';
// import { Link } from 'react-router-dom';
 // import logo from '../logo-al-sanogo.png';
import Navbar from '../components/Navbar';
import Siderbar from '../components/Siderbar';







const PaiementComp = props => {

  
 




const PrintListAnnexe = () =>{
   
                
           window.location.replace('/TablePaiementComp');
       }

const PrintListLotissement = () =>{
   
                
           window.location.replace('/TableEtatLotissement');
       }


 const LinkPrintAllVente = () => {
        window.location.replace('/TableVentes');
    }


     const LinkPrintAllPaid = () => {
        window.location.replace('/TablePaiement');
    }

	return (
        <>
        <div id="main-wrapper">
            <Navbar />
        <Siderbar />


                     {/* Content body start */}
                <div className="content-body">
                    {/* row */}
                    <div className="container-fluid">
                             <div className="page-titles">
                        <ol className="breadcrumb">
                            <h1><b style={{ color: "red" }}>Etat Global</b></h1>
                        </ol>
                    </div>













                                <div className="row">
                                    <div className="col-12">
                                       
                                    <div className="card">
                                <div className="card-header">

                                    <button 
                                    type="button" 
                                    className="btn btn-outline-info mb-2" 
                                    onClick={()=> LinkPrintAllVente()}>
                                    <i className="fa fa-print"></i> &nbsp;
                                    VENTES</button>

                                    <button 
                                    type="button" 
                                    className="btn btn-outline-success mb-2" 
                                    onClick={()=> LinkPrintAllPaid()}>
                                    <i className="fa fa-print"></i>&nbsp; 
                                    VERSEMENTS</button>
                  
                                    <button 
                                type="button" 
                                className="btn btn-outline-primary mb-2" 
                                onClick={() => PrintListLotissement()}>
                                <i className="fa fa-print"></i>&nbsp; LOTISSEMENTS
                                </button>

                                      <button 
                                type="button" 
                                className="btn btn-outline-warning mb-2" 
                                onClick={() => PrintListAnnexe()}>
                                <i className="fa fa-print"></i>&nbsp; PAIEMENT ANNEXE
                                </button>

                              
                                </div>
                               
                            </div>
                        
                                    </div>
                                 
                                </div>
                    </div>
        	  </div>
                {/* Content body end */}

        </div>
        </>

        );
}
export default PaiementComp;