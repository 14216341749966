import Navbar from '../components/Navbar';
import Siderbar from '../components/Siderbar';
import '../index.css';

import React, { useEffect, useState  } from 'react';
import { useParams} from 'react-router-dom';
import ProprietaireCRUD from '../services/ProprietaireCRUD';
import LotissementCRUD from '../services/LotissementCRUD';

import {Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const  LotissementUpdateForm = props => {

    const { id }= useParams()

const [listproprietaires, setListproprietaires] = useState([]);

useEffect(() => {
     getPropietaire();
}, []);

 const getPropietaire= () => {
      ProprietaireCRUD.getAll()
      .then((response) => {
        console.log(response.data);
        setListproprietaires(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };


const [dataLoti, setData] = useState({
            date_loti : "",   
            commune : "",
            village : "",
            ville : "",
            departement : "",
            libelle : "",
            superficie_ha: "",
            superficie_are: "",
            superficie_ca: "",
            unite : "",
            proprietaire : "",
            prometeur : "",
            debut_ilot : "",
            fin_ilot : "",
            debut_lot : "",
            fin_lot : "",
            num_arrete : "",
            centre_ope : "",
            etat : "",
            carte : "",
            situation_dossier :'',
            constitution_dossier :'',
            pv : '',
            attestation : '',
            certificat : '',
            conservation : '',
            attestation_dec : '',
            arrete_dap : '',


            numero_arrete :'',
            numero_titre_foncier :'',
            affection : '',
            habitation : '',
            centre_operation : '',
            operation: ''
});

useEffect(() => {
    if (id)
      getLoti(id);
      ListeVille();
  }, [id]);

const [villes, setVilles] = useState([])


function ListeVille(){

     LotissementCRUD.villes()
      .then((response) => {
         
            setVilles(response.data)
          
          console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
}


function getLoti(id){

        console.log(id);
     LotissementCRUD.get(id)
      .then(response => {
        console.log(response.data);
        setData(response.data[0]);
      })
      .catch(e => {
        console.log(e);
      });
}



function handle(e){
     const { name, value } = e.target;
    const newdata={...dataLoti, [name]: value }
    //newdata[e.target.id] = e.target.value
    setData(newdata)
     console.log(e)
    console.log(newdata)
}




const [selectedFileCarte , setSelectedFileCarte ] = React.useState([]);
const [selectedFilePv, setSelectedFilePv] = React.useState([]);
const [selectedFileAtt, setSelectedFileAtt] = React.useState([]);
const [selectedFileCert, setSelectedFileCert] = React.useState([]);
const [selectedFileCons, setSelectedFileCons] = React.useState([]);
const [selectedFileAttes, setSelectedFileAttes] = React.useState([]);
const [selectedFileArr, setSelectedFileArr] = React.useState([]);


const handleFileCarte = (event) => {
    setSelectedFileCarte(event.target.files[0])
    console.log(event.target.files[0])
  }

const handleFilePv = (event) => {
    setSelectedFilePv(event.target.files[0])
    console.log(event.target.files[0])
  }
const handleFileAtt = (event) => {
    setSelectedFileAtt(event.target.files[0])
    console.log(event.target.files[0])
  }
const handleFileCert = (event) => {
    setSelectedFileCert(event.target.files[0])
    console.log(event.target.files[0])
  }

const handleFileCons = (event) => {
    setSelectedFileCons(event.target.files[0])
    console.log(event.target.files[0])
  }
const handleFileAttes = (event) => {
    setSelectedFileAttes(event.target.files[0])
    console.log(event.target.files[0])
  }
const handleFileArr = (event) => {
    setSelectedFileArr(event.target.files[0])
    console.log(event.target.files[0])
  }



function updateLotissement(e, id){
   e.preventDefault();

    const formData = new FormData();
        formData.append('date_loti', dataLoti.date_loti)
        formData.append('commune', dataLoti.commune)
        formData.append('village', dataLoti.village)
        formData.append('ville', dataLoti.ville)
        formData.append('departement', dataLoti.departement)
        formData.append('libelle', dataLoti.libelle)

        formData.append('superficie_ha', dataLoti.superficie_ha)
        formData.append('superficie_ca', dataLoti.superficie_ca)
        formData.append('superficie_are', dataLoti.superficie_are)

        formData.append('unite', dataLoti.unite)
        formData.append('proprietaire', dataLoti.proprietaire)
        formData.append('prometeur', "Al Sanogo")
        formData.append('debut_ilot', dataLoti.debut_ilot)
        formData.append('fin_ilot', dataLoti.fin_ilot)

        formData.append('debut_lot', dataLoti.debut_lot)
        formData.append('fin_lot', dataLoti.fin_lot)
        formData.append('num_arrete', dataLoti.num_arrete)
        formData.append('centre_ope', dataLoti.centre_ope)

        formData.append('carte', dataLoti.carte)

        formData.append('etat', dataLoti.etat)


       formData.append("carte", selectedFileCarte);

       formData.append("pv", selectedFilePv);
       formData.append("attestation", selectedFileAtt);
       formData.append("certificat", selectedFileCert);
       formData.append("conservation", selectedFileCons);
       formData.append("attestation_dec", selectedFileAttes);
       formData.append("arrete_dap", selectedFileArr);
       formData.append('situation_dossier',dataLoti.situation_dossier)
       formData.append('constitution_dossier',dataLoti.constitution_dossier)


        formData.append("arrete", dataLoti.numero_arrete);
       formData.append("titre", dataLoti.numero_titre_foncier);
       formData.append("affectation", dataLoti.affection);
       formData.append("habitation", dataLoti.habitation);
       formData.append("centre", dataLoti.centre_operation);
       formData.append("operation", 'Lottissement');
       
    LotissementCRUD.update(id, formData)
      .then(response => {
        console.log(response.data);

           Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 5000
                    })
           setTimeout(() => {
            window.location.replace('/LotissementForm');

          }, 5000);
      })
      .catch(e => {
        console.log(e);
         Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 10000
                    })
      });
}


const LinkLotissement = () => {
        window.location.replace('/LotissementForm');
    }

    return (

<div className="">
        <Navbar />
        <Siderbar />

        <div className="content-body">
          <div className="container-fluid">

       

              <div className="page-titles">
                  <ol className="breadcrumb">
                    <h1>Modification des informations du <b style={{color:"red"}}>Lotissement</b></h1>
                  </ol>
                </div>


                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12 col-lg-12">
                        <div className="card">
                           {/* <div className="card-header">
                                <h4 className="card-title">Lotissement</h4>
                            </div>*/}
                            <div className="card-body">
                         

                                       
                                            
                                <form  onSubmit={(e)=>updateLotissement(e, dataLoti.code_loti)} encType="multipart/form-data" id="imageForm">
                                      
                                <div className="basic-form" >

                                <h4 className="mb-5 alert alert-info" style={{fontSize:'26px', textAlign:'center'}}>LOTISSEMENT</h4>


                                        <div className="row">
                                            <div className="mb-5 col-md-2">
                                                <label className="form-label">Date</label>
                                                <input 
                                                type="text" 
                                                className="form-control" 
                                                 required
                                                 readOnly
                                                defaultValue={dataLoti.date_loti}
                                                  onChange={(e)=>handle(e)}
                                                name="nom"
                                                placeholder="1234 Main St" 
                                                />
                                            </div>
                                            <div className="mb-5 col-md-2">
                                                <label className="form-label">Ville</label>
                                              <select className="form-control wide"  name="ville"   onChange={(e)=>handle(e)}>
                                               <option defaultValue={dataLoti.ville_loti}>{dataLoti.ville_loti}</option>
                                               {villes.map((rowss) => (
                                                <option key={rowss.id_ville} value={rowss.nom_ville}>{rowss.nom_ville}</option>
                                                  ))}
                                            </select>
                                        </div>

                                        <div className="mb-5 col-md-2">
                                                <label className="form-label">Département</label>
                                             
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                id="Input"  
                                                name="departement" 
                                                defaultValue={dataLoti.departement_loti}
                                               onChange={(e)=>handle(e)}
                                                placeholder="Département..." />

                                        </div>

                                            <div className="mb-5 col-md-2">
                                                <label className="form-label">Commune</label>
                                                
                                                
                                             <input 
                                                type="text" 
                                                className="form-control" 
                                                id="Input"  
                                                name="commune" 
                                               defaultValue={dataLoti.commune_loti}
                                                 onChange={(e)=>handle(e)} 
                                                placeholder="Commune..." />
                                            </div>

                                         
                                           
                                            <div className="mb-5 col-md-2">
                                                <label className="form-label">Village</label>
                                                <input type="text" className="form-control"  name="village" defaultValue={dataLoti.village_loti}   onChange={(e)=>handle(e)} placeholder="Village..." />


                                            </div>



                                           
                                       
                                       <div className="mb-3 col-md-2">
                                                <label className="form-label">Libéllé</label>
                                                <input type="text" className="form-control" name="libelle"  defaultValue={dataLoti.libelle_loti}   onChange={(e)=>handle(e)}   placeholder="Libéllé" />
                                            </div>
                                            <div className="mb-3 col-md-2">
                                                <label className="form-label">Superficie (Ha)</label>
                                                <input type="text" className="form-control" name="superficie_ha"   defaultValue={dataLoti.superficie_ha}   onChange={(e)=>handle(e)} placeholder="Superficie Ha" />
                                            </div>

                                            <div className="mb-3 col-md-2">
                                                <label className="form-label">Superficie (Are)</label>
                                                <input type="text" className="form-control" name="superficie_are"  defaultValue={dataLoti.superficie_are}   onChange={(e)=>handle(e)} placeholder="Superficie Are" />
                                            </div>

                                            <div className="mb-3 col-md-2">
                                                <label className="form-label">Superficie (CA)</label>
                                                <input type="text" className="form-control" name="superficie_ca"  defaultValue={dataLoti.superficie_ca}   onChange={(e)=>handle(e)}  placeholder="Superficie Ca" />
                                            </div>

                                           


                                            <div className="mb-5 col-md-3">
                                                <label className="form-label">Propriétaire terrien</label>
                                              <select className="form-control wide" name="proprietaire" tabIndex="null"   onChange={(e)=>handle(e)}>
                                                <option defaultValue={dataLoti.id_proprietaire}>{dataLoti.nom_prop} {dataLoti.prenom_prop}</option>
                                               {listproprietaires.map(rows =>
                                                <option key={rows.id_proprietaire} defaultValue={rows.id_proprietaire}>{rows.nom_prop} {rows.prenom_prop}</option>
                                                 )}
                                            </select>
                                        </div>

                                            <div className="mb-5 col-md-2">
                                                <label className="form-label">Promoteur</label>
                                                <input type="text" className="form-control" readOnly  name="prometeur" defaultValue={"Al Sanogo"}   onChange={(e)=>handle(e)} />
                                            </div>



                                           

                                     </div>


                                     <div className="row">
                                          <div className="mb-3 col-md-4">
                                                <label className="form-label">Nbre d’ilots</label>
                                                 <div className="row">
                                                <div className="mb-3 col-md-6">

                                                <code style={{color:'black'}}>N° Debut</code>
                                                    <input type="number" className="form-control" name="debut_ilot" defaultValue={dataLoti.debut_ilot} onChange={(e)=>handle(e)}  placeholder="1"/>
                                                </div>
                                                 <div className="mb-3 col-md-6">
                                                   <code style={{color:'black'}}>N° Fin</code>
                                                    <input type="number" className="form-control" name="fin_ilot" defaultValue={dataLoti.fin_ilot} onChange={(e)=>handle(e)} placeholder="100" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-3 col-md-4">
                                                <label className="form-label">Nbre lots</label>
                                                 <div className="row">
                                                <div className="mb-3 col-md-6">
                                                <code style={{color:'black'}}>N° Debut</code>
                                                    <input type="number" className="form-control" name="debut_lot" defaultValue={dataLoti.debut_lot} onChange={(e)=>handle(e)} placeholder="1" />
                                                </div>
                                                 <div className="mb-3 col-md-6">
                                                 <code style={{color:'black'}}>N° Fin</code>
                                                    <input type="number" className="form-control" name="fin_lot" defaultValue={dataLoti.fin_lot} onChange={(e)=>handle(e)} placeholder="100" />
                                                </div>
                                            </div>
                                        </div>

                                         <div className="mb-3 col-md-4">
                                                <label className="form-label">Numéro arrêté</label>
                                                <input type="text" className="form-control mt-4" name="num_arrete" defaultValue={dataLoti.arrete_loti} onChange={(e)=>handle(e)} placeholder="Numéro arrêté" />
                                            </div>

                                            <div className="mb-5 col-md-4">
                                                <label className="form-label">Centre opérationnel domaniale</label>
                                                <input type="text" className="form-control" name="centre_ope" defaultValue={dataLoti.centre_ope_loti} onChange={(e)=>handle(e)} placeholder="Centre opérationnel..." />
                                            </div>

                                             <div className="col-md-6">
                                              <div className="row">

                                                  <div className="mb-3 col-md-8">
                                                        <label className="form-label">Carte lotissement <code>PDF</code></label>
                                                        <input type="file" className="form-control"  name="carte" onChange={handleFileCarte} />
                                                    </div>
                                                 <div className="mb-3 mt-4 col-md-2">
                                                        {(() => {
                                                            if (dataLoti.carte) {
                                                              return (
                                                                <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/"+ dataLoti.carte} target={'_blank'} rel="noreferrer"> 
                                                                    <i className="fa fa-download fa-2x"></i>
                                                                </a>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                 <b style={{color:'red'}}>Aucun</b>
                                                              )
                                                            }
                                                          })
                                                        ()}
                                                </div>
                                            </div>
                                        </div>


                                             <div className="mb-3 col-md-3">
                                                        <label className="form-label" style={{color:'red'}}>Etat du Lotissement</label>
                                                      <select className="form-control wide" name="etat" onChange={(e)=>handle(e)} tabIndex="null">
                                                        <option defaultValue={dataLoti.etat_loti}>{dataLoti.etat_loti}</option>
                                                        <option defaultValue="En projet">En projet</option>
                                                        <option defaultValue="En cours">En cours</option>
                                                    </select>
                                                </div>
                                     </div>







                                      <hr />

                                         <h4 className="mb-5 alert alert-info" style={{fontSize:'26px', textAlign:'center'}}>Documents Lotissement</h4>

                                         <div className="row mb-4">

                                             <div className="col-md-6">
                                              <div className="row">

                                                  <div className="mb-3 col-md-8">
                                                        <label className="form-label">PV de la réunion <code>PDF</code></label>
                                                        <input type="file" className="form-control"  name="pv" onChange={handleFilePv} />
                                                    </div>
                                                 <div className="mb-3 mt-4 col-md-2">
                                                        {(() => {
                                                            if (dataLoti.pv_reunion) {
                                                              return (
                                                                <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/"+ dataLoti.pv_reunion} target={'_blank'} rel="noreferrer"> 
                                                                    <i className="fa fa-download fa-2x"></i>
                                                                </a>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                 <b style={{color:'red'}}>Aucun</b>
                                                              )
                                                            }
                                                          })
                                                        ()}
                                                </div>
                                            </div>
                                        </div>

                                          <div className="col-md-6">
                                            <div className="row">

                                                <div className="mb-3 col-md-8">
                                                        <label className="form-label">Attestations <code>PDF</code></label>
                                                        <input type="file" className="form-control" name="attestation"  onChange={handleFileAtt} />
                                                    </div>

                                                     <div className="mb-3 mt-4 col-md-2">
                                                             {(() => {
                                                            if (dataLoti.attestation) {
                                                              return (
                                                                <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/"+ dataLoti.attestation} target={'_blank'} rel="noreferrer"> 
                                                                        <i className="fa fa-download fa-2x"></i>
                                                                    </a>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                 <b style={{color:'red'}}>Aucun</b>
                                                              )
                                                            }
                                                          })
                                                        ()}
                                                    </div>
                                           </div>

                                        </div>






                                        </div>

                                         <div className="row mb-4">

                                         
                                         <div className="col-md-6">
                                             <div className="row">
                                                    <div className="mb-3 col-md-8">
                                                        <label className="form-label">Certificat de propriété <code>PDF</code></label>
                                                        <input type="file" className="form-control" name="certificat" onChange={handleFileCert} />
                                                    </div>
                                                    <div className="mb-3 mt-4 col-md-2">
                                                          {(() => {
                                                            if (dataLoti.certificat_prop) {
                                                              return (
                                                                <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/"+ dataLoti.certificat_prop} target={'_blank'} rel="noreferrer"> 
                                                                    <i className="fa fa-download fa-2x"></i>
                                                                </a>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                 <b style={{color:'red'}}>Aucun</b>
                                                              )
                                                            }
                                                          })
                                                        ()}
                                                </div>
                                            </div>
                                        </div>


                                         <div className="col-md-6">
                                          <div className="row">
                                            <div className="mb-3 col-md-8">
                                                <label className="form-label">Conservation du lotissement <code>PDF</code></label>
                                                <input type="file" className="form-control" name="conservation" onChange={handleFileCons} />
                                            </div>

                                             <div className="mb-3 mt-4 col-md-2">
                                                     {(() => {
                                                            if (dataLoti.concervation_loti) {
                                                              return (
                                                                 <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/"+ dataLoti.concervation_loti} target={'_blank'} rel="noreferrer"> 
                                                                <i className="fa fa-download fa-2x"></i>
                                                            </a>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                 <b style={{color:'red'}}>Aucun</b>
                                                              )
                                                            }
                                                          })
                                                        ()}
                                            </div>
                                            </div>
                                        </div>


                                            </div>

                                              <div className="row mb-4">

                                              <div className="col-md-6">
                                               <div className="row">
                                                <div className="mb-3 col-md-8">
                                                    <label className="form-label">Attestation decession <code>PDF</code></label>
                                                    <input type="file" className="form-control" name="attestation_dec" onChange={handleFileAttes} />
                                                </div>

                                                 <div className="mb-3 mt-4 col-md-2">
                                                         {(() => {
                                                            if (dataLoti.attestation_cession) {
                                                              return (
                                                                 <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/"+ dataLoti.attestation_cession} target={'_blank'} rel="noreferrer"> 
                                                                    <i className="fa fa-download fa-2x"></i>
                                                                </a>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                 <b style={{color:'red'}}>Aucun</b>
                                                              )
                                                            }
                                                          })
                                                        ()}
                                                </div>
                                                </div>

                                            </div>

                                         <div className="col-md-6">
                                          <div className="row">
                                            <div className="mb-3 col-md-8">
                                                <label className="form-label">Arrêté d’approbation <code>PDF</code></label>
                                                <input type="file" className="form-control" name="arrete_dap" onChange={handleFileArr} />
                                            </div>

                                            <div className="mb-3 mt-4 col-md-2">
                                                         {(() => {
                                                            if (dataLoti.arrete_approb) {
                                                              return (
                                                                  <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/"+ dataLoti.arrete_approb} target={'_blank'} rel="noreferrer"> 
                                                                    <i className="fa fa-download fa-2x"></i>
                                                                </a>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                 <b style={{color:'red'}}>Aucun</b>
                                                              )
                                                            }
                                                          })
                                                        ()}
                                                </div>
                                                </div>

                                        </div>

                                        </div>

                                          <div className="row mb-5">

                                         <div className="mb-3 col-md-6">
                                                <label className="form-label">Situation du dossier</label>
                                                <input type="text" className="form-control" name="situation_dossier" defaultValue={dataLoti.situation_dos} onChange={(e)=>handle(e)} placeholder="Situation du dossier" />
                                            </div>

                                            <div className="mb-3 col-md-6">
                                                <label className="form-label">Constitution du dossier</label>
                                                <input type="text" className="form-control" name="constitution_dossier" defaultValue={dataLoti.constitution_dos} onChange={(e)=>handle(e)} placeholder="Constitution du dossier" />
                                            </div>

                                        </div>











                                         <hr/>





                                   <h2 className="mb-5 alert alert-info" style={{fontSize:'26px', textAlign:'center'}}>Information administrative et foncière</h2>


                                         <div className="row">

                                         <div className="mb-3 col-md-4">
                                                <label className="form-label">Numéro arrêté</label>
                                                <input type="text" className="form-control" name="numero_arrete" id="numero_arrete" defaultValue={dataLoti.numero_arret} onChange={(e)=>handle(e)} placeholder="Situation du dossier" />
                                            </div>

                                            <div className="mb-3 col-md-4">
                                                <label className="form-label">Numéro du titre foncier</label>
                                                <input type="text" className="form-control" name="numero_titre_foncier" id="numero_titre_foncier" defaultValue={dataLoti.numero_titre} onChange={(e)=>handle(e)} placeholder="Constitution du dossier" />
                                            </div>

                                            <div className="mb-3 col-md-4">
                                                <label className="form-label">Affection</label>
                                                <input type="text" className="form-control" name="affection" id="affection" defaultValue={dataLoti.affectation_infos} onChange={(e)=>handle(e)} placeholder="Constitution du dossier" />
                                            </div>

                                             <div className="mb-3 col-md-4">
                                                <label className="form-label">Habitation</label>
                                                <input type="text" className="form-control" name="habitation" id="habitation" defaultValue={dataLoti.habitation} onChange={(e)=>handle(e)} placeholder="Situation du dossier" />
                                            </div>

                                            <div className="mb-3 col-md-4">
                                                <label className="form-label">Centre d’opération nominale</label>
                                                <input type="text" className="form-control" name="centre_operation" id="centre_operation" defaultValue={dataLoti.centre_nominal} onChange={(e)=>handle(e)} placeholder="Constitution du dossier" />
                                            </div>

                                        </div>




                                        
                                       <Link to={"/ProprietaireForm"} onClick={()=> LinkLotissement()}>
                                       <button type="submit" style={{float:'left'}}  className="btn btn-danger mt-5">Retour</button>
                                       </Link>
                                        <button type="submit"  className="btn btn-warning mt-5">Modifier</button>
                                       
                                

                                </div>


                                   </form>     
                                      
                                
                            </div>
                        </div>
                    </div>
                </div>

 










          </div>
        </div>
  </div>

    );

}




export default LotissementUpdateForm;
