import axios from "axios";
export default axios.create({
  // baseURL: "https://synfonny.com/api",
  baseURL: "https://alback.doubleclic-tech.com/api",
  headers: {
    "Content-type": "application/json",
    'Content-Type': 'multipart/form-data',
    'X-CSRF-TOKEN': "token.content"

  }
});