import Navbar from '../components/Navbar';
import Siderbar from '../components/Siderbar';
import '../index.css';

import React, { useEffect, useState  } from 'react';
import { useParams} from 'react-router-dom';
import ProprietaireCRUD from '../services/ProprietaireCRUD';

import {Link } from 'react-router-dom';

const  ViewProprietaireDetail = props => {

const { id }= useParams()
const [data, setData] = useState('');
const [dataLoti, setDataLoti] = useState('');

useEffect(() => {
    if (id)
      getProp(id);
      getPropLotissement(id);
  }, [id]);
    

function getProp(id){

     ProprietaireCRUD.get(id)
      .then(response => {
        console.log(response.data);
        setData(response.data[0]);
      })
      .catch(e => {
        console.log(e);
      });
}


function getPropLotissement(id){

     ProprietaireCRUD.getLotissement(id)
      .then(response => {
        console.log(response.data);
        if(response.data[0]){
        setDataLoti(response.data[0]);

        }else{
             setDataLoti('');
        }
      })
      .catch(e => {
        console.log(e);
      });
}

// const LinkProprietaire = () => {
//         window.location.replace('/ProprietaireForm');
//     }
    return (

<div className="">
        <Navbar />
        <Siderbar />

        <div className="content-body">
          <div className="container-fluid">

       

              <div className="page-titles">
                  <ol className="breadcrumb">
                    <h1><b style={{color:"red"}}>Information Propriétaire Térrien</b></h1>
                  </ol>
                </div>


                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="profile-tab">
                                    <div className="custom-tab-1">
                                        <ul className="nav nav-tabs">
                                           
                                            <li className="nav-item"><Link to={"#about-me"} data-bs-toggle="tab" className="nav-link active">Information</Link>
                                            </li>
                                            
                                        </ul>
                                        <div className="tab-content">
                                            
                                            <div id="about-me" className="tab-pane fade active show">
                                              
                                                <div className="col-lg-12">
                                                <div className="row">

                                                 <div className="col-lg-6">

                                                <div className="profile-personal-info mt-5">
                                                    <h4 className="text-primary mb-4">Renseignements personnels</h4>
                                                    <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Nom complet <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{data.nom_prop} {data.prenom_prop}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Sexe <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{data.sexe_prop}</span>
                                                        </div>
                                                    </div>
                                                     <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Email <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{data.email_prop}</span>
                                                        </div>
                                                    </div>
                                                     <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">N° téléphone <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{data.telephone_prop}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Le N° de la pièce d’identité ou l’attestation <span className="pull-end">:</span></h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{data.nume_piece_prop}</span>
                                                        </div>
                                                    </div>

                                                       <div className="row mb-4">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">la pièce scanée <code>PDF</code> <span className="pull-end">:</span></h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span> 
                                                        

                                                          {(() => {
                                                            if (data) {
                                                              return (
                                                                  <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/"+ data.piece_prop} target={'_blank'} rel="noreferrer"> 
                                                            <i className="fa fa-download fa-2x"></i>
                                                        </a>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                 <b style={{color:'red'}}>Aucun</b>
                                                              )
                                                            }
                                                          })
                                                        ()}

                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Ville <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{data.ville_prop}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">District <span className="pull-end">:</span></h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{data.nom_district}</span>
                                                        </div>
                                                    </div>
                                                     <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Département <span className="pull-end">:</span></h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{data.departement_prop}</span>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Commune <span className="pull-end">:</span></h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{data.commune_prop}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Village <span className="pull-end">:</span></h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{data.village_prop}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                </div>




                                                <div className="col-lg-6">

                                                <div className="profile-personal-info mt-5">
                                                    <h4 className="text-primary mb-4">Information du Lotissement</h4>
                                                    <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Code  <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{dataLoti.code_loti}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Date <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{dataLoti.date_loti}</span>
                                                        </div>
                                                    </div>
                                                     <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Libéllé <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{dataLoti.libelle_loti}</span>
                                                        </div>
                                                    </div>
                                                     <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Superficie Ha <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{dataLoti.superficie_ha}</span>
                                                        </div>
                                                    </div>
                                                     <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Superficie Are <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{dataLoti.superficie_are}</span>
                                                        </div>
                                                    </div>
                                                     <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Superficie Ca <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{dataLoti.superficie_ca}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Nombre d'ilots <span className="pull-end">:</span></h5>
                                                        </div>
                                                        <div className="col-md-6 col-7">
                                                        <span>{dataLoti.nombre_ilot} &nbsp;&nbsp;&nbsp;<b>De {dataLoti.debut_ilot} à {dataLoti.fin_ilot}</b></span>
                                                         
                                                        </div>
                                                    </div>

                                                     <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Nombre de lots <span className="pull-end">:</span></h5>
                                                        </div>
                                                        <div className="col-md-6 col-7">
                                                        <span>{dataLoti.nombre_lot} &nbsp;&nbsp;&nbsp;<b>De {dataLoti.debut_lot} à {dataLoti.fin_lot}</b></span>
                                                         
                                                        </div>
                                                    </div>

                                                       <div className="row mb-4">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Numéro arrêté <span className="pull-end">:</span></h5>
                                                        </div>
                                                        <div className="col-md-6 col-7">{dataLoti.arrete_loti}<span> 
                                                       
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Centre opérationnel domaniale <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{dataLoti.centre_ope_loti}</span>
                                                        </div>
                                                    </div>
                                                   
                                                     <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Etat <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span> <b style={{color:"green"}}> {dataLoti.etat_loti} </b></span>
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                                </div>





                                                 <div className="col-lg-6">

                                                <div className="profile-personal-info mt-5">
                                                    <h4 className="text-primary mb-4">Documment du Lotissement</h4>
                                                    <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">PV de la réunion <code>PDF</code>  <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>
                                                            

                                                         {(() => {
                                                            if (dataLoti.pv_reunion) {
                                                              return (
                                                                <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/"+ dataLoti.pv_reunion} target={'_blank'} rel="noreferrer"> 
                                                                    <i className="fa fa-download fa-2x"></i>
                                                                </a>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                 <b style={{color:'red'}}>Aucun</b>
                                                              )
                                                            }
                                                          })
                                                        ()}

                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Attestations <code>PDF</code> <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>
                                                        {(() => {
                                                            if (dataLoti.attestation) {
                                                              return (
                                                                <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/"+ dataLoti.attestation} target={'_blank'} rel="noreferrer"> 
                                                                        <i className="fa fa-download fa-2x"></i>
                                                                    </a>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                 <b style={{color:'red'}}>Aucun</b>
                                                              )
                                                            }
                                                          })
                                                        ()}
                                                      
                                                        </span>
                                                        </div>
                                                    </div>
                                                     <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Certificat de propriété <code>PDF</code> <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>
                                                           

                                                        {(() => {
                                                            if (dataLoti.certificat_prop) {
                                                              return (
                                                                <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/"+ dataLoti.certificat_prop} target={'_blank'} rel="noreferrer"> 
                                                                    <i className="fa fa-download fa-2x"></i>
                                                                </a>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                 <b style={{color:'red'}}>Aucun</b>
                                                              )
                                                            }
                                                          })
                                                        ()}
                                                        </span>
                                                        </div>
                                                    </div>
                                                     <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Conservation du lotissement <code>PDF</code> <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>
                                                           

                                                        {(() => {
                                                            if (dataLoti.concervation_loti) {
                                                              return (
                                                                 <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/"+ dataLoti.concervation_loti} target={'_blank'} rel="noreferrer"> 
                                                                <i className="fa fa-download fa-2x"></i>
                                                            </a>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                 <b style={{color:'red'}}>Aucun</b>
                                                              )
                                                            }
                                                          })
                                                        ()}

                                                        </span>
                                                        </div>
                                                    </div>
                                                     <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Attestation decession <code>PDF</code><span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>
                                                            

                                                            {(() => {
                                                            if (dataLoti.attestation_cession) {
                                                              return (
                                                                 <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/"+ dataLoti.attestation_cession} target={'_blank'} rel="noreferrer"> 
                                                                    <i className="fa fa-download fa-2x"></i>
                                                                </a>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                 <b style={{color:'red'}}>Aucun</b>
                                                              )
                                                            }
                                                          })
                                                        ()}
                                                        

                                                        </span>
                                                        </div>
                                                    </div>
                                                     <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Arrêté d’approbation <code>PDF</code><span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span> 

                                                       

                                                         {(() => {
                                                            if (dataLoti.arrete_approb) {
                                                              return (
                                                                  <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/"+ dataLoti.arrete_approb} target={'_blank'} rel="noreferrer"> 
                                                                    <i className="fa fa-download fa-2x"></i>
                                                                </a>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                 <b style={{color:'red'}}>Aucun</b>
                                                              )
                                                            }
                                                          })
                                                        ()}
                                                                </span>

                                                        </div>
                                                    </div>
                                                   

                                                       <div className="row mb-4">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Situation du dossier <span className="pull-end">:</span></h5>
                                                        </div>
                                                        <div className="col-md-6 col-7">
                                                        <span> 
                                                            {dataLoti.situation_dos}
                                                         </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Constitution du dossier<span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{dataLoti.constitution_dos}</span>
                                                        </div>
                                                    </div>
                                                   
                                                    
                                                </div>

                                                </div>





                                                <div className="col-lg-6">

                                                <div className="profile-personal-info mt-5">
                                                    <h4 className="text-primary mb-4">Information administrative et foncière</h4>
                                                    <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Numéro arrêté  <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{dataLoti.numero_arret}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Numéro du titre foncier <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{dataLoti.numero_titre}</span>
                                                        </div>
                                                    </div>
                                                     <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Affection <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{dataLoti.affectation_infos}</span>
                                                        </div>
                                                    </div>
                                                     <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Habitation <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{dataLoti.habitation}</span>
                                                        </div>
                                                    </div>
                                                     <div className="row mb-2">
                                                        <div className="col-md-6 col-5">
                                                            <h5 className="f-w-500">Centre d’opération nominale <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-md-6 col-7"><span>{dataLoti.centre_nominal}</span>
                                                        </div>
                                                    </div>
                                                    

                                                   
                                                    
                                                </div>
                                                </div>



                                                </div>
                                                </div>
                                            </div>
                                            
                                           {/* <Link to={"/ProprietaireForm"} onClick={()=> LinkProprietaire()}><button type="submit" style={{float:'left'}}  className="btn btn-danger mt-5">Retour</button></Link>*/}
                                            <Link to={"/ProprietaireForm"} ><button type="submit" style={{float:'left'}}  className="btn btn-danger mt-5">Retour</button></Link>
                                            <Link to={"/ProprietaireFormUpdate/"+data.id_proprietaire} ><button type="submit" style={{float:'right'}}  className="btn btn-primary mt-5">Modifier Infos Propriétaire</button></Link>

                                        </div>
                                    </div>
                                   
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

 










          </div>
        </div>
  </div>

    );

}




export default ViewProprietaireDetail;
