
import axios from '../axios/axios';

const getAll = () => {
  return axios.get("/parametrages");
};
const get = id => {
  return axios.get(`/parametrages/${id}`);
};


const create = data => {
  return axios.post("/parametrages", data);
};
const update = (id, data) => {
  return axios.put(`/parametrages/${id}`, data);
};


const remove = id => {
  return axios.delete(`/parametrages/${id}`);
};
const removeAll = () => {
  return axios.delete(`/parametrages`);
};
const findByTitle = title => {
  return axios.get(`/parametrages?title=${title}`);
};

const Autorisation = code => {
  return axios.get(`/autorisation_users/${code}`);
};


const ParametresCRUD = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
  Autorisation,
};
export default ParametresCRUD;