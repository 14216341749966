import axios from '../axios/axios';

const getAll = () => {
  return axios.get("/documents");
};
const get = id => {
  return axios.get(`/documents/${id}`);
};
const create = data => {
  return axios.post("/documents", data);
};
const update = (id, data) => {
  return axios.post(`/documents/${id}`, data);
};
const remove = id => {
  return axios.delete(`/documents/${id}`);
};
const removeAll = () => {
  return axios.delete(`/documents`);
};


const createEcheancier = data => {
  return axios.post("/documents_echeancier", data);
};


const getAllEcheancier = () => {
  return axios.get("/documents_echeancier");
};


const removeEcheancier = id => {
  return axios.get(`/del_documents_echeancier/${id}`);
};


const getEcheancier = id => {
  return axios.get(`/documents_echeancier/${id}`);
};




const createLogement = data => {
  return axios.post("/documents_logement", data);
};


const getAllLogement = () => {
  return axios.get("/documents_logement");
};


const getLogement = id => {
  return axios.get(`/documents_logement/${id}`);
};



const removeLogement = id => {
  return axios.get(`/del_documents_logement/${id}`);
};


const DocumentPrintCRUD = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  createEcheancier,
  getAllEcheancier,
  removeEcheancier,
  getEcheancier,
  createLogement,
  getAllLogement,
  getLogement,
  removeLogement,
};
export default DocumentPrintCRUD;