import Navbar from '../../components/Navbar';
import Siderbar from '../../components/Siderbar';
import './css/print.css';
import logo from '../../logo-al-sanogo.png';

import React, { useEffect, useState  } from 'react';
import { useParams} from 'react-router-dom';

import DocumentPrintCRUD from '../../services/DocumentPrintCRUD';
import Style from '../../style';


   const DetailDocs2 = props => {


const {

table,


} = Style()


    const { id }= useParams()

    useEffect(() => {
        if(id)
        Getreservation(id)
    }, [id]);

    const [detail, setDetail] = useState([]);

    const Getreservation = (id) => {

        DocumentPrintCRUD.getLogement(id)
            .then((res) => {

                setDetail(res.data);


                console.log(res.data);

            })
            .catch((error) => {
                console.error(error);
            });

    }


const Print = () => {
    window.print()
}


const LinkReservationLogement = () => {
        window.location.replace('/ListReservationLogement');
    }
    
    return (
        <>
            <div id="main-wrapper">
                <Navbar />
                <Siderbar />


                {/* Content body start */}
                <div className="content-body">
                    <div className="container-fluid">

                        <div className="col-lg-12" id="dlogo" >
                          <img className="" style={{display:'none'}} id="logo" src={logo} alt="" />
                     </div>

                     <div className="col-lg-12" id="dtitle" >
                      <div className="row">
                      <div className="col-lg-3"></div>

                      <div className="col-lg-6" id="Rtitle" style={{border:'2px solid #000', textAlign:'center', marginTop:'2%'}}>
                          <h3 style={{marginTop:'1%', fontWeight:'bold'}} id="titlename">FICHE DE RESERVATION DE LOGEMENT(S)</h3>
                      </div>
                          
                      <div className="col-lg-3"></div>
                     </div>
                     </div>

                          {detail.map((value) => (
                            <div key={value.id_rst}>
                      <div className="col-lg-12">
                      <div className="row">
                      <div className="col-lg-6">
                        </div>
                        <div className="col-lg-6" style={{textAlign:'right', marginTop:'1%'}} >
                            <h4 style={{fontWeight:'bold'}} >CODE Clt: {value.code_reserve}</h4>&nbsp;
                        </div>
                        </div>
                        </div>

                 

                         <div className="col-lg-12" >

                             <div id="fiche1" >

                         <div className="col-lg-12">
                            <table style={table}>
                                <thead>
                                <tr>
                                    <th colSpan="4" id="titreS1" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc'}} >
                                        <h3>ESPACE RESERVE AU(X) PARTICULIER(S)</h3>
                                    </th>
                                </tr>
                                <tr>
                                    <th colSpan="4" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc'}} >
                                        <h4 style={{textAlign:'left',fontWeight:'bold'}} >&nbsp;&nbsp;Nous soussignés,</h4>
                                    </th>
                                </tr>
                                <tr>

                                    <th colSpan="2" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', borderRight:'1px solid #cccc'}} >
                                        <h4 style={{color:'#05f', textAlign:'left', fontWeight:'bold'}} >&nbsp;&nbsp;Souscripteur</h4>
                                    </th>
                                    <th colSpan="2" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc'}} >
                                        <h4 style={{color:'red', fontWeight:'bold', textAlign:'left'}}>&nbsp;&nbsp;Co-souscripteur</h4>
                                    </th>
                                </tr>

                                </thead>
                                <tbody>
                                    <tr>
                                       <td colSpan="2" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', borderRight:'1px solid #cccc', textAlign:'left'}}>
                                       <h4>&nbsp;&nbsp;Nom: &nbsp;

                                      
                                    <input 
                                       type="text" 
                                       name="sousc_nom" 
                                       id="input1" 
                                       defaultValue={value.souscripteur_nom} 
                                       readOnly
                                       className="" 
                                       style={{border: 'none', fontWeight:'bold'}}
                                       placeholder="........." />
                              

                                       

                                       </h4>
                                       </td> 
                                       <td colSpan="2" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  textAlign:'left'}}>
                                       <h4>&nbsp;&nbsp;Nom:&nbsp;
                                        <input 
                                        type="text" 
                                        name="co_sousc_nom" 
                                        id="input1_2" 
                                       defaultValue={value.co_souscripteur_nom} 
                                        readOnly
                                        className="" 
                                        style={{border: 'none', fontWeight:'bold'}} 
                                        placeholder="........." />

                                       </h4>
                                       </td> 
                                    </tr>

                                    <tr>
                                       <td colSpan="2" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', borderRight:'1px solid #cccc', textAlign:'left'}}>
                                       <h4>&nbsp;&nbsp;Prénom(s):&nbsp;
                                         

                                         
                                    <input 
                                       type="text" 
                                         name="sousc_prenom" 
                                         id="input2"  
                                       defaultValue={value.souscripteur_prenom} 
                                       readOnly
                                       className="" 
                                       style={{border: 'none', fontWeight:'bold', width:'78%'}} 
                                         placeholder="........." />
                             

                                       </h4>
                                       </td> 
                                       <td colSpan="2" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', textAlign:'left'}}>
                                       <h4>&nbsp;&nbsp;Prénom(s):&nbsp;
                                         <input 
                                         type="text" 
                                         name="co_sousc_prenom" 
                                         id="input2_2" 
                                       defaultValue={value.co_souscripteur_prenom} 
                                         readOnly
                                         className="" 
                                         style={{border: 'none', fontWeight:'bold', width:'78%'}} 
                                         placeholder="........." />

                                       </h4>
                                       </td> 
                                    </tr>

                                    <tr>
                                       <td id="td_adresse" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', textAlign:'left'}}>
                                       <h4>&nbsp;&nbsp;Adresse:
                                       
                             <input 
                             type="text" 
                             name="sousc_adresse" 
                             id="input3" 
                            defaultValue={value.souscripteur_adresse} 
                             readOnly
                             className="" 
                             style={{width: '65%', border: 'none', fontWeight:'bold'}} 
                             placeholder="........." />
                            
                                                             
                                       </h4>
                                       </td> 
                                       <td id="td_ville" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', borderRight:'1px solid #cccc', textAlign:'left'}}>
                                       <h4 id="sc_ville">&nbsp;&nbsp;Ville:
                                     <input 
                                     type="text" 
                                     name="sousc_ville" 
                                     id="input3_1" 
                                     defaultValue={value.souscripteur_ville} 
                                     readOnly
                                     className="" style={{width: '70%', border: 'none', fontWeight:'bold'}} 
                                     placeholder="........." />

                                       </h4>
                                       </td> 

                                        <td id="td_adresse" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', textAlign:'left'}}>
                                       <h4>&nbsp;&nbsp;Adresse:
                                         <input 
                                         type="text" 
                                         name="co_sousc_adresse" 
                                         id="input3_2" 
                                         defaultValue={value.co_souscripteur_adresse} 
                                         readOnly
                                         className="" 
                                         style={{width: '65%', border: 'none', fontWeight:'bold'}} 
                                         placeholder="........." />

                                       </h4>
                                       </td> 
                                       <td id="td_ville" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', textAlign:'left'}}>
                                       <h4 id="co_ville">&nbsp;&nbsp;Ville:

                                         <input 
                                         type="text" 
                                         name="co_sousc_ville" 
                                         id="input3_2_3" 
                                         defaultValue={value.co_souscripteur_ville} 
                                         readOnly
                                         className="" style={{width: '65%', border: 'none', fontWeight:'bold'}} 
                                         placeholder="........." />

                                       </h4>
                                       </td> 

                                    </tr>


                                     <tr>
                                       <td colSpan="2" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', borderRight:'1px solid #cccc', textAlign:'left'}}>
                                       <h4>&nbsp;&nbsp;Cel 1:&nbsp;

                                    
                                               <input 
                                               type="text" 
                                               name="sousc_cel1" 
                                               id="input3_3" 
                                              defaultValue={value.souscripteur_cel1} 
                                               readOnly
                                               className="" 
                                               style={{border: 'none', fontWeight:'bold'}} 
                                               placeholder="........." />
                                                    

                                       </h4>
                                       </td> 
                                       <td colSpan="2" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', textAlign:'left'}}>
                                       <h4>&nbsp;&nbsp;Cel 1:&nbsp;

                                        <input 
                                        type="text" 
                                        name="co_sousc_cel1" 
                                        id="input3_4" 
                                         defaultValue={value.co_souscripteur_cel1} 
                                        readOnly
                                        className="" 
                                        style={{border: 'none', fontWeight:'bold'}} 
                                        placeholder="........." />

                                       </h4>
                                       </td> 
                                    </tr>


                                     <tr>
                                       <td colSpan="2" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', borderRight:'1px solid #cccc', textAlign:'left'}}>
                                       <h4>&nbsp;&nbsp;Cel 2:&nbsp;
                             <input 
                             type="text" 
                             name="sousc_cel2" 
                             id="input4" 
                             defaultValue={value.souscripteur_cel2} 
                             readOnly
                             className="" 
                             style={{border: 'none', fontWeight:'bold'}} 
                             placeholder="........." />

                                       </h4>
                                       </td> 
                                       <td colSpan="2" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc', textAlign:'left'}}>
                                       <h4>&nbsp;&nbsp;Cel 2:&nbsp;
                             <input 
                             type="text" 
                             name="co_sousc_cel2" 
                             id="input4_2" 
                             defaultValue={value.co_souscripteur_cel2} 
                             readOnly
                             className="" 
                             style={{border: 'none', fontWeight:'bold'}} 
                             placeholder="........." />

                                       </h4>
                                       </td> 
                                    </tr>

                                </tbody>
                            </table>
                        </div>



                        <div className="col-lg-12">
                            <table style={table}>
                                <thead>
                                <tr>
                                    <th colSpan="4" id="titreS2" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  borderRight:'1px solid #cccc', borderLeft:'1px solid #cccc'}} >
                                        <h3>ESPACESPACE RESERVE AUX SOCIETES</h3>
                                    </th>
                                </tr>
                               

                                

                                </thead>
                                <tbody>
                                <tr>
                                    <th colSpan="4" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  borderRight:'1px solid #cccc', borderLeft:'1px solid #cccc'}} >
                                        <h4 style={{textAlign:'left',fontWeight:'bold'}} >&nbsp;&nbsp;Nous soussignés,</h4>
                                    </th>
                                    
                                </tr>
                                <tr>
                                   
                                    <td colSpan="4" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  borderRight:'1px solid #cccc', borderLeft:'1px solid #cccc'}} >
                                    <input 
                                    type="text" 
                                    name="societe_titre" 
                                    id="input8" 
                                    defaultValue={value.soussigne} 
                                    readOnly
                                    className="" 
                                    style={{border: 'none', fontWeight:'bold', width:'100%', marginLeft:'5px', width:'99%'}} 
                                    placeholder="........." />

                                    </td>
                                </tr>


                                 <tr>
                                    <th colSpan="4" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  borderRight:'1px solid #cccc', borderLeft:'1px solid #cccc'}} >
                                        <h4 style={{textAlign:'left',fontWeight:'bold'}} >&nbsp;&nbsp;Forme juridique</h4>
                                    </th>
                                    
                                </tr>
                                <tr>
                                   
                                    <td colSpan="4" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  borderRight:'1px solid #cccc', borderLeft:'1px solid #cccc'}} >
                                     <input 
                                     type="text" 
                                     name="societe_form" 
                                     id="input9" 
                                     defaultValue={value.form_juridique} 
                                     readOnly
                                     className="" 
                                     style={{border: 'none', fontWeight:'bold', width:'100%', marginLeft:'5px', width:'99%'}} 
                                     placeholder="........." />

                                    </td>
                                </tr>


                                 <tr>
                                    <th colSpan="4" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  borderRight:'1px solid #cccc', borderLeft:'1px solid #cccc'}} >
                                        <h4 style={{textAlign:'left',fontWeight:'bold'}} >&nbsp;&nbsp;Représentant légal de la personne morale</h4>
                                    </th>
                                    
                                </tr>
                                <tr>
                                   
                                    <td colSpan="4" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  borderRight:'1px solid #cccc', borderLeft:'1px solid #cccc'}} >
                                        <input 
                                        type="text" 
                                        name="societe_legal" 
                                        id="input10" 
                                        defaultValue={value.representant_legal} 
                                        readOnly
                                        className="" 
                                        style={{border: 'none', fontWeight:'bold', width:'100%', marginLeft:'5px', width:'99%'}} 
                                        placeholder="........." />

                                    </td>
                                </tr>


                                <tr>
                                    <th colSpan="4" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  borderRight:'1px solid #cccc', borderLeft:'1px solid #cccc'}} >
                                        <h4 style={{textAlign:'left',fontWeight:'bold'}} >&nbsp;&nbsp;Nom</h4>
                                    </th>
                                    
                                </tr>
                                <tr>
                                   
                                    <td colSpan="4" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  borderRight:'1px solid #cccc', borderLeft:'1px solid #cccc'}} >
                                         <input 
                                           type="text" 
                                           name="societe_nom" 
                                           id="input11" 
                                           defaultValue={value.nom_representant} 
                                           readOnly
                                           className="" 
                                           style={{border: 'none', fontWeight:'bold', width:'100%', marginLeft:'5px', width:'99%'}} placeholder="........." />

                                    </td>
                                </tr>



                                 <tr>
                                    <th colSpan="4" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  borderRight:'1px solid #cccc', borderLeft:'1px solid #cccc'}} >
                                        <h4 style={{position:'absolute', textAlign:'left',fontWeight:'bold'}} >&nbsp;&nbsp;Agissant en qualité de:</h4>
                                        <h4 style={{float:'right', textAlign:'right',fontWeight:'bold'}} >dûment habilté(e)s. &nbsp;&nbsp;</h4>
                                    </th>
                                    
                                </tr>
                                <tr>
                                   
                                    <td colSpan="4" style={{borderTop:'1px solid #cccc', borderBottom:'1px solid #cccc',  borderRight:'1px solid #cccc', borderLeft:'1px solid #cccc'}} >
                                         <input 
                                         type="text" 
                                         name="societe_qualite" 
                                         id="input13" 
                                         defaultValue={value.agissant_en_qualite} 
                                         readOnly
                                         className="" 
                                         style={{border: 'none', fontWeight:'bold', width:'100%', marginLeft:'5px', width:'99%'}} placeholder="........." />

                                    </td>
                                </tr>


                                </tbody>
                            </table>
                        </div>


                        <div className="col-lg-12" style={{marginTop:'2%', textAlign:'center',}} >
                            <h3 id="textfoot" style={{color:'#000'}} >
                                Agissant en qualité de <b style={{fontSize:'18px'}}>SOUSCRIPTEUR</b> au programme d’acquisition de parcelles initié par la Société AL SANOGO,
                                Nous engageons de manière ferme et irrévocable, de nous porter acquéreur d’une parcelle de superficie:
                            </h3>
                        </div>

                        <div className="col-lg-12" style={{marginTop:'2%'}} >


                            <div className="row">
                            <div className="col-md-3" id="dvilla">
                            <h4><label style={{fontSize:'', color:'#000'}} id="villa">VILLA BASSE</label>&nbsp;


                                          {(() => {
                                 if (value.villa_basse !== null) {
                                                              return (
                                                                <>
                                    <input 
                                    type="checkbox" 
                                    name="checkbox1" 
                                    defaultvalue={'VILLA BASSE'} 
                                    checked 
                                    id="check_villa" 
                                    style={{ width:'75px', height:'20px',   fontWeight:'bold'}} 
                                    className="" />

                                    <p><b>Nbre de pèce :</b> 
                                    &nbsp; &nbsp;<b>{value.nbr_piece_villa_basse}</b>
                                    
                                    </p>

                               </>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                <>
                                    <input 
                                    type="checkbox" 
                                    name="checkbox1" 
                                    defaultvalue={'VILLA BASSE'} 
                                    disabled 
                                    id="check_villa" 
                                    style={{ width:'75px', height:'20px',   fontWeight:'bold'}} 
                                    className="" />

                                    <p><b>Nbre de pèce: </b> 
                                    
                                    </p>

                                                                </>
                                                              )
                                                            }
                                                          })
                                                        ()}

                            </h4>
                            </div>
                            <div className="col-md-3" id="dDupl">
                                 <h4>
                             &nbsp;<label style={{fontSize:'', color:'#000'}} id="dupl">DUPLEX</label>
                                 
                                 {(() => {
                                 if (value.duplex !== null) {
                                                              return (
                                                                <>
                                    <input 
                                    type="checkbox" 
                                    name="checkbox2" 
                                    defaultValue={'DUPLEX'} 
                                    checked 
                                    id="" 
                                    style={{ width:'32px', height:'20px',   fontWeight:'bold'}} 
                                    className="" />

                                    <p>
                                    &nbsp; &nbsp;<b>{value.nbr_piece_villa_basse}</b>
                                    </p>

                               </>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                <>
                                     <input 
                                     type="checkbox" 
                                    name="checkbox2" 
                                    defaultValue={'DUPLEX'} 
                                     disabled
                                    id="checkbox300" 
                                    style={{ width:'32px', height:'20px',   fontWeight:'bold'}} 
                                    className="" />
                                    

                                                                </>
                                                              )
                                                            }
                                                          })
                                                        ()}


                                
                            </h4>
                            </div>
                            <div className="col-md-3" id="dAppart">
                                <h4>
                                 &nbsp;<label style={{fontSize:'', color:'#000'}} id="appart">APPARTEMENT</label>
                                {(() => {
                                 if (value.appartement !== null) {
                                                              return (
                                                                <>
                                    <input 
                                    type="checkbox" 
                                    name="checkbox3" 
                                    defaultValue={'APPARTEMENT'} 
                                    checked 
                                    
                                    id="" 
                                    style={{ width:'32px', height:'20px',   fontWeight:'bold'}} 
                                    className="" />
                                    <p>
                                    &nbsp; &nbsp;<b>{value.nbr_piece_villa_basse}</b>
                                    </p>

                               </>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                <>
                                     <input 
                                     type="checkbox" 
                                    name="checkbox3" 
                                    defaultValue={'APPARTEMENT'} 
                                     disabled
                                    id="" 
                                    style={{ width:'32px', height:'20px',   fontWeight:'bold'}} 
                                    className="" />
                                   

                                                                </>
                                                              )
                                                            }
                                                          })
                                                        ()}

                              
                            </h4>
                            </div>

                           

                            <div className="col-md-3">
                            <div className="row">
                                <div className="" id="Dtitre_mod_reserv" style={{textAlign:'left'}} ><h4 id="titre_mod_reserv">Mode de payment: </h4></div>
                                <div className="col-md-6" id="mode">
                                    <div><h4 id="cash">Cash  

                                    {(() => {
                                 if (value.mode_paiement === 'Cash') {
                                                              return (
                                                                <>
                                    <input 
                                    type="checkbox" 
                                    name="checkbox4" 
                                    defaultValue={'Cash'} 
                                    checked 
                                    
                                    style={{ width:'32px', height:'20px'}}
                                    className="" />

                               </>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                <>
                                     <input 
                                      type="checkbox" 
                                    name="checkbox4" 
                                    defaultValue={'Cash'} 
                                     disabled
                                    style={{ width:'32px', height:'20px'}}
                                    className="" />

                                                                </>
                                                              )
                                                            }
                                                          })
                                                        ()}

                                    </h4></div>
                                    <div>
                                    <h4 id="virement">Virement  


                                    {(() => {
                                 if (value.mode_paiement === 'Virement') {
                                                              return (
                                                                <>
                                    <input 
                                    type="checkbox" 
                                    name="checkbox5" 
                                    defaultValue={'Virement'} 
                                    checked 
                                    
                                    style={{ width:'32px', height:'20px'}}
                                    className="" />

                               </>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                <>
                                     <input 
                                      type="checkbox" 
                                    name="checkbox5" 
                                    defaultValue={'Virement'} 
                                     disabled
                                    style={{ width:'32px', height:'20px'}}
                                    className="" />

                                                                </>
                                                              )
                                                            }
                                                          })
                                                        ()}
                                    </h4></div>
                                    <div><h4 id="cheque">Chèque  




                                    {(() => {
                                 if (value.mode_paiement === 'Chèque') {
                                                              return (
                                                                <>
                                    <input 
                                    type="checkbox" 
                                    name="checkbox6" 
                                    defaultValue={'Chèque'} 
                                    checked 
                                    
                                    style={{ width:'32px', height:'20px'}}
                                    className="" />

                               </>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                <>
                                     <input 
                                      type="checkbox" 
                                    name="checkbox6" 
                                    defaultValue={'Chèque'} 
                                     disabled
                                    style={{ width:'32px', height:'20px'}}
                                    className="" />

                                                                </>
                                                              )
                                                            }
                                                          })
                                                        ()}

                                                        </h4>

                                    </div>
                                </div>
                            </div>
                            </div>
                                
                            </div>
                        </div>


                        <div className="col-lg-12" style={{marginTop:'0%'}} id="">





                        <table style={{width:'100%', marginTop:'2%'}} >

                             <tr>
                                <td id="td_prix" style={{borderBottom:'1px solid #000', textAlign:'left'}} >
                                    <h3 style={{color:'#000'}}>Le prix de vente du terrain ci-dessus désignés est fixé à la somme de &nbsp;&nbsp;

                                 <input 
                                 type="text" 
                                 name="prix_lettre" 
                                 id="input7" 
                                 defaultValue={value.prix_vente} 
                                 readOnly
                                 style={{border: 'none', fontWeight:'bold',  width:'100%'}} 
                                 placeholder="........." />
                                    
                                    </h3>
                                </td>

                                
                            </tr>
                        </table>




                        
                        </div>



                             <h4 style={{textAlign:'left', color:'#000', marginTop:'3%'}} id="condition_ci_apres">Dans les conditions ci-après définies.</h4>
                         <div className="col-lg-12" style={{marginTop:'2%'}} id="dcondition">
                             <br/>
                             <br/>
                             <h3 style={{textAlign:'center', textDecoration:'underline', color:'#000', }} id="condition_g"> CONDITIONS GENERALES DE SOUSCRIPTION</h3>

                             <h5 style={{color:'#000'}} >- La signature d’un contrat de vente.</h5>
                             <h5 style={{color:'#000'}} >- Le pLe prix d’acquisition est ferme et définitif, et doit être entièrement soldé avant la remise des titres de propriété relatifs au(x) bien(s). </h5>
                             <h5 style={{color:'#000'}} >- Le versement des frais de dossiers à hauteur de <b>cinq cent mille (500.000) francs.</b></h5>
                             <h5 style={{color:'#000'}} >- Le versement d’un apport initial correspondant à <b> trente pourcent (30%)</b> du prix d’acquisition du bien foncier</h5>
                             <h5 style={{color:'#000'}} >- L’établissement d’un échelonnement du solde du prix sur une période de 
                             <input 
                                 type="text" 
                                 name="periode_de" 
                                 id="" 
                                 required
                                 defaultValue={value.periode_de} 
                                 readOnly
                                 style={{border: 'none', fontWeight:'bold',  width:'50px', textAlign:'center'}} 
                                 placeholder=".........." /> mois. Organisé selon des échéances mensuelles, bimensuelles ou trimestrielles.</h5>
                             <h5 style={{color:'#000'}}> - Il sera prélevé &nbsp;
                             <input 
                                 type="text" 
                                 name="pourcentage" 
                                 id="" 
                                 readOnly
                                 required
                                 defaultValue={value.pourcentage} 
                                 style={{border: 'none', fontWeight:'bold',  width:'90px', textAlign:'center'}} 
                                 placeholder="...................." />

                                 &nbsp;
                                 pour cent (
                                     <input 
                                 type="text" 
                                 name="num_pourcentage" 
                                 id="" 
                                 readOnly
                                 required
                                 defaultValue={value.num_pourcentage} 
                                 style={{border: 'none', fontWeight:'bold',  width:'50px', textAlign:'center'}} 
                                 placeholder="..........." />)% sur l’apport initial constitué par le <b>SOUSCRIPTEUR</b> en guise d’indemnité d’immobilisation si le <b>SOUSCRIPTEUR</b> ne donne pas
                                suite à sa  &nbsp;&nbsp;&nbsp;réservation  pour quelque motif que ce soit dans un délai de Trois (03) mois à compter de la signature du contrat ou après le dernier versement enregistré à la
                                <br /> &nbsp;&nbsp;&nbsp;comptabilité de la Société AL SANOGO. Cette somme restera acquise à la Société AL SANOGO à titre d’indemnité d’immobilisation. </h5>

                                  <h5 style={{color:'#000'}}> &nbsp;&nbsp;&nbsp;Le <b>SOUSCRIPTEUR</b> s’oblige, de manière diligente à respecter toutes les obligations prises aux termes des présentes.</h5>
                         </div>

                         <div className="col-lg-12" style={{marginTop:'2%'}}>

                         <div className="row">
                             <div className="col-lg-6" id="vide"></div>
                             <div className="col-lg-3" id="faita">
                             <p>
                             <h4 style={{position:'absolute'}} > Fait à: </h4>
                                 <h4 className="" id="lieu" style={{ fontWeight:'bold', marginLeft:'22%'}}>
                                 <input 
                                 type="text" 
                                 name="fait_a" 
                                 id="fait_a" 
                                 required
                                 readOnly
                                 defaultValue={value.lieu_enreg} 
                                 
                                 style={{border:"none", fontWeight:'bold',  width:'100%'}} 
                                 placeholder="......." />
                                 </h4>
                            </p>
                             </div>
                             <div className="col-lg-3" id="date_fait">
                             <h4 style={{position:'absolute'}} >Le: </h4>
                                 <h4 className="" id="date" style={{borderBottom:'1px solid #000', fontWeight:'bold', marginLeft:'10%'}}>
                                 &nbsp;{value.date_enreg}
                             </h4>
                             </div>
                         </div>
                             
                             
                         </div>




                         <div className="col-lg-12" style={{marginTop:'5%'}}>

                         <div className="row">
                             <div className="col-lg-4" id="Signature1">
                                  <h3 style={{textDecoration:'underline', fontWeight:'bold'}} id="titre_sign1" >Signature du souscripteur</h3>
                             </div>

                             <div className="col-lg-4" id="Signature2">
                             <h3 style={{textDecoration:'underline', fontWeight:'bold'}} id="titre_sign2" >Agent responsable</h3>
                             <h4 style={{color:'#000'}} id="respo_name"> {value.user_by} </h4>

                             </div>

                             <div className="col-lg-3" id="Signature3">
                             <h3 style={{textDecoration:'underline', fontWeight:'bold'}} id="titre_sign3">Signature du cosouscripteur</h3>
                                 
                            
                             </div>
                         </div>

                           <div className="col-lg-12" style={{marginTop:'5%', textAlign:'center'}} id="footerBottom">
                               
                           <h5>
                               La société AL SANOGO Société Anonyme avec conseil d’administration, au capital de 200 000 000 Frs CFA Siège Social : Abidjan
                            Cocody Riviera-Palmeraie 01 BP 5793 Abidjan 01 RCCM N° CI-ABJ-03-2018-B13-30877 - CC N° 1861919 T - Tel : (+225) 22 46 82 51 Cel
                            : (+225) 05 33 98 98 - (+225) 07 33 98 98 Ecobank : CI059 01046 121250615001 42 - Code Swift : ECOCCIAB <b style={{color:"#05f"}} >www.alsanogo.com</b>
                              &nbsp;&nbsp;<b style={{color:"#05f"}} >alsanogo@alsanogo.com</b>

                           </h5>

                           </div>
                             
                             
                         </div>


                        </div>
                         

                    </div>
                    </div>
                          ))}

            <button onClick={LinkReservationLogement} className="btn btn-danger mt-5 mb-5 col-3" style={{float:'left'}} id="btnRetour">Retour</button>
                   
            <button type="submit"  onClick={(e) => Print(e)} className="btn btn-outline-primary mt-5 mb-5 col-3" id="btnPrint">Imprimer</button>

                   
                    </div>

                </div>
                {/* Content body end */}

            </div>

        </>

    );
}
export default DetailDocs2;