import axios from '../axios/axios';

const getAll = () => {
  return axios.get("/vente");
};

const get = id => {
  return axios.get(`/vente/${id}`);
};


const detail_lot_Vendu = (id) => {
  return axios.get(`/show_lot_vendu/${id}`);
};

const getVente = (numIlot,lotiss,codeIlot, id) => {
  return axios.get(`/vente_detail/${numIlot}/${lotiss}/${codeIlot}/${id}`);
};

const getVentePaiement = (numIlot,lotiss,codeIlot, id, montant) => {
  return axios.get(`/vente_detail_versement/${numIlot}/${lotiss}/${codeIlot}/${id}/${montant}`);
};

const getLotVendu = id => {
  return axios.get(`/lot_vendu/${id}`);
};


const SUMPULotVendu = id => {
  return axios.get(`/somme_vendu/${id}`);
};

const create = data => {
  return axios.post("/vente", data);
};


const ResteApayer = data => {
  return axios.post("/vente_recouvrement", data);
};


const Desitements = data => {
  return axios.post("/vente_desiste", data);
};

const AllDesitements = () => {
  return axios.get("/vente_desiste");
};

const GetDesitements = id => {
  return axios.get(`/vente_desiste/${id}`);
};

const DesitementsRestore = id => {
  return axios.get(`/vente_restore/${id}`);
};


const updateVente = data => {
  return axios.post(`/vente_update`, data);
};

const remove = id => {
  return axios.delete(`/vente/${id}`);
};

const removeIlot = (ilot, lot, remorcel) => {
  return axios.get(`/vente_retrait/${ilot}/${lot}/${remorcel}`);
};

const removeAll = () => {
  return axios.delete(`/vente`);
};


const getAllVersement = () => {
  return axios.get("/paiement");
};


const getVersement = code => {
  return axios.get(`/paiement/${code}`);
};

const removeVersement = id => {
  return axios.get(`/paiement_delete/${id}`);
};

const createCession = data => {
  return axios.post("/cession  ", data);
};


const getCession = code => {
  return axios.get(`/cession_detail/${code}`);
};


const nbreVenteToDay = () => {
  return axios.get(`/vente_par_jour`);
};


const PrixUnitaireLot = data => {
  return axios.post(`/lots_prix`, data);
};


const getVersementId = id => {
  return axios.get(`/paiement_get/${id}`);
};



const DeleteCession = (code) => {
  return axios.get(`/delete_cession/${code}`);
};



const createPaidComplementaire = data => {
  return axios.post(`/paiement_complementaire`, data);
};



const PaidComplementaireBy = (code) => {
  return axios.get(`/paiement_by_code/${code}`);
};


const DetailPaidComplementaireBy = (code) => {
  return axios.get(`/detail_paiement_by_code/${code}`);
};

const ListAcquereursPaiementComp = () => {
  return axios.get(`/list_Group_paiement_comp`);
};

const ListPaiementComp = () => {
  return axios.get(`/list_paiement_comp`);
};

const DeletePaiementComp = (id) => {
  return axios.get(`/del_paiement_comp/${id}`);
};

const DeleteAcqPaiementComp = (id) => {
  return axios.get(`/del_acq_paiement_comp/${id}`);
};

const SumAllPaiementComp = () => {
  return axios.get(`/SumAmounAnnexe`);
};


const Mtt_pu_lot = data => {
  return axios.post(`/calcule_pu_lot`, data);
};



const LotsVendu = (code, nblov) => {
  return axios.get(`/update_lotiss_lot_vendu/${code}/${nblov}`);
};


const NbreLotsVendu = code => {
  return axios.get(`/nbr_lot_vendu/${code}`);
};


const CountNbrReservation = () => {
  return axios.get(`/nbr_reservation`);
};


const VentesCRUD = {
  getAll,
  get,
  detail_lot_Vendu,
  getVente,
  getLotVendu,
  create,
  updateVente,
  remove,
  removeAll,
  ResteApayer,
  Desitements,
  AllDesitements,
  GetDesitements,
  DesitementsRestore,
  removeIlot,
  getAllVersement,
  getVersement,
  removeVersement,
  createCession,
  getCession,
  nbreVenteToDay,
  PrixUnitaireLot,
  SUMPULotVendu,
  getVersementId,
  DeleteCession,
  createPaidComplementaire,
  PaidComplementaireBy,
  DetailPaidComplementaireBy,
  ListAcquereursPaiementComp,
  ListPaiementComp,
  DeletePaiementComp,
  DeleteAcqPaiementComp,
  SumAllPaiementComp,
  Mtt_pu_lot,
  LotsVendu,
  NbreLotsVendu,
  getVentePaiement,
  CountNbrReservation
 
};
export default VentesCRUD;