export default function style() {

	const table = {
		'width': '100%',
		'marginTop': '3%',

	}
	const thead = {
		'border' : '1px solid #000',

	}

	const th = {
		'borderTop' : '1px solid #000',
		'borderLeft' : '1px solid #000',
		'borderRight' : '1px solid #000',
		'borderBottom' : '1px solid #000',
		// 'padding': '0.5%',
		'fontSize':'14px',
	}

	const tbody = {
		'borderTop' : '1px solid #000',
		'borderLeft' : '1px solid #000',
		'borderRight' : '1px solid #000',
		'borderBottom' : '1px solid #000',

	}

	const td = {
		'borderTop' : '1px solid #000',
		'borderLeft' : '1px solid #000',
		'borderRight' : '1px solid #000',
		'borderBottom' : '1px solid #000',
		// 'padding': '0.5%',
	}

	return {
		table,
		thead,
		th,
		tbody,
		td,
	}
}