import React, {useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Siderbar from '../components/Siderbar';
// import {Link } from 'react-router-dom';
import '../index.css';

import Swal from 'sweetalert2';

import UserCRUD from '../services/UserCRUD';
import AuhtUser from '../services/AuthUser';

function Profile(){


 const [photo, setPhoto] = useState()
 const [nameuser, setNameuser] = useState()
 const [email, setEmail] = useState()
 const [telephone, setTelephone] = useState()
 const [adresse, setAdresse] = useState()
 // const [age, setAge] = useState()
 const [contrat, setContrat] = useState()
  const [role, setRole] = useState()
  const [username, setUsername] = useState()
  const [password, setPassword] = useState()
  const { token, logout } = AuhtUser()
  const [statut, setStatut] = useState()
 const [idusers, setIdusers] = useState()
 const [datenaissance, setDatenaissance] = useState()

  useEffect(() => {
    fetchUserDetail()
    getAllInfosUser(statut)
  }, [statut])

 const fetchUserDetail = () => {
      setStatut(JSON.parse(sessionStorage.getItem('token')))
  }

const getAllInfosUser = () => {

       UserCRUD.get(statut)
      .then((res) => {

        setIdusers(res.data[0].id)
        setPhoto(res.data[0].photo)
        setNameuser(res.data[0].name)
        setEmail(res.data[0].email)
        setRole(res.data[0].role)
        setUsername(res.data[0].username)
        setPassword(res.data[0].password)
        setTelephone(res.data[0].tel)
        setAdresse(res.data[0].adresse)
        // setAge(res.data[0].age)
        setContrat(res.data[0].contrat)
        setDatenaissance(res.data[0].date_naiss)

         console.log(res.data);
      })
      .catch((error) => {
        console.warn(error)
      })
    }
 

 const [dataUsers, setDataUsers] = useState({
    photoProfile : "",
    naissance : "",
    nomComplet:"",
    telp : "",
    email : "",
    adresse : "",
    username : "",
    password : "",
 })


const [photoP, setPhotoP] = useState([]);

const handlePhoto = (event) => {
    setPhotoP(event.target.files[0])
    console.log(event.target.files[0])
  }

const [dataInfos, setDataInfos] = useState([]);


const handleInfos = (e) => {
     const { name, value } = e.target;
    const newdata={...dataInfos, [name]: value }
    setDataInfos(newdata)
    console.log(newdata)
  }



function UpdateInfosUsers(e, id){

    e.preventDefault();

    const formData = new FormData();

    formData.append('idUser', id)
    formData.append('photoUser', photoP)
    formData.append('nomUser', dataInfos.nomComplet)
    formData.append('naissanceUser', dataInfos.naissance)
    formData.append('telUser', dataInfos.telp)
    formData.append('emailUser', dataInfos.email)
    formData.append('adresseUser', dataInfos.adresse)
    formData.append('username', dataInfos.username)
    formData.append('password', dataInfos.password)

   UserCRUD.updateUser(formData)
      .then(response => {
        console.log(response.data);

           Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 2000
                    })
           setTimeout(() => {
            window.location.reload();

          }, 2000);
      })
      .catch(e => {
        console.log(e);
         Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 10000
                    })
      });
}




    return (

<div className="">
        <Navbar />
        <Siderbar />

        <div className="content-body">
          <div className="container-fluid">

        

 

                





                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12 col-lg-12">
                        <div className="card">
                           
                            <div className="card-body">

                            

                                <div className="basic-form">
                               <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="profile-tab">
                                    <div className="custom-tab-1">
                                        <ul className="nav nav-tabs">
                                            
                                            <li className="nav-item"><a href="#about-me" data-bs-toggle="tab" className="nav-link active show">Informations</a>
                                            </li>
                                            <li className="nav-item"><a href="#profile-settings" data-bs-toggle="tab" className="nav-link">Modification</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            
                                            <div id="about-me" className="tab-pane fade active show">
                                                <div className="profile-about-me">
                                                    <div className="col-lg-12">
                                                    <div className="profile-head">
                                                      
                                                        <div className="profile-info mt-5">
                                                            <div className="profile-photo">
                                                                
                                                                {(() => {
                                                            if (photo) {
                                                              return (
                                                                <img 
                                                                src={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/"+photo} 
                                                                className="img-fluid rounded-circle" 
                                                                alt="" 
                                                                style={{width:'150px'}}
                                                                />
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                <>
                                                                 <i className="fa fa-user-circle fa-3x"></i><br />
                                                                 <code><i className="fa fa-exclamation-triangle"></i> photo</code>
                                                                 </>

                                                              )
                                                            }
                                                          })
                                                        ()}
                                                            </div>
                                                            <div className="profile-details">
                                                                <div className="profile-name px-3 pt-2">
                                                                    <h4 className="text-primary mb-0">{nameuser}</h4>
                                                                    <p>{role}</p>
                                                                </div>
                                                                <div className="profile-email px-2 pt-2">
                                                                    <h4 className="text-muted mb-0"></h4>
                                                                    
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                                </div>
                                              
                                                
                                                <div className="profile-personal-info">
                                                    <h4 className="text-primary mb-4">Information Personnel</h4>
                                                  

                                                    <div >
                                                    <div className="row mb-2">
                                                        <div className="col-sm-3 col-5">
                                                            <h5 className="f-w-500">Nom complet <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-sm-9 col-7"><span>{nameuser}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-sm-3 col-5">
                                                            <h5 className="f-w-500">Email <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-sm-9 col-7">{email}
                                                        </div>
                                                    </div>
                                                     <div className="row mb-2">
                                                        <div className="col-sm-3 col-5">
                                                            <h5 className="f-w-500">Téléphone <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-sm-9 col-7"><span>
                                                        
                                                        {(() => {
                                                            if (telephone) {
                                                              return (
                                                                <>{telephone}</>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                 <b style={{color:'red'}}>Aucun</b>
                                                              )
                                                            }
                                                          })
                                                        ()}
                                                        </span>
                                                        </div>
                                                    </div>
                                                     <div className="row mb-2">
                                                        <div className="col-sm-3 col-5">
                                                            <h5 className="f-w-500">Date naissance <span className="pull-end">:</span>
                                                            </h5>
                                                        </div>
                                                        <div className="col-sm-9 col-7">
                                                        <span>
                                                        {(() => {
                                                            if (datenaissance) {
                                                              return (
                                                                <>{datenaissance}</>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                 <b style={{color:'red'}}>Aucun</b>
                                                              )
                                                            }
                                                          })
                                                        ()}
                                                        </span>
                                                        </div>
                                                    </div>
                                                     <div className="row mb-2">
                                                        <div className="col-sm-3 col-5">
                                                            <h5 className="f-w-500">Adresse <span className="pull-end">:</span></h5>
                                                        </div>
                                                        <div className="col-sm-9 col-7"><span>
                                                         {(() => {
                                                            if (adresse) {
                                                              return (
                                                                <>{adresse}</>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                 <b style={{color:'red'}}>Aucun</b>
                                                              )
                                                            }
                                                          })
                                                        ()}
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-sm-3 col-5">
                                                            <h5 className="f-w-500">Contrat <span className="pull-end">:</span></h5>
                                                        </div>
                                                        <div className="col-sm-9 col-7"><span>
                                                        {(() => {
                                                            if (contrat) {
                                                              return (
                                                                <>{contrat}</>
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                 <b style={{color:'red'}}>Aucun</b>
                                                              )
                                                            }
                                                          })
                                                        ()}
                                                        </span>
                                                        </div>
                                                    </div>
                                                   
                                                   
                                                    <div className="row mb-2">
                                                        <div className="col-sm-3 col-5">
                                                            <h5 className="f-w-500">Nom utilisateur <span className="pull-end">:</span></h5>
                                                        </div>
                                                        <div className="col-sm-9 col-7"><span>{username}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-sm-3 col-5">
                                                            <h5 className="f-w-500">Mot de passe <span className="pull-end">:</span></h5>
                                                        </div>
                                                        <div className="col-sm-9 col-7"><span>**********</span>
                                                        </div>
                                                    </div>
                                                </div>
                                              
                                                </div>
                                            </div>
                                            <div id="profile-settings" className="tab-pane fade">
                                                <div className="pt-3">
                                                    <div className="settings-form mt-5">
                                                        
                                                        <form  onSubmit={(e)=>UpdateInfosUsers(e, idusers)} encType="multipart/form-data" id="Form">
                                                          <div className="row mb-4">
                                                              

                                                            <div className="mb-3 col-md-2">
                                                             {(() => {
                                                            if (photo) {
                                                              return (
                                                                <img src={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/"+photo} className="img-fluid rounded-circle" alt="" />
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                <>
                                                                 <i className="fa fa-user-circle fa-3x"></i><br />
                                                                 <code><i className="fa fa-exclamation-triangle"></i> photo</code>
                                                                 </>

                                                              )
                                                            }
                                                          })
                                                        ()}
                                                             </div>

                                                             <div className="mb-3 col-md-6">
                                                                 <span className="input-group-text">Importer Votre photo</span>
                                                                            <div className="form-file">
                                                                                <input 
                                                                                type="file" 
                                                                                className="form-file-input form-control" 
                                                                                name="photoProfile"
                                                                                onChange={handlePhoto}
                                                                                />
                                                                            </div>
                                                             </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label">Nom complet</label>
                                                                    <input 
                                                                      type="text" 
                                                                      name="nomComplet" 
                                                                      placeholder="Nom complet" 
                                                                      className="form-control" 
                                                                       onChange={(e)=>handleInfos(e)}
                                                                      defaultValue={nameuser}
                                                                      />
                                                                </div>
                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label">Date de naissance</label>
                                                                    <input 
                                                                     type="date" 
                                                                     name="naissance"
                                                                     placeholder="telp" 
                                                                     className="form-control" 
                                                                      onChange={(e)=>handleInfos(e)}
                                                                     defaultValue={datenaissance}
                                                                     />
                                                                </div>
                                                            </div>
                                                          
                                                            <div className="row mb-3">
                                                              <div className="mb-3 col-md-6">
                                                                <label className="form-label">Telephone</label>
                                                                <input 
                                                                  type="text" 
                                                                  name="telp"
                                                                  placeholder="Telephone" 
                                                                  className="form-control" 
                                                                   onChange={(e)=>handleInfos(e)}
                                                                  defaultValue={telephone}
                                                                  />
                                                            </div>

                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label">Email</label>
                                                                    <input 
                                                                     type="email" 
                                                                     name="email"
                                                                     placeholder="Email" 
                                                                     className="form-control"
                                                                      onChange={(e)=>handleInfos(e)}
                                                                     defaultValue={email} 
                                                                     />
                                                                </div>
                                                                
                                                            </div>
                                                            <div className="mb-4">
                                                                    <label className="form-label">Adresse</label>
                                                                    <input 
                                                                     type="text" 
                                                                     name="adresse"
                                                                     placeholder="Apartment, studio, or floor" 
                                                                     className="form-control" 
                                                                      onChange={(e)=>handleInfos(e)}
                                                                     defaultValue={adresse}
                                                                     />
                                                                </div>
                                                                 <div className="row">
                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label">Username</label>
                                                                <input 
                                                                  type="text" 
                                                                  name="username"
                                                                  placeholder="1234 Main St" 
                                                                  className="form-control" 
                                                                   onChange={(e)=>handleInfos(e)}
                                                                  defaultValue={username}
                                                                  />
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label">Mot de passe</label>
                                                                <input 
                                                                  type="password" 
                                                                  name="password"
                                                                  placeholder="*********" 
                                                                  className="form-control" 
                                                                   onChange={(e)=>handleInfos(e)}
                                                                  defaultValue={password}
                                                                  />
                                                            </div>
                                                            </div>
                                                           
                                                            <button className="btn btn-warning" type="submit">Modifier</button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                                        
                                </div>
                            </div>
                          
                        </div>
                    </div>
                </div>

          </div>
        </div>
  </div>

        );
    
}
export default Profile;