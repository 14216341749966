import React, { useEffect, useState } from 'react';
//import axios from 'axios';
// import {Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Siderbar from '../components/Siderbar';
import '../index.css';

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import CompteAcquereurCRUD from '../services/CompteAcquereurCRUD';
import ContratAcquereurCRUD from '../services/ContratAcquereurCRUD';

import Swal from 'sweetalert2';

import { Button, Form, Modal } from 'react-bootstrap';

const CompteAcquereurForm = () => {

  const [dataAcquereur, setDataAcquereur] = useState({
    nom: "",
    prenom: "",
    sexe: "",
    numero_piece: "",
    telephone: "",
    lieu_habitation: "",
    domiciliation: "",
    adresse_employeur: "",
    profession: "",
    email: "",
    type_client: "",
    situation_matrimonial: "",
  });

  const [dataAcquereurConjoint, setDataAcquereurConjoint] = useState({

    nom_conjoint: "",
    prenom_conjoint: "",
    telephone_conjoint: "",
    profession_conjoint: ""
  })

  function HandleChangeClient(e) {
    const name = e.target.name;
    const value = e.target.value;

    const data = { ...dataAcquereur, [name]: value }
    setDataAcquereur(data)
    console.log(data)
  }

  function HandleChangeConjoint(e) {
    const name = e.target.name;
    const value = e.target.value;

    const data = { ...dataAcquereurConjoint, [name]: value }
    setDataAcquereurConjoint(data)
    console.log(data)
  }

  const [show, setShow] = useState(false)

  function HandleChangeSituationClient(e) {
    const name = e.target.name;
    const value = e.target.value;

    const data = { ...dataAcquereur, [name]: value }
    setDataAcquereur(data)
    console.log(data)

    if (value === 'Marié') {
      setShow((s) => !s)
    } else {
      setShow(false)
      setDataAcquereurConjoint(false)
    }

  }

  function handleSubmit(event) {
    event.preventDefault();
    const bodyFormData = new FormData();

    bodyFormData.append('nom', dataAcquereur.nom);
    bodyFormData.append('prenom', dataAcquereur.prenom);
    bodyFormData.append('sexe', dataAcquereur.sexe);
    bodyFormData.append('numero_piece', dataAcquereur.numero_piece);
    bodyFormData.append('telephone', dataAcquereur.telephone);
    bodyFormData.append('habitation', dataAcquereur.lieu_habitation);
    bodyFormData.append('domiciliation_bank', dataAcquereur.domiciliation);
    bodyFormData.append('address_empl', dataAcquereur.adresse_employeur);
    bodyFormData.append('profession', dataAcquereur.profession);
    bodyFormData.append('email', dataAcquereur.email);
    bodyFormData.append('type', dataAcquereur.type_client);
    bodyFormData.append('matrimonial', dataAcquereur.situation_matrimonial);
    bodyFormData.append('nom_conj', dataAcquereurConjoint.nom_conjoint);
    bodyFormData.append('prenom_conj', dataAcquereurConjoint.prenom_conjoint);
    bodyFormData.append('telephone_conj', dataAcquereurConjoint.telephone_conjoint);
    bodyFormData.append('profession_conj', dataAcquereurConjoint.profession_conjoint);

    CompteAcquereurCRUD.create(bodyFormData)
      .then((response) => {
        console.log(response.data);
        getAcquereur()
        document.querySelector("#AcquereurForm").reset();
        setDataAcquereur('')
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: response.data.message,
          showConfirmButton: false,
          timer: 5000
        })
      })
      .catch((error) => {
        console.error(error.response);
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: error.response.data.message,
          showConfirmButton: false,
          timer: 5000
        })
      });
  }

  const [listeAcquereur, setListeAcquereur] = useState([])

  useEffect(() => {
    getAcquereur()
  }, [])

  const getAcquereur = () => {
    CompteAcquereurCRUD.getAll()
      .then((res) => {
        setListeAcquereur(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }


  //  Internally, customStyles will deep merges your customStyles with the default styling.
  // const customStyles = {
  //     cells: {
  //         style: {
  //             borderLeft: '1px solid', // override the cell padding for data cells
  //             borderRight: '1px solid',
  //         },
  //     },
  // };

  const columns = [
    {
      name: "N°",
      cell: (row) => (
        <b style={{ color: "black" }}> {row.numero_acq} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Nom & Prenom",
      cell: (row) => (
        <b style={{ color: "black" }}> {row.nom_acq} {row.prenom_acq} </b>
      ),
      sortable: true,
      reorder: true
    },
    // {
    //     name: "Sexe",
    //     cell: (row) => (
    //         <b style={{color:"black"}}> {row.sexe_acq} </b>
    //          ),
    //     sortable: true,
    //     reorder: true
    // },

    //  {
    //     name: "N° Pièce",
    //     cell: (row) => (
    //         <b style={{color:"black"}}> {row.numero_piece_acq} </b>
    //          ),
    //     sortable: true,
    //     reorder: true
    // },
    // {
    //   name: "Téléphone",
    //   cell: (row) => (
    //     <b style={{ color: "black" }}> {row.telephone_acq} </b>
    //   ),
    //   sortable: true,
    //   reorder: true
    // },
    //  {
    //     name: "Profession",
    //     cell: (row) => (
    //         <b style={{color:"black"}}> {row.profession_acq} </b>
    //          ),
    //     sortable: true,
    //     reorder: true
    // },
    {
      name: "Type Client",
      cell: (row) => (
        row.type_acq === 'Direct' ? (
          <span style={{ color: 'green' }}><b>Direct</b></span>
        ) : row.type_acq === 'Prospect' ? (
          <span style={{ color: 'red' }}><b>Prospect</b></span>
        ) : (
          ""
        )
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Contrats & visite",
      cell: (row) => (
        row.type_acq === 'Direct' ? (
          <div>
            <div className="d-flex">
              {/*<Link to={'/ContratAcquereurForm/'+row.numero_acq}>      */}
              <button type="button" className="btn btn-secondary btn-xs me-2" onClick={() => ContratAcquereur(row.numero_acq)}><i className="fa fa-list"></i></button>
              {/*</Link>*/}
            </div>
          </div>
        ) : row.type_acq === 'Prospect' ? (
          <div>
            <div className="d-flex">
              {/*<Link to={'/ContratAcquereurForm/'+row.numero_acq}>  */}
              <button type="button" className="btn btn-primary btn-xs me-2" onClick={() => ContratAcquereur(row.numero_acq)}><i className="fa fa-car"></i></button>
              {/* </Link>*/}
            </div>
          </div>
        ) : (
          ""
        )
      ),
      width: "150px",                       // added line here
      headerStyle: (selector, id) => {
        return { textAlign: "center" };   // removed partial line here
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <div className="d-flex">
            <button className="btn btn-info btn-xs me-2" onClick={() => viewAcquereur(row.numero_acq)}><i className="fa fa-eye"></i></button>
            <button className="btn btn-warning btn-xs me-2" onClick={() => UpdateAcquereur(row.numero_acq)}><i className="fa fa-edit"></i></button>
            {/*<button type="button" onClick={() => LotsComplementaire(row.code_ilot)} className="btn btn-outline-info btn-xs me-2"><i className="fa fa-plus"></i></button>*/}
            <button className="btn btn-danger btn-xs" onClick={() => deleteAcquereur(row.numero_acq)}><i className="fa fa-trash"></i></button>
          </div>
        </div>
      ),
      width: "200px",                       // added line here
      headerStyle: (selector, id) => {
        return { textAlign: "center" };   // removed partial line here
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ]



  /***** DELETE DATA *******/

  const deleteAcquereur = (id) => {
    Swal.fire({
      title: 'Es-tu sûr?',
      text: "Vous ne pourrez pas revenir en arrière! les paiement du client serons aussi supprimé",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprime-le!'
    }).then((result) => {
      if (result.isConfirmed) {
        CompteAcquereurCRUD.remove(id)
          .then(response => {
            console.log(response.data);
            getAcquereur()
            Swal.fire(
              'Supprimé!',
              'Votre fichier a été supprimé.',
              'success'
            )
          })
          .catch(e => {
            console.log(e);
          });

      }
    })
  };
  /***** END DELETE DATA *******/

  const [showAcquereur, setShowAcquereur] = useState(false);
  const [showUpdateAcquereur, setUpdateAcquereur] = useState(false);
  const [detailAcquereur, setDetailAcquereur] = useState([]);
  const [detailContrat, setDetailContrat] = useState([]);

  const viewAcquereur = (id) => {
    setShowAcquereur(true)
    CompteAcquereurCRUD.get(id)
      .then((res) => {
        setDetailAcquereur(res.data);
        Detailcontrats(id);
        console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }


  const Detailcontrats = (id) => {
    ContratAcquereurCRUD.get(id)
      .then((res) => {
        setDetailContrat(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleClose = () => setShowAcquereur(false)

  const [codeAcq, setCodeAcq] = useState(false)

  const UpdateAcquereur = (id) => {
    setUpdateAcquereur(true)
    setCodeAcq(id);
    CompteAcquereurCRUD.get(id)
      .then((res) => {
        setDetailAcquereur(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleCloseUpdate = () => setUpdateAcquereur(false)

  function ContratAcquereur(id) {
    CompteAcquereurCRUD.verification(id)
      .then((res) => {
        if (res.data === 1) {
          window.location.replace('/ContratAcquereurUpdateForm/' + id)
        } else {
          window.location.replace('/ContratAcquereurForm/' + id)
        }
        console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function handleUpdateSubmit(event) {
    event.preventDefault();
    const bodyFormData = new FormData();

    bodyFormData.append('nom', dataAcquereur.nom);
    bodyFormData.append('prenom', dataAcquereur.prenom);
    bodyFormData.append('sexe', dataAcquereur.sexe);
    bodyFormData.append('numero_piece', dataAcquereur.numero_piece);
    bodyFormData.append('telephone', dataAcquereur.telephone);
    bodyFormData.append('habitation', dataAcquereur.lieu_habitation);
    bodyFormData.append('domiciliation_bank', dataAcquereur.domiciliation);
    bodyFormData.append('address_empl', dataAcquereur.adresse_employeur);
    bodyFormData.append('profession', dataAcquereur.profession);
    bodyFormData.append('email', dataAcquereur.email);
    bodyFormData.append('type', dataAcquereur.type_client);
    bodyFormData.append('matrimonial', dataAcquereur.situation_matrimonial);
    bodyFormData.append('nom_conj', dataAcquereurConjoint.nom_conjoint);
    bodyFormData.append('prenom_conj', dataAcquereurConjoint.prenom_conjoint);
    bodyFormData.append('telephone_conj', dataAcquereurConjoint.telephone_conjoint);
    bodyFormData.append('profession_conj', dataAcquereurConjoint.profession_conjoint);

    // console.log(dataAcquereur.profession)

    CompteAcquereurCRUD.update(codeAcq, bodyFormData)
      .then((response) => {
        if (response.data.status === 'successs') {
          console.log(response.data);
          getAcquereur()
          handleCloseUpdate(true)
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: response.data.message,
            showConfirmButton: false,
            timer: 5000
          })
        }
        if (response.data.status === 'error') {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: response.data.message,
            showConfirmButton: false,
            timer: 5000
          })
        }
      })
      .catch((error) => {
        console.error(error.response);
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: error.response.data.message,
          showConfirmButton: false,
          timer: 5000
        })
      });
  }

  return (
    <div className="">
      <Navbar />
      <Siderbar />

      <div className="content-body">
        <div className="container-fluid">
          <div className="page-titles">
            <ol className="breadcrumb">
              <h1><b style={{ color: "red" }}>Compte Acquéreur / Prospect</b></h1>
            </ol>
          </div>
          <Modal show={showAcquereur} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>
                <h3>Informations acquéreur</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {detailAcquereur.map((res) => (
                <div className="row" key={res.id_acq}>
                  <div className="basic-form" >
                    <div className="row">
                      <div className="mb-3 col-md-3">
                        <label className="form-label">Numero</label>
                        <h4>{res.numero_acq}</h4>
                      </div>
                      <div className="mb-3 col-md-3">
                        <label className="form-label">Nom</label>
                        <h4>{res.nom_acq}</h4>
                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label">Prenom</label>
                        <h4>{res.prenom_acq}</h4>
                      </div>
                      <div className="mb-3 col-md-2">
                        <label className="form-label">Sexe</label>
                        <h4>{res.sexe_acq}</h4>
                      </div>
                      <div className="mb-4 col-md-6">
                        <label className="form-label">Le N° de la pièce d’identité ou l’attestation</label>
                        <h4>{res.numero_piece_acq}</h4>
                      </div>
                      <div className="mb-3 col-md-3">
                        <label className="form-label">N° téléphone</label>
                        <h4>{res.telephone_acq}</h4>
                      </div>
                      <div className="mb-3 col-md-3">
                        <label className="form-label">Lieu d'habitation</label>
                        <h4>{res.habitation_acq}</h4>
                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label">Domiciliation bancaire</label>
                        <h4>{res.domiciliation_bank_acq}</h4>
                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label">Adresse employeur</label>
                        <h4>{res.address_empl_acq}</h4>
                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label">Profession</label>
                        <h4>{res.profession_acq}</h4>
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label">Email</label>
                        <h4>{res.email_acq}</h4>
                      </div>
                      <div className="mb-3 col-md-3">
                        <label className="form-label"> Type client</label>
                        {(() => {
                          if (res.type_acq === 'Direct') {
                            return (
                              <h4 style={{ color: 'green' }}>{res.type_acq}</h4>
                            )

                          } else {
                            return (
                              <h4 style={{ color: 'red' }}>{res.type_acq}</h4>
                            )
                          }
                        })
                          ()}
                      </div>
                      <div className="mb-3 col-md-3">
                        <label className="form-label">Situation matrimoniale</label>
                        <h4>{res.matrimonial_acq}</h4>
                      </div>
                    </div>
                    {(() => {
                      if (res.matrimonial_acq === 'Marié') {
                        return (
                          <div>
                            <hr className="mb-3" />
                            <div className="row">
                              <h4 className="mb-5">Informations du conjoint</h4>
                              <div className="mb-3 col-md-3">
                                <label className="form-label">Nom</label>
                                <h4>{res.nom_conj_acq}</h4>
                              </div>
                              <div className="mb-3 col-md-3">
                                <label className="form-label">Prenom</label>
                                <h4>{res.prenom_conj_acq}</h4>
                              </div>
                              <div className="mb-3 col-md-3">
                                <label className="form-label">N° téléphone</label>
                                <h4>{res.telephone_conj_acq}</h4>
                              </div>
                              <div className="mb-3 col-md-3">
                                <label className="form-label">Profession</label>
                                <h4>{res.profession_conj_acq}</h4>
                              </div>
                            </div>
                          </div>
                        )
                      } else {
                        return (
                          <p></p>
                        )
                      }
                    })
                      ()}

                    <hr className="mb-3" />

                    {(() => {
                      if (res.type_acq === 'Direct') {
                        return (
                          <div>
                            {detailContrat.map((value) => (
                              <div className="row" key={value.id_contrat_acq}>
                                <h3 className="mb-4">Informations contrat</h3>
                                <div className="mb-3 col-md-4">
                                  <label className="form-label">Source de provenance</label>
                                  <h4>{value.source}</h4>
                                </div>
                                <div className="mb-3 col-md-4">
                                  <label className="form-label">Contrat de réservation</label>
                                  <p>
                                    {(() => {
                                      if (value.ficher_contrat) {
                                        return (
                                          <a href={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/" + res.ficher_contrat} target={'_blank'} rel="noreferrer">
                                            <i className="fa fa-download fa-2x"></i>
                                          </a>
                                        )
                                      } else {
                                        return (
                                          <b style={{ color: 'red' }}>Aucun</b>
                                        )
                                      }
                                    })
                                      ()}
                                  </p>
                                </div>
                                {(() => {
                                  if (value.source === "Apporteur d'affaire") {
                                    return (
                                      <div className="mb-3 col-md-4">
                                        <label className="form-label">Nom de l’apporteur</label>
                                        <h4>{value.nom_apporteur}</h4>
                                      </div>
                                    )
                                  } else {
                                    return (
                                      <></>
                                    )
                                  }
                                })
                                  ()}
                                <div className="mb-3 col-md-4">
                                  <label className="form-label">Projet intéressé</label>
                                  <h4>{value.type_proj}</h4>
                                </div>
                                <div className="mb-3 col-md-4">
                                  <label className="form-label">Visite effectuer</label>
                                  <h4>{value.visite}</h4>
                                </div>
                                {(() => {
                                  if (value.visite === "OUI") {
                                    return (
                                      <>
                                        <div className="mb-3 col-md-4">
                                          <label className="form-label">Date de la visite</label>
                                          <h4>{value.date_visite}</h4>
                                        </div>
                                        <div className="mb-3 col-md-4">
                                          <label className="form-label">Commentaire</label>
                                          <h4>{value.commentaire}</h4>
                                        </div>
                                      </>
                                    )
                                  } else {
                                    return (
                                      <></>
                                    )
                                  }
                                })
                                  ()}
                              </div>
                            ))}
                          </div>
                        )
                      } else {
                        return (
                          <div>
                            {detailContrat.map((value) => (
                              <div className="row" key={value.id_contrat_acq}>
                                <h3 className="mb-5">Informations visite</h3>
                                <div className="mb-3 col-md-4">
                                  <label className="form-label">Visite effectuer</label>
                                  <h4>{value.visite}</h4>
                                </div>
                                {(() => {
                                  if (value.visite === "OUI") {
                                    return (
                                      <>
                                        <div className="mb-3 col-md-4">
                                          <label className="form-label">Date de la visite</label>
                                          <h4>{value.date_visite}</h4>
                                        </div>
                                        <div className="mb-3 col-md-4">
                                          <label className="form-label">Commentaire</label>
                                          <h4>{value.commentaire}</h4>
                                        </div>
                                      </>
                                    )
                                  } else {
                                    return (
                                      <></>
                                    )
                                  }
                                })
                                  ()}
                              </div>
                            ))}
                          </div>
                        )
                      }
                    })
                      ()}
                  </div>
                </div>
              ))}
            </Modal.Body>
            <Modal.Footer>

              <Button onClick={handleClose} variant='secondary'>
                Fermer
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showUpdateAcquereur} onHide={handleCloseUpdate} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>
                <h3>Modifier Informations acquéreur</h3>
              </Modal.Title>
            </Modal.Header>

            <Form onSubmit={(e) => handleUpdateSubmit(e)} id="formUpdateAcq">
              <Modal.Body>
                <div className="row">
                  {detailAcquereur.map((ress) => (
                    <div className="basic-form" key={ress.id_acq}>
                      <div className="row">
                        <div className="mb-3 col-md-4">
                          <label className="form-label">Nom</label>
                          <input
                            type="text"
                            className="form-control"
                            id="nomInput"
                            required
                            defaultValue={ress.nom_acq}
                            onChange={(e) => HandleChangeClient(e)}
                            name="nom"
                            placeholder="...."
                          />
                        </div>
                        <div className="mb-3 col-md-5">
                          <label className="form-label">Prenom</label>
                          <input
                            type="text"
                            className="form-control" id="prenomInput"
                            defaultValue={ress.prenom_acq}
                            onChange={(e) => HandleChangeClient(e)}
                            name="prenom"
                            placeholder="...."
                          />
                        </div>
                        <div className="mb-3 col-md-3">
                          <label className="form-label">Sexe</label>
                          <select className="form-control" id="sexeInput" required onChange={(e) => HandleChangeClient(e)} name="sexe">
                            <option defaultValue={ress.sexe_acq}>{ress.sexe_acq}</option>
                            <option defaultValue="Homme">Homme</option>
                            <option defaultValue="Femme">Femme</option>
                          </select>
                        </div>
                        <div className="mb-4 col-md-6">
                          <label className="form-label">Le N° de la pièce d’identité ou l’attestation</label>
                          <input
                            type="text"
                            className="form-control" id="num_pieceInput"
                            defaultValue={ress.numero_piece_acq}
                            onChange={(e) => HandleChangeClient(e)}
                            name="numero_piece"
                            required
                            placeholder="Ex: CNI00120120010..."
                          />
                        </div>
                        <div className="mb-3 col-md-4">
                          <label className="form-label">N° téléphone</label>
                          <input
                            type="number"
                            className="form-control" id="telephoneInput"
                            defaultValue={ress.telephone_acq}
                            onChange={(e) => HandleChangeClient(e)}
                            name="telephone"
                            placeholder="N° téléphone"
                            required
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <label className="form-label">Lieu d'habitation</label>
                          <input
                            type="text"
                            className="form-control" id=""
                            placeholder="Lieu d'habitation..."
                            defaultValue={ress.habitation_acq}
                            onChange={(e) => HandleChangeClient(e)}
                            name="lieu_habitation"
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <label className="form-label">Domiciliation bancaire</label>
                          <input
                            type="text"
                            className="form-control" id=""
                            placeholder="Domiciliation bancaire..."
                            defaultValue={ress.domiciliation_bank_acq}
                            onChange={(e) => HandleChangeClient(e)}
                            name="domiciliation"
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <label className="form-label">Adresse employeur</label>
                          <input
                            type="text"
                            className="form-control" id=""
                            placeholder="Adresse employeur..."
                            defaultValue={ress.address_empl_acq}
                            onChange={(e) => HandleChangeClient(e)}
                            name="adresse_employeur"
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <label className="form-label">Profession</label>
                          <input
                            type="text"
                            className="form-control" id=""
                            placeholder="Profession..."
                            defaultValue={ress.profession_acq}
                            onChange={(e) => HandleChangeClient(e)}
                            name="profession"
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <label className="form-label">Email</label>
                          <input
                            type="email"
                            className="form-control" id=""
                            placeholder="Email"
                            defaultValue={ress.email_acq}
                            onChange={(e) => HandleChangeClient(e)}
                            name="email"
                          />
                        </div>
                        <div className="mb-3 col-md-4">
                          <label className="form-label"> Type client</label>
                          <select className="form-control wide" required onChange={(e) => HandleChangeClient(e)} name="type_client">
                            <option defaultValue={ress.type_acq}>{ress.type_acq}</option>
                            <option defaultValue="Direct">Direct</option>
                            <option defaultValue="Prospect">Prospect</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="mb-3 col-md-4">
                          <label className="form-label">Situation matrimoniale</label>
                          <select className="form-control wide" onChange={(e) => HandleChangeSituationClient(e)} name="situation_matrimonial">
                            <option defaultValue={ress.matrimonial_acq}>{ress.matrimonial_acq}</option>
                            <option defaultValue="Marié">Marié</option>
                            <option defaultValue="Célibataire">Célibataire</option>
                          </select>
                        </div>
                      </div>
                      {(() => {
                        if (ress.matrimonial_acq === 'Marié') {
                          return (
                            <div>
                              <hr className="mb-5" />
                              <div className="row">
                                <h4 className="mb-5">Informations du conjoint</h4>
                                <div className="mb-3 col-md-4">
                                  <label className="form-label">Nom du conjoint</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="nomInput"
                                    defaultValue={ress.nom_conj_acq}
                                    onChange={(e) => HandleChangeConjoint(e)}
                                    name="nom_conjoint"
                                    placeholder="...."
                                  />
                                </div>
                                <div className="mb-3 col-md-6">
                                  <label className="form-label">Prenom du conjoint</label>
                                  <input
                                    type="text"
                                    className="form-control" id="prenomInput"
                                    defaultValue={ress.prenom_conj_acq}
                                    onChange={(e) => HandleChangeConjoint(e)}
                                    name="prenom_conjoint"
                                    placeholder="...."
                                  />
                                </div>
                                <div className="mb-3 col-md-4">
                                  <label className="form-label">N° téléphone du conjoint</label>
                                  <input
                                    type="number"
                                    className="form-control" id="telephoneInput"
                                    defaultValue={ress.telephone_conj_acq}
                                    onChange={(e) => HandleChangeConjoint(e)}
                                    name="telephone_conjoint"
                                    placeholder="N° téléphone"
                                  />
                                </div>
                                <div className="mb-3 col-md-6">
                                  <label className="form-label">Profession du conjoint</label>
                                  <input
                                    type="text"
                                    className="form-control" id="professionInput"
                                    defaultValue={ress.profession_conj_acq}
                                    onChange={(e) => HandleChangeConjoint(e)}
                                    name="profession_conjoint"
                                    placeholder="Profession"
                                  />
                                </div>
                              </div>
                            </div>
                          )

                        } else {
                          return (
                            <div>
                              <hr className="mb-5" />
                              <div className="row" style={{ visibility: show ? "visible" : "hidden" }}>
                                <h4 className="mb-5">Informations du conjoint</h4>
                                <div className="mb-3 col-md-4">
                                  <label className="form-label">Nom du conjoint</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="nomInput"
                                    value={dataAcquereur.nom_conjoint}
                                    onChange={(e) => HandleChangeConjoint(e)}
                                    name="nom_conjoint"
                                    placeholder="...."
                                  />
                                </div>
                                <div className="mb-3 col-md-6">
                                  <label className="form-label">Prenom du conjoint</label>
                                  <input
                                    type="text"
                                    className="form-control" id="prenomInput"
                                    value={dataAcquereur.prenom_conjoint}
                                    onChange={(e) => HandleChangeConjoint(e)}
                                    name="prenom_conjoint"
                                    placeholder="...."
                                  />
                                </div>
                                <div className="mb-3 col-md-4">
                                  <label className="form-label">N° téléphone du conjoint</label>
                                  <input
                                    type="number"
                                    className="form-control" id="telephoneInput"
                                    value={dataAcquereur.telephone_conjoint}
                                    onChange={(e) => HandleChangeConjoint(e)}
                                    name="telephone_conjoint"
                                    placeholder="N° téléphone"
                                  />
                                </div>
                                <div className="mb-3 col-md-6">
                                  <label className="form-label">Profession du conjoint</label>
                                  <input
                                    type="text"
                                    className="form-control" id="professionInput"
                                    value={dataAcquereur.profession_conjoint}
                                    onChange={(e) => HandleChangeConjoint(e)}
                                    name="profession_conjoint"
                                    placeholder="Profession"
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        }
                      })
                        ()}
                    </div>
                  ))}
                </div>
              </Modal.Body>
              <Modal.Footer>

                <Button onClick={handleCloseUpdate} variant='secondary'>
                  Fermer
                </Button>
                <Button type="submit" variant='warning'>
                  Modifier
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Enregistrement des informations</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal">
                  </button>
                </div>
                <form onSubmit={(e) => handleSubmit(e)} encType="multipart/form-data" id="AcquereurForm">
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12 col-lg-12">
                        <div className="basic-form">
                          <div className="row">
                            <div className="mb-3 col-md-4">
                              <label className="form-label">Nom</label>
                              <input
                                type="text"
                                className="form-control"
                                id="nomInput"
                                required
                                value={dataAcquereur.nom}
                                onChange={(e) => HandleChangeClient(e)}
                                name="nom"
                                placeholder="...."
                              />
                            </div>
                            <div className="mb-3 col-md-5">
                              <label className="form-label">Prenom</label>
                              <input
                                type="text"
                                className="form-control" id="prenomInput"
                                value={dataAcquereur.prenom}
                                onChange={(e) => HandleChangeClient(e)}
                                name="prenom"
                                placeholder="...."
                              />
                            </div>
                            <div className="mb-3 col-md-3">
                              <label className="form-label">Sexe</label>
                              <select className="form-control" id="sexeInput" required onChange={(e) => HandleChangeClient(e)} name="sexe">
                                <option value="">Sexe..</option>
                                <option value="Homme">Homme</option>
                                <option value="Femme">Femme</option>
                              </select>
                            </div>
                            <div className="mb-4 col-md-6">
                              <label className="form-label">Le N° de la pièce d’identité ou l’attestation</label>
                              <input
                                type="text"
                                className="form-control" id="num_pieceInput"
                                value={dataAcquereur.numero_piece}
                                onChange={(e) => HandleChangeClient(e)}
                                name="numero_piece"
                                required
                                placeholder="Ex: CNI00120120010..."
                              />
                            </div>
                            <div className="mb-3 col-md-4">
                              <label className="form-label">N° téléphone</label>
                              <input
                                type="number"
                                className="form-control" id="telephoneInput"
                                value={dataAcquereur.telephone}
                                onChange={(e) => HandleChangeClient(e)}
                                name="telephone"
                                placeholder="N° téléphone"
                                
                              />
                            </div>
                            <div className="mb-3 col-md-6">
                              <label className="form-label">Lieu d'habitation</label>
                              <input
                                type="text"
                                className="form-control" id=""
                                placeholder="Lieu d'habitation..."
                                value={dataAcquereur.lieu_habitation}
                                onChange={(e) => HandleChangeClient(e)}
                                name="lieu_habitation"
                              />
                            </div>
                            <div className="mb-3 col-md-6">
                              <label className="form-label">Domiciliation bancaire</label>
                              <input
                                type="text"
                                className="form-control" id=""
                                placeholder="Domiciliation bancaire..."
                                value={dataAcquereur.domiciliation}
                                onChange={(e) => HandleChangeClient(e)}
                                name="domiciliation"
                              />
                            </div>
                            <div className="mb-3 col-md-6">
                              <label className="form-label">Adresse employeur</label>
                              <input
                                type="text"
                                className="form-control" id=""
                                placeholder="Adresse employeur..."
                                value={dataAcquereur.adresse_employeur}
                                onChange={(e) => HandleChangeClient(e)}
                                name="adresse_employeur"
                              />
                            </div>
                            <div className="mb-3 col-md-6">
                              <label className="form-label">Profession</label>
                              <input
                                type="text"
                                className="form-control" id=""
                                placeholder="Profession..."
                                value={dataAcquereur.profession}
                                onChange={(e) => HandleChangeClient(e)}
                                name="profession"
                              />
                            </div>
                            <div className="mb-3 col-md-6">
                              <label className="form-label">Email</label>
                              <input
                                type="email"
                                className="form-control" id=""
                                placeholder="Email"
                                value={dataAcquereur.email}
                                onChange={(e) => HandleChangeClient(e)}
                                name="email"
                              />
                            </div>
                            <div className="mb-3 col-md-4">
                              <label className="form-label"> Type client</label>
                              <select className="form-control wide" onChange={(e) => HandleChangeClient(e)} name="type_client">
                                <option value="">...</option>
                                <option value="Direct">Direct</option>
                                <option value="Prospect">Prospect</option>
                              </select>
                            </div>
                          </div>
                          <div className="row">
                            <div className="mb-3 col-md-4">
                              <label className="form-label">Situation matrimoniale</label>
                              <select className="form-control wide" onChange={(e) => HandleChangeSituationClient(e)} name="situation_matrimonial">
                                <option value="">...</option>
                                <option value="Marié">Marié</option>
                                <option value="Célibataire">Célibataire</option>
                              </select>
                            </div>
                          </div>
                          <hr className="mb-5" />
                          <div className="row" style={{ visibility: show ? "visible" : "hidden" }}>
                            <h4 className="mb-5">Informations du conjoint</h4>
                            <div className="mb-3 col-md-4">
                              <label className="form-label">Nom du conjoint</label>
                              <input
                                type="text"
                                className="form-control"
                                id="nomInput"
                                value={dataAcquereur.nom_conjoint}
                                onChange={(e) => HandleChangeConjoint(e)}
                                name="nom_conjoint"
                                placeholder="...."
                              />
                            </div>
                            <div className="mb-3 col-md-6">
                              <label className="form-label">Prenom du conjoint</label>
                              <input
                                type="text"
                                className="form-control" id="prenomInput"
                                value={dataAcquereur.prenom_conjoint}
                                onChange={(e) => HandleChangeConjoint(e)}
                                name="prenom_conjoint"
                                placeholder="...."
                              />
                            </div>
                            <div className="mb-3 col-md-4">
                              <label className="form-label">N° téléphone du conjoint</label>
                              <input
                                type="number"
                                className="form-control" id="telephoneInput"
                                value={dataAcquereur.telephone_conjoint}
                                onChange={(e) => HandleChangeConjoint(e)}
                                name="telephone_conjoint"
                                placeholder="N° téléphone"
                              />
                            </div>
                            <div className="mb-3 col-md-6">
                              <label className="form-label">Profession du conjoint</label>
                              <input
                                type="text"
                                className="form-control" id="professionInput"
                                value={dataAcquereur.profession_conjoint}
                                onChange={(e) => HandleChangeConjoint(e)}
                                name="profession_conjoint"
                                placeholder="Profession"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger light" data-bs-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn-primary">Enregistrer</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Datatable</h4>
                  <button type="button" className="btn btn-primary mb-2" data-bs-toggle="modal" data-bs-target=".bd-example-modal-lg">Enregistrement</button>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <DataTableExtensions
                      columns={columns}
                      data={listeAcquereur}
                      export={false}
                      print={false}
                    >
                      <DataTable
                        title="DataTable"
                        // customStyles={customStyles}
                        responsive
                        noHeader
                        defaultSortFieldId={1}
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        // selectableRows
                        persistTableHead
                        highlightOnHover
                        exportHeaders={false}

                      />
                    </DataTableExtensions>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default CompteAcquereurForm;