import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import logo from '../logo-al-sanogo.png';
import Navbar from '../components/Navbar';
import Siderbar from '../components/Siderbar';
import '../print.css';
import PrintStyle from '../PrintStyle';


import VentesCRUD from '../services/VentesCRUD';






const RecuPaidComp = props => {

    const {
        stylesBorder,
        styleHeade,
        // stylesBorder1,
        // bodyBorder,
        // bodyBorderEnd,
        smallBody,
        EndBorder,
        // textStylesFooter,
        // textStylesFooter1,
        textEndPosition,
        spaceSubHeader,
        // textFooter 
    } = PrintStyle()



    useEffect(() => {
        
            PaiementComplementaire();
            DetailPaid();

    }, []);


    const [dataPaid, setDataPaid] = useState([])

    const PaiementComplementaire = () => {

        VentesCRUD.ListPaiementComp()
            .then((res) => {

                setDataPaid(res.data);

                console.log(res.data);

            })
            .catch((error) => {
                console.error(error);
            });

    }



 const [tva, setTva] = useState([])
 const [montanTotal, setMontanTotal] = useState([])
 const [montanTotalHT, setMontanTotalHT] = useState([])

    const DetailPaid = () => {

        VentesCRUD.SumAllPaiementComp()
            .then((res) => {
                
                setMontanTotal(res.data[0].SumMontant)
                setTva(res.data[0].sumAmountTva)
                setMontanTotalHT(res.data[0].sumAmountHT)

                console.log(res.data);

            })
            .catch((error) => {
                console.error(error);
            });

    }


   
function PrintRecu(){
   
                
                window.print();
            
                
            }

   


    const LinkPaiementComplementaire = () => {
        window.location.replace('/Etatglobal');
    }



    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    var dateDay = dd + '/' + mm + '/' + yyyy;





function currencyFormat(num) {
   return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$&,')
}


    return (
        <>
            <div id="main-wrapper">
                <Navbar />
                <Siderbar />

                {/* Content body start */}
                <div className="content-body">
                    {/* row */}
                    <div className="container-fluid">
                       
                                <div className="row">
                                    <div className="col-12">
                                        {/* Header */}
                                        <div className='row'>
                                            <div className='row'>
                                                <div className='col-8'>
                                                    <img src={logo} alt="Img" style={{ width: '200px' }} />
                                                </div>
                                                <div className='col-4' style={textEndPosition}>
                                                    Abidjan, le {dateDay}
                                                </div>
                                            </div>
                                        </div>
                                        {/* SubHeader */}
                                        <div className='row' style={spaceSubHeader}>
                                            <div className='col-6'>
                                               
                                            </div>
                                            <div className='col-6'>
                                                {/*<b>FACTURE CLIENT N° : </b> {result.code_vente}*/}
                                               
                                            </div>
                                        </div>
                                        {/* Body */}
                                        <div className="row" style={{marginTop:'50px'}}>
                                            <div className="col-12">
                                                <div className="row">
                                                    <table >
                                                        <thead>
                                                            <tr>
                                                            <th className='footTxtTwo' style={stylesBorder}>
                                                                    <b>ACQUEREUR</b>
                                                                </th>

                                                                <th className='footTxtTwo' style={stylesBorder}>
                                                                    <b>VENTE</b>
                                                                </th>
                                                                <th className='footTxtTwo' style={stylesBorder}>
                                                                    <b>DESIGNATION</b>
                                                                </th>
                                                                <th className='footTxtTwo' style={stylesBorder}>
                                                                    <b>MONTANT HT</b>
                                                                </th>
                                                                <th className='footTxtTwo' style={stylesBorder}>
                                                                    <b>TVA 18%</b>
                                                                </th>
                                                                <th className='footTxtTwo' style={stylesBorder}>
                                                                    <b>MONTANT TTC</b>
                                                                </th>
                                                               
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {dataPaid.map((res) => (
                                                                <tr key={res.id_paid_comp}>
                                                                   <td style={{borderTop:'1px solid #000', borderBottom:'1px solid #000', borderLeft:'1px solid #000', borderRight:'1px solid #000'}}>
                                                                   {res.nom_acq+' '+res.prenom_acq}</td>
                                                                    <td style={{borderTop:'1px solid #000', borderBottom:'1px solid #000', borderLeft:'1px solid #000', borderRight:'1px solid #000'}}>
                                                                    {res.codevente}</td>
                                                                    <td style={{borderTop:'1px solid #000', borderBottom:'1px solid #000', borderLeft:'1px solid #000', borderRight:'1px solid #000'}}>
                                                                    {res.objets}</td>
                                                                    <td style={{borderTop:'1px solid #000', borderBottom:'1px solid #000', borderLeft:'1px solid #000', borderRight:'1px solid #000'}}>
                                                                    {parseInt(res.montant) - parseInt(res.montant_tva)}</td>
                                                                     <td style={{borderTop:'1px solid #000', borderBottom:'1px solid #000', borderLeft:'1px solid #000', borderRight:'1px solid #000'}}>
                                                                    {res.montant_tva}</td>
                                                                    <td style={{borderTop:'1px solid #000', borderBottom:'1px solid #000', borderLeft:'1px solid #000', borderRight:'1px solid #000'}}>
                                                                    {res.montant}</td>
                                                                    
                                                                   
                                                                </tr>
                                                            ))}

                                                           
                                                           <tr>
                                                                <td colSpan={3} ></td>
                                                                <td style={smallBody}>{montanTotalHT}</td>
                                                                <td style={smallBody}>{tva}</td>
                                                                <td style={smallBody}>
                                                                    {montanTotal}
                                                                </td>
                                                            </tr>

                                                            
                                                            
                                                           
                                                            
                                                         </tbody>
                                                    </table>

                                                    
                                                </div>
                                            </div>
                                        </div>
                                        {/* Footer */}
                                        
                                    </div>
                                    <>
                                        <button type="button" className="btn btn-danger mt-5 mb-5 me-5 col-2" id="btnRetour" onClick={() => LinkPaiementComplementaire()}>Retour</button>
                                         <button type="button"  className="btn btn-outline-primary mt-5 mb-5 col-3" id="btnPrint" onClick={()=> PrintRecu()}>Imprimer</button>

                                    </>
                                </div>

                    </div>
                </div>
                {/* Content body end */}
                

            </div>
        </>

    );
}
export default RecuPaidComp;