
import React from 'react';
// import React, { useEffect, useState } from 'react';

import DashComponents from '../components/DashComponents';
// import Loading from '../components/Loading';

function Home() {
 
  // const [loading, setLoading] = useState(null)

  //   const fetchUserDetail = () => {
  //     if (sessionStorage.getItem('token')) {
  //       setLoading(true)
  //     } else {
  //       setLoading(null)
  //     }
  //   }

    // useEffect(() => {
    //      setTimeout(() => {
    //         fetchUserDetail()
               
    //         }, 2000);
    // }, [])

    return (
      <>
       
      {/*{loading ? <DashComponents /> : <Loading />}*/}
      <DashComponents />

      </>
    );
}

export default Home;
