import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
 // import logo from '../logo-al-sanogo.png';
import Navbar from '../components/Navbar';
import Siderbar from '../components/Siderbar';

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import { Button, Form, Modal } from 'react-bootstrap';

import CompteAcquereurCRUD from '../services/CompteAcquereurCRUD';

import Swal from 'sweetalert2';

import VentesCRUD from '../services/VentesCRUD';
import UserCRUD from '../services/UserCRUD';
import AuhtUser from '../services/AuthUser';

const PaiementComp = props => {


const [nameuser, setNameuser] = useState()
  const { token, logout } = AuhtUser()
  const [statut, setStatut] = useState()

  useEffect(() => {
    fetchUserDetail()
    getAllInfosUser(statut)
  }, [statut])

 const fetchUserDetail = () => {
      setStatut(JSON.parse(sessionStorage.getItem('token')))
  }

const getAllInfosUser = () => {

       UserCRUD.get(statut)
      .then((res) => {

        setNameuser(res.data[0].name)
        // setRole(res.data[0].role)
      })
      .catch((error) => {
        console.warn(error)
      })
    }



  
 const [showModal, setShowModal] = useState(false);

  const PaiementModal = () => {
    setShowModal(true)
  }

  const handleClose = () => {
    setShowModal(false)
  }

useEffect(() =>{
     ListeAquereurSoldezero();
     Liste_paiement();
}, [])


const [dataPaiement, setDataPaiement] = useState([]);

const Liste_paiement = () =>{
    VentesCRUD.ListAcquereursPaiementComp()
      .then((res) => {
        setDataPaiement(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
}


const columns = [
    
    {
      name: "Nom & Prenom",
      cell: (row) => (
        <b style={{ color: "black" }}> {row.nom_acq} {row.prenom_acq} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Vente",
      cell: (row) => (
        <b style={{ color: "black" }}> {row.codeventes} </b>
      ),
      sortable: true,
      reorder: true
    },
   
     {
      name: "Montant HT",
      cell: (row) => (
        <b style={{ color: "black" }}> {parseInt(row.montant_ttc) - parseInt(row.montants_tva)} </b>
      ),
      sortable: true,
      reorder: true
    },

    {
      name: "Montant TVA",
      cell: (row) => (
        <b style={{ color: "black" }}> {row.montants_tva} </b>
      ),
      sortable: true,
      reorder: true
    },

    {
      name: "Montant TTC",
      cell: (row) => (
        <b style={{ color: "black" }}> {parseInt(row.montant_ttc)} </b>
      ),
      sortable: true,
      reorder: true
    },
    
     {
      name: "Date",
      cell: (row) => (
        <b style={{ color: "black" }}> {row.date_p} {row.heure_p} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Emis par",
      cell: (row) => (
        <b style={{ color: "black" }}> {row.users} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <div className="d-flex">
            
            <button className="btn btn-outline-warning btn-xs me-3" onClick={() => PrintDetailPaiement(row.code_p)}><i className="fa fa-print"></i></button>
            <button className="btn btn-primary btn-xs me-3" onClick={() => DetailPaiement(row.code_p, row.nom_acq, row.codeventes)}><i className="fa fa-eye"></i></button>
            <button className="btn btn-danger btn-xs" onClick={() => DeleteAcquereurAnnexe(row.code_p)}><i className="fa fa-trash"></i></button>
          </div>
        </div>
      ),
      width: "200px",                       // added line here
      headerStyle: (selector, id) => {
        return { textAlign: "center" };   // removed partial line here
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ]


const [showModalDetail, setShowModalDetail] = useState(false);
const [dataAnnex, setDataAnnex] = useState([]);
const [nomClit, setNomClit] = useState([]);
const [codeV, setCodeV] = useState([]);

  const DetailPaiement = (code, nom, codeV) => {
    setShowModalDetail(true)
    setNomClit(nom)
    setCodeV(codeV)

    VentesCRUD.PaidComplementaireBy(code)
      .then((res) => {

          setDataAnnex(res.data)


         console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }


const columnsAnnexe = [
    
    {
      name: "Annexe",
      cell: (row) => (
        <b style={{ color: "black" }}> {row.objets} </b>
      ),
      sortable: true,
      reorder: true
    },
   
     {
      name: "Montant TTC",
      cell: (row) => (
        <b style={{ color: "black" }}> {row.montant} </b>
      ),
      sortable: true,
      reorder: true
    },
    
     {
      name: "Date",
      cell: (row) => (
        <b style={{ color: "black" }}> {row.date_paie} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <div className="d-flex">
            
            <button className="btn btn-danger btn-xs" onClick={() => DeletePaiementAnnexe(row.id_paid_comp, row.code_paie,row.montant, row.objets)}><i className="fa fa-trash"></i></button>
          </div>
        </div>
      ),
      width: "200px",                       // added line here
      headerStyle: (selector, id) => {
        return { textAlign: "center" };   // removed partial line here
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ]

const handleCloseDetail = () => {
    setShowModalDetail(false)
  }

const [acquereurs, setAquereurs] = useState([]);

const ListeAquereurSoldezero = () =>{
    CompteAcquereurCRUD.ClientDirectSoldZero()
      .then((res) => {
        setAquereurs(res.data);
        // console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
}


 const [dataAcquereur, setDataAcquereur] = useState({
    client: "",
  });


const [lotissement, setLotissement] =  useState([]);
const [numilots, setNumilots] =  useState([]);
const [codeVente, setCodeVente] =  useState([]);

   function handleAcquereur(e) {
    const name = e.target.name;
    const value = e.target.value;

    const data = { ...dataAcquereur, [name]: value }
    setDataAcquereur(data)
    console.log(data)

     CompteAcquereurCRUD.VenteClientDirect(value)
      .then((res) => {

          setLotissement(res.data[0].libelle_loti)
          setNumilots(res.data[0].numero_ilot)
          setCodeVente(res.data[0].code_vente)

         console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });


      
  }


useEffect(() =>{
  if(codeVente)
     ListLotVendu(codeVente);
}, [codeVente])


const [listeLots, setListeLots] = useState([])

  function ListLotVendu(codeVente){
    CompteAcquereurCRUD.LotsClientDirect(codeVente)
      .then((res) => {

        setListeLots(res.data);

         console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

const [datacomp, setDatacomp] =  useState([]);

function handleComp(e){
    const {name, value} = e.target;

    setDatacomp({
        ...datacomp,
        [name]: value
    })
     console.log(datacomp)
}

function handleSubmit(e){
    e.preventDefault();

    const bodyFormData = new FormData();

     bodyFormData.append('code_vente_client', dataAcquereur.client);
     bodyFormData.append('emeteur', nameuser);

    Object.keys(datacomp).map(function (cles) {
      bodyFormData.append(cles, datacomp[cles]);
      return [cles, datacomp[cles]];
       
    });


         VentesCRUD.createPaidComplementaire(bodyFormData)
          .then((response) => {
            console.log(response.data);
             if (response.data.status === 'success') {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: response.data.message,
              showConfirmButton: false,
              timer: 5000
            })

            Liste_paiement()

             setDatacomp('')

             handleClose(true)
              setTimeout(() => {
                window.location.replace('/RecuPaidComp/' + response.data.codePaid);
              }, 3000);

        }

            if (response.data.status === 'error') {
              Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: response.data.message,
                  showConfirmButton: false,
                  timer: 5000
                })
            }
          })
          .catch((error) => {
            console.error(error.response);
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: error.response.data.message,
              showConfirmButton: false,
              timer: 5000
            })
          });
}

 const DeleteAcquereurAnnexe = (id) => {
    Swal.fire({
      title: 'Es-tu sûr?',
      html: "Vous ne pourrez pas revenir en arrière! <br> Le paiement sera supprimer",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprime-le!'
    }).then((result) => {
      if (result.isConfirmed) {
        VentesCRUD.DeleteAcqPaiementComp(id)
          .then(response => {
            console.log(response.data);
            Liste_paiement()
            Swal.fire(
              'Supprimé!',
              'Le paiement a été supprimé.',
              'success'
            )
          })
          .catch(e => {
            console.log(e);
          });

      }
    })
  };


 const DeletePaiementAnnexe = (id, code, amount, objet) => {
    Swal.fire({
      title: ''+objet+' <br>Es-tu sûr?',
      html: "Vous ne pourrez pas revenir en arrière! <br> Le paiement sera supprimer",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprime-le!'
    }).then((result) => {
      if (result.isConfirmed) {
        VentesCRUD.DeletePaiementComp(id)
          .then(response => {
            console.log(response.data);
            Liste_paiement()
            DetailPaiement(code)
            Swal.fire(
              'Supprimé!',
              'Le paiement a été supprimé.',
              'success'
            )
          })
          .catch(e => {
            console.log(e);
          });

      }
    })
  };


const PrintDetailPaiement  = (code) =>{
   window.location.replace('/RecuPaidComp/' + code);
}

const PrintList = () =>{
   
                
           window.location.replace('/TablePaiementComp');
       }

	return (
        <>
        <div id="main-wrapper">
            <Navbar />
        <Siderbar />


                     {/* Content body start */}
                <div className="content-body">
                    {/* row */}
                    <div className="container-fluid">
                             <div className="page-titles">
                        <ol className="breadcrumb">
                            <h1><b style={{ color: "red" }}>Paiement Annexe</b></h1>
                        </ol>
                    </div>


<Modal show={showModalDetail} onHide={handleCloseDetail} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>
                <h1 style={{ color: 'orange' }}>Annexe ({nomClit} - {codeV})</h1>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="table-responsive">
                <DataTable
                  columns={columnsAnnexe}
                  data={dataAnnex}
                  title="DataTable"
                  responsive
                  noHeader
                  defaultSortFieldId={1}
                  defaultSortField="id"
                  defaultSortAsc={false}
                  // pagination
                  // selectableRows
                  persistTableHead
                  highlightOnHover
                  exportHeaders={false}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>

              <Button onClick={handleCloseDetail} variant='secondary'>
                Fermer
              </Button>
            </Modal.Footer>
          </Modal>



<Modal show={showModal} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>
                <h1>Paiement Annexe</h1>
              </Modal.Title>
            </Modal.Header>
            <Form onSubmit={(e) => handleSubmit(e)} id="formComplementaire">

              <Modal.Body>
                <div className="row">
                    <div className="mb-5 col-md-6">
                        <label className="form-label">Acquéreur</label>
                        <select className="form-control wide" name="client" required  onChange={(e) => handleAcquereur(e)}>
                          <option value="">Selection</option>
                          {acquereurs.map((rows) => (
                            <option key={rows.id_vente} value={rows.code_vente}>{rows.nom_acq} {rows.prenom_acq} ({rows.code_vente})</option>
                          ))}
                        </select>
                      </div>

                       <div className="mb-5 col-md-3">
                    {(() => {
                                    if (lotissement) {
                                      return (
                                         <>
                                         <label className="form-label">Lotissement</label>
                                         <h3>{lotissement}</h3>
                                         </>

                                      )
                                                           
                                    } else {
                                      return (
                                         <></>
                                      )
                                    }
                                  })
                                ()}
                </div>


                 <div className="mb-5 col-md-3">
                    {(() => {
                                    if (numilots) {
                                      return (
                                         <>
                                         <label className="form-label">Numero Ilot</label>
                                         <h3>{numilots}</h3>
                                         </>

                                      )
                                                           
                                    } else {
                                      return (
                                         <></>
                                      )
                                    }
                                  })
                                ()}
                </div>


                </div>

                <div className="row">
                                <label className="mb-3">LOTS VENDU</label>
                  {listeLots.map((ress) => (
                    <div className="col-xl-2 col-xxl-2 col-2 mb-5" key={ress.id_vendu}>
                      {(() => {
                                    if (ress.remorcele_v === '0') {
                                      return (
                                         <label
                                      className="form-check-label badge badge-success"
                                      htmlFor={"customCheckBox" + ress.id_lot}
                                      style={{ fontSize: '2em' }}>
                                      Lot {ress.lot}
                                    </label>
                                      )
                                                           
                                    } else {
                                      return (
                                         <label
                                      className="form-check-label badge badge-success"
                                      htmlFor={"customCheckBox" + ress.id_lot}
                                      style={{ fontSize: '2em' }}>
                                      Lot {ress.lot} biss
                                    </label>
                                      )
                                    }
                                  })
                                ()}
                    </div>
                  ))}
                </div>

                <hr />
                <table id="example" className="table display min-w850">
                        <thead>
                          <tr>
                            <th style={{color:'blue'}}>Objet</th>
                            <th style={{color:'blue'}}>Montant</th>
                            <th style={{color:'blue'}}>Autre infos</th>
                          </tr>
                        </thead>
                        <tbody>
              {function () {
                            let paid = []
                            for (let i = parseInt(1); i < parseInt(5) + 1; i++) {
                              paid.push(

                     <tr key={i}>
                        
                        <td>
                        <select className="form-control wide" onChange={(e) => handleComp(e)} name={"objet"+i}>
                          <option value="">Selection</option>
                          <option value="FRAIS D'OUVERTURE DE DOSSIER">FRAIS D'OUVERTURE DE DOSSIER</option>
                          <option value="FRAIS DOSSIER TECHNIQUE">FRAIS DOSSIER TECHNIQUE</option>
                          <option value="FRAIS DE COMPULSOIRE">FRAIS DE COMPULSOIRE</option>
                          <option value="FRAIS D'INSCRIPTION DANS LE GUIDE">FRAIS D'INSCRIPTION DANS LE GUIDE</option>
                          <option value="FRAIS ACD">FRAIS ACD</option>
                        </select>
                        </td>

                        <td>
                        <input
                            type="number"
                            className="form-control"
                            value={datacomp.montant}
                            onChange={(e) => handleComp(e)}
                            name={"montant"+i}
                            placeholder="Montant"
                          />
                      </td>

                      <td>
                        <input
                            type="text"
                            className="form-control"
                            value={datacomp.desc}
                            onChange={(e) => handleComp(e)}
                            name={"desc"+i}
                            placeholder="autre information..."
                          />
                      </td>
                     
                    </tr>

                      )
                            }
                            return paid
                          }()
                          }
                 </tbody>
                      </table>
              </Modal.Body>
              <Modal.Footer>

                <Button onClick={handleClose} variant='secondary'>
                  Fermer
                </Button>
                <Button type="submit" className="btn btn-primary">
                  Effectuer
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>






                                <div className="row">
                                    <div className="col-12">
                                       
                                    <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Datatable</h4>
                                     {/* <button 
                                type="button" 
                                className="btn btn-outline-warning mb-2" 
                                onClick={() => PrintList()}>
                                <i className="fa fa-print"></i> Etat Annexe
                                </button>*/}

                                <button 
                                type="button" 
                                className="btn btn-primary mb-2" 
                                onClick={() => PaiementModal()}>
                                Effectuer un Paiement
                                </button>

                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <DataTableExtensions
                                            columns={columns}
                                            data={dataPaiement}
                                            export={false}
                                            print={false}
                                        >
                                            <DataTable
                                                title="DataTable"
                                                // customStyles={customStyles}
                                                responsive
                                                noHeader
                                                defaultSortFieldId={1}
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                // selectableRows
                                                persistTableHead
                                                highlightOnHover
                                                exportHeaders={false}
                                            />
                                        </DataTableExtensions>
                                    </div>
                                </div>
                            </div>
                        
                                    </div>
                                 
                                </div>
                    </div>
        	  </div>
                {/* Content body end */}

        </div>
        </>

        );
}
export default PaiementComp;