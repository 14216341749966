import React from 'react';

const appFooter = () => {
    return (
        <div className="footer">
            <div className="copyright">
                <p>Copyright © Designed &amp; Developed by <a href="https://www.doubleclic-tech.com/" target="_blank" rel="noreferrer">Doubleclic Tech</a> 2022</p>
            </div>
        </div>

    );
};

export default appFooter;