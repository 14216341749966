import axios from '../axios/axios';

const getAll = () => {
  return axios.get("/visite");
};
const get = id => {
  return axios.get(`/visite/${id}`);
};
const create = data => {
  return axios.post("/visite", data);
};
const update = (id, data) => {
  return axios.post(`/visite/${id}`, data);
};
const remove = id => {
  return axios.delete(`/visite/${id}`);
};
const removeAll = () => {
  return axios.delete(`/visite`);
};
const verification = code => {
  return axios.get(`/operation_client/${code}`);
};

const update_contrat = data => {
  return axios.post(`/operation_client_update`, data);
};

const ContratAcquereurCRUD = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  verification,
  update_contrat,
};
export default ContratAcquereurCRUD;