import axios from "axios";
import { useState } from 'react';
// import { useNavigate } from "react-router-dom";

export default function AuhtUser() {
    // const navigate = useNavigate()

    const getToken = () => {
        const tokenString = sessionStorage.getItem('token')
        try {
            const userToken = JSON.parse(tokenString)
            return userToken
        } catch (err) {
            return err.message
        }
    }

    
    const [token, setToken] = useState(getToken())

    const saveToken = (token) => {
        sessionStorage.setItem('token', JSON.stringify(token))

        setToken(token)
        // location.reload('/Dash')
        // navigate('/Dash')
        // props.history.push('/Dash')
        window.location.replace('/Dash')
    }

    const logout = () => {
        sessionStorage.clear()
        // navigate('/')
        window.location.replace('/')
    }

    const http = axios.create({
        baseURL: "https://alback.doubleclic-tech.com/api",
        headers: {
            "Content-type": "application/json",
            // "Authorization": "Bearer ${token}",
            "X-CSRF-TOKEN": "token.content"
        }
    });
    return {
        setToken: saveToken,
        // setEmail: saveToken,
        token,
        getToken,
        http,
        logout
    }
}