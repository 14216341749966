 import logo from '../logo-al-sanogo.png';
 // import userProfile from '../17.jpg';
import { NavLink, Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import AuhtUser from '../services/AuthUser';
import UserCRUD from '../services/UserCRUD';
import Swal from 'sweetalert2';


function Navbar() {
  //const { http } = AuhtUser()


  const LinkDash = () => {
        window.location.replace('/Dash');
    }

  const [name, setName] = useState()
  const [role, setRole] = useState()
  const { token, logout } = AuhtUser()
  const [statut, setStatut] = useState()
 const [photo, setPhoto] = useState()

  useEffect(() => {
    fetchUserDetail()
    getAllInfos(statut)
  }, [statut])

  const fetchUserDetail = () => {
      setStatut(JSON.parse(sessionStorage.getItem('token')))
  }


  const logoutUser = () => {

     Swal.fire({
      title: 'Deconnectez la session ?',
      html: "Vous serrez Deconnecté",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui'
    }).then((result) => {
      if (result.isConfirmed) {
        if (token !== undefined) {
          logout()
        }

      }
    })

    
  }


    const getAllInfos = () => {

      // http.get('/login/', {statut: statut})
       UserCRUD.get(statut)
      .then((res) => {
        // console.warn(res)
        console.log(res.data)
        // console.log(statut)

        setName(res.data[0].name)
        setRole(res.data[0].role)
        setPhoto(res.data[0].photo)

      })
      .catch((error) => {
        console.warn(error)
      })
    }


 const LinkProfile = () => {
        window.location.replace('/Profile');
    }

    return (
        <div id="">
             <div className="nav-header">
            <NavLink to="/Dash" className="brand-logo" >
                <img className="" style={{marginLeft: '80px'}} id="logo" src={logo} onClick={()=> LinkDash()} alt="" />
                
            </NavLink>

            {/*<div className="nav-control">
                <div className="hamburger">
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                </div>
            </div>*/}
        </div>

        <div className="header">
            <div className="header-content">
                <nav className="navbar navbar-expand">
                    <div className="collapse navbar-collapse justify-content-between">
                        <div className="header-left">
                            
                        </div>

                        <ul className="navbar-nav header-right">
              
            
              
                            <li className="nav-item dropdown header-profile">
                                <Link className="nav-link" to="#" role="button" data-bs-toggle="dropdown">
                  <div className="header-info">
                    <span className="text-black">{name}</span>
                    <p className="fs-12 mb-0">{role}</p>
                  </div>
                                    
                                    
                                     {(() => {
                                                            if (photo) {
                                                              return (
                                                                <img 
                                                                src={"https://alback.doubleclic-tech.com/lotissement/storage/app/public/"+photo}
                                                                width="20"  
                                                                alt="" 
                                                                />
                                                              )
                                                           
                                                            } else {
                                                              return (
                                                                <>
                                                                 <i className="fa fa-user-circle fa-3x"></i>
                                                                 </>

                                                              )
                                                            }
                                                          })
                                                        ()}
                                </Link>
                                <div className="dropdown-menu dropdown-menu-end">
                                    <Link to="/Profile" className="dropdown-item ai-icon">
                                        <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                        <span className="ms-2" onClick={LinkProfile}>Profile </span>
                                    </Link>
                                    {/*<Link to="email-inbox.html" className="dropdown-item ai-icon">
                                        <svg id="icon-inbox" xmlns="http://www.w3.org/2000/svg" className="text-success" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                        <span className="ms-2">Inbox </span>
                                    </Link>*/}
                                    <Link to="#" className="dropdown-item ai-icon">
                                        <svg id="icon-logout" xmlns="http://www.w3.org/2000/svg" className="text-danger" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                                        <span role="button" className="ms-2" onClick={logoutUser}>Deconnexion </span>
                                    </Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>


        </div>
      
    );
}

export default Navbar;
