import Navbar from '../components/Navbar';
import Siderbar from '../components/Siderbar';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import VentesCRUD from '../services/VentesCRUD';
import Swal from 'sweetalert2';

function Ventes() {
  useEffect(() => {
    ListVente();
  }, []);

  const [getVente, setGetVente] = useState([])

  function ListVente() {
    VentesCRUD.AllDesitements()
      .then((response) => {
        console.log(response.data);
        setGetVente(response.data)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const columns = [
   {
      name: "Date",
      cell: (row) => (
        <b style={{ color: "black" }}> {row.date_desiste} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Vente",
      cell: (row) => (
        <b style={{ color: "black" }}> {row.code_vente} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Nom & Prenom",
      cell: (row) => (
        <b style={{ color: "black" }}> {row.nom_acq} {row.prenom_acq} </b>
      ),
      sortable: true,
      reorder: true
    },
    // {
    //   name: "Montant LOTS",
    //   cell: (row) => (
    //     <b style={{ color: "black" }}> {row.total_pay} </b>
    //   ),
    //   sortable: true,
    //   reorder: true
    // },
   
    {
      name: "Commentaire",
      cell: (row) => (
        <b style={{ color: "black" }}> {row.commentaire} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <div className="d-flex">
            <button className="btn btn-info btn-xs me-2" onClick={() => viewVente(row.code_vente)}><i className="fa fa-eye"></i></button>
            {/*<button className="btn btn-outline-primary btn-xs  me-2" onClick={() => DesistementVente(row.code_vente)}><i className="flaticon-plugin"></i></button>*/}
            <button className="btn btn-danger btn-xs" onClick={() => deleteVente(row.code_vente)}><i className="fa fa-trash"></i></button>
          </div>
        </div>
      ),
      width: "200px",                       // added line here
      headerStyle: (selector, id) => {
        return { textAlign: "center" };   // removed partial line here
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ]

  const [showModalVente, setShowModalVente] = useState(false);
  const [detailVente, setDetailVente] = useState([]);
  const [codeVente, setCodeVente] = useState([]);

  const viewVente = (id) => {
    setShowModalVente(true)
    setCodeVente(id)
    VentesCRUD.GetDesitements(id)
      .then((res) => {
        setDetailVente(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const columnsLot = [
    {
      name: "Lotissement",
      cell: (rows) => (
        <b style={{ color: "black" }}> {rows.libelle_loti} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "Ilot",
      cell: (rows) => (
        <b style={{ color: "black" }}> {rows.num_ilot} </b>
      ),
      sortable: true,
      reorder: true
    },
    {
      name: "N°Lot",
      cell: (rows) => (
        <b style={{ color: "black" }}>
        {(() => {
            if (rows.remorcele === '0') {
              return (
                <>{rows.lot}</>
              )
                                                                   
            } else {
              return (
                <>{rows.lot} bis </>
              )
            }
        })
        ()}
         

         </b>
      ),
      sortable: true,
      reorder: true
    },

    {
      name: "P.U",
      cell: (rows) => (
        <b style={{ color: "black" }}> {rows.prix_vendu} </b>
      ),
      sortable: true,
      reorder: true
    },

    
  ]

  const handleCloseModalVente = () => setShowModalVente(false)

  // const DesistementVente = (code) => {
  //   Swal.fire({
  //     title: 'Voulez-vous Rétablir la vente ?',
  //     html: "La vente sera rétablir avec les lots",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Oui, je retablis!'
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       VentesCRUD.DesitementsRestore(code)
  //         .then(response => {
  //           console.log(response.data);
  //           ListVente()
  //           Swal.fire(
  //             'Restoration éffectué!',
  //             'La vente a été rétablli.',
  //             'success'
  //           )
  //         })
  //         .catch(e => {
  //           console.log(e);
  //         });
  //     }
  //   })
  // };

  /***** DELETE DATA *******/

  const deleteVente = (code) => {
    Swal.fire({
      title: 'Es-tu sûr?',
      html: "Vous ne pourrez pas revenir en arrière! <br> La vente sera supprimer et les lots qui lui sont affilié seront disponible",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprime-le!'
    }).then((result) => {
      if (result.isConfirmed) {
        VentesCRUD.remove(code)
          .then(response => {
            console.log(response.data);
            ListVente()
            Swal.fire(
              'Supprimé!',
              'Votre fichier a été supprimé.',
              'success'
            )
          })
          .catch(e => {
            console.log(e);
          });

      }
    })
  };

  return (
    <div className="">
      <Navbar />
      <Siderbar />

      <div className="content-body">
        <div className="container-fluid">
          <div className="page-titles">
            <ol className="breadcrumb">
              <h1><b style={{ color: "red" }}>Desistements</b></h1>
            </ol>
          </div>

          <Modal show={showModalVente} onHide={handleCloseModalVente} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>
                <h1>LOTS DESISTES ({codeVente})</h1>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="table-responsive">
                <DataTableExtensions
                  columns={columnsLot}
                  data={detailVente}
                  export={false}
                  print={false}
                >
                  <DataTable
                    title="DataTable"
                    responsive
                    noHeader
                    defaultSortFieldId={1}
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    // selectableRows
                    persistTableHead
                    highlightOnHover
                    exportHeaders={false}

                  />
                </DataTableExtensions>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleCloseModalVente} variant='secondary'>
                Fermer
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Datatable</h4>

                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <DataTableExtensions
                      columns={columns}
                      data={getVente}
                      export={false}
                      print={false}
                    >
                      <DataTable
                        title="DataTable"
                        // customStyles={customStyles}
                        responsive
                        noHeader
                        defaultSortFieldId={1}
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        // selectableRows
                        persistTableHead
                        highlightOnHover
                        exportHeaders={false}
                      />
                    </DataTableExtensions>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ventes;
